import React, { useMemo } from "react";
import { BankAccountSummary, EwalletAccountSummary } from ".";
import { BankAccount, EwalletAccount } from "actions";
import { ReturnItem } from "types";

export const checkRefundMethodAvailability = (returnItems: ReturnItem[], refundMethodName: string): boolean => {
  return returnItems.some((item) => item.returnMethod.name === refundMethodName);
};

interface ReceivablePaymentDetailsSummaryProps {
  returnItems: ReturnItem[];
  bankAccount: BankAccount;
  ewalletAccount: EwalletAccount;
}

export const ReceivablePaymentDetailsSummary: React.FC<ReceivablePaymentDetailsSummaryProps> = ({
  returnItems,
  bankAccount,
  ewalletAccount,
}) => {
  const hasOnlineBankTransfer = useMemo(
    () => checkRefundMethodAvailability(returnItems, "online_bank_transfer"),
    [returnItems],
  );
  const hasEwalletTransfer = useMemo(
    () => checkRefundMethodAvailability(returnItems, "ewallet_transfer"),
    [returnItems],
  );

  if (!hasOnlineBankTransfer && !hasEwalletTransfer) {
    return null;
  }

  return (
    <React.Fragment>
      {hasOnlineBankTransfer && bankAccount && <BankAccountSummary bankAccount={bankAccount} />}
      {hasEwalletTransfer && ewalletAccount && <EwalletAccountSummary ewalletAccount={ewalletAccount} />}
    </React.Fragment>
  );
};
