import { ActionTypes } from "./types";
import { Variant } from "./formModal";
import { FromAddress } from "./order";
import { ReturnReason } from "actions";
import { ShippingMethod, Question } from "types";

export interface ReturnMethod {
  id: string;
  shopId: number;
  globalId: string;
  returnMethodId: number;
  enabled: boolean;
  name: string;
  displayName: string;
  hasCustomName: boolean;
  description: string;
  type: string;
}

export interface BonusCreditInfo {
  exchangeBonusCreditMaxValuePerOrder: number | null;
  storeCreditBonusCreditMaxValuePerOrder: number | null;
}

export interface RefundMethod extends ReturnMethod {}
export interface ExchangeMethod extends ReturnMethod {}

export interface AppendCurrentReturnItemAction {
  type: ActionTypes.appendCurrentReturnItem;
}

export interface ClearCurrentReturnItemAction {
  type: ActionTypes.clearCurrentReturnItem;
}

export interface AddReturnItemsAction {
  type: ActionTypes.addReturnItems;
  payload: ReturnItem[];
}

export interface AddBonusCreditInfoAction {
  type: ActionTypes.addBonusCreditInfo;
  payload: {
    exchangeBonusCreditMaxValuePerOrder: number | null;
    storeCreditBonusCreditMaxValuePerOrder: number | null;
  };
}

export interface RemoveReturnItemFromCartAction {
  type: ActionTypes.removeReturnItemFromCart;
  payload: string;
}

export interface SetInstantExchangeAction {
  type: ActionTypes.setInstantExchange;
  payload: boolean;
}

export interface SetP2PReturnsAction {
  type: ActionTypes.setP2PReturns;
  payload: boolean;
}

export interface SetShippingMethodAction {
  type: ActionTypes.setShippingMethod;
  payload: ShippingMethod;
}

export interface SetCustomerBorneShippingAction {
  type: ActionTypes.setCustomerBorneShipping;
  payload: boolean;
}

export interface SetFromAddressAction {
  type: ActionTypes.setFromAddress;
  payload: FromAddress | null;
}

export interface SetQuestionsAction {
  type: ActionTypes.setQuestions;
  payload: Question[];
}

type SelectedProduct = {
  id: number | null;
  title: string;
  image: {
    src: string;
  };
};

export type UploadedImage = {
  id: string | null;
  uppyFileId: string;
  storage: string;
  metadata: {
    size: number;
    filename: string;
    mime_type?: string;
  };
};

export interface ReturnItem {
  id?: number;
  bundleItemId: number | null;
  bonusCreditAmountCents: number;
  uuid: string;
  currentProductPageInfo: string;
  discountAmount: number;
  exchangeForOtherProductDetail?: string;
  exchangeItemName?: string;
  extraNote: string;
  fulfillmentStatus: string | null;
  images?: UploadedImage[];
  lineItemId: string;
  price: string;
  productId: number | null;
  productTitle: string;
  questions: Question[];
  rejectReason: string;
  status: string;
  returnItemImageUrl: string;
  returnItemName: string;
  returnItemPrice: number;
  returnMethod: ReturnMethod;
  returnMethodType: string;
  returnReason: ReturnReason;
  selectedCollectionId: string;
  selectedProduct: SelectedProduct;
  shopPrice: number;
  sku: string;
  taxPrice: number;
  exchangingVariant?: Variant;
  variantTitle: string;
  variantId: number | null;
  variantPrice: string;
  vendor: string;
  taxRate: number;
}

export interface ReturnItems {
  shippingMethod: ShippingMethod;
  customerBorneShipping: boolean;
  fromAddress: FromAddress | null;
  currentReturnItem: ReturnItem;
  cart: ReturnItem[];
  isDirty: boolean;
  questions: Question[];
  bonusCreditInfo: BonusCreditInfo;
  instantExchange: boolean;
  instantExchangeSet: boolean;
  p2pReturns: boolean;
}

type propertyType =
  | "bundleItemId"
  | "currentProductPageInfo"
  | "discountAmount"
  | "exchangeForOtherProductDetail"
  | "extraNote"
  | "fulfillmentStatus"
  | "images"
  | "lineItemId"
  | "price"
  | "productId"
  | "productTitle"
  | "questions"
  | "returnItemImageUrl"
  | "returnItemName"
  | "returnItemPrice"
  | "returnMethod"
  | "returnMethodType"
  | "returnReason"
  | "selectedCollectionId"
  | "selectedProduct"
  | "sku"
  | "shopPrice"
  | "taxPrice"
  | "uuid"
  | "exchangingVariant"
  | "variantId"
  | "variantTitle"
  | "variantPrice"
  | "vendor"
  | "bonusCreditAmountCents"
  | "taxRate";

export interface SetCurrentReturnItemPropertyAction {
  type: ActionTypes.setCurrentReturnItemProperty;
  payload: {
    property: propertyType;
    value:
      | string
      | number
      | Variant
      | ReturnReason
      | ReturnMethod
      | SelectedProduct
      | string[]
      | Question[]
      | UploadedImage[]
      | null;
  };
}

export interface SetReturnItemDirtyAction {
  type: ActionTypes.setReturnItemDirty;
}

export const appendCurrentReturnItem = (): AppendCurrentReturnItemAction => {
  return {
    type: ActionTypes.appendCurrentReturnItem,
  };
};

export const clearCurrentReturnItem = (): ClearCurrentReturnItemAction => {
  return {
    type: ActionTypes.clearCurrentReturnItem,
  };
};

export const addReturnItems = (returnItems: ReturnItem[]): AddReturnItemsAction => {
  return {
    type: ActionTypes.addReturnItems,
    payload: returnItems,
  };
};

export const addBonusCreditInfo = (maxBonusCredit: BonusCreditInfo): AddBonusCreditInfoAction => {
  return {
    type: ActionTypes.addBonusCreditInfo,
    payload: maxBonusCredit,
  };
};

export const removeReturnItemFromCart = (lineItemId: string): RemoveReturnItemFromCartAction => {
  return {
    type: ActionTypes.removeReturnItemFromCart,
    payload: lineItemId,
  };
};

// add optional  image property
export const setCurrentReturnItemProperty = (
  property: propertyType,
  value:
    | string
    | number
    | Variant
    | ReturnReason
    | ReturnMethod
    | SelectedProduct
    | Question[]
    | UploadedImage[]
    | null,
): SetCurrentReturnItemPropertyAction => {
  return {
    type: ActionTypes.setCurrentReturnItemProperty,
    payload: {
      property,
      value,
    },
  };
};

export const setInstantExchange = (instantExchange: boolean): SetInstantExchangeAction => {
  return { type: ActionTypes.setInstantExchange, payload: instantExchange };
};

export const setP2PReturns = (p2pReturns: boolean): SetP2PReturnsAction => {
  return { type: ActionTypes.setP2PReturns, payload: p2pReturns };
};

export const setReturnItemDirty = (): SetReturnItemDirtyAction => {
  return { type: ActionTypes.setReturnItemDirty };
};

export const setShippingMethod = (shippingMethod: ShippingMethod): SetShippingMethodAction => {
  return {
    type: ActionTypes.setShippingMethod,
    payload: shippingMethod,
  };
};

export const setCustomerBorneShipping = (customerBorneShipping: boolean): SetCustomerBorneShippingAction => {
  return {
    type: ActionTypes.setCustomerBorneShipping,
    payload: customerBorneShipping,
  };
};

export const setFromAddress = (address: FromAddress | null): SetFromAddressAction => {
  return {
    type: ActionTypes.setFromAddress,
    payload: address,
  };
};

export const setQuestions = (questions: Question[]): SetQuestionsAction => {
  return {
    type: ActionTypes.setQuestions,
    payload: questions,
  };
};
