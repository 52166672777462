import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Form, Message } from "semantic-ui-react";
import { Button } from "components";
import * as Sentry from "@sentry/react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { usePaymentPageContext } from "../PaymentPageProvider";

export const PaymentIntentForm: React.FC<any> = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { returnId: returnOrderId } = useParams<{ returnId: string }>();
  const { usePaymentIntent, onSuccessPaymentAction } = usePaymentPageContext();
  const { isLoading } = usePaymentIntent();

  const { t } = useTranslation("orderFlow");
  const history = useHistory();
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);

  const clearAndSetError = (message: string) => {
    if (elements) {
      elements.getElement("payment")?.update({ readOnly: false });
      elements.getElement("payment")?.clear();
      setErrorMessage(`${message}.`);
    }
  };

  const handleSubmit = async () => {
    if (!stripe || !elements || isLoading) {
      return;
    }

    setIsSubmitting(true);
    setErrorMessage("");

    elements.getElement("payment")?.update({ readOnly: true });

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (paymentIntent && paymentIntent.status === "succeeded") {
      try {
        await onSuccessPaymentAction(paymentIntent.id);
        history.push(`/${shopSlug}/returns/${returnOrderId}?redirectFromExchangePaymentPage=true`);
      } catch (error) {
        clearAndSetError(t(error.message));
      }
    } else if (error && error.message) {
      clearAndSetError(error.message);
      Sentry.captureException(error);
    } else {
      clearAndSetError(t("exchangePaymentPage.error"));
    }
    setIsSubmitting(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Message error visible={!!errorMessage}>
        {errorMessage}
      </Message>
      <PaymentElement />
      <br />
      <Button fluid color="primaryColor" type="submit" disabled={isSubmitting} loading={isSubmitting}>
        {t("exchangePaymentPage.submit")}
      </Button>
    </Form>
  );
};
