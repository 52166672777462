import React from "react";
import { Card, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { CardWithShadow } from "components";
import { ContactDetails, ShippingAddressSummary, ShopifyShippingAddressSummary } from "components";
import { ReturnItem, ShippingAddress, Shop } from "types";

interface ShippingAddressDetailsSummaryProps {
  shippingAddress: ShippingAddress;
  shop: Shop;
  returnItems: ReturnItem[];
}

const hasExchange = (returnItems: ReturnItem[]): boolean => {
  return returnItems.some((returnItem: ReturnItem) => returnItem.returnMethod.type === "Exchange");
};

const getAddressFormHeader = (
  autoHideValidShippingAddress: boolean,
  returnItems: ReturnItem[],
  t: Function,
): string => {
  if (autoHideValidShippingAddress || !hasExchange(returnItems)) {
    return t("addressForm.senderAddressHeader");
  } else {
    return t("addressForm.exchangeShippingAddressHeader");
  }
};

export const ShippingAddressDetailsSummary: React.FC<ShippingAddressDetailsSummaryProps> = ({
  shippingAddress,
  shop,
  returnItems,
}) => {
  const { t } = useTranslation("orderFlow");
  const addressHeader = getAddressFormHeader(shop.autoHideValidShippingAddress, returnItems, t);
  const isShopify = shop.ecomProvider === "Shopify";

  return (
    <CardWithShadow>
      <Card.Content>
        <Card.Header style={{ paddingBottom: "1rem", display: "flex", justifyContent: "space-between" }}>
          <span>{addressHeader}</span>
        </Card.Header>
        <Grid columns={2}>
          <Grid.Column>
            <Card.Header>
              <span style={{ textDecoration: "underline" }}>{t("addressForm.address")}</span>
              {isShopify ? (
                <ShopifyShippingAddressSummary shippingAddress={shippingAddress} />
              ) : (
                <ShippingAddressSummary shippingAddress={shippingAddress} />
              )}
            </Card.Header>
          </Grid.Column>
          <ContactDetails shippingAddress={shippingAddress} />
        </Grid>
      </Card.Content>
    </CardWithShadow>
  );
};
