import { appendCurrentReturnItem, closeFormModal, ReturnMethod, setCurrentReturnItemProperty } from "actions";
import { Button as CustomButton, PageHeader } from "components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import styles from "./ReturnMethodsSelector.module.css";

interface Props {
  refundMethods: ReturnMethod[];
}

const listingPriorities: Record<string, number> = {
  original_payment_method: 0,
  online_bank_transfer: 1,
  ewallet_transfer: 2,
};

export const RefundMethodsSelector = ({ refundMethods }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("orderFlow");

  const sortedRefundMethods = refundMethods.sort((a: ReturnMethod, b: ReturnMethod) => {
    return listingPriorities[a.name] - listingPriorities[b.name];
  });
  const [selectedRefundMethod, setSelectedRefundMethod] = useState<ReturnMethod | null>(sortedRefundMethods[0]);

  function handleContinue() {
    dispatch(setCurrentReturnItemProperty("returnMethod", selectedRefundMethod));
    dispatch(appendCurrentReturnItem());
    dispatch(closeFormModal());
  }

  if (sortedRefundMethods.length === 1) {
    handleContinue();
  }

  return (
    <div>
      <PageHeader header={t("selectRefundMethod") as string} />
      {sortedRefundMethods.map((refundMethod) => {
        const selected = selectedRefundMethod?.id === refundMethod.id;
        return (
          <div className={styles.buttonContainer} key={refundMethod.id}>
            <Button
              basic
              color={selected ? "green" : undefined}
              fluid
              onClick={() => setSelectedRefundMethod(refundMethod)}
            >
              <div className={styles.buttonContent}>
                <div className={styles.descriptionContainer}>
                  <div className={styles.textBox}>
                    <div className={styles.titleContainer}>
                      <strong>{refundMethod.displayName}</strong>
                    </div>
                    <p>{refundMethod.description}</p>
                  </div>
                </div>
                <Icon
                  color="green"
                  name="check circle"
                  style={{
                    visibility: selected ? "visible" : "hidden",
                  }}
                />
              </div>
            </Button>
          </div>
        );
      })}
      <div className={styles.buttonContainer}>
        <CustomButton color="primaryColor" disabled={!selectedRefundMethod} fluid onClick={() => handleContinue()}>
          {t("continue")}
        </CustomButton>
      </div>
    </div>
  );
};
