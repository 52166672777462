import { ReturnSteps } from "actions";

export const getRoutes = (shopSlug: string, returnOrderId: null | string = null): Record<number, string> => {
  return {
    [ReturnSteps.customerInfo]: `/${shopSlug}`,
    [ReturnSteps.stageOneQuestions]: `/${shopSlug}/returns/${returnOrderId}/stage_one_questions`,
    [ReturnSteps.returnItems]: `/${shopSlug}/returns/${returnOrderId}/return_items_selection`,
    [ReturnSteps.shippingMethod]: `/${shopSlug}/returns/${returnOrderId}/shipping_method_selection`,
    [ReturnSteps.shippingAddress]: `/${shopSlug}/returns/${returnOrderId}/shipping_address_details`,
    [ReturnSteps.receivablePaymentDetails]: `/${shopSlug}/returns/${returnOrderId}/receivable_payment_details`,
    [ReturnSteps.summaryAndPaymentDetails]: `/${shopSlug}/returns/${returnOrderId}/summary_and_payment_details`,
    [ReturnSteps.submitted]: `/${shopSlug}/returns/${returnOrderId}/submitted`,
    [ReturnSteps.success]: `/${shopSlug}/returns/${returnOrderId}`,
  };
};
