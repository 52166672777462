import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { fetchOrder } from "api";
import { addOrder, setError } from "actions";

export const useOrder = (returnOrderId: string, options = {}) => {
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const dispatch = useDispatch();

  const query = useQuery({
    queryKey: ["order", returnOrderId],
    queryFn: () => fetchOrder(shopSlug, returnOrderId),
    onSuccess: (order) => {
      dispatch(addOrder(order));
    },
    onError: (error) => {
      if (error instanceof Error) dispatch(setError(error));
    },
    // need to refetch order info for returnability validation purpose
    // order line items' quantity will change if the item(s) is/are returned/refunded on ecom platform
    // set staleTime to 10 minutes since we don't want to refetch too often
    staleTime: 60000 * 10, // 10 minutes
    refetchInterval: 60000 * 10, // 10 minutes
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    ...options,
  });

  return query;
};
