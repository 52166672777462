import { ActionTypes } from "./types";
import { LineItem } from ".";

export interface CloseFormModalAction {
  type: ActionTypes.closeFormModal;
}

export interface FormModal {
  isOpen: boolean;
  step: FormModalSteps;
  selectedLineItem: LineItem | null;
  stepsHistory: number[];
}

export interface FormModalPushAction {
  type: ActionTypes.formModalPush;
  payload: {
    step: number;
    appendStepHistory: boolean;
  };
}

export interface FormModalGoBackAction {
  type: ActionTypes.formModalGoBack;
}

export enum FormModalSteps {
  stageTwoQuestions,
  reasons,
  extraInformation,
  stageThreeQuestions,
  returnMethodTypes,
  returnMethodsSelector,
  exchangeProductsSelector,
  variantsSelector,
  otherProductForm,
}

export interface OpenFormModalAction {
  type: ActionTypes.openFormModal;
}

export interface ResumeFormModalAction {
  type: ActionTypes.resumeFormModal;
}

export interface SetLineItemInFormModalAction {
  type: ActionTypes.setLineItemInFormModal;
  payload: LineItem;
}

export interface Variant {
  id: string;
  exchangeableByInventory: boolean;
  exchangeableByPrice: boolean;
  exchangeableByAvailability: boolean;
  displayPrice: { cents: number; currencyIso: string };
  grams: number;
  imageUrl: string;
  inventoryPolicy: string;
  inventoryQuantity: number;
  notExchangeableByInventoryMessage: string;
  notExchangeableByPriceMessage: string;
  presentmentPriceWithTax: { cents: number; currencyIso: string };
  taxRate: number;
  option1: string | null;
  option2: string | null;
  option3: string | null;
  price: string;
  shopPriceCents: number;
  title: string | null;
}

export const closeFormModal = (): CloseFormModalAction => {
  return {
    type: ActionTypes.closeFormModal,
  };
};

export const formModalPush = (step: number, appendStepHistory = true): FormModalPushAction => {
  return {
    type: ActionTypes.formModalPush,
    payload: {
      step,
      appendStepHistory,
    },
  };
};

export const formModalGoBack = (): FormModalGoBackAction => {
  return {
    type: ActionTypes.formModalGoBack,
  };
};

export const nullVariant: Variant = {
  id: "",
  exchangeableByInventory: true,
  exchangeableByPrice: true,
  exchangeableByAvailability: true,
  displayPrice: { cents: 0, currencyIso: "" },
  grams: 0,
  imageUrl: "",
  inventoryPolicy: "deny",
  inventoryQuantity: 0,
  notExchangeableByInventoryMessage: "",
  notExchangeableByPriceMessage: "",
  presentmentPriceWithTax: { cents: 0, currencyIso: "" },
  taxRate: 0,
  option1: null,
  option2: null,
  option3: null,
  price: "0.00",
  shopPriceCents: 0,
  title: "",
};

export const openFormModal = (): OpenFormModalAction => {
  return {
    type: ActionTypes.openFormModal,
  };
};

export const resumeFormModal = (): ResumeFormModalAction => {
  return {
    type: ActionTypes.resumeFormModal,
  };
};

export const setLineItemInFormModal = (lineItem: LineItem): SetLineItemInFormModalAction => {
  return {
    type: ActionTypes.setLineItemInFormModal,
    payload: lineItem,
  };
};
