import { Courier } from "actions";
import { ShippingMethod } from "types";

export const nullCourier: Courier = {
  id: "0",
  name: "",
  country: "",
  onDemand: false,
  weekend: false,
  holiday: false,
  startTime: "",
  cutoffTime: "",
  supportedPostcodes: [],
  sameDayDelivery: false,
  hourRange: 0,
  noticeHour: 0,
  allowCustomTrackingNumber: false,
  pickup: false,
  dropOff: false,
  dropOffLocationsUrl: "",
  logoUrl: "",
};

export const nullShippingMethod: ShippingMethod = {
  id: "",
  apiProvider: "",
  availableForCustomerBorne: true,
  badge: "",
  courier: nullCourier,
  customerBorne: false,
  customerMarkup: 0,
  description: "",
  dropOffLocationsUrl: "",
  enabled: false,
  fixedPrice: false,
  logoUrl: "",
  name: "",
  priceCents: 0,
  priceCurrency: "",
  requiresLabel: false,
  retailerMarkup: 0,
  rightLabel: "",
  serviceType: "OnDemandPickup",
  shopId: 0,
  storeListUrl: "",
  title: "",
  type: "IntegratedShippingMethod",
  uuid: "",
};
