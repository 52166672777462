import React from "react";
import { Icon } from "semantic-ui-react";
import { ReturnItemsSummaryGroup } from "./types";
import camelCase from "camelcase";

export function returnItemsGroupTitle(returnItemsGroup: ReturnItemsSummaryGroup, t: Function) {
  if (!returnItemsGroup.title) return null;

  if (returnItemsGroup.title.type === "require_payment") {
    return (
      <span style={{ color: "#C0123C" }}>
        <Icon name="exclamation circle" color="red" />
        {t("orderSummary.returnItemsGroupTitle.requirePayment", {
          courier: returnItemsGroup.title.content.courier,
          amount: returnItemsGroup.title.content.amount,
        })}
      </span>
    );
  } else if (returnItemsGroup.title.type === "tracking_number") {
    return t("orderSummary.returnItemsGroupTitle.trackingNumber", {
      trackingNumber: returnItemsGroup.title.content.trackingNumber,
      courier: returnItemsGroup.title.content.courier,
    });
  } else {
    return t(`orderSummary.returnItemsGroupTitle.${camelCase(returnItemsGroup.title.type)}`);
  }
}
