import { Country } from "@shopify/address";
import { ShippingAddress } from "actions";
import { ShippingAddress as SummaryShippingAddress } from "types";

const IGNORED_FIELD = ["", "company", "firstName", "lastName", "phone"];

function rowLayoutToNames(rowStr: string) {
  return rowStr.split(/{|}/).filter((attr) => !IGNORED_FIELD.includes(attr));
}

export function getFieldsLayout(country: Country) {
  // NOTE: Shopify represents formatting fields as:
  // "{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{country}{province}{zip}_{phone}"
  // This function returns: [["address1"],["address2"],["city"],["province","zip"]]
  return country.formatting.show
    .split("_")
    .map(rowLayoutToNames)
    .filter((row) => row.length > 0);
}

export function getAddressFieldValue(name: string, shippingAddress: ShippingAddress | SummaryShippingAddress) {
  switch (name) {
    case "country":
      return shippingAddress.country;
    case "address1":
      return shippingAddress.address1;
    case "address2":
      return shippingAddress.address2;
    case "zip":
      return shippingAddress.zip;
    case "city":
      return shippingAddress.city;
    case "province":
      return shippingAddress.province;
  }
}
