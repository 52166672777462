import React from "react";
import { useContext, createContext } from "react";
import { useInstantExchangePaymentIntent, useRegularExchangePaymentIntent } from "./hooks";
import { updateInstantExchangePaymentTransaction, updateExchangeOrder } from "./api";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

type ContextProps = {
  usePaymentIntent: Function;
  onSuccessPaymentAction: Function;
};

const PaymentPageContext = createContext<ContextProps>({
  usePaymentIntent: () => {},
  onSuccessPaymentAction: () => {},
});

function PaymentPageProvider({ children }: any) {
  const instantExchange = useSelector((state: StoreState) => state.returnItems.instantExchange);
  const instantExchangeSet = useSelector((state: StoreState) => state.returnItems.instantExchangeSet);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const { exchangeOrderId } = useRegularExchangePaymentIntent({ enabled: !instantExchange && instantExchangeSet });

  const regularExchangeContextValues = {
    usePaymentIntent: useRegularExchangePaymentIntent,
    onSuccessPaymentAction: updateExchangeOrder(exchangeOrderId),
  };
  const instantExchangeContextValues = {
    usePaymentIntent: useInstantExchangePaymentIntent,
    onSuccessPaymentAction: updateInstantExchangePaymentTransaction(returnOrderId),
  };

  const values = instantExchange ? instantExchangeContextValues : regularExchangeContextValues;

  return <PaymentPageContext.Provider value={values}>{children}</PaymentPageContext.Provider>;
}

function usePaymentPageContext() {
  const context = useContext(PaymentPageContext);
  if (context === undefined) {
    throw new Error("usePaymentPageContext must be used within a PaymentPageProvider");
  }
  return context;
}

export { PaymentPageProvider, usePaymentPageContext };
