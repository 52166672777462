import React from "react";
import { isMobile } from "react-device-detect";

import { Modal } from "semantic-ui-react";
import { FullscreenContainer } from "./FullscreenContainer";

export const AdaptiveModal = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  if (isMobile) {
    return (
      <FullscreenContainer>
        <div {...props}></div>
      </FullscreenContainer>
    );
  } else {
    return (
      <Modal size="small" open dimmer="inverted" style={{ margin: 0, padding: 0 }}>
        <div {...props}></div>
      </Modal>
    );
  }
};
