import React from "react";
import { BankAccount } from "actions";
import { Card } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { CardWithShadow } from "components";

interface BankAccountSummaryProps {
  bankAccount: BankAccount;
}

export const BankAccountSummary: React.FC<BankAccountSummaryProps> = ({ bankAccount }) => {
  const { t } = useTranslation("orderFlow");
  const { bank, beneficiary, number } = bankAccount;

  return (
    <CardWithShadow>
      <Card.Content>
        <Card.Header style={{ paddingBottom: "1rem", display: "flex", justifyContent: "space-between" }}>
          <span>{t("orderSummary.bankDetails")}</span>
        </Card.Header>
        <Card.Description>
          {t("bankAccountField.bankName")}: {bank}
        </Card.Description>
        <Card.Description>
          {t("bankAccountField.accountName")}: {beneficiary}
        </Card.Description>
        <Card.Description>
          {t("bankAccountField.accountNumber")}: {number}
        </Card.Description>
      </Card.Content>
    </CardWithShadow>
  );
};
