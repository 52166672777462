import camelcaseKeys from "camelcase-keys";
import { PostCo360API } from "api/constants";
import qs from "qs";
import * as Sentry from "@sentry/react";

export type ExchangeableCollection = {
  id: string;
  title: string;
  productsCount: number;
};

export const getExchangeableCollections = async (
  productId: number | null,
  shopId: number | null,
): Promise<ExchangeableCollection[]> => {
  try {
    const params = {
      product_id: productId,
      filter: {
        shop_id: shopId,
      },
    };

    const res = await PostCo360API().get(`/api/v2/customer/exchangeable_collections?${qs.stringify(params)}`);

    return camelcaseKeys(res.data);
  } catch (error) {
    Sentry.captureException(error);
    throw new Error((error as Error).message);
  }
};
