import { ActionTypes } from "./types";

export interface ResetStoreAction {
  type: ActionTypes.resetStore;
}

export const resetStore = (): ResetStoreAction => {
  return {
    type: ActionTypes.resetStore,
  };
};
