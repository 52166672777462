import { useIsEmbedded } from "hooks/useIsEmbedded";
import React, { ReactNode, useEffect, useState } from "react";

interface Props {
  children?: ReactNode;
}

export const StickyBottomAppBar = ({ children }: Props) => {
  const { isEmbedded } = useIsEmbedded();

  const EmbeddedStickyBottomAppBar = () => {
    const [stickyBottomPosition, setStickyBottomPosition] = useState(0);

    useEffect(() => {
      window.top?.postMessage({ type: "stickyBottomAppBarReposition" }, "*");
    }, []);

    useEffect(() => {
      const onMessageHandler = (event: MessageEvent) => {
        if (typeof event.data !== "object" || !event.data) {
          return;
        }

        const { type, data } = event.data;
        if (type === "stickyBottomAppBarPosition") {
          setStickyBottomPosition(data);
        }
      };

      window.addEventListener("message", onMessageHandler);

      return () => window.removeEventListener("message", onMessageHandler);
    }, []);

    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: stickyBottomPosition,
          zIndex: 6,
          left: 0,
          right: 0,
          transitionTimingFunction: "linear",
        }}
      >
        {children}
      </div>
    );
  };

  const NonEmbeddedStickyBottomAppBar = () => (
    <div
      style={{
        paddingTop: 0,
        paddingBottom: 0,
        position: "sticky",
        bottom: 0,
        zIndex: 6,
      }}
    >
      {children}
    </div>
  );

  return isEmbedded ? <EmbeddedStickyBottomAppBar /> : <NonEmbeddedStickyBottomAppBar />;
};
