import { Action, ActionTypes, CurrentShop } from "actions";

const initialState: CurrentShop = {
  id: null,
  allowCustomTrackingNumber: false,
  allowExchange: true,
  allowRefund: true,
  autoHideValidShippingAddress: false,
  country: "",
  currency: "",
  customerSupportEmail: "",
  customTrackingNumberRegexes: "",
  discardedStripeAccountId: "",
  discardedStripeChargesEnabled: false,
  discardedStripeParentAccountId: "",
  ecomProvider: "",
  exchangeRequestDisclaimer: "",
  exchangeBalanceRefundMethod: "",
  exchangeSetting: {
    productImageObjectFit: "cover",
    hideCollectionProductsCount: true,
    sizeGuideUrl: "",
  },
  exchangeV2Enabled: false,
  hasPreprintedReturnLabel: false,
  instantExchangeEnabled: false,
  allowInstantExchangeOnly: false,
  minimumExchangeQuantity: 0,
  name: "",
  onlyReturnFromShippingCountry: false,
  returnPolicyLink: "",
  returnReasons: [
    {
      id: "0",
      extraNote: false,
      optionalExtraNote: false,
      globalId: "",
      imageUpload: false,
      optionalImageUpload: false,
      name: "",
    },
  ],
  collectPaymentMethodOnSubmit: false,
  shopDomain: "",
  shopLocales: [],
  shopSlug: "",
  shopUrl: "",
  stripeAccountId: "",
  stripeChargesEnabled: false,
  stripeParentAccountId: "",
  stripePendingOnboarding: false,
  userInterfaceSetting: {
    id: 0,
    customCss: "",
    orderEmailPhoneLabelTranslations: {},
    orderEmailPhonePlaceholderTranslations: {},
    orderIdLabelTranslations: {},
    orderIdPlaceholderTranslations: {},
    primaryColor: "",
    startButtonNameTranslations: {},
    primaryButtonTextColor: "",
    successPageContent: "",
    titleTranslations: {},
    returnReasonImageUploadLabelTranslations: {},
    returnReasonExtraNoteLabelTranslations: {},
    topUpRequiredMessageTranslations: {},
    standardExchangeTitleTranslations: {},
    standardExchangeDescriptionTranslations: {},
    bonusCreditExchangeTitleTranslations: {},
    bonusCreditExchangeDescriptionTranslations: {},
    standardStoreCreditTitleTranslations: {},
    standardStoreCreditDescriptionTranslations: {},
    bonusCreditStoreCreditTitleTranslations: {},
    bonusCreditStoreCreditDescriptionTranslations: {},
    refundTitleTranslations: {},
    refundDescriptionTranslations: {},
    hideOpmRefundAmount: false,
  },
  whiteLabel: false,
  returnItemsSelectionDisclaimer: "",
  p2pSetting: null,
  allowGiftReturns: false,
};

export const currentShopReducer = (state: CurrentShop = initialState, action: Action): CurrentShop => {
  switch (action.type) {
    case ActionTypes.setShopSlug:
      return {
        ...state,
        shopSlug: action.payload,
      };
    case ActionTypes.setCurrentShop:
      return action.payload;
    default:
      return state;
  }
};
