import React from "react";
import { useTranslation } from "react-i18next";
import { getFormattedPriceString } from "helpers";
import { Grid } from "semantic-ui-react";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { ExchangingLineItems } from "./ExchangingLineItems";
import { useIsEmbedded } from "hooks/useIsEmbedded";

export const ExchangeOrderSection: React.FC = (): JSX.Element => {
  const { instantExchangeExchangeOrderInvoice: invoice } = useInvoiceContext();
  const { extraLineItems, total, refundBreakdown, lineItems, chargeAdjustments, totalBeforeChargeAdjustment } = invoice;
  const { t } = useTranslation("orderFlow");
  const { isEmbedded } = useIsEmbedded();
  // If no charge adjustment, the total and totalBeforeChargeAdjustment will be the same
  const totalAmount = chargeAdjustments ? totalBeforeChargeAdjustment : total;

  const totalTitle = () => {
    if (total.cents >= 0) {
      return t("summary.totalToPay");
    } else {
      const refundMethod = refundBreakdown && refundBreakdown[0].refundMethod;
      const refundMethodName = refundMethod.split("_").join(" ");
      return t("summary.instantExchangeTotalRefund", { refundMethodName });
    }
  };

  return (
    <React.Fragment>
      <ExchangingLineItems lineItems={lineItems} />
      <Grid columns={2} style={{ padding: "8px 16px" }}>
        {extraLineItems.map(({ name, presentmentPrice, description }) => (
          <React.Fragment key={name}>
            <Grid.Column computer={12} style={{ paddingTop: "0.75rem", paddingBottom: "0.5rem" }}>
              <div>{name}</div>
              {description && (
                <span style={{ color: "gray", fontStyle: "italic", fontSize: "0.9rem" }}>{description}</span>
              )}
            </Grid.Column>
            <Grid.Column computer={4} textAlign="right" style={{ paddingTop: "0.75rem", paddingBottom: "0.5rem" }}>
              {getFormattedPriceString(presentmentPrice.cents, presentmentPrice.currencyIso, { useParenthesis: true })}
            </Grid.Column>
          </React.Fragment>
        ))}
        <Grid.Column computer={12} style={{ fontWeight: total.cents >= 0 ? "bold" : null }}>
          {totalTitle()}
        </Grid.Column>
        <Grid.Column
          computer={4}
          textAlign="right"
          style={{ fontWeight: total.cents >= 0 ? "bold" : null, paddingLeft: isEmbedded ? "1rem" : 0 }}
        >
          {getFormattedPriceString(Math.abs(totalAmount.cents), total.currencyIso)}
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};
