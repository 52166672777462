import { Action, ActionTypes } from "../actions";

const initialState: Error[] = [];

export const errorsReducer = (state: Error[] = initialState, action: Action): Error[] => {
  switch (action.type) {
    case ActionTypes.clearErrors:
      return initialState;
    case ActionTypes.setError:
      return [...state, action.payload];
    case ActionTypes.setErrors:
      return [...state, ...action.payload];
    case ActionTypes.resetStore:
      return initialState;
    default:
      return state;
  }
};
