import { AxiosResponse } from "axios";
import { FormValues, ReturnOrder } from "./types";
import camelcaseKeys from "camelcase-keys";
import * as Sentry from "@sentry/react";
import { PostCo360API, fetchClient } from "api/constants";

export async function fetchReturnOrder(returnOrderId: string, email: string | null) {
  try {
    const response: AxiosResponse<{ data: ReturnOrder }> = await PostCo360API().get(
      `/api/v2/return_orders/${returnOrderId}.json?include=address,shipment,shipping_method&fields[shipping_methods]=shipping_service,courier`,
    );
    const returnOrder = camelcaseKeys({ ...response.data.data }, { deep: true });
    if (
      returnOrder.status === "reviewed" &&
      (returnOrder.address.email === email || returnOrder.shipment.pickupEmail === email) &&
      returnOrder.requiresPayment
    ) {
      return returnOrder;
    } else {
      throw new Error("Email does not match/ Order does not requires scheduling or payment.");
    }
  } catch (error) {
    throw Error(error.message);
  }
}

export async function updateShipment(
  returnOrderId: string,
  values: FormValues,
  paymentMethodId: string | undefined,
  paymentIntentId: string | undefined,
) {
  try {
    return fetchClient(`/api/v1/return_orders/${returnOrderId}/shipment`, {
      method: "PUT",
      body: {
        shipment: {
          trackingNumber: values.trackingNumber,
          noPrinter: values.noPrinter,
          paymentMethodId: paymentMethodId,
          paymentIntentId: paymentIntentId,
        },
      },
    });
  } catch (error) {
    const message = error.message;
    if (message) {
      throw Error(message);
    } else {
      Sentry.captureException(error);
      throw Error("Something went wrong, please try again later.");
    }
  }
}
