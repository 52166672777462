export const listingPriorities: { [key: string]: number } = {
  apparel21_voucher: 0,
  gift_card: 1,
  discount_code: 2,
  original_payment_method: 3,
  online_transfer: 4,
  same_product: 5,
  other_product: 6,
};

export const storeCreditNames: string[] = [
  "gift_card",
  "discount_code",
  "apparel21_voucher",
  "rise_ai_store_credit",
  "shopify_store_credit",
];
