import React from "react";
import { isMobile } from "react-device-detect";
import { DesktopView, MobileView } from "./components";
import { useCheckCurrentStep } from "helpers/currentStep";
import { ReturnSteps } from "actions";

export const ReceivablePaymentDetails: React.FC = (): JSX.Element | null => {
  useCheckCurrentStep(ReturnSteps.receivablePaymentDetails);

  return isMobile ? <MobileView /> : <DesktopView />;
};
