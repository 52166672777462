import React from "react";
import {
  ReceivablePaymentDetailsSummary,
  ShippingMethodSummary,
  ShippingAddressDetailsSummary,
  ReturnItemsSummary,
} from "./components";
import { useTranslation } from "react-i18next";
import { Summary } from "types";

interface ReturnOrderDetailsProps {
  summary: Summary;
}

export const ReturnOrderDetails: React.FC<ReturnOrderDetailsProps> = ({ summary }): JSX.Element => {
  const {
    bankAccount,
    ewalletAccount,
    returnItems,
    shippingMethod,
    shipment,
    shippingAddress,
    shop,
    returnOrderStatus,
    custEmail,
  } = summary;

  const { t } = useTranslation("orderFlow");
  const returnItemSummaryTitle = t("orderSummary.itemsToReturn");
  return (
    <>
      <ReturnItemsSummary title={returnItemSummaryTitle} returnItems={returnItems} />
      <ShippingMethodSummary
        custEmail={custEmail}
        shipment={shipment}
        shippingMethod={shippingMethod}
        returnOrderStatus={returnOrderStatus}
      />
      <ShippingAddressDetailsSummary shippingAddress={shippingAddress} shop={shop} returnItems={returnItems} />
      <ReceivablePaymentDetailsSummary
        returnItems={returnItems}
        bankAccount={bankAccount}
        ewalletAccount={ewalletAccount}
      />
    </>
  );
};
