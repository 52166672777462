import React from "react";
import { Grid } from "semantic-ui-react";
import styles from "../InstantExchangeSection.module.css";
import { useInstantExchangeData } from "hooks/useInstantExchange";
import { Loading } from "components";
import { TemporaryHoldLine } from "./TemporaryHoldLine";
import { SavePaymentMethod } from "./SavePaymentMethod";
import { ChargeAndRefund } from "./ChargeAndRefund";

export const PreSubmissionInstantExchangeSection: React.FC = (): JSX.Element => {
  const {
    instantExchangeData: { paymentOption },
    isLoading,
  } = useInstantExchangeData();
  const chargeAndRefund = paymentOption === "charge_and_refund";

  if (isLoading) return <Loading />;

  return (
    <div className={styles.instantExchangeSection} style={{ padding: "8px 16px" }}>
      <Grid columns={2}>
        <TemporaryHoldLine />
        {chargeAndRefund ? <ChargeAndRefund /> : <SavePaymentMethod />}
      </Grid>
    </div>
  );
};
