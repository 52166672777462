import { Action, ActionTypes } from "../actions";

const initialState: boolean = true;

export const loadingReducer = (state: boolean = initialState, action: Action): boolean => {
  switch (action.type) {
    case ActionTypes.clearLoading:
      return false;
    case ActionTypes.setLoading:
      return true;
    default:
      return state;
  }
};
