import React from "react";
import { ReturnItemsSummaryGroup } from "./types";
import { useTranslation } from "react-i18next";
import { PostSubmitReturnItemsGroups } from "./PostSubmitReturnItemsGroups";
import { useGetReturnItemsSummary } from "../hooks";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

export const PostSubmitReturnItemsSummary = () => {
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const { returnItemsSummary } = useGetReturnItemsSummary(returnOrderId);

  const { t } = useTranslation("orderFlow");

  const { normalReturnItems, p2pReturnItems } = returnItemsSummary;
  const hasNormalReturnItems = normalReturnItems.length > 0;
  const hasP2PReturnItems = p2pReturnItems.length > 0;

  const separatedByBorder = (returnItemsGroup: ReturnItemsSummaryGroup[]) => {
    if (returnItemsGroup.length > 1) return true;
    if (returnItemsGroup.some((group) => group.title)) return true;
    else return false;
  };
  const separatedByBorderForNormalItems = separatedByBorder(normalReturnItems);
  const separatedByBorderForP2PItems = separatedByBorder(p2pReturnItems);

  return (
    <React.Fragment>
      <PostSubmitReturnItemsGroups
        returnItemsGroups={normalReturnItems}
        separatedByBorder={separatedByBorderForNormalItems}
        title={hasP2PReturnItems ? t("orderSummary.returnItemType.regularReturn") : undefined}
      />
      <PostSubmitReturnItemsGroups
        returnItemsGroups={p2pReturnItems}
        separatedByBorder={separatedByBorderForP2PItems}
        title={hasNormalReturnItems ? t("orderSummary.returnItemType.renewReturn") : undefined}
      />
    </React.Fragment>
  );
};
