import { PostCo360API } from "api/constants";
import { useMutation, useQuery } from "react-query";
import camelcaseKeys from "camelcase-keys";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { nullP2PRequestInfo } from "constants/p2pRequest";

const getP2PRequestInfo = async (returnOrderId: string): Promise<any> => {
  try {
    const response = await PostCo360API().get(`/api/v2/customer/return_orders/${returnOrderId}/p2p/request_info`);

    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};

const createP2PRequest = async (variables: { returnOrderId: string }): Promise<any> => {
  const { returnOrderId } = variables;

  try {
    const response = await PostCo360API().post(`/api/v2/customer/return_orders/${returnOrderId}/p2p/request`);

    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};

function useCreateP2PRequest(options = {}) {
  const { mutate: create, ...others } = useMutation(createP2PRequest, {
    retry: false,
    onError: (_error: Error) => {},
    ...options,
  });

  return { create, ...others };
}

export const useP2PRequestInfo = (options = {}) => {
  const returnOrderId = useSelector((store: StoreState) => store.order.returnOrderId);
  const { data, ...others } = useQuery({
    queryKey: ["p2pRequestInfo", returnOrderId],
    queryFn: () => getP2PRequestInfo(returnOrderId),
    ...options,
  });

  const p2pRequestInfo = data ? data : nullP2PRequestInfo;

  return { p2pRequestInfo, ...others };
};

export { useCreateP2PRequest };
