import React, { useState } from "react";
import { Card, Grid, Accordion, Menu, Icon } from "semantic-ui-react";
import { ReturnMethodTag, Button, DeclineReason, SquareImage } from "components";
import styles from "./BundleReturnLineItemCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { StoreState } from "reducers";
import {
  LineItem,
  ReturnItems,
  ReturnItem,
  setLineItemInFormModal,
  clearCurrentReturnItem,
  setCurrentReturnItemProperty,
  openFormModal,
  setReturnItemDirty,
  removeReturnItemFromCart,
} from "actions";
import { Dispatch } from "redux";
import { createUUID } from "helpers";

interface Props {
  lineItem: LineItem;
}

const handleSelectLineItem = (dispatch: Dispatch<any>, lineItem: LineItem): void => {
  dispatch(setLineItemInFormModal(lineItem));
  dispatch(clearCurrentReturnItem());
  dispatch(setCurrentReturnItemProperty("uuid", createUUID()));
  dispatch(setCurrentReturnItemProperty("bundleItemId", lineItem.bundleItemId ?? null));
  dispatch(setCurrentReturnItemProperty("lineItemId", lineItem.id.toString()));
  dispatch(setCurrentReturnItemProperty("productId", lineItem.productId));
  dispatch(setCurrentReturnItemProperty("variantId", lineItem.variantId));
  dispatch(setCurrentReturnItemProperty("productTitle", lineItem.productTitle));
  dispatch(setCurrentReturnItemProperty("variantTitle", lineItem.variantTitle));
  dispatch(setCurrentReturnItemProperty("fulfillmentStatus", lineItem.fulfillmentStatus));
  dispatch(setCurrentReturnItemProperty("returnItemImageUrl", lineItem.imageUrl));
  dispatch(setCurrentReturnItemProperty("returnItemPrice", lineItem.unitPrice));
  dispatch(setCurrentReturnItemProperty("taxPrice", lineItem.taxPrice));
  dispatch(setCurrentReturnItemProperty("taxRate", lineItem.taxRate));
  dispatch(setCurrentReturnItemProperty("discountAmount", lineItem.discountAmount));
  dispatch(setCurrentReturnItemProperty("sku", lineItem.sku));
  dispatch(setCurrentReturnItemProperty("shopPrice", lineItem.shopPrice));
  dispatch(setCurrentReturnItemProperty("returnItemName", lineItem.name));
  dispatch(setCurrentReturnItemProperty("vendor", lineItem.vendor));
  dispatch(openFormModal());
};

const getReturnItemsInCart = (lineItem: LineItem, returnItems: ReturnItems): ReturnItem[] => {
  return returnItems.cart.filter(
    (returnItem) => returnItem.lineItemId === lineItem.id.toString() && returnItem.sku === lineItem.sku,
  );
};

export const BundleReturnLineItemCard = ({ lineItem }: Props): JSX.Element => {
  const { id: lineItemId, bundleItems } = lineItem;
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation("orderFlow");

  const order = useSelector((state: StoreState) => state.order);
  const returnItems = useSelector((state: StoreState) => state.returnItems);
  const isEditable = ["pending", "draft", "update_draft"].includes(order.status);

  return (
    <Accordion fluid as={Menu} vertical>
      <Menu.Item>
        <Accordion.Title active={active} index={0} onClick={() => setActive(!active)}>
          <Grid>
            <Grid.Column mobile={4} tablet={4} computer={4}>
              <SquareImage src={lineItem.imageUrl} />
            </Grid.Column>
            <Grid.Column mobile={10} tablet={11} computer={11}>
              <p className={styles.itemTitleWithBundleReturn}>{lineItem.name}</p>
              <p className={styles.itemMetaText}>{`Quantity: ${lineItem.quantity}`}</p>
              <p className={styles.itemMetaText}>{lineItem.displayPriceString}</p>
              <p className={styles.itemMetaText}>
                <i>Items can be returned individually</i>
              </p>
            </Grid.Column>
            <Grid.Column mobile={2} table={1} computer={1}>
              <Icon className={styles.accordionDropdownIcon} name="dropdown" size="large" />
            </Grid.Column>
          </Grid>
        </Accordion.Title>
        <Accordion.Content active={active}>
          {bundleItems.map((bundleItem) => {
            const { variantId, name, displayPriceString, quantity, imageUrl, returnable, unreturnableReason } =
              bundleItem;
            const returnItemsInCart = getReturnItemsInCart(bundleItem, returnItems);
            const returnItemsInCartCount = returnItemsInCart.length;
            const notReturnable = quantity === 0;

            const isReturnable = returnItemsInCartCount < quantity && isEditable && returnable;
            const cursorStyle = isReturnable ? "pointer" : "not-allowed";
            const opacityStyle = returnable ? 1 : 0.5;

            const canReturnNextItem = isEditable && returnItemsInCartCount > 0 && returnItemsInCartCount < quantity;
            const onClickToContinueReturnFlow = () => {
              if (isReturnable) handleSelectLineItem(dispatch, bundleItem);
            };

            return (
              <div style={{ marginLeft: "20px", marginTop: "10px" }} key={`${lineItemId}-${variantId}`}>
                <Card
                  fluid
                  onClick={onClickToContinueReturnFlow}
                  style={{ cursor: cursorStyle, opacity: opacityStyle }}
                  disable={notReturnable.toString()}
                >
                  <Card.Content>
                    <Grid>
                      <Grid.Column mobile={4} tablet={4} computer={4}>
                        <SquareImage src={imageUrl} />
                      </Grid.Column>
                      <Grid.Column mobile={12} tablet={12} computer={12}>
                        <p
                          className={
                            notReturnable ? styles.disabledItemTitleWithBundleReturn : styles.itemTitleWithBundleReturn
                          }
                        >
                          {name}
                        </p>
                        <p className={styles.itemMetaText}>
                          <Trans
                            i18nKey="orderSummary.itemCount"
                            values={{ count: returnItemsInCartCount, total: quantity }}
                          >
                            {notReturnable ? (
                              <Trans i18nKey="orderSummary.notReturnableItem">
                                {t("orderSummary.notReturnableItem", {
                                  count: returnItemsInCartCount,
                                })}
                              </Trans>
                            ) : (
                              <Trans i18nKey="orderSummary.itemCount">
                                {t("orderSummary.itemCount", {
                                  count: returnItemsInCartCount,
                                  total: quantity,
                                })}
                              </Trans>
                            )}
                          </Trans>
                        </p>
                        <p className={styles.itemMetaText}>{displayPriceString}</p>
                        <ReturnMethodTag
                          cancellable={isEditable}
                          onCancel={(id: string) => {
                            dispatch(setReturnItemDirty());
                            dispatch(removeReturnItemFromCart(id));
                          }}
                          returnItems={returnItemsInCart}
                        />
                        {!returnable && <p className={styles.unreturnableReason}>{unreturnableReason}</p>}
                        {canReturnNextItem && (
                          <div style={{ marginTop: "10px" }}>
                            <Button color="primaryColor" onClick={onClickToContinueReturnFlow} size="small">
                              {t("orderSummary.returnNextItem")}
                            </Button>
                          </div>
                        )}
                        <DeclineReason returnItems={returnItemsInCart} />
                      </Grid.Column>
                    </Grid>
                  </Card.Content>
                </Card>
              </div>
            );
          })}
        </Accordion.Content>
      </Menu.Item>
    </Accordion>
  );
};
