import React from "react";
import { useRef, useState, useEffect } from "react";
import { Icon, Menu } from "semantic-ui-react";
import styles from "./ExchangeProductSelector.module.css";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import { isMobile } from "react-device-detect";
import { ReturnMethod, setCurrentReturnItemProperty } from "actions";
import { CollectionTab } from "./types";

type CollectionTabsProps = {
  tabs: CollectionTab[];
  selectedTab: CollectionTab;
  onTabSelected: (tab: CollectionTab) => void;
  exchangeMethods: ReturnMethod[];
};

export const CollectionTabs = ({ tabs, selectedTab, onTabSelected, exchangeMethods }: CollectionTabsProps) => {
  const dispatch = useDispatch();

  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);

  const primaryColor = useSelector((state: StoreState) => state.frontendConfigs.primaryColor);
  const hideCollectionProductsCount = useSelector(
    (state: StoreState) => state.currentShop.exchangeSetting.hideCollectionProductsCount,
  );

  const tabContainerRef = useRef<HTMLDivElement | null>(null);
  const [scrollable, setScrollable] = useState(true);
  const [hideStartArrow, setHideStartArrow] = useState(true);
  const [hideEndArrow, setHideEndArrow] = useState(false);

  useEffect(() => {
    if (tabContainerRef.current) {
      const element = tabContainerRef.current;

      const selectedTabElement = element.getElementsByClassName(selectedTab.id)[0];

      if (selectedTabElement instanceof HTMLElement) {
        element.scrollTo({ left: selectedTabElement.offsetLeft });
      }

      const containerWidth = element.clientWidth;
      const tabsWidth = element.getElementsByTagName("div")[0]!.clientWidth;
      setScrollable(containerWidth < tabsWidth);
    }
  }, [selectedTab]);

  function handleTabOnClick(tab: CollectionTab) {
    onTabSelected(tab);
    dispatch(setCurrentReturnItemProperty("selectedCollectionId", tab.id));

    switch (tab.id) {
      case "same_product":
        dispatch(
          setCurrentReturnItemProperty(
            "returnMethod",
            exchangeMethods.find((method) => method.name === "same_product")!,
          ),
        );
        dispatch(
          setCurrentReturnItemProperty("selectedProduct", {
            id: currentReturnItem.productId,
            title: currentReturnItem.productTitle,
            image: { src: currentReturnItem.returnItemImageUrl },
          }),
        );
        break;
      default:
        dispatch(
          setCurrentReturnItemProperty("returnMethod", exchangeMethods.find((method) => method.name === "advanced")!),
        );
        dispatch(
          setCurrentReturnItemProperty("selectedProduct", {
            id: null,
            title: "",
            image: { src: "" },
          }),
        );
        break;
    }
  }

  return (
    <div className={styles.tabsBar} style={{ marginTop: isMobile ? "2px" : "10px" }}>
      {!scrollable || hideStartArrow ? (
        <></>
      ) : (
        <Icon
          color="grey"
          name="angle left"
          onClick={() => {
            tabContainerRef.current!.scrollTo({
              left: tabContainerRef.current!.scrollLeft - 200,
            });
          }}
          style={{ paddingTop: "3px", cursor: "pointer" }}
        />
      )}
      <div
        ref={tabContainerRef}
        className={styles.scrollableContainer}
        onScroll={() => {
          const container = tabContainerRef.current!;
          setHideStartArrow(container.scrollLeft === 0);
          setHideEndArrow(container.scrollWidth - container.scrollLeft - container.clientWidth < 5);
        }}
      >
        <Menu pointing secondary style={{ width: "max-content", border: "none" }}>
          {tabs.map((tab) => (
            <Menu.Item
              style={{
                fontSize: isMobile ? "13px" : "15px",
                borderColor: primaryColor,
                borderWidth: tab.id === selectedTab.id ? "4px" : 0,
                paddingBottom: tab.id === selectedTab.id ? "8px" : "12px",
                fontWeight: "bold",
              }}
              key={tab.id}
              content={`${tab.title.toUpperCase()}${hideCollectionProductsCount ? "" : ` (${tab.productsCount})`}`}
              className={tab.id}
              active={selectedTab.id === tab.id}
              onClick={() => handleTabOnClick(tab)}
            />
          ))}
        </Menu>
      </div>
      {!scrollable || hideEndArrow ? (
        <></>
      ) : (
        <Icon
          name="angle right"
          color="grey"
          onClick={() => {
            tabContainerRef.current!.scrollTo({
              left: tabContainerRef.current!.scrollLeft + 200,
            });
          }}
          style={{ paddingTop: "3px", cursor: "pointer" }}
        />
      )}
    </div>
  );
};
