import { useBackButton } from "hooks/useBackButton";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import * as React from "react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";

interface IFloatingBackButtonProps {}

export const FloatingBackButton: React.FunctionComponent<IFloatingBackButtonProps> = () => {
  const { canGoBack, goBack } = useBackButton();
  const { isEmbedded } = useIsEmbedded();

  if (!isEmbedded || !canGoBack) return null;

  return (
    <div style={{ position: "absolute", left: 16, top: 16, zIndex: 10, backgroundColor: "white" }}>
      <Button icon basic color="black" onClick={goBack}>
        <Icon name="chevron left" />
      </Button>
    </div>
  );
};
