import { Question } from "types";

function getExistingAnswers(question: Question, answeredQuestion: Question) {
  if (answeredQuestion.type === "text") {
    return answeredQuestion.answers;
  } else {
    return answeredQuestion.answers.filter((answer) => question.answerOptions.find((option) => option.gid === answer));
  }
}
export function populateAnswers(questions: Question[], answeredQuestions: Question[]) {
  return questions.map((question: Question) => {
    // Populate the answer for the same questions from redux
    const answeredQuestion = answeredQuestions.find(
      (answeredQuestion: Question) => answeredQuestion.id === question.id,
    );

    if (!answeredQuestion) return question;
    return {
      ...question,
      answers: getExistingAnswers(question, answeredQuestion),
    };
  });
}

export function getQuestionAnswerPairs(questions: Question[]) {
  return questions.map((question) => {
    return {
      gid: question.gid,
      answers: question.answers,
    };
  });
}
