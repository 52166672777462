import React from "react";
import { DesktopView, MobileView } from "./components";
import { ReturnSteps } from "actions";
import { useCheckCurrentStep } from "helpers/currentStep";
import { isMobile } from "react-device-detect";

export const ShippingAddressDetails: React.FC = (): JSX.Element | null => {
  useCheckCurrentStep(ReturnSteps.shippingAddress);

  return isMobile ? <MobileView /> : <DesktopView />;
};
