import React from "react";
import { Card } from "semantic-ui-react";

export const CardWithShadow: React.FC = ({ children }): JSX.Element => {
  const boxShadow = "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)";
  return (
    <Card fluid style={{ boxShadow }}>
      {children}
    </Card>
  );
};
