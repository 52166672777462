import React from "react";
import { Grid } from "semantic-ui-react";
import styles from "../InstantExchangeSection.module.css";
import { useTranslation } from "react-i18next";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { useInstantExchangeData } from "hooks/useInstantExchange";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { getFormattedPriceString } from "helpers";

export const TemporaryHoldLine = () => {
  const { t } = useTranslation("orderFlow");
  const { isEmbedded } = useIsEmbedded();
  const {
    instantExchangeData: { paymentOption, shipBackWindow },
  } = useInstantExchangeData();
  const { instantExchangeInvoice, instantExchangeExchangeOrderInvoice: exchangeOrderInvoice } = useInvoiceContext();
  const { returningLineItems, presentmentCurrency: currency } = exchangeOrderInvoice;
  const returnItemsAmountCents = returningLineItems.reduce(
    (acc, returnItem) => Math.abs(returnItem.presentmentPrice.cents) + acc,
    0,
  );
  const chargeAndRefund = paymentOption === "charge_and_refund";
  const chargeAndRefundTotalCents = instantExchangeInvoice.totalBeforeChargeAdjustment.cents;
  const instantExchangeAmountCents = chargeAndRefund ? chargeAndRefundTotalCents : returnItemsAmountCents;
  const instantExchangeAmount = getFormattedPriceString(instantExchangeAmountCents, currency);

  const instantExchangeDescription = chargeAndRefund
    ? t("summary.instantExchange.chargeOnSubmitDescription", { instantExchangeAmount })
    : t("summary.instantExchange.saveOnSubmitDescription", { shipBackWindow, instantExchangeAmount });

  return (
    <>
      <Grid.Column computer={12} className={styles.subTotalDue}>
        <div>{t("summary.instantExchange.temporaryHold")}</div>
        <div style={{ fontStyle: "italic", fontSize: "0.85rem", fontWeight: "normal" }}>
          {instantExchangeDescription}
        </div>
      </Grid.Column>
      <Grid.Column
        computer={4}
        textAlign="right"
        className={styles.subTotalDue}
        style={{ paddingLeft: isEmbedded ? "1rem" : 0 }}
      >
        {instantExchangeAmount}
      </Grid.Column>
    </>
  );
};
