import { useIsEmbedded } from "hooks/useIsEmbedded";
import React, { ReactNode } from "react";

export const EmbeddedAppIosScrollChainingSupport: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { isEmbedded } = useIsEmbedded();
  const platform = navigator.platform;

  return isEmbedded && platform === "iPhone" ? (
    <div
      onScroll={(event) => {
        const element = event.target;

        if (!(element instanceof Element)) {
          return;
        }

        const elementHasBeenScrolledToTheTop = element.scrollTop === 0;
        const elementHasBeenScrolledToTheBottom =
          Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 1;

        if (elementHasBeenScrolledToTheTop) {
          window.top?.postMessage({ type: "scrollUp" }, "*");
        } else if (elementHasBeenScrolledToTheBottom) {
          window.top?.postMessage({ type: "scrollDown" }, "*");
        }
      }}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};
