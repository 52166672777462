import React from "react";
import { useReturnOrderStatus } from "hooks/useReturnOrder";
import { PreSubmissionInstantExchangeSection } from "./PreSubmissionSection/PreSubmissionInstantExchangeSection";
import { PostSubmissionInstantExchangeSection } from "./PostSubmissionInstantExchangeSection";

export const InstantExchangeSection: React.FC = (): JSX.Element => {
  const { editableReturnOrder } = useReturnOrderStatus();

  return editableReturnOrder ? <PreSubmissionInstantExchangeSection /> : <PostSubmissionInstantExchangeSection />;
};
