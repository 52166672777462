import { Country } from "@shopify/address";
import { UseTranslationResponse } from "react-i18next";

// NOTE: "" comes from calling .split and the rest are not in address fields
const IGNORED_FOR_VALIDATION = ["", "company", "firstName", "lastName", "phone"];
// NOTE: country field is explicitly rendered, so we ignore it here
const IGNORED_FOR_RENDER = ["country", ...IGNORED_FOR_VALIDATION];

function rowLayoutToNames(rowStr: string) {
  return rowStr.split(/{|}/).filter((attr) => !IGNORED_FOR_RENDER.includes(attr));
}

export function getFormLayout(country: Country) {
  // NOTE: Shopify represents formatting fields as:
  // "{firstName}{lastName}_{company}_{address1}_{address2}_{city}_{country}{province}{zip}_{phone}"
  // This function returns: [["address1"],["address2"],["city"],["province","zip"]]
  return country.formatting.edit
    .split("_")
    .map(rowLayoutToNames)
    .filter((row) => row.length > 0);
}

export function getRequiredFields(country: Country) {
  const countryOptionalFields = [...Object.keys(country.optionalLabels), IGNORED_FOR_VALIDATION];
  return country.formatting.edit.split(/{|_|}/).filter((attr) => attr !== "" && !countryOptionalFields.includes(attr));
}

export function getRequiredErrorMessage(country: Country, fieldName: string, t: UseTranslationResponse["t"]) {
  const label = (country.labels as { [key: string]: string })[fieldName];
  return t("addressForm.requiredMessage", { label });
}

export function getMinErrorMessage(
  country: Country,
  fieldName: string,
  length: number,
  t: UseTranslationResponse["t"],
) {
  const label = (country.labels as { [key: string]: string })[fieldName];
  return t("addressForm.minMessage", { label, length });
}

export function getMaxErrorMessage(
  country: Country,
  fieldName: string,
  length: number,
  t: UseTranslationResponse["t"],
) {
  const label = (country.labels as { [key: string]: string })[fieldName];
  return t("addressForm.maxMessage", { label, length });
}

export function getFormatErrorMessage(country: Country, fieldName: string, t: UseTranslationResponse["t"]) {
  const label = (country.labels as { [key: string]: string })[fieldName];
  return t("addressForm.formatMessage", { label });
}

export function getSupportedCountryErrorMessage(t: UseTranslationResponse["t"]) {
  return t("addressForm.supportedCountryMessage");
}

export function getSupportedProvinceErrorMessage(t: UseTranslationResponse["t"]) {
  return t("addressForm.supportedProvinceMessage");
}
