import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "reducers";
import { ReturnMethod, setError, closeFormModal, ReturnItem } from "actions";
import { filterReturnMethods } from "helpers";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import qs from "qs";
import { fetchClient } from "api/constants";
import { useTranslation } from "react-i18next";
import { storeCreditNames } from "constants/returnMethod";

export const getReturnMethods = async (
  shopId: number | null,
  orderId: string,
  returnReason: string,
  productId: number | null,
  variantId: number | null,
  lineItemId: string,
  bundleItemId: number | null,
  returnOrderId: string,
  potentialOpmRefundAmountCents: number,
  currency: string,
): Promise<any> => {
  try {
    const language = Cookies.get("language");

    const query = {
      filter: {
        shopId,
        enabled: true,
      },
      policy_rule_data: {
        orderId,
        returnReason,
        productId,
        variantId,
        lineItemId,
        bundleItemId,
        returnOrderId,
      },
      evaluatePolicyRule: true,
      locale: language,
      potentialOpmRefundAmountCents,
      currency,
    };
    const queryString = qs.stringify(query, { arrayFormat: "brackets" });

    return fetchClient(`/api/v2/return_methods.json?${queryString}`);
  } catch (error) {
    Sentry.captureException(error);
    throw Error(error.message);
  }
};

function useReturnMethods() {
  const dispatch = useDispatch();

  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const orderId = useSelector((state: StoreState) => state.order.id);
  const currency = useSelector((state: StoreState) => state.order.currency);
  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);
  const returnReason = currentReturnItem.returnReason.globalId;
  const { productId, variantId, lineItemId, bundleItemId } = currentReturnItem;
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const cart = useSelector((state: StoreState) => state.returnItems.cart);

  const { t } = useTranslation("orderFlow");

  let opmRefundsInCart = cart.filter((item: ReturnItem) => item.returnMethod.name === "original_payment_method");
  opmRefundsInCart.push(currentReturnItem);

  // This const is used when return order is an exchange order to filter out OPM return method for the currentReturnItem if
  // (opmRefundAmountOfItemsInCart + currentReturnItem's display price) > Maximum refundable amount
  const potentialOpmReturnAmountCents = opmRefundsInCart.reduce((totalAmount: number, refund: ReturnItem) => {
    return totalAmount + refund.returnItemPrice + refund.taxPrice - refund.discountAmount;
  }, 0);

  const { data, ...others } = useQuery({
    queryKey: ["returnMethods", shopId, orderId, returnReason, productId, variantId, lineItemId, bundleItemId],
    queryFn: () =>
      getReturnMethods(
        shopId,
        orderId,
        returnReason,
        productId,
        variantId,
        lineItemId,
        bundleItemId,
        returnOrderId,
        potentialOpmReturnAmountCents,
        currency,
      ),
    onSuccess: (response: { data: ReturnMethod[] }) => {
      if (response.data.length === 0) {
        dispatch(setError(Error(t("errorMessage.notReturnable"))));
        dispatch(closeFormModal());
      }
    },
    onError: (error: Error) => {
      dispatch(setError(error as Error));
      dispatch(closeFormModal());
    },
    enabled: !!lineItemId,
  });

  const responseData = data ? data.data : [];
  const returnMethods = filterReturnMethods(responseData, cart);

  const refundMethods = returnMethods.filter(
    (method) => method.type === "Refund" && !storeCreditNames.includes(method.name),
  );
  const exchangeMethods = returnMethods.filter((method) => method.type === "Exchange");
  const storeCreditMethods = returnMethods.filter((method) => storeCreditNames.includes(method.name));

  return { returnMethods, refundMethods, exchangeMethods, storeCreditMethods, ...others };
}

export { useReturnMethods };
