import React, { useEffect, useState, createContext, useContext } from "react";
import { useShipmentInvoice, useRegularReturnInvoice, useInstantExchangeInvoices } from "hooks/useInvoices";
import { MainInvoice, ShipmentInvoice, nullShipmentInvoice, nullMainInvoice } from "constants/invoice";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { Loading } from "components";
import { useP2PRequestInfo } from "hooks/useP2PRequest";
import { nullP2PRequestInfo, P2PRequestInfo } from "constants/p2pRequest";

interface InvoiceContextType {
  shipmentInvoice: ShipmentInvoice;
  regularReturnInvoice: MainInvoice;
  instantExchangeInvoice: MainInvoice;
  instantExchangeExchangeOrderInvoice: MainInvoice;
  instantExchangeRefundInvoice: null | MainInvoice;
  requiresPayment: boolean;
  p2pRequestInfo: P2PRequestInfo;
}

const InvoiceContext = createContext<InvoiceContextType>({
  shipmentInvoice: nullShipmentInvoice,
  regularReturnInvoice: nullMainInvoice,
  instantExchangeInvoice: nullMainInvoice,
  instantExchangeExchangeOrderInvoice: nullMainInvoice,
  instantExchangeRefundInvoice: null,
  requiresPayment: false,
  p2pRequestInfo: nullP2PRequestInfo,
});

export function InvoiceProvider({ children }: any) {
  const instantExchange = useSelector((state: StoreState) => state.returnItems.instantExchange);
  const instantExchangeSet = useSelector((state: StoreState) => state.returnItems.instantExchangeSet);
  const collectPaymentMethodOnSubmit = useSelector(
    (state: StoreState) => state.currentShop.collectPaymentMethodOnSubmit,
  );
  const stripeIntegrationEnabled = useSelector((state: StoreState) => state.order.stripeIntegrationEnabled);
  const shouldFetchRegularReturnInvoice = Boolean(!instantExchange && instantExchangeSet);
  const shouldFetchInstantExchangeInvoices = Boolean(instantExchange && instantExchangeSet);

  const { shipmentInvoice } = useShipmentInvoice();
  const { p2pRequestInfo, isFetching: isFetchingP2PRequestInfo } = useP2PRequestInfo();
  const {
    regularReturnInvoice,
    isFetching: isFetchingRegularReturnInvoice,
    refetch: refetchRegularReturnInvoice,
    isFetchedAfterMount: regularReturnInvoiceFetched,
  } = useRegularReturnInvoice({ enabled: shouldFetchRegularReturnInvoice });
  const {
    invoices: instantExchangeInvoices,
    isFetching: isFetchingInstantExchangeInvoices,
    refetch: fetchInstantExchangeInvoices,
    isFetchedAfterMount: instantExchangeInvoicesFetched,
  } = useInstantExchangeInvoices({ enabled: shouldFetchInstantExchangeInvoices });
  const { instantExchangeExchangeOrderInvoice } = instantExchangeInvoices;
  const [requiresPayment, setRequiresPayment] = useState<boolean>(false);

  useEffect(() => {
    if (!stripeIntegrationEnabled) return;

    if (instantExchange) {
      setRequiresPayment(true);
    } else if (collectPaymentMethodOnSubmit) {
      const shipmentRequiresPayment =
        shipmentInvoice && shipmentInvoice.total.cents > 0 && shipmentInvoice.payAt === "after_review";
      const invoiceRequiresPayment = regularReturnInvoice && regularReturnInvoice.total.cents > 0;
      setRequiresPayment(shipmentRequiresPayment || invoiceRequiresPayment);
    } else {
      setRequiresPayment(false);
    }
  }, [
    instantExchange,
    shipmentInvoice,
    regularReturnInvoice,
    instantExchangeExchangeOrderInvoice,
    collectPaymentMethodOnSubmit,
    stripeIntegrationEnabled,
  ]);

  useEffect(() => {
    if (shouldFetchInstantExchangeInvoices && !instantExchangeInvoicesFetched) {
      fetchInstantExchangeInvoices();
    }
    if (shouldFetchRegularReturnInvoice && !regularReturnInvoiceFetched) {
      refetchRegularReturnInvoice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instantExchange]);

  const value = {
    shipmentInvoice,
    regularReturnInvoice,
    ...instantExchangeInvoices,
    requiresPayment,
    p2pRequestInfo,
  };

  if (
    isFetchingRegularReturnInvoice ||
    isFetchingInstantExchangeInvoices ||
    !instantExchangeSet ||
    isFetchingP2PRequestInfo
  ) {
    return <Loading />;
  }

  return <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>;
}

export function useInvoiceContext() {
  const context = useContext(InvoiceContext);
  if (!context) {
    throw Error("useInvoiceContext must be used within an InvoiceProvider");
  }

  return context;
}
