import React from "react";
import { cloneReturnOrder } from "api";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useHistory } from "react-router-dom";
import { changeReturnStep, clearLoading, ReturnSteps, setError, setLoading } from "actions";
import { getRoutes } from "constants/routes";
import { Button, Icon } from "semantic-ui-react";

export const EditReturnOrderButton = () => {
  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const dispatch = useDispatch();
  const history = useHistory();

  const onEditButtonClick = async () => {
    try {
      dispatch(setLoading());

      const { clonedReturnOrderId, nextStep } = await cloneReturnOrder({ shopId, returnOrderId });
      const nextStepEnum = Object.values(ReturnSteps).indexOf(nextStep);

      dispatch(changeReturnStep(nextStepEnum));

      const nextRoute = getRoutes(shopSlug, clonedReturnOrderId)[nextStepEnum];
      history.push(nextRoute);

      dispatch(clearLoading());
    } catch (err) {
      dispatch(setError(err));
    } finally {
      dispatch(clearLoading());
    }
  };

  return (
    <Button onClick={onEditButtonClick} compact>
      <Icon name="edit" />
      <span>Edit Request</span>
    </Button>
  );
};
