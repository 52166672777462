import React from "react";
import { ReturnItem } from "actions";
import { Grid, Image, Label, Popup } from "semantic-ui-react";
import { getFormattedPriceString } from "helpers";
import { useTruncateItemCardSentence } from "hooks/useTruncateItemCardSentence";
import { useTranslation } from "react-i18next";
import styles from "./ReturnItemSummary.module.css";
import { variantName, getRefundMethodBadgeText, getExchangeMethodBadgeText } from "helpers";

interface PreSubmitReturnItemSummaryProps {
  returnItem: ReturnItem;
  orderCurrency: string;
}

export const PreSubmitReturnItemSummary: React.FC<PreSubmitReturnItemSummaryProps> = ({
  returnItem,
  orderCurrency,
}): JSX.Element => {
  const { truncateSentence } = useTruncateItemCardSentence();
  const { t } = useTranslation("orderFlow");

  const itemPrice = orderCurrency
    ? getFormattedPriceString(
        returnItem.returnItemPrice + returnItem.taxPrice - returnItem.discountAmount,
        orderCurrency,
      )
    : "";

  const returnTypeInfo =
    returnItem.returnMethod.type === "Exchange"
      ? getExchangeMethodBadgeText(returnItem, t("forExchange"), truncateSentence(variantName(returnItem)))
      : getRefundMethodBadgeText(returnItem, t);

  const { rejectReason, status } = returnItem;
  const itemRejected = status === "rejected";

  return (
    <Grid.Column>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <div style={{ height: "60px", aspectRatio: "1 / 1" }}>
            <Image
              rounded
              disabled={itemRejected}
              inline
              src={returnItem.returnItemImageUrl}
              style={{ height: "100%", objectFit: "cover", aspectRatio: "1 / 1" }}
            />
            {itemRejected && (
              <div style={{ position: "absolute", top: 0, left: "40px" }}>
                <Popup position="top center" trigger={<Label size="mini">{t("exchangePaymentPage.rejected")}</Label>}>
                  <Popup.Content style={{ color: "gray" }}>
                    {t("exchangePaymentPage.rejectReason")}: {rejectReason}
                  </Popup.Content>
                </Popup>
              </div>
            )}{" "}
          </div>
          <div className={styles.itemSummary}>
            <div className={styles.header}>{returnItem.returnItemName}</div>
            <div className={styles.subheader}>
              <p>{returnTypeInfo}</p>
            </div>
          </div>
        </div>
        <div>
          <b>{itemPrice}</b>
        </div>
      </div>
    </Grid.Column>
  );
};
