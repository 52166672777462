import { DesktopView, MobileView } from "./components";
import React from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { NotFound } from "pages/NotFound/NotFound";
import { InvoiceProvider } from "contexts/InvoiceProvider";
import { PaymentPageProvider } from "./PaymentPageProvider";
import { Loading } from "components";
import { useAuthContext } from "contexts/AuthProvider";

export const ExchangeOrderPayment: React.FC<any> = (): JSX.Element => {
  const { returnId } = useParams<{ returnId: string }>();
  const { isLoading } = useAuthContext();
  const returnOrderStatus = useSelector((state: StoreState) => state.order.status);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);

  if (isLoading) {
    return <Loading />;
  }

  if (returnOrderStatus !== "pending_payment_from_customer" || returnId !== returnOrderId.toString()) {
    return <NotFound />;
  }

  return (
    <PaymentPageProvider>
      <InvoiceProvider>{isMobile ? <MobileView /> : <DesktopView />}</InvoiceProvider>
    </PaymentPageProvider>
  );
};
