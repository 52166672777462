import React from "react";

export const Co2WarningIcon = () => (
  <div
    style={{
      backgroundColor: "#FDC465",
      borderRadius: "50%",
      padding: 10,
      width: 50,
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.75157 2.75397C11.1947 0.252583 14.8049 0.252582 16.248 2.75397L25.4409 18.6883C26.8832 21.1883 25.0789 24.3123 22.1927 24.3123H3.80686C0.920643 24.3123 -0.883643 21.1883 0.558664 18.6883L9.75157 2.75397ZM13 9.31223C13.5178 9.31223 13.9375 9.73196 13.9375 10.2497V14.9372C13.9375 15.455 13.5178 15.8747 13 15.8747C12.4823 15.8747 12.0625 15.455 12.0625 14.9372V10.2497C12.0625 9.73196 12.4823 9.31223 13 9.31223ZM13 19.6247C13.5178 19.6247 13.9375 19.205 13.9375 18.6872C13.9375 18.1695 13.5178 17.7497 13 17.7497C12.4823 17.7497 12.0625 18.1695 12.0625 18.6872C12.0625 19.205 12.4823 19.6247 13 19.6247Z"
        fill="white"
      />
    </svg>
  </div>
);
