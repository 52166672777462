import { formModalPush, FormModalSteps } from "actions";
import { useReturnMethods } from "hooks/useReturnMethods";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Message } from "semantic-ui-react";
import styles from "./AdditionalMessage.module.css";

export const AdditionalMessage = () => {
  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();
  const { refundMethods, storeCreditMethods } = useReturnMethods();

  const hasAdditionalMessage = refundMethods.concat(storeCreditMethods).length > 0;
  const additionalMessageText = () => {
    if (refundMethods.length && storeCreditMethods.length) {
      return t("productSelector.additionalMessage.linkText.refundAndStoreCredit");
    } else if (refundMethods.length) {
      return t("productSelector.additionalMessage.linkText.refundOnly");
    } else if (storeCreditMethods.length) {
      return t("productSelector.additionalMessage.linkText.storeCreditOnly");
    }
  };
  const additionalMessageLink = () => (
    <Link
      to="#"
      style={{ color: "black", fontWeight: 700, textDecoration: "underline" }}
      onClick={() => dispatch(formModalPush(FormModalSteps.returnMethodTypes))}
    >
      {additionalMessageText()}
    </Link>
  );

  if (!hasAdditionalMessage) return null;
  return (
    <Message className={styles.messageContainer}>
      <p style={{ marginBottom: "1.5px" }}>{t("productSelector.additionalMessage.base")}</p>
      {additionalMessageLink()}
    </Message>
  );
};
