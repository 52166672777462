import { Icon, Menu } from "semantic-ui-react";
import { LocaleSelect } from "./LocaleSelect";
import { ReturnSteps, resetStore } from "../../actions";
import { StoreState } from "reducers/index";
import { useBackButton } from "hooks/useBackButton";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import logo from "./lola-logo.jpg";
import React from "react";
import styles from "./Navbar.module.css";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { useAuthContext } from "contexts/AuthProvider";

interface NavbarProps {
  baseUrl: string;
}

export const Navbar: React.FC<NavbarProps> = ({ baseUrl }): JSX.Element => {
  const currentShop = useSelector((state: StoreState) => state.currentShop);
  const logoImageUrls = useSelector((state: StoreState) => state.frontendConfigs.logoImageUrls);
  const returnStep = useSelector((state: StoreState) => state.returnStep);

  const history = useHistory();
  const dispatch = useDispatch();
  const { broadcastLogout } = useAuthContext();

  const { t } = useTranslation();
  const { isEmbedded } = useIsEmbedded();

  function parsedShopUrl() {
    try {
      return new URL(currentShop.shopUrl);
    } catch {
      return new URL("https://" + currentShop.shopUrl);
    }
  }

  const handleClick = () => {
    if (returnStep === ReturnSteps.customerInfo) {
      window.location.href = parsedShopUrl().toString();
    } else {
      dispatch(resetStore());
      history.push(baseUrl);
      broadcastLogout();
    }
  };

  const navBarLogoWebp = logoImageUrls?.webp?.navbar?.toString() || logo;
  const navBarLogoPng = logoImageUrls?.png?.navbar?.toString() || logo;

  const { goBack, canGoBack } = useBackButton();

  if (isEmbedded && currentShop.country !== "Vietnam") return <></>;

  return (
    <Menu attached="top" className={styles.navbar} size="large">
      <div className={styles.backContainer}>
        <div>
          {canGoBack && (
            <span className={styles.backLink} onClick={goBack}>
              <Icon name="chevron left" />
              {t("back")}
            </span>
          )}
        </div>
        <LocaleSelect />
      </div>
      {!isEmbedded && (
        <picture className={styles.logo} onClick={handleClick}>
          <source media="(min-width: 0px)" type="image/webp" srcSet={navBarLogoWebp} />
          <source media="(min-width: 0px)" type="image/png" srcSet={navBarLogoPng} />
          <img src={navBarLogoPng} height="100%" width="auto" alt="Shop logo" />
        </picture>
      )}
    </Menu>
  );
};
