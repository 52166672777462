import React from "react";
import parse from "html-react-parser";
import { Overview } from "components";
import { Grid, Header } from "semantic-ui-react";
import { ReturnOrderDetailsSummary } from "components";
import { useTranslation } from "react-i18next";
import { PaymentAndSubmission } from "./PaymentAndSubmission";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { ReturnTypeTabs } from "../ReturnTypeTabs";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { P2PSection } from "../P2PSection/P2PSection";

export const DesktopView: React.FC<any> = (): JSX.Element => {
  const isP2PReturns = useSelector((state: StoreState) => state.returnItems.p2pReturns);
  const { t } = useTranslation("orderFlow");
  const { p2pRequestInfo } = useInvoiceContext();
  const p2pPromotionalDescription = useSelector(
    (state: StoreState) => state.currentShop.p2pSetting?.promotionalDescription,
  );
  return (
    <div style={{ height: "100%", padding: "10px 0px 0px 0px" }}>
      <Grid columns={2} style={{ height: "100%" }}>
        <Grid.Column mobile={16} computer={8} style={{ backgroundColor: "#fbfbfb", padding: "40px 120px" }}>
          <Grid.Row style={{ marginBottom: "24px" }}>
            <Header as="h2">{t("summary.header")}</Header>
          </Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <ReturnOrderDetailsSummary />
          </Grid.Column>
        </Grid.Column>
        <Grid.Column mobile={16} table={16} computer={8} style={{ padding: "40px 120px" }}>
          {p2pRequestInfo.requestable ? (
            <div style={{ padding: "1rem" }}>
              <ReturnTypeTabs />
              {p2pPromotionalDescription ? (
                <div style={{ textAlign: "center", marginTop: 16 }}>
                  <i style={{ color: "#217005" }}>{parse(p2pPromotionalDescription)}</i>
                </div>
              ) : null}
            </div>
          ) : null}

          {!isP2PReturns ? (
            <>
              <Overview />
              <PaymentAndSubmission />
            </>
          ) : (
            <P2PSection />
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
};
