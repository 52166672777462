import React, { useEffect, useState } from "react";
import { PAYMENT_STATUS } from "../constants";
import { useStripe } from "@stripe/react-stripe-js";
import { Form, Message } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { setError } from "actions";

interface HandleCardActionProps {
  paymentIntentSecret: string | null;
  paymentStatus: string;
  callback: Function;
}

export const HandleCardAction: React.FC<HandleCardActionProps> = ({ paymentIntentSecret, paymentStatus, callback }) => {
  const [showMessage, setShowMessage] = useState(false);
  const stripe = useStripe();
  const dispatch = useDispatch();

  const requires3ds = !!stripe && paymentStatus === PAYMENT_STATUS.requiresAction && !!paymentIntentSecret;

  const handleCardAction = async () => {
    if (!!stripe && paymentStatus === PAYMENT_STATUS.requiresAction && !!paymentIntentSecret) {
      const result = await stripe.handleCardAction(paymentIntentSecret);
      //result returns either result.paymentIntent or result.error
      if (result.paymentIntent && result.paymentIntent.status === PAYMENT_STATUS.requiresConfirmation) {
        callback(result.paymentIntent.status, result.paymentIntent.id);
      } else {
        //handle error if authentication failed or cancelled
        if (result.error) {
          dispatch(setError(Error(result.error.message)));
          callback(PAYMENT_STATUS.failed);
          setShowMessage(false);
        }
      }
    }
  };

  useEffect(() => {
    setShowMessage(requires3ds);
    handleCardAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentIntentSecret, paymentStatus]);

  return (
    <React.Fragment>
      <Form.Field>
        <Message warning visible={showMessage}>
          Processing 3D Secure Authentication. Please don't close or refresh the page.
        </Message>
      </Form.Field>
    </React.Fragment>
  );
};
