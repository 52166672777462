import React from "react";

export const ConnectorLine = ({ color, dashed }: { color: string; dashed?: boolean }) => (
  <div
    style={{
      flexGrow: 1,
      width: "100%",
      backgroundPosition: "top",
      backgroundSize: "3px 30px",
      backgroundImage: `linear-gradient(to bottom, ${color} ${dashed ? "50%" : "100%"}, rgba(255,255,255,0) 0%)`,
      backgroundRepeat: "repeat-y",
    }}
  />
);
