import camelcaseKeys from "camelcase-keys";
import { PostCo360API } from "api/constants";
import { Summary } from "types";

export const getSummary = async (returnOrderId: string): Promise<Summary> => {
  try {
    const response: { data: Summary } = await PostCo360API().get(
      `/api/v2/customer/return_orders/${returnOrderId}/summary.json`,
    );
    return camelcaseKeys({ ...response.data }, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};
