import { Action, ActionTypes, ShopLocale, defaultLocale } from "../actions";
import { getStoredCustomerLocale } from "helpers";

const initialState = getStoredCustomerLocale() || defaultLocale;

export const localeReducer = (state = initialState, action: Action): ShopLocale => {
  switch (action.type) {
    case ActionTypes.setLocale:
      return action.payload;
    default:
      return state;
  }
};
