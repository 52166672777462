import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, ModalContent } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useHistory } from "react-router-dom";
import { Button } from "components";
import { ProductListingList } from "./components/ProductListingList";
import { useP2PProductListings } from "hooks/useP2PProductListings";
import { AdaptiveModal } from "components/AdaptiveModal";
import { isMobile } from "react-device-detect";
import { StickyBottomAppBar } from "StickyBottomAppBar";

export const P2PRequestPage = () => {
  const { t } = useTranslation("orderFlow");
  const history = useHistory();
  const returnOrderId = useSelector((store: StoreState) => store.order.returnOrderId);
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);

  const { productListings, isLoading } = useP2PProductListings({ staleTime: Infinity });

  return (
    <AdaptiveModal style={{ padding: 32 }}>
      <header style={{ textAlign: "center" }}>
        <img
          src={
            isMobile
              ? "https://360-static-assets.postco.co/left-arrow.png"
              : "https://360-static-assets.postco.co/close.png"
          }
          style={{
            height: 12,
            cursor: "pointer",
            marginBottom: isMobile ? 16 : 10,
            display: "block",
            marginLeft: isMobile ? 0 : "auto",
          }}
          onClick={history.goBack}
        />
        <div>
          <h1 style={{ fontSize: "1.8rem", fontWeight: "bolder" }}>
            {productListings
              .flatMap((productListings) => productListings.imageUploadRequirement.instructions)
              .every((instruction) => instruction.image)
              ? t("p2pRequestPage.successfulTitle")
              : t("p2pRequestPage.title")}
          </h1>
          <p style={{ fontSize: "1rem", fontWeight: "normal" }}>{t("p2pRequestPage.description")}</p>
        </div>
      </header>
      <Divider />
      <ModalContent scrolling style={{ marginRight: "-10px" }}>
        <ProductListingList>
          {productListings.map((productListing, index) => (
            <ProductListingList.Item
              key={index}
              productListing={productListing}
              onClick={() => {
                history.push(`/${shopSlug}/returns/${returnOrderId}/p2p/product_listings/${productListing.id}`);
              }}
            />
          ))}
        </ProductListingList>
        <StickyBottomAppBar>
          <Button
            color="primaryColor"
            fluid
            disabled={
              isLoading ||
              productListings
                .flatMap((productListing) => productListing.imageUploadRequirement.instructions)
                .some((instruction) => !instruction.image)
            }
            onClick={() => {
              history.goBack();
            }}
          >
            {t("common.submit")}
          </Button>
        </StickyBottomAppBar>
      </ModalContent>
    </AdaptiveModal>
  );
};
