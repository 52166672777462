import { Variant } from "actions";
import React, { useContext } from "react";
import { Button } from "components";
import styles from "./VariantSelector.module.css";
import { Popup } from "semantic-ui-react";
import {
  getDisabledMessage,
  variantFromSelectionFilter,
  evaluateOptionDisability,
  evaluatePendingSelection,
} from "./helpers";
import { VariantSelectorContext } from "./context";

interface VariantOptionButtonProps {
  onClick: (optionValue: string | null) => void;
  option: "option1" | "option2" | "option3";
  optionValue: string | null;
  isSelected: Boolean;
}

export const VariantOptionButton: React.FC<VariantOptionButtonProps> = ({
  onClick,
  option,
  optionValue,
  isSelected,
}) => {
  const { selectedOption1, selectedOption2, selectedOption3, haveOption1, haveOption2, haveOption3, variants } =
    useContext(VariantSelectorContext);

  const variant = variants.find((variant: Variant) =>
    variantFromSelectionFilter(option, optionValue, variant, selectedOption1, selectedOption2, selectedOption3),
  );
  const isOptionDisabled = evaluateOptionDisability(
    option,
    optionValue,
    variant,
    variants,
    selectedOption1,
    selectedOption2,
  );
  const isPendingSelection = evaluatePendingSelection(
    option,
    selectedOption1,
    selectedOption2,
    selectedOption3,
    haveOption1,
    haveOption2,
    haveOption3,
  );

  return (
    <Popup
      key={optionValue as string}
      popper={<div style={{ filter: "none" }}></div>}
      content={getDisabledMessage(variant)}
      disabled={isPendingSelection || !isOptionDisabled}
      position="top center"
      wide
      trigger={
        <div className={styles.variantButton} key={optionValue as string}>
          <Button
            basic={!isSelected}
            color="primaryColor"
            style={{ marginBottom: "0.3rem" }}
            onClick={() => onClick(optionValue)}
            disabled={isOptionDisabled}
          >
            {optionValue}
          </Button>
        </div>
      }
    />
  );
};
