import React from "react";
import { EwalletAccount } from "actions";
import { Card } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { CardWithShadow } from "components";

interface EwalletAccountSummaryProps {
  ewalletAccount: EwalletAccount;
}

export const EwalletAccountSummary: React.FC<EwalletAccountSummaryProps> = ({ ewalletAccount }) => {
  const { t } = useTranslation("orderFlow");
  const { provider, name, identifier } = ewalletAccount;

  return (
    <CardWithShadow>
      <Card.Content>
        <Card.Header style={{ paddingBottom: "1rem", display: "flex", justifyContent: "space-between" }}>
          <span>{t("orderSummary.ewalletDetails")}</span>
        </Card.Header>
        <Card.Description>
          {t("ewalletAccountField.accountName")}: {name}
        </Card.Description>
        <Card.Description>
          {t("ewalletAccountField.provider")}: {provider}
        </Card.Description>
        <Card.Description>
          {t("ewalletAccountField.identifier")}: {identifier}
        </Card.Description>
      </Card.Content>
    </CardWithShadow>
  );
};
