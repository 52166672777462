import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ReturnMethod,
  appendCurrentReturnItem,
  closeFormModal,
  formModalGoBack,
  setCurrentReturnItemProperty,
} from "actions";
import { Loading } from "components/Loading/Loading";

export default function ExchangeV2Iframe({
  url,
  exchangeMethods,
  returningProductId,
}: {
  url: URL;
  exchangeMethods: ReturnMethod[];
  returningProductId: number | null;
}) {
  const dispatch = useDispatch();
  const [iframeIsLoading, setIframeIsLoading] = useState(true);

  function postShowShopifyHeaderMessage() {
    window.parent.postMessage({ type: "showShopifyHeader" }, "*");
  }

  // add message listener to handle post messages from iframe
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== process.env.REACT_APP_NEW_CUSTOMER_APP_URL) return;

      if (event.data.type === "closeNewCustomerApp") {
        postShowShopifyHeaderMessage();
        dispatch(formModalGoBack());
      }
      if (event.data.type === "selectedVariant") {
        const selectedVariant = event.data.selectedVariant;
        const selectedProduct = event.data.selectedProduct;
        if (selectedVariant) {
          dispatch(
            setCurrentReturnItemProperty("selectedProduct", {
              id: selectedProduct.id,
              title: selectedProduct.title,
              image: { src: selectedProduct.imageUrl },
            }),
          );
          dispatch(setCurrentReturnItemProperty("bonusCreditAmountCents", event.data.finalBonusCreditCents));
          dispatch(setCurrentReturnItemProperty("exchangingVariant", selectedVariant));
          if (exchangeMethods.length === 1) {
            dispatch(setCurrentReturnItemProperty("returnMethod", exchangeMethods[0]));
          } else {
            const selectedExchangeMethod =
              returningProductId === selectedVariant.productId ? "same_product" : "advanced";
            dispatch(
              setCurrentReturnItemProperty(
                "returnMethod",
                exchangeMethods.find((method) => method.name === selectedExchangeMethod)!,
              ),
            );
          }
        }
        postShowShopifyHeaderMessage();
        dispatch(appendCurrentReturnItem());
        dispatch(closeFormModal());
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {iframeIsLoading && (
        <div style={{ position: "absolute", height: "100dvh", width: "100vw", zIndex: 15 }}>
          <Loading />
        </div>
      )}
      <iframe
        src={url.toString()}
        title="Exchange"
        frameBorder="0"
        style={{ height: "100dvh", width: "100vw" }}
        onLoad={() => {
          setIframeIsLoading(false);
          window.parent.postMessage({ type: "hideShopifyHeader" }, "*");
        }}
      ></iframe>
    </>
  );
}
