import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { StoreState } from "reducers";
import { changeReturnStep, clearLoading, ReturnSteps, setError, setLoading } from "actions";
import { deleteReturnOrder } from "api";
import { getRoutes } from "constants/routes";
import { useQueryClient } from "react-query";
import { Button, Icon } from "semantic-ui-react";

interface Props {
  nextReturnOrderId: number | null;
}

export const CancelRequestButton = ({ nextReturnOrderId }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);

  const onCancelButtonClick = async () => {
    const confirmed = window.confirm("Are you sure you want to cancel this return?");
    if (!confirmed) return;

    try {
      dispatch(setLoading());

      await deleteReturnOrder({ returnOrderId });

      if (nextReturnOrderId) {
        history.replace(`/${shopSlug}/returns/${nextReturnOrderId}/details`);
        queryClient.invalidateQueries(["getReturnOrders"]);
      } else {
        dispatch(changeReturnStep(ReturnSteps.customerInfo));
        history.replace(getRoutes(shopSlug)[ReturnSteps.customerInfo]);
      }
    } catch (err) {
      dispatch(setError(err));
    } finally {
      dispatch(clearLoading());
    }
  };

  return (
    <Button onClick={onCancelButtonClick} compact>
      <Icon name="close" />
      <span style={{ color: "#C0123C" }}>Cancel Request</span>
    </Button>
  );
};
