import React from "react";
import InstoreIcon from "./instore_icon.png";
import { Image } from "semantic-ui-react";
import { Button } from "components";
import styles from "./Descriptions.module.css";
import { ShippingMethod } from "types";
import { useTranslation } from "react-i18next";
interface InstoreReturnsDescriptionProps {
  shopName: string;
  method: ShippingMethod;
}

export const InstoreReturnsDescription: React.FC<InstoreReturnsDescriptionProps> = ({ shopName, method }) => {
  const { t } = useTranslation("orderFlow");
  return (
    <div className={styles.container}>
      <div style={{ width: 35 }}>
        <Image src={InstoreIcon} style={{ height: 20, width: 20 }} centered />
      </div>
      <div className={styles.textBox}>
        <div className={styles.labelContainer}>
          <Button as="div" color="primaryColor" size="mini">
            In-Store
          </Button>
        </div>
        <div className={styles.titleContainer}>
          <strong>{`Drop off at ${shopName} retail stores`}</strong>
        </div>
        <p>Drop off your return items at any of our nearest retail stores</p>
      </div>
      <div className={styles.rightTextBox}>
        <strong>{t("rates.free")}</strong>
        <div>
          <i>
            Store map{" "}
            <a href={`${method.storeListUrl}`} target="_blank" rel="noopener noreferrer">
              here
            </a>
          </i>
        </div>
      </div>
    </div>
  );
};
