import React from "react";
import { Button } from "components";
import styles from "../ReturnMethodTypeSelector.module.css";

interface NormalReturnMethodTypeButtonProps {
  isBasic?: boolean;
  onClick: Function;
  children: string;
}

export const NormalReturnMethodTypeButton: React.FC<NormalReturnMethodTypeButtonProps> = ({
  isBasic = false,
  onClick: handleOnClick,
  children,
}) => {
  const HighPriorityButton = () => {
    return (
      <Button
        color={"primaryColor"}
        fluid
        onClick={handleOnClick}
        className={styles.highPriorityNormalReturnMethodButton}
      >
        {children}
      </Button>
    );
  };

  const LowPriorityButton = () => {
    return (
      <Button
        basic={isBasic}
        fluid
        onClick={handleOnClick}
        id="lowPriorityNormalReturnMethodButton"
        className={styles.lowPriorityNormalReturnMethodButton}
      >
        {children}
      </Button>
    );
  };

  return <div className={styles.buttonContainer}>{isBasic ? <LowPriorityButton /> : <HighPriorityButton />}</div>;
};
