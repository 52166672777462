import { ReturnSteps, returnStepBack, changeReturnStep } from "actions";
import { StoreState } from "reducers";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getMobileEmbeddedKeyFromStorage, useReceivablePaymentMethod } from "helpers";
import { getRoutes } from "constants/routes";
import { useAuthContext } from "contexts/AuthProvider";

function useBackButton() {
  const { broadcastLogout } = useAuthContext();
  const dispatch = useDispatch();

  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const returnStep = useSelector((state: StoreState) => state.returnStep);
  const questions = useSelector((state: StoreState) => state.returnItems.questions);
  const history = useHistory();
  const { hasEwalletTransfer, hasOnlineBankTransfer } = useReceivablePaymentMethod();

  const noBackButtonPages = [ReturnSteps.customerInfo, ReturnSteps.submitted, ReturnSteps.success];

  const canGoBack = !noBackButtonPages.includes(returnStep) && window.location.pathname.split("/").length > 2;

  const noStageOneQuestions = questions.find((question) => question.stage === 1) === undefined;

  function goBack() {
    if (returnStep === ReturnSteps.summaryAndPaymentDetails && !hasEwalletTransfer && !hasOnlineBankTransfer) {
      dispatch(changeReturnStep(ReturnSteps.shippingAddress));
    } else if (returnStep === ReturnSteps.returnItems && noStageOneQuestions) {
      const isOriginFromMobileEmbeddedApp = Boolean(getMobileEmbeddedKeyFromStorage());

      if (isOriginFromMobileEmbeddedApp) {
        // Use browser goBack function to go back to the embedded CustomerInfo page
        history.goBack();
      } else {
        dispatch(changeReturnStep(ReturnSteps.customerInfo));
        history.push(getRoutes(shopSlug)[ReturnSteps.customerInfo]);
        broadcastLogout();

        return;
      }
    } else {
      dispatch(returnStepBack());
    }
    history.goBack();
  }

  return { canGoBack, goBack };
}

export { useBackButton };
