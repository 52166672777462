import { ExchangingLineItems, ExtraLineItems, ReturningItemsSummary, Summary } from "components";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import React from "react";
import { PaymentAndSubmission as DesktopPaymentAndSubmission } from "../DesktopView/PaymentAndSubmission";
import { PaymentAndSubmission as MobilePaymentAndSubmission } from "../MobileView/PaymentAndSubmission";
import { isMobile } from "react-device-detect";
import { RenewReturnImagesAccordion } from "components/RenewReturnImagesAccordion";

export const P2PSummary = () => {
  const { regularReturnInvoice } = useInvoiceContext();
  const { extraLineItems, subtotal, exchangingForLineItems } = regularReturnInvoice;

  return (
    <>
      <div style={{ padding: isMobile ? 0 : "1rem" }}>
        <ReturningItemsSummary afterResolvedInvoice={regularReturnInvoice} />
        {exchangingForLineItems && <ExchangingLineItems exchangingForLineItems={exchangingForLineItems} />}
        <RenewReturnImagesAccordion />
        {extraLineItems.length > 0 && <ExtraLineItems extraLineItems={extraLineItems} subtotal={subtotal} />}
        <Summary afterResolvedInvoice={regularReturnInvoice} />
      </div>
      {isMobile ? <MobilePaymentAndSubmission /> : <DesktopPaymentAndSubmission />}
    </>
  );
};
