import { RefundSummary } from "components/RefundSummary";
import { MainInvoice } from "constants/invoice";
import { getFormattedPriceString } from "helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Grid, Icon, Popup } from "semantic-ui-react";
import { useReturnOrderStatus } from "hooks/useReturnOrder";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import styles from "./Summary.module.css";

interface SummaryProps {
  afterResolvedInvoice: MainInvoice;
  pastTense?: boolean;
}

interface RowProps {
  title: string;
  amount: string;
  isTotal?: boolean;
}

export const Summary: React.FC<SummaryProps> = ({ afterResolvedInvoice, pastTense = false }): JSX.Element => {
  const isP2PReturns = useSelector((state: StoreState) => state.returnItems.p2pReturns);
  const { total, lineItems, returningLineItems, chargeAdjustments, totalBeforeChargeAdjustment } = afterResolvedInvoice;
  const { t } = useTranslation("orderFlow");
  const { completedReturnOrder } = useReturnOrderStatus();

  const hasTopUp = lineItems.some((lineItem) => {
    if (!lineItem.exchangeItem) return false;

    return (
      lineItem.exchangeItem.presentmentPrice.cents +
        // presentmentPrice and bonusCreditAmount are in negative value
        (lineItem.returnItem.presentmentPrice.cents + lineItem.returnItem.bonusCreditAmount.cents) >
      0
    );
  });

  const description = pastTense || completedReturnOrder ? t("summary.totalPaid") : t("summary.totalToPay");
  const storeCreditRefundMethods = ["gift_card", "discount_code", "apparel21_voucher", "rise_ai_store_credit"];
  const refundMethodNames = [
    ...storeCreditRefundMethods,
    "original_payment_method",
    "online_bank_transfer",
    "ewallet_transfer",
  ];
  const lineItemsRefundMethods = [
    ...new Set(
      returningLineItems
        .filter((lineItem) => refundMethodNames.includes(lineItem.returnMethodName))
        .map((lineItem) => lineItem.returnMethodName),
    ),
  ];
  const hasRefunds = lineItemsRefundMethods.length > 0;

  function getStoreCreditText(refundMethod: string): string {
    switch (refundMethod) {
      case "gift_card":
        return t("summary.giftCard");
      case "discount_code":
        return t("summary.discountCode");
      case "apparel21_voucher":
        return t("summary.apparel21Voucher");
      case "rise_ai_store_credit":
        return t("summary.riseAiStoreCredit");
      case "shopify_store_credit":
        return t("summary.shopifyStoreCredit");
      default:
        return "";
    }
  }

  function joinStoreCreditTexts() {
    const storeCreditTexts = lineItemsRefundMethods.map((refundMethod) => getStoreCreditText(refundMethod));
    if (storeCreditTexts.length === 1) return storeCreditTexts[0];
    if (storeCreditTexts.length === 2) return storeCreditTexts.join(" and ");

    const lastTwo = storeCreditTexts.slice(-2);
    const rest = storeCreditTexts.slice(0, -2);
    return [...rest, lastTwo.join(", ")].join(" and ");
  }

  function getTopUpOffsetText() {
    const hasStoreCreditRefundsOnly = lineItemsRefundMethods.every((method) =>
      storeCreditRefundMethods.includes(method),
    );

    return hasStoreCreditRefundsOnly
      ? t("summary.topUpOffsetStoreCredit", { storeCredits: joinStoreCreditTexts() })
      : t("summary.topUpOffsetRefund");
  }

  const OffsetHelperText = (): JSX.Element => {
    return hasTopUp && hasRefunds && !pastTense ? (
      <i style={{ fontSize: "12px", fontWeight: "normal", marginTop: "8px", color: "#515151" }}>
        {getTopUpOffsetText()}
      </i>
    ) : (
      <></>
    );
  };
  const P2pHelperText = (): JSX.Element => {
    return isP2PReturns ? (
      <p style={{ fontWeight: "normal", fontSize: "12px", color: "#515151", marginTop: "4px" }}>
        You’ll only be required to pay this amount when a buyer purchases your item, or when the item is past its
        holding period.
      </p>
    ) : (
      <></>
    );
  };

  const totalAmount = getFormattedPriceString(total.cents, total.currencyIso, { useParenthesis: true });
  const totalBeforeChargeAdjustmentAmount = getFormattedPriceString(
    totalBeforeChargeAdjustment.cents,
    totalBeforeChargeAdjustment.currencyIso,
    {
      useParenthesis: true,
    },
  );

  const InfoRow: React.FC<RowProps> = ({ title, amount, isTotal }) => (
    <>
      <Grid.Column computer={12} style={{ paddingBottom: 0 }}>
        {title}
        {isTotal && <P2pHelperText />}
      </Grid.Column>
      <Grid.Column computer={4} textAlign="right" style={{ paddingBottom: 0 }}>
        {amount}
      </Grid.Column>
      {isTotal && <OffsetHelperText />}
    </>
  );

  const ChargeAdjustmentRow = () => (
    <>
      <Grid.Column computer={12} className={styles.chargeAdjustmentRow}>
        <span>{t("summary.chargeAdjustment")}</span>
        <Popup
          trigger={<Icon name="info circle" size="small" />}
          content={"This outstanding amount is waived."}
          position="top right"
          wide="very"
        />
      </Grid.Column>
      <Grid.Column computer={4} textAlign="right" className={styles.chargeAdjustmentRow}>
        {getFormattedPriceString(chargeAdjustments.cents, chargeAdjustments.currencyIso, {
          useParenthesis: true,
        })}
      </Grid.Column>
    </>
  );

  const ChargeAdjustmentSummary = () => (
    <>
      <Divider className={styles.summaryDivider} />
      <Grid columns={2} className={styles.summaryGrid}>
        <InfoRow title={t("summary.totalToPay")} amount={totalBeforeChargeAdjustmentAmount} />
        <ChargeAdjustmentRow />
        <InfoRow title={t("summary.totalPaid")} amount={totalAmount} isTotal={true} />
      </Grid>
    </>
  );

  const RegularSummary = () => (
    <>
      <Divider className={styles.summaryDivider} />
      <Grid columns={2} className={styles.summaryGrid}>
        <InfoRow title={description} amount={totalAmount} isTotal={true} />
      </Grid>
    </>
  );

  if (totalBeforeChargeAdjustment.cents >= 0) {
    return chargeAdjustments.cents !== 0 ? <ChargeAdjustmentSummary /> : <RegularSummary />;
  } else {
    return <RefundSummary afterResolvedInvoice={afterResolvedInvoice} pastTense={pastTense} />;
  }
};
