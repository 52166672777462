import React from "react";
import { Icon } from "semantic-ui-react";
import parse from "html-react-parser";

import { Button } from "components";
import styles from "./Descriptions.module.css";
import { ShippingMethod } from "types";
import { useTranslation } from "react-i18next";

interface CustomDescriptionProps {
  method: ShippingMethod;
}

export const CustomDescription: React.FC<CustomDescriptionProps> = ({ method }) => {
  const { t } = useTranslation("orderFlow");

  function parseDescriptionIntoMultipleLines(description: string): JSX.Element[] {
    if (!description) return [<></>];

    return description
      .split("\n")
      .filter((description) => description)
      .map((description: string) => (
        <p key={description} style={{ marginBottom: "2px" }}>
          {description}
        </p>
      ));
  }

  function rightLabel(): string {
    if (!!method.rightLabel) {
      return method.rightLabel;
    } else {
      const label = method.requiresLabel ? t("rates.free") : t("rates.varies");
      return `<strong>${label}</strong>`;
    }
  }

  return (
    <div className={styles.container}>
      <div style={{ width: 35 }}>
        <Icon name="box" size="large" />
      </div>
      <div className={styles.textBox}>
        {method.badge && (
          <div className={styles.labelContainer}>
            <Button as="div" color="primaryColor" size="mini">
              {method.badge}
            </Button>
          </div>
        )}
        <div className={styles.titleContainer}>
          <strong>{method.name}</strong>
        </div>
        {parseDescriptionIntoMultipleLines(method.description)}
      </div>
      <div className={styles.rightTextBox}>{parse(rightLabel())}</div>
    </div>
  );
};
