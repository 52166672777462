import Dinero from "dinero.js";

export const getCurrencyPrecision = (currency: string) => {
  const specialCurrencyPrecisions: { [key: string]: number } = {
    BHD: 3,
    BIF: 0,
    CLF: 4,
    CLP: 0,
    DJF: 0,
    GNF: 0,
    IQD: 3,
    ISK: 0,
    JOD: 3,
    JPY: 0,
    KMF: 0,
    KRW: 0,
    KWD: 3,
    LYD: 3,
    OMR: 3,
    PYG: 0,
    RWF: 0,
    TND: 3,
    UGX: 0,
    UYI: 0,
    UYW: 4,
    VND: 0,
    VUV: 0,
    XAF: 0,
    XOF: 0,
    XPF: 0,
  };
  if (Object.keys(specialCurrencyPrecisions).includes(currency)) {
    return specialCurrencyPrecisions[currency];
  } else {
    return 2;
  }
};

type dineroFormat = {
  precision: number;
  useDelimiter?: boolean;
  symbol?: "$" | "USD" | "dollar";
};
export const getDineroFormat = ({ precision, useDelimiter = false, symbol }: dineroFormat) => {
  let format = useDelimiter ? "0,0" : "0";

  if (precision > 0) {
    format += ".";
    for (let i = 0; i < precision; i++) {
      format += "0";
    }
  }
  if (symbol) {
    format = symbol === "dollar" ? `${format}${symbol}` : `${symbol}${format}`;
  }
  return format;
};

// set useBrackets to true if one wish to display negative money to (USD 10.00) i/o -USD 10.00
export function getFormattedPriceString(
  cents: number,
  currency: string,
  { useParenthesis } = { useParenthesis: false },
): string {
  const precision = getCurrencyPrecision(currency);
  const amount = useParenthesis ? Math.abs(cents) : cents;

  const formattedPriceString = Dinero({
    amount: amount,
    currency: currency as any,
    precision: precision,
  }).toFormat(getDineroFormat({ precision, useDelimiter: true, symbol: "USD" }));

  if (useParenthesis && cents < 0) return `(${formattedPriceString})`;
  return formattedPriceString;
}

export function checkPriceIsWholeNumber(amount: number, currency: string) {
  const precision = getCurrencyPrecision(currency);

  const amountCents = Dinero({
    amount: amount,
    currency: currency as any,
    precision: precision,
  }).getAmount();

  const divisor = precision === 0 ? 1 : 10 ** precision;

  return amountCents % divisor === 0;
}
