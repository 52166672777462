export const trimCompanyName = (companyName: string): string => {
  return companyName.replace(/ PostCo$/, "");
};

export const capitalize = (word: string): string => {
  if (typeof word !== "string") return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const snakeToCamelCase = (str: string) => str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());

export const snakeToCapitalize = (str: string) => str.split("_").map(capitalize).join(" ");
