import { createContext } from "react";
import { Variant } from "actions";

type ContextProps = {
  selectedOption1: string | null;
  selectedOption2: string | null;
  selectedOption3: string | null;
  haveOption1: boolean;
  haveOption2: boolean;
  haveOption3: boolean;
  variants: Variant[];
};

export const VariantSelectorContext = createContext<ContextProps>({
  selectedOption1: null,
  selectedOption2: null,
  selectedOption3: null,
  haveOption1: false,
  haveOption2: false,
  haveOption3: false,
  variants: [],
});
