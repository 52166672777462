import { ShippingAddress } from "actions";
import { useFormikContext } from "formik";
import { useAuspostData } from "hooks/useAuspostData";
import React from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { AuspostData } from "./AuspostCityField";

export const AuspostZipField = () => {
  const { errors, values, touched, setFieldValue } = useFormikContext<ShippingAddress>();
  const { city, zip } = values;

  // Fetch AuspostPostcode data
  let { auspostData, isLoading } = useAuspostData(values.province);

  // Filter out zip options based on selected city
  if (city) {
    auspostData = auspostData?.filter((data: AuspostData) => data.city === city.toUpperCase());
  }
  const uniquePostcodes = [...new Set(auspostData?.map((data: AuspostData) => data.zip))] as string[];
  const zipFieldOptions = uniquePostcodes.map((zip) => ({
    text: zip,
    value: zip,
  }));

  // Dropdown value setter
  function handleZipChange(_e: React.SyntheticEvent, data: DropdownProps) {
    const newZip = data.value as string;
    setFieldValue("zip", newZip);
  }

  return (
    <Dropdown
      error={touched.zip && !!errors.zip}
      name="zip"
      disabled={!values.province || isLoading}
      loading={isLoading}
      onChange={handleZipChange}
      options={zipFieldOptions}
      placeholder={"Select postcode"}
      value={zip}
      fluid
      clearable
      search
      selection
    />
  );
};
