import React, { Fragment } from "react";
import { Field, FieldProps, useFormikContext } from "formik";
import { Form, Header, DropdownProps, Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styles from "./AddressFields.module.css";
import { ShippingAddress } from "actions";
import { getCountryOptions, getStateOptions, getDistrictOptions, getWardOptions } from "./helper";

interface AddressFieldProps {
  header: string;
}

export const AddressFields: React.FC<AddressFieldProps> = ({ header }) => {
  const { t } = useTranslation("orderFlow");
  const { errors, setFieldValue, values } = useFormikContext<ShippingAddress>();

  const cityLabel = values.country === "Australia" ? "Suburb" : t("addressForm.city");
  return (
    <Fragment>
      <div className={styles.addressFieldsHeaderContainer}>
        <Header sub>{header}</Header>
      </div>
      <Form.Group widths="equal">
        <Field name="address1">
          {({ field, form }: FieldProps<ShippingAddress["address1"]>): JSX.Element => (
            <Form.Field>
              <label>{t("addressForm.line1")}</label>
              <Form.Input error={form.touched.address1 && form.errors.address1} type="text" {...field} />
            </Form.Field>
          )}
        </Field>
        <Field name="address2">
          {({ field, form }: FieldProps<ShippingAddress["address2"]>): JSX.Element => (
            <Form.Field>
              <label>{`${t("addressForm.line2")} (${"optional"})`}</label>
              <Form.Input
                error={form.touched.address2 && form.errors.address2}
                type="text"
                {...field}
                value={field.value || ""}
              />
            </Form.Field>
          )}
        </Field>
      </Form.Group>
      {values.country !== "Vietnam" && (
        <Form.Group widths="equal">
          <Field name="city">
            {({ field, form }: FieldProps<ShippingAddress["city"]>): JSX.Element => (
              <Form.Field>
                <label>{cityLabel}</label>
                <Form.Input error={form.touched.city && form.errors.city} type="text" {...field} />
              </Form.Field>
            )}
          </Field>
          <Field name="zip">
            {({ field, form }: FieldProps<ShippingAddress["zip"]>): JSX.Element => (
              <Form.Field>
                <label>{t("addressForm.zip")}</label>
                <Form.Input error={form.touched.zip && form.errors.zip} type="text" {...field} />
              </Form.Field>
            )}
          </Field>
        </Form.Group>
      )}
      <Form.Group widths="equal">
        <Field name="country">
          {({ field, form }: FieldProps<ShippingAddress["country"]>): JSX.Element => (
            <Form.Field>
              <label>{t("addressForm.country")}</label>
              <Dropdown
                error={form.touched.country && !!form.errors.country}
                name="country"
                onChange={(_e: React.SyntheticEvent, data: DropdownProps) => {
                  setFieldValue("country", data.value as string);
                  setFieldValue("province", "");
                  setFieldValue("district", "");
                  setFieldValue("ward", "");
                }}
                options={getCountryOptions()}
                placeholder={"Select Country"}
                value={field.value}
                fluid
                search
                selection
              />
              {errors.country && <div className="ui pointing above prompt label">{errors.country}</div>}
            </Form.Field>
          )}
        </Field>
        {values.country !== "United Kingdom" && (
          <Field name="province">
            {({ field, form }: FieldProps<ShippingAddress["province"]>): JSX.Element => (
              <Form.Field>
                <label>{t("addressForm.province")}</label>
                <Dropdown
                  error={form.touched.province && !!form.errors.province}
                  name="province"
                  disabled={!values.country}
                  onChange={(_e: React.SyntheticEvent, data: DropdownProps) => {
                    setFieldValue("province", data.value as string);
                    setFieldValue("district", "");
                    setFieldValue("ward", "");
                  }}
                  options={getStateOptions(values.country)}
                  placeholder={values.country ? "Select Province/State" : "Select Country first"}
                  value={field.value}
                  fluid
                  search
                  selection
                />
                {errors.province && <div className="ui pointing above prompt label">{errors.province}</div>}
              </Form.Field>
            )}
          </Field>
        )}
      </Form.Group>
      {values.country === "Vietnam" && (
        <Form.Group widths="equal">
          <Field name="district">
            {({ field, form }: FieldProps<ShippingAddress["country"]>): JSX.Element => (
              <Form.Field>
                <label>{t("addressForm.district")}</label>
                <Dropdown
                  error={form.touched.district && !!form.errors.district}
                  name="district"
                  disabled={!values.province}
                  onChange={(_e: React.SyntheticEvent, data: DropdownProps) => {
                    setFieldValue("district", data.value as string);
                    setFieldValue("ward", "");
                  }}
                  options={getDistrictOptions(values.country, values.province)}
                  placeholder={values.province ? "Select District" : "Select Province/State first"}
                  value={field.value}
                  fluid
                  search
                  selection
                />

                {errors.district && <div className="ui pointing above prompt label">{errors.district}</div>}
              </Form.Field>
            )}
          </Field>
          <Field name="ward">
            {({ field, form }: FieldProps<ShippingAddress["ward"]>): JSX.Element => (
              <Form.Field>
                <label>{t("addressForm.ward")}</label>
                <Dropdown
                  error={form.touched.ward && !!form.errors.ward}
                  name="ward"
                  disabled={!values.district}
                  onChange={(_e: React.SyntheticEvent, data: DropdownProps) => {
                    setFieldValue("ward", data.value as string);
                  }}
                  options={getWardOptions(values.country, values.province, values.district)}
                  placeholder={values.district ? "Select Ward" : "Select District first"}
                  value={field.value}
                  fluid
                  search
                  selection
                />
                {errors.ward && <div className="ui pointing above prompt label">{errors.ward}</div>}
              </Form.Field>
            )}
          </Field>
        </Form.Group>
      )}
    </Fragment>
  );
};
