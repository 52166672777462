function storageAvailable() {
  try {
    const x = "__storage_test__";
    sessionStorage.setItem(x, x);
    sessionStorage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      sessionStorage &&
      sessionStorage.length !== 0
    );
  }
}

interface Credentials {
  orderNameOrId: string;
  emailOrPhone: string;
}

export function saveCredentialsToStorage(credentials: Credentials): void {
  const { orderNameOrId, emailOrPhone } = credentials;
  if (storageAvailable()) {
    sessionStorage.setItem("postcoOrderNameOrId", orderNameOrId);
    sessionStorage.setItem("postcoEmailOrPhone", emailOrPhone);
  }
}

export function getCredentialsFromStorage() {
  let orderNameOrId: string = "";
  let emailOrPhone: string = "";

  if (storageAvailable()) {
    orderNameOrId = sessionStorage.getItem("postcoOrderNameOrId") || "";
    emailOrPhone = sessionStorage.getItem("postcoEmailOrPhone") || "";
  }
  return { orderNameOrId, emailOrPhone };
}

export function deleteCredentialsInStorage() {
  if (storageAvailable()) {
    sessionStorage.removeItem("postcoOrderNameOrId");
    sessionStorage.removeItem("postcoEmailOrPhone");
  }
}

export function saveMobileEmbeddedKeyToStorage(embedded: string): void {
  if (storageAvailable()) {
    sessionStorage.setItem("mobileEmbedded", embedded);
  }
}

export function getMobileEmbeddedKeyFromStorage() {
  if (storageAvailable()) {
    return sessionStorage.getItem("mobileEmbedded");
  }
}
