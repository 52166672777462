import React from "react";
import { Loading, PageHeader, Overview } from "components";
import { Accordion, Header } from "semantic-ui-react";
import { createSummaryPanels, indexOf, PANEL_KEYS } from "helpers/summary";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { useShipment } from "components/ShippingMethodSummary/hooks";
import { ReturnOrderSummaryTabs } from "./ReturnOrderSummaryTabs";
import { useAuthContext } from "contexts/AuthProvider";
import { useGetReturnOrders } from "../hooks";
import { InvoiceProvider } from "contexts/InvoiceProvider";

export const MobileView: React.FC = (): JSX.Element => {
  const { isEmbedded } = useIsEmbedded();
  const { isLoading } = useAuthContext();
  const { t } = useTranslation("orderFlow");

  const location = useLocation();
  const matchResult = location.pathname.match(/returns\/([\d]+)/);
  const returnOrderIdFromUrl = matchResult && matchResult.length ? matchResult[1] : "";
  const { returnOrders, isGettingReturnOrders } = useGetReturnOrders(returnOrderIdFromUrl);

  const {
    shipment: {
      id: shipmentId,
      shippingMethod: { requireShipment },
    },
  } = useShipment();
  const showShippingMethodSummary = Boolean(shipmentId) || !requireShipment;

  const summaryPanels = createSummaryPanels(showShippingMethodSummary, t);
  const overviewIndex = indexOf(summaryPanels, PANEL_KEYS.overview);
  const defaultActiveIndex = [overviewIndex];

  return (
    <div
      style={{
        padding: "0.5rem",
        height: "100%",
        marginTop: isEmbedded ? "16px" : 0,
      }}
    >
      <div>
        <PageHeader header={t("summary.header")} textAlign="left" />
      </div>
      {!isGettingReturnOrders && returnOrders.length > 0 ? (
        <div style={{ marginBottom: "20px" }}>
          <ReturnOrderSummaryTabs returnOrders={returnOrders} />
        </div>
      ) : null}
      {isLoading ? (
        <Loading />
      ) : (
        <InvoiceProvider>
          <Accordion defaultActiveIndex={defaultActiveIndex} panels={summaryPanels} exclusive={false} />

          <div style={{ padding: "1rem", marginTop: "10px" }}>
            <Header as="h3">{t("invoiceSummary.header")}</Header>
          </div>
          <Overview />
        </InvoiceProvider>
      )}
    </div>
  );
};
