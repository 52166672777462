import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import camelcaseKeys from "camelcase-keys";
import { PostCo360API } from "api/constants";

export interface FeatureFlags {
  discountCodeRefund: boolean;
  displayShipmentFee: boolean;
  exchangeOrderShippingRate: boolean;
  giftCardRefund: boolean;
  reserveExchange: boolean;
  whiteLabel: boolean;
  stickyButtons: boolean;
}

export const initialFeatureFlags: FeatureFlags = {
  discountCodeRefund: false,
  displayShipmentFee: false,
  exchangeOrderShippingRate: false,
  giftCardRefund: false,
  reserveExchange: false,
  whiteLabel: false,
  stickyButtons: false,
};

const getFeatureFlags = async (shopId: number): Promise<FeatureFlags> => {
  try {
    const response = await PostCo360API().get(`/api/v2/customer/shops/${shopId}/feature_flags`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw Error(error.message);
  }
};

export const useFeatureFlags = () => {
  const shopId = useSelector((state: StoreState) => state.currentShop.id) as number;
  const { data, ...others } = useQuery({
    queryKey: ["featureFlags", shopId],
    queryFn: () => getFeatureFlags(shopId),
  });
  const featureFlags: FeatureFlags = data ? camelcaseKeys({ ...data }) : initialFeatureFlags;
  return { featureFlags, ...others };
};
