import useWindowSize from "./useWindowSize";
import { isMobile, isTablet } from "react-device-detect";

function useTruncateItemCardSentence() {
  const { width } = useWindowSize();

  // Used to divide screen width to get "ideal" truncated length of input text
  const getDenominator = () => {
    if (isMobile) {
      if (window.screen.width >= 520) {
        return 22;
      }

      return 27;
    } else if (isTablet) {
      return 22;
    } else {
      return 18;
    }
  };

  // Used to determine maximum length for truncated input text
  const getMaxCharCount = () => {
    if (isMobile) {
      if (window.screen.width >= 520) {
        return 40;
      }

      return 30;
    } else if (isTablet) {
      return 40;
    } else {
      if (window.screen.width < 4000) {
        return 45;
      }

      return 50;
    }
  };

  function truncateSentence(sentence: string): string {
    let truncatedLength = width / getDenominator();
    const minChars = 12;
    const maxChars = getMaxCharCount();

    if (truncatedLength > maxChars) truncatedLength = maxChars;
    if (truncatedLength < minChars) truncatedLength = minChars;

    if (sentence.length > truncatedLength) {
      return sentence.slice(0, truncatedLength) + "...";
    } else {
      return sentence;
    }
  }

  return { truncateSentence };
}

export { useTruncateItemCardSentence };
