import React from "react";
import { Form, Checkbox, CheckboxProps } from "semantic-ui-react";
import { Field, FieldProps, getIn } from "formik";

interface CustomCheckboxProps {
  content: string;
  fieldName: string;
  onChange: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
}

export const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ content, fieldName, onChange }) => {
  return (
    <Form.Group widths="equal">
      <Field name={fieldName}>
        {({ form }: FieldProps) => (
          <Form.Field>
            <Checkbox
              onChange={onChange}
              label={<label style={{ fontSize: "13px", color: "#637381" }}>{content}</label>}
            />
            {getIn(form.touched, fieldName) && getIn(form.errors, fieldName) ? (
              <div className="ui pointing above prompt label">{getIn(form.errors, fieldName)}</div>
            ) : null}
          </Form.Field>
        )}
      </Field>
    </Form.Group>
  );
};
