export interface P2PProductListing {
  returnItemImageUrl: string;
  returnItemName: string;
  returnItemPaidPrice: string;
}

export interface P2PRequestInfo {
  requestable: boolean;
  presentmentIncentiveAmount: string;
}

export const nullP2PRequestInfo: P2PRequestInfo = {
  requestable: false,
  presentmentIncentiveAmount: "",
};
