import React from "react";
import { Image } from "semantic-ui-react";

import styles from "./SquareImage.module.css";

interface SquareImageProps {
  src: string;
  width?: string | number;
  className?: string;
  disable?: boolean;
}

export const SquareImage: React.FC<SquareImageProps> = ({ src, width = "100%", className = "", disable = false }) => (
  <div className={`${styles.container} ${className}`} style={{ width }}>
    <Image src={src} disabled={disable} style={{ objectFit: "contain", aspectRatio: "1" }} />
  </div>
);
