import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "reducers";
import { Radio, Card, Popup, Icon } from "semantic-ui-react";
import { setInstantExchange } from "actions";
import { useTranslation } from "react-i18next";
import { useInstantExchangeData } from "hooks/useInstantExchange";

interface ExchangeOption {
  label: string;
  description: string;
  checked: boolean;
  value: boolean;
  tooltipContent: string;
}

interface ExchangeOptions {
  instantExchange: ExchangeOption;
  regularExchange: ExchangeOption;
}

export const ExchangeOption = ({ type }: { type: "instantExchange" | "regularExchange" }): JSX.Element => {
  const {
    instantExchangeData: { paymentOption, shipBackWindow },
    isLoading,
  } = useInstantExchangeData();
  const allowInstantExchangeOnly = useSelector((state: StoreState) => state.currentShop.allowInstantExchangeOnly);
  const dispatch = useDispatch();
  const { t } = useTranslation("orderFlow");

  const instantExchange = useSelector((state: StoreState) => state.returnItems.instantExchange);
  const exchangeOptions: ExchangeOptions = {
    instantExchange: {
      label: t("exchangeOptions.instantExchangeLabel"),
      description: t("exchangeOptions.instantExchangeDescription"),
      checked: instantExchange,
      value: true,
      tooltipContent:
        paymentOption === "chargeAndRefund"
          ? t("exchangeOptions.chargeAndRefundTooltip")
          : t("exchangeOptions.savePaymentMethodTooltip", { shipBackWindow }),
    },
    regularExchange: {
      label: t("exchangeOptions.regularExchangeLabel"),
      description: t("exchangeOptions.regularExchangeDescription"),
      checked: !instantExchange,
      value: false,
      tooltipContent: t("exchangeOptions.regularExchangeTooltip"),
    },
  };

  const { label, description, checked, value, tooltipContent } = exchangeOptions[type];
  const border = checked ? "1px solid black" : "none";
  const handleSelect = () => {
    dispatch(setInstantExchange(value));
  };

  useEffect(() => {
    allowInstantExchangeOnly && dispatch(setInstantExchange(true));
  }, [allowInstantExchangeOnly]);

  if (isLoading) return <></>;

  return (
    <Card fluid style={{ padding: "1rem", border: border }} onClick={handleSelect}>
      <div style={{ fontWeight: "bold" }}>
        <Radio label={label} checked={checked} />{" "}
        <Popup
          trigger={<Icon name="info circle" size="small" />}
          content={tooltipContent}
          position="top center"
          wide="very"
        />
      </div>
      <div style={{ paddingTop: "0.4rem", paddingLeft: "2rem", fontSize: "1rem", color: "black" }}>{description}</div>
    </Card>
  );
};
