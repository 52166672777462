import React from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "components";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Header } from "semantic-ui-react";
import { useStripePromise } from "helpers";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { PaymentIntentForm } from "./components/PaymentIntentForm";
import { SetupIntentForm } from "./components/SetupIntentForm";
import { useStripeIntent } from "./hooks";

interface Props {
  header?: string;
  showPaymentDetails?: boolean;
}

export const PaymentDetails: React.FC<Props> = ({ header, showPaymentDetails = true }): JSX.Element => {
  const { t } = useTranslation("orderFlow");
  const currentShop = useSelector((state: StoreState) => state.currentShop);
  const { clientSecret, isLoading, type } = useStripeIntent({ enabled: showPaymentDetails });
  const stripePromise = useStripePromise(currentShop);

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: {
      theme: "stripe",
    },
  };

  if (!header) header = t("orderSummary.paymentDetails");

  if (!showPaymentDetails) return <></>;
  if (isLoading) return <Loading />;

  return (
    <div style={{ padding: "1rem" }}>
      <Elements key={clientSecret} stripe={stripePromise} options={options}>
        <Header>{header}</Header>
        {type === "setup_intent" ? <SetupIntentForm /> : <PaymentIntentForm />}
      </Elements>
    </div>
  );
};
