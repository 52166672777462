import React from "react";
import { Header } from "semantic-ui-react";
import { ReturningItemsSummary, ExtraLineItems, Summary, ExchangingLineItems } from "components";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { ExchangeOptionsSection } from "../ExchangeOptionsSection";
import { RenewReturnImagesAccordion } from "components/RenewReturnImagesAccordion";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

export const RegularOverview: React.FC = (): JSX.Element => {
  const returnOrderStatus = useSelector((store: StoreState) => store.order.status);
  const isPostSubmission = !["draft", "update_draft"].includes(returnOrderStatus);
  const isP2PReturns = useSelector((state: StoreState) => state.returnItems.p2pReturns);

  const { regularReturnInvoice, p2pRequestInfo } = useInvoiceContext();

  const { t } = useTranslation("orderFlow");

  const showHeader = !isMobile && (!p2pRequestInfo.requestable || isPostSubmission);

  if (!regularReturnInvoice) return <></>;

  const { extraLineItems, subtotal, exchangingForLineItems } = regularReturnInvoice;

  return (
    <div style={{ padding: isMobile ? 0 : "1rem" }}>
      {showHeader && (
        <div
          style={{
            display: "flex",
            paddingBottom: "20px",
            paddingTop: "10px",
            paddingLeft: "20px",
          }}
        >
          <Header>{t("invoiceSummary.header")}</Header>
        </div>
      )}

      <ReturningItemsSummary afterResolvedInvoice={regularReturnInvoice} />
      {exchangingForLineItems && <ExchangingLineItems exchangingForLineItems={exchangingForLineItems} />}
      {isPostSubmission && isP2PReturns && <RenewReturnImagesAccordion />}
      {extraLineItems.length > 0 && <ExtraLineItems extraLineItems={extraLineItems} subtotal={subtotal} />}
      <Summary afterResolvedInvoice={regularReturnInvoice} />
      <ExchangeOptionsSection />
    </div>
  );
};
