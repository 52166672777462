import React, { useEffect } from "react";
import { QuestionAnswerForm } from "components/QuestionAnswerForm/QuestionAnswerForm";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import {
  formModalPush,
  FormModalSteps,
  formModalGoBack,
  setCurrentReturnItemProperty,
  clearCurrentReturnItem,
  updateLineItemsReturnability,
  closeFormModal,
} from "actions";
import { FormModalContent } from "..";
import { Grid } from "semantic-ui-react";
import { Loading } from "components";
import { getQuestionAnswerPairs } from "helpers/questions";
import { Question } from "types";
import { useReturnableByQuestion } from "hooks/useReturnableByQuestions";
import { useTranslation } from "react-i18next";
import { QuestionFormProvider, useQuestionFormContext } from "contexts/QuestionFormProvider";

const StageThreeQuestionsChild: React.FC<{}> = () => {
  const { stage, questions, questionsData, page, setPage, isFetching } = useQuestionFormContext();

  const { t } = useTranslation("orderFlow");
  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const dispatch = useDispatch();
  const formModal = useSelector((state: StoreState) => state.formModal);
  const answeredQuestions = useSelector((state: StoreState) => state.returnItems.currentReturnItem.questions);
  const otherStagesQuestions = answeredQuestions.filter((q: Question) => q.stage !== stage);
  const currentLineItemId = useSelector((state: StoreState) => state.returnItems.currentReturnItem.lineItemId);
  const { id: orderId, returnOrderId } = useSelector((state: StoreState) => state.order);
  const { productId, variantId, lineItemId, bundleItemId, returnReason } = useSelector(
    (state: StoreState) => state.returnItems.currentReturnItem,
  );

  const { mutate: evaluate, isLoading: evaluating } = useReturnableByQuestion({
    onSuccess: (data: { data: { returnable: boolean } }) => {
      if (!data.data.returnable) {
        dispatch(updateLineItemsReturnability([currentLineItemId], t));
        dispatch(clearCurrentReturnItem());
        dispatch(closeFormModal());
      } else {
        dispatch(formModalPush(FormModalSteps.returnMethodTypes));
        dispatch(setCurrentReturnItemProperty("questions", [...otherStagesQuestions, ...questions]));
      }
    },
  });

  useEffect(() => {
    if (!isFetching) {
      if (questionsData.length === 0) {
        dispatch(formModalPush(FormModalSteps.returnMethodTypes, false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <FormModalContent
      onPrev={() => {
        if (formModal.stepsHistory.length > 0 && page === 0) {
          dispatch(formModalGoBack());
          dispatch(setCurrentReturnItemProperty("questions", [...otherStagesQuestions]));
        } else {
          setPage(page - 1);
        }
      }}
      showBackButton={formModal.stepsHistory.length > 0 ? true : page !== 0}
    >
      {isFetching || questions.length === 0 ? (
        <Grid style={{ paddingBottom: "32px" }}>
          <Grid.Column>
            <Loading />
          </Grid.Column>
        </Grid>
      ) : (
        <QuestionAnswerForm
          buttonLoading={evaluating}
          handleContinue={() => {
            const questionAnswerPairs = getQuestionAnswerPairs(questions);

            evaluate({
              shopId,
              orderId,
              returnOrderId,
              productId,
              variantId,
              lineItemId,
              bundleItemId,
              returnReason: returnReason?.globalId || "",
              questionAnswerPairs,
            });
          }}
        />
      )}
    </FormModalContent>
  );
};

export const StageThreeQuestions: React.FC<any> = () => {
  return (
    <QuestionFormProvider stage={3}>
      <StageThreeQuestionsChild />
    </QuestionFormProvider>
  );
};
