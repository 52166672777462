import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import { Shipment } from "types/summary";
import { ShippingMethodButton } from ".";

interface ShippingDetailsButtonsRowProps {
  shipment: Shipment;
}

export const ShippingDetailsButtonsRow: React.FC<ShippingDetailsButtonsRowProps> = ({
  shipment,
}): JSX.Element | null => {
  const { t } = useTranslation("orderFlow");
  const { customsFormUrl, shipmentLabelLink, trackingUrl, qrCodeUrl } = shipment;
  const labelButtonUrl = qrCodeUrl ? qrCodeUrl : shipmentLabelLink;
  const labelButtonLabel = qrCodeUrl ? t("shipmentInfo.button.qrCode") : t("shipmentInfo.button.downloadLabel");

  if (!customsFormUrl && !shipmentLabelLink && !trackingUrl) return null;

  return (
    <Grid style={{ paddingTop: "0.5rem" }}>
      <Grid.Row>
        {customsFormUrl && <ShippingMethodButton url={customsFormUrl}>Customs Form</ShippingMethodButton>}
        {labelButtonUrl && labelButtonLabel && (
          <ShippingMethodButton url={labelButtonUrl}>{labelButtonLabel}</ShippingMethodButton>
        )}
        {trackingUrl && (
          <ShippingMethodButton basic url={trackingUrl}>
            {t("shipmentInfo.button.trackShipment")}
          </ShippingMethodButton>
        )}
      </Grid.Row>
    </Grid>
  );
};
