import React from "react";
import { PaymentDetails } from ".";
import { BackgroundImage, Overview } from "components";
import { Grid, Message, Container, Card, Header } from "semantic-ui-react";
import { ReturnItemsSummary } from "components";
import { StoreState } from "reducers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const DesktopView: React.FC<any> = (): JSX.Element => {
  const currentShop = useSelector((state: StoreState) => state.currentShop);
  const stripeChargesEnabled = currentShop.stripeAccountId
    ? currentShop.stripeChargesEnabled
    : currentShop.discardedStripeChargesEnabled;

  const errorMessage = () => {
    return (
      <Message
        error
        header="Payment Error"
        content={`Oops! It looks like there's something wrong with our payment provider. Please reach out to our support team at ${currentShop.customerSupportEmail}.`}
      />
    );
  };

  const { t } = useTranslation("orderFlow");
  const returnItemSummaryTitle = t("orderSummary.returnedItems");

  return (
    <BackgroundImage>
      <div style={{ display: "flex" }}>
        <Grid container>
          <Grid.Column>
            <Grid centered>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <Container style={{ height: "80vh", overflow: "auto", marginTop: "4rem" }}>
                  {!stripeChargesEnabled && errorMessage()}
                  <Card fluid style={{ padding: "0 2rem" }}>
                    <ReturnItemsSummary title={returnItemSummaryTitle} />
                  </Card>
                  <Card fluid style={{ paddingBottom: "1rem" }}>
                    <Overview />
                  </Card>
                </Container>
              </Grid.Column>
              {stripeChargesEnabled && (
                <Grid.Column mobile={16} tablet={16} computer={8}>
                  <Container style={{ marginTop: "4rem" }}>
                    <PaymentDetails />
                  </Container>
                </Grid.Column>
              )}
            </Grid>
          </Grid.Column>
        </Grid>
      </div>
    </BackgroundImage>
  );
};
