import { getFormattedPriceString } from "helpers/currency";
import { ShippingMethod } from "types";

export function formatPriceString(cents: number, currency: string): string {
  const formattedPriceString = getFormattedPriceString(cents, currency);

  return currency === "GBP" ? `${formattedPriceString} (incl. VAT)` : formattedPriceString;
}

export function getShippingMethodInfo(method: ShippingMethod) {
  const fallbackCurrency = "USD";
  const currency = method.priceCurrency ?? fallbackCurrency;
  const requiresPayment = method.customerBorne;
  const priceCents = method.priceCents;
  const price = getFormattedPriceString(priceCents, currency);

  return { requiresPayment, price, currency, priceCents };
}
