import React from "react";
import { Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "reducers";
import { ReturnSteps, setLocale } from "actions";

export const LocaleSelect: React.FC = () => {
  const shopLocales = useSelector((state: StoreState) => state.currentShop.shopLocales);
  const locale = useSelector((state: StoreState) => state.locale);
  const returnStep = useSelector((state: StoreState) => state.returnStep);
  const dispatch = useDispatch();

  const showLocaleSelector = returnStep === ReturnSteps.customerInfo || !returnStep;

  if (!showLocaleSelector || shopLocales.length < 2) return null;

  return (
    <div style={{ paddingRight: "5px" }}>
      <Dropdown text={`🌏ㅤ${locale.name}`}>
        <Dropdown.Menu>
          {shopLocales.map((shopLocale) => (
            <Dropdown.Item
              key={shopLocale.code}
              text={shopLocale.name}
              onClick={() => dispatch(setLocale(shopLocale))}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
