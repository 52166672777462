import Cookie from "js-cookie";
import { ShopLocale } from "actions";

// Use dispatch(setLocale(shopLocale)) to set locale, setCustomerLocaleCookie will be called inside setLocale
export const setCustomerLocaleCookie = (locale: ShopLocale) => Cookie.set("postco-360-locale", JSON.stringify(locale));
export const clearCustomerLocaleCookie = () => Cookie.remove("postco-360-locale");
export const getStoredCustomerLocale = () => {
  try {
    return JSON.parse((Cookie.get("postco-360-locale") || window.LOCALE) as string) as ShopLocale;
  } catch (e) {
    return null;
  }
};

export const setShopLocale = (locale: ShopLocale) => (window.LOCALE = JSON.stringify(locale));
