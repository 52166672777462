import React from "react";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import styles from "./Descriptions.module.css";
import { ShippingMethod } from "types";
import parse from "html-react-parser";

interface MailDescriptionProps {
  method: ShippingMethod;
}

export const MailDescription: React.FC<MailDescriptionProps> = ({ method }) => {
  const { t } = useTranslation("orderFlow");

  return (
    <div className={styles.container}>
      <div style={{ width: 35 }}>
        <Icon name="mail outline" size="large" />
      </div>
      <div className={styles.textBox}>
        <div className={styles.titleContainer}>
          <strong>{method.title}</strong>
        </div>
        {parse(method.description)}
      </div>
      <div className={styles.rightTextBox}>
        <strong>{t("rates.varies")}</strong>
      </div>
    </div>
  );
};
