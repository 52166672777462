import { ShippingAddress } from "actions";
import { useFormikContext } from "formik";
import { useAuspostData } from "hooks/useAuspostData";
import React, { useEffect } from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";

export interface AuspostData {
  zip: string;
  city: string;
  state_code: string;
}

export const AuspostCityField = () => {
  const { errors, values, touched, setFieldValue } = useFormikContext<ShippingAddress>();
  const { city, zip } = values;

  // Fetch AuspostPostcode data
  let { auspostData, isLoading } = useAuspostData(values.province);

  // Filter out city options based on selected zip
  if (zip) {
    auspostData = auspostData?.filter((data: AuspostData) => data.zip === zip);
  }

  const uniqueCities = [...new Set(auspostData?.map((data: AuspostData) => data.city))] as string[];
  const cityFieldOptions = uniqueCities.map((city: string) => ({
    text: city,
    value: city,
  }));

  // Dropdowns value setter
  function handleCityChange(_e: React.SyntheticEvent, data: DropdownProps) {
    const newCity = data.value as string;
    setFieldValue("city", newCity);
  }

  useEffect(() => {
    setFieldValue("city", city?.toUpperCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);

  return (
    <Dropdown
      error={touched.city && !!errors.city}
      name="city"
      loading={isLoading}
      disabled={!values.province || isLoading}
      onChange={handleCityChange}
      options={cityFieldOptions}
      placeholder={"Select suburb"}
      value={values.city}
      fluid
      clearable
      search
      selection
    />
  );
};
