import React, { useEffect } from "react";
import { Button } from "components/Custom";
import { ProductCard } from "./ProductCard";
import styles from "./ExchangeProductSelector.module.css";
import { CollectionProduct, CollectionTab } from "./types";
import { Loading } from "components";
import { useAllCollectionsProducts } from "./hooks";
import { NoProductsFound } from "./NoProductsFound";

interface Props {
  sortedCollectionTabs: CollectionTab[];
}

export const AllCollectionsProducts = ({ sortedCollectionTabs }: Props) => {
  const allTab = sortedCollectionTabs.find((tab) => tab.id === "all")!;
  const sortedCollectionIds = sortedCollectionTabs.reduce((acc: string[], tab: CollectionTab) => {
    if (!["same_product", "all"].includes(tab.id)) {
      acc.push(tab.id);
    }
    return acc;
  }, []);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage, isFetchingNextPage, autoFetchNextPage } =
    useAllCollectionsProducts(sortedCollectionIds);

  const uniqueProducts = data.pages.reduce((acc: CollectionProduct[], page) => {
    page.products.forEach((product) => {
      if (!acc.some((p) => p.id === product.id)) {
        acc.push(product);
      }
    });
    return acc;
  }, []);

  useEffect(() => {
    if (!isFetching && autoFetchNextPage) {
      fetchNextPage();
    }
  }, [isFetching]);

  const loading = isLoading || (uniqueProducts.length === 0 && isFetchingNextPage);

  if (loading) {
    return <Loading />;
  }

  return uniqueProducts.length === 0 ? (
    <NoProductsFound title="NO PRODUCTS FOUND" description="No exchangeable products available" />
  ) : (
    <div className={styles.productGridContainer}>
      <div className={styles.productCardGrid}>
        {uniqueProducts.map((product) => (
          <ProductCard product={product} key={product.id} />
        ))}
      </div>
      <div className={styles.footerSection}>
        {hasNextPage && (
          <div className={styles.loadMoreButtonContainer}>
            <Button
              color="primaryColor"
              loading={isFetchingNextPage}
              disabled={!hasNextPage || isFetchingNextPage}
              onClick={() => fetchNextPage()}
            >
              LOAD MORE
            </Button>
          </div>
        )}
        <div className={styles.footerDescription}>
          <p>
            Viewing {uniqueProducts.length} exchangeable product(s) of {allTab.productsCount} product(s)
          </p>
        </div>
      </div>
    </div>
  );
};
