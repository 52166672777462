import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Header, Icon, Message, Modal } from "semantic-ui-react";
import { Formik, FormikProps } from "formik";
import { ShippingAddress, setFromAddress, ReturnSteps, updateReturnOrderThenContinue } from "actions";
import { StoreState } from "reducers";
import styles from "./DropOffModal.module.css";
import { useTranslation } from "react-i18next";
import { AddressFields, Button, ContactDetailsFields, ShopifyAddressFields } from "components";
import { ShippingMethod } from "types";
import { getValidationSchema } from "./helpers";
import { useShippingFee } from "hooks/useShippingFee";
import { useHistory } from "react-router-dom";

interface DropOffModalProps {
  method: ShippingMethod;
  isOpen: boolean;
  onClose: () => void;
}

export const DropOffModal: React.FC<DropOffModalProps> = ({ method, isOpen, onClose }) => {
  const courier = method.courier;
  const order = useSelector((state: StoreState) => state.order);
  const dispatch = useDispatch();
  const { t } = useTranslation("orderFlow");
  const isShopify = useSelector((state: StoreState) => state.currentShop.ecomProvider === "Shopify");
  const fromAddress = useSelector((state: StoreState) => state.returnItems.fromAddress);
  const history = useHistory();
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const { isSuccess, isError, error, isLoading } = useShippingFee("dropOffFee", method, fromAddress, isSubmit, {
    onError: (_: Error) => {
      setIsSubmit(false);
    },
    retry: false,
  });

  useEffect(() => {
    if (isSuccess) {
      setIsSubmit(false);
      if (method.fixedPrice || !method.customerBorne) {
        dispatch(updateReturnOrderThenContinue(history, ReturnSteps.shippingMethod));
      } else {
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const submitForm = (values: ShippingAddress) => {
    dispatch(setFromAddress(values));
    setIsSubmit(true);
  };

  if (!courier) return null;

  return (
    <Modal
      centered={false}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      dimmer="blurring"
      onClose={onClose}
      open={isOpen}
      size="small"
      className={"dropoffModal"}
    >
      <div className={styles.headerContainer}>
        <div>
          <Header as="h3" style={{ marginBottom: "2px" }}>
            {t("customerAddress")}
          </Header>
          <span className={styles.subheader}>{t("dropOffModal.description")}</span>
        </div>
        <Icon className={styles.headerIcon} name="close" onClick={onClose} />
      </div>
      <Modal.Content>
        <Modal.Description>
          {isError && (
            <Message negative>
              <Message.Header>Error calculating rate</Message.Header>
              <p>{error?.message}</p>
            </Message>
          )}
          <Formik
            initialValues={{ ...order.shippingAddress, email: order.shippingAddress.email || (order.email as string) }}
            onSubmit={submitForm}
            validationSchema={getValidationSchema(isShopify, courier.name)}
            validateOnChange={false}
            enableReinitialize
          >
            {(formikProps: FormikProps<ShippingAddress>): JSX.Element => {
              const { handleSubmit } = formikProps;
              return (
                <Form onSubmit={handleSubmit}>
                  <br />
                  {isShopify ? (
                    <ShopifyAddressFields header={t("addressForm.address")} validateShippyProFields />
                  ) : (
                    <AddressFields header={t("addressForm.address")} />
                  )}
                  <ContactDetailsFields phoneNumber={order.shippingAddress.phone} />
                  <div className={styles.submitContainer}>
                    <Button disabled={isLoading} loading={isLoading} color="primaryColor" fluid type="submit">
                      {isLoading ? "Validating address ..." : t("continue")}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
