import React from "react";
import { Card, Grid, Header } from "semantic-ui-react";
import { getAddressFormHeader } from "pages/ShippingAddressDetails/helpers";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useTranslation } from "react-i18next";
import { ShopifyShippingAddressSummary, ShippingAddressSummary, ContactDetails } from "components";
import { Link } from "react-router-dom";
import { changeReturnStep, ReturnSteps } from "actions";
import { useReturnOrderStatus } from "hooks/useReturnOrder";
import { isMobile } from "react-device-detect";

export const ShippingAddressDetailsSummary: React.FC<any> = () => {
  const autoHideValidShippingAddress = useSelector(
    (state: StoreState) => state.currentShop.autoHideValidShippingAddress,
  );
  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();
  const cart = useSelector((state: StoreState) => state.returnItems.cart);
  const addressHeader = getAddressFormHeader(autoHideValidShippingAddress, cart, t);
  const isShopify = useSelector((state: StoreState) => state.currentShop.ecomProvider === "Shopify");
  const shippingAddress = useSelector((state: StoreState) => state.order.shippingAddress);
  const { editableReturnOrder } = useReturnOrderStatus();

  const handleClick = (): void => {
    dispatch(changeReturnStep(ReturnSteps.shippingAddress));
  };

  return (
    <div style={{ borderBottom: !isMobile ? "1px solid #D9D9D9" : "", padding: isMobile ? "4px 16px" : "30px 0px" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
        <span>
          <Header as={isMobile ? "h4" : "h3"}>{addressHeader}</Header>
        </span>
        {editableReturnOrder && (
          <Link to="#" style={{ textDecoration: "underline", fontSize: "1rem" }} onClick={handleClick}>
            {`${t(`shippingMethodSummary.edit`)}`}
          </Link>
        )}
      </div>
      <Grid columns={2}>
        <Grid.Column>
          <Card.Header>
            <span style={{ textDecoration: "underline" }}>{t("addressForm.address")}</span>
            {isShopify ? (
              <ShopifyShippingAddressSummary shippingAddress={shippingAddress} />
            ) : (
              <ShippingAddressSummary shippingAddress={shippingAddress} />
            )}
          </Card.Header>
        </Grid.Column>
        <ContactDetails shippingAddress={shippingAddress} />
      </Grid>
    </div>
  );
};
