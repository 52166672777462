import React from "react";
import { Container, Button } from "semantic-ui-react";
import { Dot } from "pure-react-carousel";
import styles from "./ImageCarousel.module.css";

interface ImageDotProps {
  imgUrls: string[];
}

export const ImageDot: React.FC<ImageDotProps> = ({ imgUrls }): JSX.Element => {
  return (
    <Container className={styles.slideDotImageContainer}>
      <Button.Group>
        {imgUrls.map((img, index) => (
          <Dot slide={index} className={styles.slideDot} key={index}>
            <img src={img} alt={`pagination dot ${index}`} key={index} className={styles.slideDotImage} />
          </Dot>
        ))}
      </Button.Group>
    </Container>
  );
};
