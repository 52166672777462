import { useState } from "react";
import { CurrentShop } from "actions";
import { StripeConstructorOptions, loadStripe } from "@stripe/stripe-js";

function getPublishableKey(currentShop: CurrentShop, fallbackToDiscardedStripeAccount?: boolean): string {
  const stripeParentAccountId = currentShop.stripeParentAccountId;
  const publishableKeys: Record<string, string> = {
    acct_1G0Hm0Bf6kkmCY2c: process.env.REACT_APP_STRIPE_MY_PUBLISHABLE_KEY as string,
    acct_1JcJbMBZWYJ9s2te: process.env.REACT_APP_STRIPE_SG_PUBLISHABLE_KEY as string,
  };

  if (fallbackToDiscardedStripeAccount) {
    if (currentShop.stripeAccountId) {
      return publishableKeys[stripeParentAccountId];
    } else {
      return publishableKeys[currentShop.discardedStripeParentAccountId];
    }
  }

  const onboarded = !currentShop.stripePendingOnboarding;
  if (currentShop.stripeAccountId && onboarded) {
    return publishableKeys[stripeParentAccountId];
  }

  if (currentShop.currency === "MYR") {
    return process.env.REACT_APP_STRIPE_MY_PUBLISHABLE_KEY as string;
  } else {
    return process.env.REACT_APP_STRIPE_SG_PUBLISHABLE_KEY as string;
  }
}

function stripeAccount(stripeAccountId: string) {
  return {
    stripeAccount: stripeAccountId,
  };
}

function getStripeOptions(
  currentShop: CurrentShop,
  fallbackToDiscardedStripeAccount?: boolean,
): StripeConstructorOptions | undefined {
  const stripeAccountId = currentShop.stripeAccountId;

  if (fallbackToDiscardedStripeAccount) {
    if (stripeAccountId) {
      return stripeAccount(stripeAccountId);
    } else {
      return stripeAccount(currentShop.discardedStripeAccountId);
    }
  }

  const onboarded = !currentShop.stripePendingOnboarding;
  if (stripeAccountId && onboarded) {
    return stripeAccount(stripeAccountId);
  }
  return undefined;
}

export const useStripePromise = (currentShop: CurrentShop, fallbackToDiscardedStripeAccount?: boolean) => {
  const [stripePromise] = useState(() =>
    loadStripe(
      getPublishableKey(currentShop, fallbackToDiscardedStripeAccount),
      getStripeOptions(currentShop, fallbackToDiscardedStripeAccount),
    ),
  );
  return stripePromise;
};
