import React, { Fragment } from "react";
import { Field, FieldProps } from "formik";
import { Form, Header } from "semantic-ui-react";
import styles from "./BankAccountFields.module.css";
import { BankAccount, EwalletAccount, ReturnSteps, ShippingAddress } from "actions";
import { useTranslation } from "react-i18next";
import { ibanCountries } from "./constants";
import * as ibantools from "ibantools";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

type OnlineTransfer = {
  bankAccount: BankAccount;
  ewalletAccount: EwalletAccount;
};
type FormValues = ShippingAddress & OnlineTransfer;

type BankAccountFieldType = {
  country: string;
};

export const BankAccountFields: React.FC<BankAccountFieldType> = ({ country }) => {
  const { t } = useTranslation("orderFlow");
  const ifscRequired = country === "India";
  const returnStep = useSelector((state: StoreState) => state.returnStep);
  const receivablePaymentDetailsPage = returnStep === ReturnSteps.receivablePaymentDetails;
  const className = receivablePaymentDetailsPage
    ? styles.bankingDetailsHeaderMainPage
    : styles.bankingDetailsHeaderContainer;

  const validateIban = (value: string) => {
    if (!value) return;
    let errorMessage;
    //apply iban validation for iban mandatory countries
    if (ibanCountries.includes(country)) {
      value = value.replace(/ /g, "");
      if (!ibantools.isValidIBAN(value)) {
        errorMessage = "Please enter valid IBAN account number";
      }
    }
    return errorMessage;
  };
  return (
    <Fragment>
      <div className={className}>
        <Header sub={!receivablePaymentDetailsPage}>{t("bankAccountField.header")}</Header>
      </div>

      <Form.Group widths="equal">
        <Field name="bankAccount.bank">
          {({ field, form: { touched, errors } }: FieldProps<BankAccount["bank"], FormValues>): JSX.Element => (
            <Form.Field>
              <label>{t("bankAccountField.bankName")}:</label>
              <Form.Input error={touched.bankAccount?.bank && errors.bankAccount?.bank} type="text" {...field} />
            </Form.Field>
          )}
        </Field>
        <Field name="bankAccount.beneficiary">
          {({ field, form: { touched, errors } }: FieldProps<BankAccount["beneficiary"], FormValues>): JSX.Element => (
            <Form.Field>
              <label>{t("bankAccountField.accountName")}:</label>
              <Form.Input
                error={touched.bankAccount?.beneficiary && errors.bankAccount?.beneficiary}
                type="text"
                {...field}
              />
            </Form.Field>
          )}
        </Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Field name="bankAccount.number" validate={validateIban}>
          {({ field, form: { touched, errors } }: FieldProps<BankAccount["number"], FormValues>): JSX.Element => (
            <Form.Field>
              <label>{t("bankAccountField.accountNumber")}:</label>
              <Form.Input error={touched.bankAccount?.number && errors.bankAccount?.number} type="text" {...field} />
            </Form.Field>
          )}
        </Field>
        {ifscRequired && (
          <Field name="bankAccount.ifsc">
            {({ field, form: { touched, errors } }: FieldProps<BankAccount["ifsc"], FormValues>): JSX.Element => (
              <Form.Field>
                <label>{t("bankAccountField.ifscCode")}:</label>
                <Form.Input error={touched.bankAccount?.ifsc && errors.bankAccount?.ifsc} type="text" {...field} />
              </Form.Field>
            )}
          </Field>
        )}
      </Form.Group>
    </Fragment>
  );
};
