import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import React from "react";
import { setCurrentReturnItemProperty } from "actions";
import { getFormattedAmount, handleContinue } from "../helpers";
import parse from "html-react-parser";
import { ReturnMethodTypeButton } from "./ReturnMethodTypeButton";

type Props = {
  bonusCredit: number;
  prioritized: boolean;
};

export const ExchangeButton = ({ bonusCredit, prioritized }: Props) => {
  const locale = useSelector((state: StoreState) => state.locale.code);
  const uiSetting = useSelector((state: StoreState) => state.currentShop.userInterfaceSetting);
  const currency = useSelector((state: StoreState) => state.order.currency);
  const dispatch = useDispatch();
  const displayPrice = useSelector((state: StoreState) => state.formModal.selectedLineItem!.displayPrice);
  const returnItemValue = getFormattedAmount(displayPrice.cents, currency);
  const formattedExchangeBonusCredit = getFormattedAmount(bonusCredit, currency);

  const exchangeTitleTranslations = bonusCredit
    ? uiSetting.bonusCreditExchangeTitleTranslations
    : uiSetting.standardExchangeTitleTranslations;
  const exchangeDescriptionTranslations = bonusCredit
    ? uiSetting.bonusCreditExchangeDescriptionTranslations
    : uiSetting.standardExchangeDescriptionTranslations;

  const exchangeTitle = exchangeTitleTranslations[locale]
    ?.replaceAll("{{ return_item_value }}", returnItemValue)
    ?.replaceAll("{{ bonus_credit_amount }}", formattedExchangeBonusCredit);
  const exchangeDescription =
    exchangeDescriptionTranslations[locale]
      ?.replaceAll("{{ return_item_value }}", returnItemValue)
      ?.replaceAll("{{ bonus_credit_amount }}", formattedExchangeBonusCredit) ?? "";

  return (
    <ReturnMethodTypeButton
      title={exchangeTitle}
      description={parse(exchangeDescription)}
      isPrioritized={prioritized}
      bonusCredit={bonusCredit}
      onClick={() => {
        if (bonusCredit) {
          dispatch(setCurrentReturnItemProperty("bonusCreditAmountCents", bonusCredit));
        }
        handleContinue("exchange", dispatch);
      }}
    />
  );
};
