import React, { useState } from "react";
import { formModalPush, FormModalSteps, ReturnMethod, setCurrentReturnItemProperty } from "actions";
import { Button as CustomButton, PageHeader } from "components";
import { Button, Icon } from "semantic-ui-react";
import styles from "./ReturnMethodsSelector.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";

interface Props {
  exchangeMethods: ReturnMethod[];
}

const listingPriorities: Record<string, number> = {
  same_product: 0,
  advanced: 1,
  other_product: 2,
};

export const ExchangeMethodsSelector = ({ exchangeMethods }: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const { t } = useTranslation("orderFlow");
  const selectedLineItem = useSelector((state: StoreState) => state.formModal.selectedLineItem);
  const returningProductId = selectedLineItem?.productId || null;
  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);
  const previouslySelectedReturnMethod = Boolean(currentReturnItem.returnMethod.id)
    ? currentReturnItem.returnMethod
    : null;

  const sortedExchangeMethods = exchangeMethods.sort((a: ReturnMethod, b: ReturnMethod) => {
    return listingPriorities[a.name] - listingPriorities[b.name];
  });
  const [selectedExchangeMethod, setSelectedExchangeMethod] = useState<ReturnMethod | null>(
    previouslySelectedReturnMethod ?? sortedExchangeMethods[0],
  );
  const advancedExchange = sortedExchangeMethods.find((method) => method.name === "advanced");
  const otherProductExchange = sortedExchangeMethods.find((method) => method.name === "other_product");

  // Skip the selector when only one ExchangeMethod is available
  if (sortedExchangeMethods.length === 1) {
    const exchangeMethod = sortedExchangeMethods[0];
    dispatch(setCurrentReturnItemProperty("returnMethod", exchangeMethod));

    if (exchangeMethod.name === "same_product") {
      setSelectedProduct();
      dispatch(formModalPush(FormModalSteps.variantsSelector, false));
    } else if (exchangeMethod.name === "advanced") {
      dispatch(formModalPush(FormModalSteps.exchangeProductsSelector, false));
      dispatch(setCurrentReturnItemProperty("selectedCollectionId", "all"));
    } else if (exchangeMethod.name === "other_product") {
      dispatch(formModalPush(FormModalSteps.otherProductForm, false));
    }

    return null;
  } else if (!Boolean(otherProductExchange)) {
    // Skip the selector and go straight to the ExchangeProductSelector when there is no other
    // product exchange. No longer need this check when other_product is removed from the app.
    const exchangeMethod = sortedExchangeMethods[0];
    if (exchangeMethod.name === "same_product") {
      setSelectedProduct();
    }

    dispatch(
      setCurrentReturnItemProperty(
        "selectedCollectionId",
        exchangeMethod.name === "same_product" ? "same_product" : "all",
      ),
    );
    dispatch(setCurrentReturnItemProperty("returnMethod", exchangeMethod));
    dispatch(formModalPush(FormModalSteps.exchangeProductsSelector, false));

    return null;
  }

  function setSelectedProduct() {
    dispatch(
      setCurrentReturnItemProperty("selectedProduct", {
        id: returningProductId,
        title: selectedLineItem?.productTitle as string,
        image: { src: selectedLineItem?.imageUrl as string },
      }),
    );
  }

  // We will only render a list of ExchangeMethods if there are more than one exchange methods
  // and other product exchange is one of them.
  // We can remove this function and also render null for this Component when
  // other product exchange is removed from the app.
  function handleContinue() {
    dispatch(setCurrentReturnItemProperty("returnMethod", selectedExchangeMethod));
    switch (selectedExchangeMethod?.name) {
      case "same_product":
        setSelectedProduct();
        dispatch(setCurrentReturnItemProperty("selectedCollectionId", "same_product"));
        const nextStep = !Boolean(advancedExchange)
          ? FormModalSteps.variantsSelector
          : FormModalSteps.exchangeProductsSelector;
        dispatch(formModalPush(nextStep));
        break;
      case "advanced":
        dispatch(setCurrentReturnItemProperty("selectedCollectionId", "all"));
        dispatch(formModalPush(FormModalSteps.exchangeProductsSelector));
        break;
      case "other_product":
        dispatch(formModalPush(FormModalSteps.otherProductForm));
        break;
    }
  }

  return (
    <div>
      <PageHeader header={t("selectExchangeMethod") as string} />
      {sortedExchangeMethods.map((exchangeMethod) => {
        const selected = selectedExchangeMethod?.id === exchangeMethod.id;
        return (
          <div className={styles.buttonContainer} key={exchangeMethod.id}>
            <Button
              basic
              color={selected ? "green" : undefined}
              fluid
              onClick={() => setSelectedExchangeMethod(exchangeMethod)}
            >
              <div className={styles.buttonContent}>
                <div className={styles.descriptionContainer}>
                  <div className={styles.textBox}>
                    <div className={styles.titleContainer}>
                      <strong>{exchangeMethod.displayName}</strong>
                    </div>
                    <p>{exchangeMethod.description}</p>
                  </div>
                </div>
                <Icon
                  color="green"
                  name="check circle"
                  style={{
                    visibility: selected ? "visible" : "hidden",
                  }}
                />
              </div>
            </Button>
          </div>
        );
      })}
      <div className={styles.buttonContainer}>
        <CustomButton color="primaryColor" disabled={!selectedExchangeMethod} fluid onClick={() => handleContinue()}>
          {t("continue")}
        </CustomButton>
      </div>
    </div>
  );
};
