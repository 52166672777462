import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, CheckboxProps, Message } from "semantic-ui-react";
import { Formik, FormikProps } from "formik";
import { Button, CustomCheckbox, ContactDetailsFields, AddressFields, ShopifyAddressFields } from "components";
import { useTranslation } from "react-i18next";
import { FromAddress, Courier, setFromAddress, ReturnSteps, updateReturnOrderThenContinue } from "actions";
import { getValidationSchema } from "./helper";
import { sanitizeAddress } from "components/AddressFields/helper";
import styles from "./FromAddressForm.module.css";
import { TrackingNumberField } from "components/TrackingNumberField/TrackingNumberField";
import { StoreState } from "reducers";
import { useShippingFee } from "hooks/useShippingFee";
import { ShippingMethod } from "types";
import { useHistory } from "react-router-dom";

export interface ExtraFormValues {
  pickupDateTimeInfo?: boolean;
  noPrinter?: boolean;
}

export type FromAddressFormDetails = FromAddress & ExtraFormValues;

interface FromAddressFormProps {
  address: FromAddressFormDetails;
  courier: Courier;
  onDemandPickup?: boolean;
  allowCustomTrackingNumer?: boolean;
  shippingMethod: ShippingMethod;
  setOpenModal: Function;
}

export const FromAddressForm: React.FC<FromAddressFormProps> = ({
  address,
  courier,
  onDemandPickup = false,
  allowCustomTrackingNumer = false,
  shippingMethod,
  setOpenModal,
}) => {
  const { t } = useTranslation("orderFlow");
  const shop = useSelector((state: StoreState) => state.currentShop);
  const { hasPreprintedReturnLabel } = shop;
  const isShopify = useSelector((state: StoreState) => state.currentShop.ecomProvider === "Shopify");
  const fromAddress = useSelector((state: StoreState) => state.returnItems.fromAddress);
  const history = useHistory();

  const dispatch = useDispatch();
  const validationSchema = getValidationSchema(onDemandPickup, shop, isShopify);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const { isSuccess, isError, error, isLoading } = useShippingFee("pickupFee", shippingMethod, fromAddress, isSubmit, {
    onError: (_: Error) => {
      setIsSubmit(false);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setIsSubmit(false);
      if (shippingMethod && (shippingMethod.fixedPrice || !shippingMethod.customerBorne)) {
        dispatch(updateReturnOrderThenContinue(history, ReturnSteps.shippingMethod));
      } else {
        setOpenModal("none");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOpenModal, isSuccess]);

  const handleFormOnSubmit = (values: FromAddressFormDetails) => {
    dispatch(setFromAddress(values));
    setIsSubmit(true);
  };

  return (
    <>
      {isError && (
        <Message negative>
          <Message.Header>Error calculating rate</Message.Header>
          <p>{error?.message}</p>
        </Message>
      )}

      <Formik
        initialValues={sanitizeAddress(address)}
        onSubmit={handleFormOnSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        {(formikProps: FormikProps<FromAddressFormDetails>): JSX.Element => {
          const { handleSubmit, setFieldValue, values } = formikProps;

          return (
            <Form onSubmit={handleSubmit}>
              <Fragment>
                {isShopify ? (
                  <ShopifyAddressFields header={t("addressForm.pickupHeader")} />
                ) : (
                  <AddressFields header={t("addressForm.pickupHeader")} />
                )}
                <ContactDetailsFields phoneNumber={address.phone} />
                {onDemandPickup ? (
                  <CustomCheckbox
                    content="Your return parcel will be scheduled to be collected on the next business day after your return request has been approved."
                    fieldName="pickupDateTimeInfo"
                    onChange={(_e: React.FormEvent, data: CheckboxProps): void => {
                      setFieldValue("pickupDateTimeInfo", data.checked as boolean);
                    }}
                  />
                ) : (
                  // Normal Pickup and allowCustomTrackingNumber only for VNCPost now
                  allowCustomTrackingNumer && (
                    <Fragment>
                      <CustomCheckbox
                        content="I don't have a printer to print the shipment label"
                        fieldName="noPrinter"
                        onChange={(_e: React.FormEvent, data: CheckboxProps): void => {
                          setFieldValue("noPrinter", data.checked as boolean);
                          setFieldValue("trackingNumber", "");
                        }}
                      />
                      {values.noPrinter && (
                        <div className={styles.trackingNumberContainer}>
                          <TrackingNumberField courier={courier} hasPreprintedReturnLabel={hasPreprintedReturnLabel} />
                        </div>
                      )}
                    </Fragment>
                  )
                )}
              </Fragment>
              <div className={styles.submitContainer}>
                <Button disabled={isLoading} loading={isLoading} color="primaryColor" fluid type="submit">
                  {isLoading ? "Validating address ..." : t("continue")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
