import { ChangeReturnStepAction } from "./returnStep";
import { ClearErrorsAction, SetErrorAction, SetErrorsAction } from "./errors";
import { ClearLoadingAction, SetLoadingAction } from "./loading";
import {
  AddOrderAction,
  UpdateShippingAddressAction,
  UpdateLineItemsReturnabilityAction,
  UpdateBankAccountAction,
  UpdateEwalletAccountAction,
  UpdateStripePaymentMethodAction,
  UpdateStripePaymentIntentIdAction,
  UpdateReturnOrderIdAction,
} from "./order";

import { ResetStoreAction } from "./reset";
import {
  AppendCurrentReturnItemAction,
  AddReturnItemsAction,
  AddBonusCreditInfoAction,
  ClearCurrentReturnItemAction,
  RemoveReturnItemFromCartAction,
  SetCurrentReturnItemPropertyAction,
  SetReturnItemDirtyAction,
  SetShippingMethodAction,
  SetFromAddressAction,
  SetCustomerBorneShippingAction,
  SetQuestionsAction,
  SetInstantExchangeAction,
  SetP2PReturnsAction,
} from "./returnItems";
import { NormalSampleAction, ThunkSampleAction } from "./sample";
import {
  CloseFormModalAction,
  OpenFormModalAction,
  ResumeFormModalAction,
  SetLineItemInFormModalAction,
  FormModalPushAction,
  FormModalGoBackAction,
} from "./formModal";
import {
  ClearConfigLoadingAction,
  SetBackgroundImageUrlAction,
  SetPrimaryColorAction,
  SetLogoImageUrlAction,
  SetFaviconUrlAction,
  SetTitleAction,
  SetMobileBackgroundImageUrlAction,
} from "./frontendConfigs";
import { SetLocaleAction } from "./locale";
import { SetCurrentShopAction, SetShopSlugAction } from "./currentShop";
import { AddHolidayAction } from "./holidays";

export enum ActionTypes {
  addOrder = "addOrder",
  addHoliday = "addHoliday",
  addQuestions = "addQuestions",
  toggleMultiSelectionAnswer = "toggleMultiSelectionAnswer",
  toggleSingeChoiceAnswer = "toggleSingleChoiceAnswer",
  appendCurrentReturnItem = "appendCurrentReturnItem",
  addReturnItems = "addReturnItems",
  addBonusCreditInfo = "addBonusCreditInfo",
  changeReturnStep = "changeReturnStep",
  clearConfigLoading = "clearConfigLoading",
  clearCurrentReturnItem = "clearCurrentReturnItem",
  clearErrors = "clearErrors",
  closeFormModal = "closeFormModal",
  clearLoading = "clearLoading",
  formModalGoBack = "formModalGoBack",
  formModalPush = "formModalPush",
  normalSample = "normalSample",
  openFormModal = "openFormModal",
  resetStore = "resetStore",
  resumeFormModal = "resumeFormModal",
  setBackgroundImageUrl = "setBackgroundImageUrl",
  setMobileBackgroundImageUrl = "setMobileBackgroundImageUrl",
  setCurrentShop = "setCurrentShop",
  setCurrentReturnItemProperty = "setCurrentReturnItemProperty",
  setLogoImageUrls = "setLogoImageUrls",
  setPrimaryColor = "setPrimaryColor",
  setReturnItemDirty = "setReturnItemDirty",
  setShopSlug = "setShopSlug",
  setShopCountry = "setShopCountry",
  setError = "setError",
  setErrors = "setErrors",
  setInstantExchange = "setInstantExchange",
  setP2PReturns = "setP2PReturns",
  setLineItemInFormModal = "setLineItemInFormModal",
  setLocale = "setLocale",
  setLoading = "setLoading",
  setLocationSearch = "setLocationSearch",
  setShippingMethod = "setShippingMethod",
  setCustomerBorneShipping = "setCustomerBorneShipping",
  setSearchField = "setSearchField",
  setFromAddress = "setFromAddress",
  setQuestions = "setQuestions",
  thunkSample = "thunkSample",
  updateShippingAddress = "updateShippingAddress",
  updateLineItemsReturnability = "updateLineItemsReturnability",
  updateStripePaymentMethod = "updateStripePaymentMethod",
  updateStripePaymentIntentId = "updateStripePaymentIntentId",
  removeReturnItemFromCart = "removeReturnItemFromCart",
  setFaviconUrl = "setFaviconUrl",
  setTitle = "setTitle",
  setCustomShippingMethodId = "setCustomShippingMethodId",
  updateBankAccount = "updateBankAccount",
  updateEwalletAccount = "updateEwalletAccount",
  updateReturnOrderId = "updateReturnOrderId",
}

export type Action =
  | AddOrderAction
  | AddHolidayAction
  | AddReturnItemsAction
  | AddBonusCreditInfoAction
  | AppendCurrentReturnItemAction
  | ChangeReturnStepAction
  | ClearConfigLoadingAction
  | ClearCurrentReturnItemAction
  | ClearErrorsAction
  | ClearLoadingAction
  | CloseFormModalAction
  | FormModalPushAction
  | FormModalGoBackAction
  | NormalSampleAction
  | OpenFormModalAction
  | ResetStoreAction
  | ResumeFormModalAction
  | SetCurrentReturnItemPropertyAction
  | SetErrorAction
  | SetErrorsAction
  | SetLocaleAction
  | SetLineItemInFormModalAction
  | SetLoadingAction
  | SetShippingMethodAction
  | SetCustomerBorneShippingAction
  | ThunkSampleAction
  | UpdateShippingAddressAction
  | UpdateLineItemsReturnabilityAction
  | SetBackgroundImageUrlAction
  | SetMobileBackgroundImageUrlAction
  | SetPrimaryColorAction
  | SetLogoImageUrlAction
  | SetInstantExchangeAction
  | SetP2PReturnsAction
  | SetShopSlugAction
  | SetCurrentShopAction
  | SetFromAddressAction
  | SetQuestionsAction
  | RemoveReturnItemFromCartAction
  | SetReturnItemDirtyAction
  | SetFaviconUrlAction
  | SetTitleAction
  | UpdateBankAccountAction
  | UpdateEwalletAccountAction
  | UpdateStripePaymentMethodAction
  | UpdateReturnOrderIdAction
  | UpdateStripePaymentIntentIdAction;
