import React, { useState } from "react";
import { ReturnItemsSummary, Button, Title, Overview } from "components";
import { PaymentDetails } from ".";
import { Accordion, Message } from "semantic-ui-react";
import styles from "./MobileView.module.css";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

export const MobileView: React.FC<any> = (): JSX.Element => {
  const [showSubmit, setShowSubmit] = useState(false);
  const { t } = useTranslation("orderFlow");
  const returnItemSummaryTitle = t("orderSummary.itemsToReturn");
  const overviewTitle = t("exchangePaymentPage.header");
  const currentShop = useSelector((state: StoreState) => state.currentShop);
  const stripeChargesEnabled = currentShop.stripeAccountId
    ? currentShop.stripeChargesEnabled
    : currentShop.discardedStripeChargesEnabled;

  const panels = [
    {
      title: { content: <Title>{returnItemSummaryTitle}</Title> },
      content: { content: <ReturnItemsSummary title={returnItemSummaryTitle} /> },
    },
    {
      title: { content: <Title>{overviewTitle}</Title> },
      content: { content: <Overview /> },
    },
  ];

  const errorMessage = () => {
    return (
      <Message
        error
        header="Payment Error"
        content={`Oops! It looks like there's something wrong with our payment provider. Please reach out to our support team at ${currentShop.customerSupportEmail}.`}
      />
    );
  };

  return (
    <div style={{ padding: "0.5rem" }}>
      {!stripeChargesEnabled && errorMessage()}

      <Accordion defaultActiveIndex={[1]} panels={panels} exclusive={false} />

      <div style={{ paddingBottom: "3rem" }}>
        <br />
      </div>
      <div className={styles.bottomButtonContainer}>
        <Button
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
          fluid
          color="primaryColor"
          onClick={() => setShowSubmit(true)}
          disabled={!stripeChargesEnabled}
        >
          {t("exchangePaymentPage.proceedToPayment")}
        </Button>
      </div>

      <BottomSheet open={showSubmit} onDismiss={() => setShowSubmit(false)}>
        <PaymentDetails />
      </BottomSheet>
    </div>
  );
};
