import * as yup from "yup";

export const bankAccountValidationShape = {
  bankAccount: yup.object().shape({
    bank: yup.string().required("Bank name is required"),
    beneficiary: yup.string().required("Account name is required"),
    number: yup.string().required("Account number is required"),
    ifsc: yup.string().test("IFSC code", "IFSC code is required", function (val) {
      const { country } = this.options.context as {
        country: string;
      };
      if (country === "India") {
        return val ? true : false;
      } else {
        return true;
      }
    }),
  }),
};

export const ibanCountries: string[] = [
  "Andorra",
  "Austria",
  "Azerbaijan",
  "Bahrain",
  "Belarus",
  "Belgium",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Estonia",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Holy See (the)",
  "Ireland",
  "Italy",
  "Kazakhstan",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Moldova",
  "Monaco",
  "Netherlands",
  "Portugal",
  "Qatar",
  "Romania",
  "San Marino",
  "Slovak Republic",
  "Slovenia",
  "Spain",
  "Switzerland",
  "Turkey",
  "Ukraine",
  "United Arab Emirates",
];
