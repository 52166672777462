import React from "react";
import { Grid, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ReturnOrderDetails } from "./";
import { Overview } from "./Overview";
import { Summary } from "types";

interface DesktopViewProps {
  summary: Summary;
}

export const DesktopView: React.FC<DesktopViewProps> = ({ summary }): JSX.Element => {
  const { t } = useTranslation("orderFlow");
  const { invoice, returnOrderStatus, returnItems } = summary;

  return (
    <Grid container>
      <Grid.Column>
        <Grid>
          <Grid.Row style={{ padding: "2rem 0 0 1rem" }}>
            <Header as="h2">{t("summary.header")}</Header>
          </Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <ReturnOrderDetails summary={summary} />
          </Grid.Column>
          <Grid.Column mobile={16} table={16} computer={8}>
            <Overview invoice={invoice} returnOrderStatus={returnOrderStatus} returnItems={returnItems} />
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  );
};
