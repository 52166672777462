import * as yup from "yup";
import { CurrentShop } from "actions";
import { contactDetailsValidationShape } from "components/ContactDetailsFields/constants";
import { addressValidationShape } from "components/AddressFields/constants";

function getSupportedPickupCountryValidationShape(shopCountry: CurrentShop["country"]) {
  return {
    country: yup
      .string()
      .required("Please select your address country")
      .test("Pickup country check", "Courier pick-up is currently not available here", (value) => {
        if (value) {
          return shopCountry === value;
        } else {
          return false;
        }
      }),
  };
}

function buildOnDemandPickupValidationShape(shop: CurrentShop) {
  return {
    ...contactDetailsValidationShape,
    ...getSupportedPickupCountryValidationShape(shop.country),
    pickupDateTimeInfo: yup.boolean().oneOf([true], "This field must be checked"),
  };
}
function buildNormalPickupValidationShape(shop: CurrentShop) {
  return {
    ...contactDetailsValidationShape,
    ...getSupportedPickupCountryValidationShape(shop.country),
    noPrinter: yup.boolean(),
    trackingNumber: yup.string().when("noPrinter", {
      is: true,
      then: yup.string().when("country", {
        // Vietnam's VNC Post does not need the tracking number
        is: (country) => country !== "Vietnam",
        then: yup.string().required("Original tracking number is required if you don't have a printer"),
      }),
    }),
  };
}

export function getValidationSchema(onDemandPickup: boolean, shop: CurrentShop, isShopify: boolean) {
  let schema = {};
  if (!isShopify) schema = { ...addressValidationShape };

  if (onDemandPickup) {
    return yup.object().shape({
      ...schema,
      ...buildOnDemandPickupValidationShape(shop),
    });
  } else {
    return yup.object().shape({
      ...schema,
      ...buildNormalPickupValidationShape(shop),
    });
  }
}
