import React from "react";
import { ReturningLineItem as ReturningLineItemType } from "constants/invoice";
import { Grid } from "semantic-ui-react";
import { getFormattedPriceString } from "helpers";
import { useTranslation } from "react-i18next";

interface ReturningLineItemProps {
  lineItem: ReturningLineItemType;
}

export const ReturningLineItem: React.FC<ReturningLineItemProps> = ({ lineItem }): JSX.Element => {
  const { t } = useTranslation("orderFlow");

  let translatedReturnMethod;

  switch (lineItem.returnMethodType) {
    case "Refund":
      translatedReturnMethod = t("summary.returnMethod.refund");
      break;
    case "Store Credit":
      translatedReturnMethod = t("summary.returnMethod.storeCredit");
      break;
    case "Exchange":
      translatedReturnMethod = t("summary.returnMethod.exchange");
      break;
    default:
      translatedReturnMethod = lineItem.returnMethodType;
      break;
  }

  return (
    <React.Fragment>
      <Grid.Column style={{ paddingBlock: "0.5rem" }}>
        <p style={{ marginBottom: 0 }}>{lineItem.name}</p>
        <p style={{ fontStyle: "italic", color: "gray", fontSize: "0.95rem" }}>{translatedReturnMethod}</p>
      </Grid.Column>
      <Grid.Column textAlign="right">
        {getFormattedPriceString(lineItem.presentmentPrice.cents, lineItem.presentmentPrice.currencyIso, {
          useParenthesis: true,
        })}
      </Grid.Column>
    </React.Fragment>
  );
};
