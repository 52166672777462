import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Grid, Icon } from "semantic-ui-react";

export const StaticInstantExchangeOption: React.FC = (): JSX.Element => {
  const { t } = useTranslation("orderFlow");

  return (
    <div style={{ padding: "2rem 0 1rem 0" }}>
      <div style={{ fontWeight: "bold" }}>{t("exchangeOptions.exchangeOptions")}</div>
      <Card fluid style={{ padding: "1rem", paddingLeft: "2rem" }}>
        <Grid columns={2} verticalAlign="middle">
          <Grid.Column width={1}>
            <Icon color="green" name="check circle" />
          </Grid.Column>
          <Grid.Column width={14}>
            <div style={{ fontWeight: "bold" }}>{t("exchangeOptions.instantExchangeLabel")}</div>
            <div style={{ paddingTop: "0.4rem", fontSize: "1rem", color: "black" }}>
              {t("exchangeOptions.instantExchangeDescription")}
            </div>
          </Grid.Column>
        </Grid>
      </Card>
    </div>
  );
};
