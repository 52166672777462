import React, { ReactNode, useState } from "react";
import { Accordion as SemanticAccordion, AccordionContent, AccordionTitle, Icon } from "semantic-ui-react";

type AccordionSectionProps = {
  title: ReactNode;
  content: ReactNode;
  openByDefault?: boolean;
};
export const Accordion = ({ panels }: { panels: AccordionSectionProps[] }) => {
  return (
    <SemanticAccordion>
      {panels.map((section, index) => (
        <AccordionSection key={index} {...section} />
      ))}
    </SemanticAccordion>
  );
};

export const AccordionSection = ({ title, content, openByDefault }: AccordionSectionProps) => {
  const [open, setOpen] = useState(openByDefault);
  return (
    <>
      <AccordionTitle
        style={{ backgroundColor: "#fbfbfb", borderRadius: 10, padding: "12px 16px" }}
        active={open}
        onClick={() => setOpen(!open)}
      >
        <Icon name="dropdown" /> {title}
      </AccordionTitle>
      <AccordionContent active={open}>{content}</AccordionContent>
    </>
  );
};
