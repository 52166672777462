import React from "react";
import { createContext, useContext } from "react";
import { useFeatureFlags, initialFeatureFlags } from "hooks/useFeatureFlags";

const FeatureFlags = createContext(initialFeatureFlags);

export const useFeatureFlagsContext = () => {
  const context = useContext(FeatureFlags);
  if (!context) {
    throw Error("useFeatureFlagsContext must be used within a useFeatureFlagsProvider");
  }
  return context;
};

export const FeatureFlagsProvider = (props: any) => {
  const { featureFlags } = useFeatureFlags();
  return <FeatureFlags.Provider value={featureFlags} {...props} />;
};
