import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "semantic-ui-react";

import {
  ReturnMethodTypeSelector,
  ExtraInformationForm,
  ReturnReasonSelector,
  StageTwoQuestions,
  StageThreeQuestions,
  ReturnMethodsSelector,
  VariantsSelector,
} from "./components";
import { FormModalSteps, clearCurrentReturnItem, closeFormModal } from "actions";
import { StoreState } from "reducers";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { ExchangeProductSelector } from "./components/ExchangeProductSelector/ExchangeProductSelector";
import { OtherProductForm } from "./components/ExchangeItemForm";
import ExchangeV2 from "./components/ExchangeV2/ExchangeV2";

export const FormModal: React.FC<any> = () => {
  const dispatch = useDispatch();
  const formModal = useSelector((state: StoreState) => state.formModal);
  const { isEmbedded } = useIsEmbedded();
  const exchangeV2Enabled = useSelector((state: StoreState) => state.currentShop.exchangeV2Enabled);

  const handleCloseModal = (): void => {
    dispatch(clearCurrentReturnItem());
    dispatch(closeFormModal());
  };

  useEffect(() => {
    if (isEmbedded) {
      window.scrollTo(0, 0);
      window.top?.postMessage({ type: "scrollToTop" }, "*");
    }
  }, [formModal.isOpen, formModal.step, isEmbedded]);

  const renderCurrentStep = (): JSX.Element | null => {
    const steps = [
      <StageTwoQuestions />,
      <ReturnReasonSelector />,
      <ExtraInformationForm />,
      <StageThreeQuestions />,
      <ReturnMethodTypeSelector />,
      <ReturnMethodsSelector />,
      <ExchangeProductSelector />,
      <VariantsSelector />,
      <OtherProductForm />, // Remove once other product exchange is removed
    ];

    return steps[formModal.step] || null;
  };

  if (!formModal.selectedLineItem) return null;

  if (
    exchangeV2Enabled &&
    [FormModalSteps["exchangeProductsSelector"], FormModalSteps["variantsSelector"]].includes(formModal.step)
  ) {
    return (
      <div style={{ backgroundColor: "white", position: "absolute", zIndex: 10, top: 0, bottom: 0, right: 0, left: 0 }}>
        <ExchangeV2 />
      </div>
    );
  }

  return (
    <Modal
      centered={false}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      dimmer="blurring"
      onClose={handleCloseModal}
      open={formModal.isOpen}
      size="small"
      className={"formModal"}
    >
      {renderCurrentStep()}
    </Modal>
  );
};
