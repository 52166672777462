import { useMemo } from "react";
import { ReturnItem } from "actions";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

const checkRefundMethodAvailability = (returnItems: ReturnItem[], refundMethodName: string): boolean => {
  return returnItems.some((item) => item.returnMethod.name === refundMethodName);
};

export const useReceivablePaymentMethod = () => {
  const cart = useSelector((state: StoreState) => state.returnItems.cart);
  const hasOnlineBankTransfer = useMemo(() => checkRefundMethodAvailability(cart, "online_bank_transfer"), [cart]);
  const hasEwalletTransfer = useMemo(() => checkRefundMethodAvailability(cart, "ewallet_transfer"), [cart]);
  return { hasOnlineBankTransfer, hasEwalletTransfer };
};
