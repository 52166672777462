import { Loading } from "components";
import { useP2PProductListings } from "hooks/useP2PProductListings";
import { NotFound } from "pages";
import { useDestroyImage, useImageUpload } from "../hooks";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import { ImageUpload } from "./ImageUpload/ImageUpload";
import { AdaptiveModal } from "components/AdaptiveModal";

interface Props {
  onFinish: () => void;
  show: "from first unfulfilled" | number;
}

export const ContinuousImageUpload = ({ show, onFinish }: Props) => {
  const history = useHistory();

  const { productListingId } = useParams();
  const { productListings, isSuccess, isLoading, refetch } = useP2PProductListings({ staleTime: Infinity });
  const productListing = productListings.find((productListing) => productListing.id === Number(productListingId));
  const instructions = productListing?.imageUploadRequirement.instructions ?? [];

  const [currentInstructionIndex, setCurrentInstructionIndex] = useState<number | "is determinging">("is determinging");

  useEffect(() => {
    if (isSuccess) {
      if (show === "from first unfulfilled") {
        setCurrentInstructionIndex(instructions.findIndex((instruction) => !instruction.image));
      } else {
        setCurrentInstructionIndex(instructions.length - 1);
      }
    }
  }, [isSuccess]);

  const { upload, isLoading: isUploading } = useImageUpload({ onSuccess: refetch });
  const { destroy, isLoading: isDestroying } = useDestroyImage({ onSuccess: refetch });

  if (isLoading || currentInstructionIndex === "is determinging") {
    return (
      <AdaptiveModal>
        <Modal.Content>
          <Loading />
        </Modal.Content>
      </AdaptiveModal>
    );
  }

  if (!productListing) {
    return <NotFound />;
  }

  const instruction = instructions[currentInstructionIndex];

  return (
    <ImageUpload
      instructions={instructions}
      selectedInstructionIndex={currentInstructionIndex}
      isUploading={isUploading}
      isDestroying={isDestroying}
      onImageUploaded={(uploadedImage) => {
        upload({
          imageData: JSON.stringify(uploadedImage),
          productListingId: productListing.id,
          instructionId: instruction.id,
        });
      }}
      onImageRemoved={() => {
        destroy({ imageId: instruction.image!.id, productListingId });
      }}
      onBack={() => {
        if (currentInstructionIndex === 0) {
          history.goBack();
        } else {
          setCurrentInstructionIndex(currentInstructionIndex - 1);
        }
      }}
      onProceed={() => {
        if (currentInstructionIndex === instructions.length - 1) {
          onFinish();
        } else {
          setCurrentInstructionIndex(currentInstructionIndex + 1);
        }
      }}
    />
  );
};
