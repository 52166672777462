import React from "react";
import { ShippingAddressDetailsSummary, ShippingMethodSummary, ReturnItemsSummary } from "components";
import { useTranslation } from "react-i18next";

export const ReturnOrderDetailsSummary = () => {
  const { t } = useTranslation("orderFlow");
  const returnItemSummaryTitle = t("orderSummary.itemsToReturn");
  return (
    <>
      <ReturnItemsSummary title={returnItemSummaryTitle} />
      <ShippingMethodSummary />
      <ShippingAddressDetailsSummary />
    </>
  );
};
