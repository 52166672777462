import React, { Fragment } from "react";
import { Field, FieldProps } from "formik";
import { Form, Header } from "semantic-ui-react";
import styles from "./EwalletAccoutFields.module.css";

import { BankAccount, EwalletAccount, ReturnSteps, ShippingAddress } from "actions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
type OnlineTransfer = {
  bankAccount: BankAccount;
  ewalletAccount: EwalletAccount;
};
type FormValues = ShippingAddress & OnlineTransfer;

export const EwalletAccountFields: React.FC = () => {
  const { t } = useTranslation("orderFlow");
  const returnStep = useSelector((state: StoreState) => state.returnStep);
  const receivablePaymentDetailsPage = returnStep === ReturnSteps.receivablePaymentDetails;
  const className = receivablePaymentDetailsPage
    ? styles.ewalletDetailsHeaderMainPage
    : styles.ewalletDetailsHeaderContainer;

  return (
    <Fragment>
      <div className={className}>
        <Header sub={!receivablePaymentDetailsPage}>{t("ewalletAccountField.header")}</Header>
      </div>
      <Form.Group widths="equal">
        <Field name="ewalletAccount.provider">
          {({ field, form: { touched, errors } }: FieldProps<EwalletAccount["provider"], FormValues>): JSX.Element => (
            <Form.Field>
              <label>{t("ewalletAccountField.provider")}:</label>
              <Form.Input
                error={touched.ewalletAccount?.provider && errors.ewalletAccount?.provider}
                type="text"
                {...field}
              />
            </Form.Field>
          )}
        </Field>
        <Field name="ewalletAccount.name">
          {({ field, form: { touched, errors } }: FieldProps<EwalletAccount["name"], FormValues>): JSX.Element => (
            <Form.Field>
              <label>{t("ewalletAccountField.accountName")}:</label>
              <Form.Input error={touched.ewalletAccount?.name && errors.ewalletAccount?.name} type="text" {...field} />
            </Form.Field>
          )}
        </Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Field name="ewalletAccount.identifier">
          {({
            field,
            form: { touched, errors },
          }: FieldProps<EwalletAccount["identifier"], FormValues>): JSX.Element => (
            <Form.Field>
              <label>{t("ewalletAccountField.identifier")}:</label>
              <Form.Input
                error={touched.ewalletAccount?.identifier && errors.ewalletAccount?.identifier}
                type="text"
                {...field}
              />
            </Form.Field>
          )}
        </Field>
      </Form.Group>
    </Fragment>
  );
};
