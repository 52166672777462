import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Field, FieldProps, validateYupSchema, yupToFormErrors, useFormikContext } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Form, Header, DropdownProps, Dropdown, Popup, Icon } from "semantic-ui-react";
import { Loading } from "components";
import { ShippingAddress, shopifyLocales } from "actions";
import { useShopifyCountries } from "hooks/useShopifyCountries";
import {
  getFormLayout,
  getRequiredErrorMessage,
  getRequiredFields,
  getSupportedCountryErrorMessage,
  getSupportedProvinceErrorMessage,
} from "./helpers";
import { checkIfRequired, shopifyAddressValidationSchema, shopifyAddressValidationShape } from "./constants";
import styles from "./ShopifyAddressFields.module.css";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useShippingZones } from "hooks/useShippingZones";
import {
  getCountryRegex,
  getProvinceRegex,
  getSupportedCountriesAndProvinces,
  hasExchange,
  parseCountryName,
} from "pages/ShippingAddressDetails/helpers";
import { AuspostZipField } from "./AuspostZipField";
import { AuspostCityField } from "./AuspostCityField";

interface ShopifyAddressFieldsProps {
  header: string;
  autoHideIfValid?: boolean;
  validateShippingZones?: boolean;
  validateShippyProFields?: boolean;
  shippingAddressPage?: boolean;
}

export const ShopifyAddressFields: React.FC<ShopifyAddressFieldsProps> = ({
  header,
  autoHideIfValid = false,
  validateShippingZones = false,
  validateShippyProFields = false,
  shippingAddressPage = false,
}) => {
  const { errors, values, setValues } = useFormikContext<ShippingAddress>();
  const { t, i18n } = useTranslation("orderFlow");
  const localeCode = i18n.language;

  const cart = useSelector((state: StoreState) => state.returnItems.cart);
  const cartHasExchange = useMemo(() => hasExchange(cart), [cart]);

  const {
    countries,
    findCountry,
    isLoading: countriesAreLoading,
  } = useShopifyCountries(shopifyLocales[localeCode] || "en-US");
  const { shippingZones, isLoading: shippingZonesAreLoading } = useShippingZones();
  const { supportedCountriesAndProvinces, allCountriesAreSupported } = useMemo(
    () => getSupportedCountriesAndProvinces(shippingZones, findCountry),
    [shippingZones, findCountry],
  );
  const isLoading = countriesAreLoading || shippingZonesAreLoading;
  const shopifyCountry = findCountry(values.countryCode);
  const labels = shopifyCountry.labels;
  const formLayout = getFormLayout(shopifyCountry);

  const [schema, setSchema] = useState(shopifyAddressValidationSchema(shopifyCountry, t));
  const [isHidden, setIsHidden] = useState(false);

  const { onlyReturnFromShippingCountry } = useSelector((state: StoreState) => state.currentShop);
  const disableCountryField = Boolean(values?.country) && onlyReturnFromShippingCountry;

  const courierName = useSelector((state: StoreState) => state.returnItems.shippingMethod.courier?.name);
  const isAuspost = courierName === "Australia Post";
  const isAustralia = values.country === "Australia";
  const showAuspostField = isAuspost && isAustralia;

  const apiProvider = useSelector((state: StoreState) => state.returnItems.shippingMethod.apiProvider);

  useEffect(() => {
    // Our implementation of useShopifyCountries is a async and it causes problem when the
    // countries hasn't be set yet, the schema will be using the null country in the
    // schema, this useEffect is to make use it uses the correct country in schema.
    // Update schema once loaded and on country change for shipping zone validation.
    if (!isLoading) {
      const schemaShape = shopifyAddressValidationShape(shopifyCountry, t);

      updateSchemaForShippingZonesValidation(schemaShape);

      const addressValidationSchema = yup.object().shape(schemaShape);
      setSchema(addressValidationSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, values.countryCode]);

  // only runs if shop set autoHideValidShippingAddress to true
  useEffect(() => {
    if (autoHideIfValid && !isLoading) {
      // validate the fields once the countries data is fetched from Shopify
      setIsHidden(Object.keys(validateAll() || {}).length === 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoHideIfValid, isLoading]);

  function validate(name: string) {
    try {
      validateYupSchema(values, schema, true, { courierName: courierName });
    } catch (err) {
      const yupErrors = yupToFormErrors(err) as { [key: string]: string };
      return yupErrors[name];
    }
  }

  function validateAll() {
    try {
      validateYupSchema(values, schema, true);
    } catch (err) {
      return yupToFormErrors(err);
    }
  }

  function handleCountryChange(_e: React.SyntheticEvent, data: DropdownProps) {
    const country = findCountry(data.value as string);
    const countryName = country.name;
    setValues((values) => ({
      ...values,
      country: parseCountryName(countryName),
      countryCode: data.value as string,
      provinceCode: "",
      province: "",
    }));
  }

  function handleProvinceChange(_e: React.SyntheticEvent, data: DropdownProps) {
    const newProvinceCode = data.value as string;
    const province = shopifyCountry.zones.find((z) => z.code === newProvinceCode);
    const provinceName = province?.name;
    setValues((values) => ({
      ...values,
      provinceCode: newProvinceCode,
      province: provinceName || "",
      city: "",
      zip: "",
    }));
  }

  function updateSchemaForShippingZonesValidation(schemaShape: ReturnType<typeof shopifyAddressValidationShape>) {
    // validateShippingZones is used by ShippingAddressFields for the sender's address
    if (validateShippingZones && cartHasExchange && !allCountriesAreSupported) {
      const requiredFields = getRequiredFields(shopifyCountry);
      const countryRegex = getCountryRegex(supportedCountriesAndProvinces);
      const provinceRegex = getProvinceRegex(supportedCountriesAndProvinces, shopifyCountry);

      schemaShape.country = yup
        .string()
        .matches(countryRegex, getSupportedCountryErrorMessage(t))
        .test(
          "country",
          getRequiredErrorMessage(shopifyCountry, "country", t),
          checkIfRequired("country", requiredFields),
        );
      schemaShape.province = yup
        .string()
        .matches(provinceRegex, getSupportedProvinceErrorMessage(t))
        .test(
          "province",
          getRequiredErrorMessage(shopifyCountry, "zone", t),
          checkIfRequired("province", requiredFields),
        );
    }
  }

  function renderAttrFields(attr: string) {
    switch (attr) {
      case "address1":
        return (
          <Field key={attr} name="address1" validate={() => validate("address1")}>
            {({ field, form: { touched, errors } }: FieldProps<ShippingAddress["address1"]>): JSX.Element => (
              <Form.Field>
                <label>{labels["address1"]}</label>
                <Form.Input error={touched.address1 && errors.address1} type="text" {...field} />
              </Form.Field>
            )}
          </Field>
        );
      case "address2":
        return (
          <Field key={attr} name="address2" validate={() => validate("address2")}>
            {({ field, form: { touched, errors } }: FieldProps<ShippingAddress["address2"]>): JSX.Element => (
              <Form.Field>
                <label>{labels["address2"]}</label>
                <Form.Input
                  error={touched.address2 && errors.address2}
                  type="text"
                  {...field}
                  value={field.value || ""}
                />
              </Form.Field>
            )}
          </Field>
        );
      case "zip":
        return (
          <Field key={attr} name="zip" validate={() => validate("zip")}>
            {({ field, form: { touched, errors } }: FieldProps<ShippingAddress["zip"]>): JSX.Element => (
              <Form.Field>
                <label>{labels["postalCode"]}</label>
                {showAuspostField ? (
                  <AuspostZipField />
                ) : (
                  <Form.Input error={touched.zip && errors.zip} type="text" {...field} />
                )}
              </Form.Field>
            )}
          </Field>
        );
      case "city":
        return (
          <Field key={attr} name="city" validate={() => validate("city")}>
            {({ field, form: { touched, errors } }: FieldProps<ShippingAddress["city"]>): JSX.Element => (
              <Form.Field>
                <label>{labels["city"]}</label>
                {showAuspostField ? (
                  <AuspostCityField />
                ) : (
                  <Form.Input error={touched.city && errors.city} type="text" {...field} />
                )}
              </Form.Field>
            )}
          </Field>
        );
      case "province":
        return (
          <Field key={attr} name="province" validate={() => validate("province")}>
            {({ field, form: { touched, errors } }: FieldProps<ShippingAddress["province"]>): JSX.Element => {
              const provinceOptionsPresent = (shopifyCountry.zones.length || 0) > 0;
              const provinceOptions = (shopifyCountry.zones || []).map((zone) => ({
                text: zone.name,
                value: zone.code,
              }));
              return (
                <Form.Field>
                  <label>{labels["zone"]}</label>
                  {provinceOptionsPresent ? (
                    <Dropdown
                      error={touched.province && !!errors.province}
                      name="province"
                      onChange={handleProvinceChange}
                      options={provinceOptions}
                      placeholder={"Select province"}
                      value={values.provinceCode}
                      fluid
                      search
                      selection
                    />
                  ) : (
                    <Form.Input error={touched.province && errors.province} type="text" {...field} />
                  )}
                  {errors.province && <div className="ui pointing above prompt label">{errors.province}</div>}
                </Form.Field>
              );
            }}
          </Field>
        );
      default:
        return null;
    }
  }

  if (isLoading) {
    return <Loading />;
  } else if (isHidden) {
    return null;
  } else {
    return (
      <Fragment>
        {shippingAddressPage ? (
          <div className={styles.addressFieldsHeaderMainPage}>
            <Header style={{ display: "flex" }}>
              <Header.Content>{t("addressForm.addressHeader")}</Header.Content>
              <Popup
                trigger={
                  <Header.Content style={{ paddingLeft: "0.5rem", display: "flex", alignItems: "center" }}>
                    <Icon name="info circle" size="small" />
                  </Header.Content>
                }
                content={t("addressForm.addressHelpText")}
                position="bottom left"
              />
            </Header>
          </div>
        ) : (
          <div className={styles.addressFieldsHeaderContainer}>
            <Header sub>{header}</Header>
          </div>
        )}

        <Form.Group widths="equal">
          <Field name="country" validate={() => validate("country")}>
            {({ form }: FieldProps<ShippingAddress>): JSX.Element => (
              <Form.Field>
                <label>{t("addressForm.country")}</label>
                <Dropdown
                  error={form.touched.country && !!form.errors.country}
                  name="country"
                  onChange={handleCountryChange}
                  options={countries.map((country) => ({ text: country.name, value: country.code }))}
                  placeholder={t("addressForm.countryPlaceholder")}
                  value={values.countryCode}
                  disabled={disableCountryField}
                  fluid
                  search
                  selection
                />
                {errors.country && <div className="ui pointing above prompt label">{errors.country}</div>}
              </Form.Field>
            )}
          </Field>
        </Form.Group>
        {formLayout.map((row, index) => (
          <Form.Group key={index} widths="equal">
            {row.map((fieldName) => renderAttrFields(fieldName))}
          </Form.Group>
        ))}
      </Fragment>
    );
  }
};
