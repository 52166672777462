import { Order, ShippingAddress, ReturnItem } from "actions";
import { sanitizeAddress } from "components/AddressFields/helper";
import * as yup from "yup";
import { contactDetailsValidationShape } from "components/ContactDetailsFields/constants";
import { addressValidationShape, baseAddressShape } from "components/AddressFields/constants";
import { Country as ShippingZoneCountry, ShippingZone } from "types/shippingZone";
import { Country as ShopifyCountry } from "@shopify/address-consts";

interface CountryAndProvinces {
  [key: string]: {
    country: string;
    provinces: Set<string>;
  };
}

export const shippingAddressValidationSchema = (isShopify: boolean) => {
  return yup.object().shape({
    ...(isShopify ? baseAddressShape : addressValidationShape),
    ...contactDetailsValidationShape,
  });
};

export const getInitialAddressValues = (
  order: Order,
  findCountry: (countryCode: string) => ShopifyCountry,
): ShippingAddress => {
  const shopifyCountry = findCountry(order.shippingAddress.countryCode);
  const provinceName = shopifyCountry.zones.find((zone) => zone.code === order.shippingAddress.provinceCode)?.name;

  const address = {
    ...order.shippingAddress,
    address2: order.shippingAddress.address2 || "",
    firstName: order.customer.firstName || "",
    lastName: order.customer.lastName || "",
    email: order.shippingAddress.email || order.customer.email || order.email || "",
    phone: order.shippingAddress.phone || order.customer.phone || order.phone || "",
    country: parseCountryName(shopifyCountry.name),
    province: provinceName || "",
  };
  const initialAddress: ShippingAddress = sanitizeAddress(address);
  return initialAddress;
};

export const hasExchange = (cart: ReturnItem[]): boolean => {
  return cart.filter((returnItem: ReturnItem) => returnItem.returnMethod.type === "Exchange").length > 0;
};

export const getAddressFormHeader = (
  autoHideValidShippingAddress: boolean,
  cart: ReturnItem[],
  t: Function,
): string => {
  if (autoHideValidShippingAddress || !hasExchange(cart)) {
    return t("addressForm.senderAddressHeader");
  } else {
    return t("addressForm.exchangeShippingAddressHeader");
  }
};

export function parseCountryName(name: string) {
  switch (name) {
    case "Isle of Man":
      // Shopify Address is returning the name as Isle of Man
      // countryParser doesn't recognize Isle of Man and our backend is expecting Isle Of Man
      return "Isle Of Man";
    case "Hong Kong SAR":
      // Shopify Address is returning the name as Hong Kong SAR
      // countryParser doesn't recognize Hong Kong SAR and our backend is expecting Hong Kong
      return "Hong Kong";
    case "Macao SAR":
      // Shopify Address is returning the name as Macao SAR
      // countryParser doesn't recognize Macao SAR and our backend is expecting Macao
      return "Macao";
    default:
      return name;
  }
}

export const getSupportedCountriesAndProvinces = (
  shippingZones: ShippingZone[],
  findCountry: (countryCode: string) => ShopifyCountry,
) => {
  let allCountriesAreSupported = false;
  let supportedCountriesAndProvinces: CountryAndProvinces = {};

  shippingZones.forEach((shippingZone: ShippingZone) => {
    shippingZone.countries.forEach((shippingZoneCountry: ShippingZoneCountry) => {
      if (shippingZoneCountry.code === "*") {
        allCountriesAreSupported = true;
      } else {
        const countryCode = shippingZoneCountry.code;
        const shopifyCountry = findCountry(countryCode);
        const supportedProvinces = shopifyCountry.zones.filter((zone) => {
          return shippingZoneCountry.provinces.find((province) => province.code === zone.code);
        });
        const supportedCountryAndProvince = supportedCountriesAndProvinces[countryCode];

        if (supportedCountryAndProvince) {
          supportedCountriesAndProvinces[countryCode].provinces = new Set([
            ...supportedCountryAndProvince.provinces,
            ...supportedProvinces.map((province) => province.name),
          ]);
        } else {
          supportedCountriesAndProvinces[countryCode] = {
            country: parseCountryName(shopifyCountry.name),
            provinces: new Set(supportedProvinces.map((province) => province.name)),
          };
        }
      }
    });
  });

  return { allCountriesAreSupported, supportedCountriesAndProvinces };
};

export const getCountryRegex = (supportedCountriesAndProvinces: CountryAndProvinces) => {
  return new RegExp(
    Object.keys(supportedCountriesAndProvinces)
      .map((countryCode) => supportedCountriesAndProvinces[countryCode].country)
      .join("|"),
  );
};

export const getProvinceRegex = (
  supportedCountriesAndProvinces: CountryAndProvinces,
  shopifyCountry: ShopifyCountry,
): RegExp => {
  const shopifyCountryCode = shopifyCountry.code;
  const provinces = supportedCountriesAndProvinces[shopifyCountryCode]?.provinces;
  return provinces ? new RegExp(Array.from(provinces).join("|")) : /^$/;
};
