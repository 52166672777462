import { UploadedImage } from "actions/returnItems";
import { ImageUploadRequirementInstruction } from "hooks/useP2PProductListings";
import React from "react";
import { isMobile } from "react-device-detect";
import { DesktopImageUpload } from "./DesktopImageUpload";
import { MobileImageUpload } from "./MobileImageUpload";

interface Props {
  instructions: ImageUploadRequirementInstruction[];
  selectedInstructionIndex: number;
  isUploading: boolean;
  isDestroying: boolean;
  onImageUploaded: (uploadedImage: UploadedImage) => void;
  onImageRemoved: () => void;
  onBack?: () => void;
  onProceed: () => void;
}

export const ImageUpload = (props: Props) => {
  if (isMobile) {
    return <MobileImageUpload {...props} />;
  } else {
    return <DesktopImageUpload {...props} />;
  }
};
