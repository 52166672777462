import React from "react";
import { RegularOverview, InstantExchangeOverview } from "./components";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

export const Overview: React.FC = (): JSX.Element => {
  const instantExchange = useSelector((store: StoreState) => store.returnItems.instantExchange);

  return instantExchange ? <InstantExchangeOverview /> : <RegularOverview />;
};
