import React, { useState } from "react";
import { Modal, Image } from "semantic-ui-react";

export const SampleImagesViewer = ({
  sampleImages,
  open,
  onClose,
}: {
  sampleImages: { url: string }[];
  open: boolean;
  onClose: () => void;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const hasPrevious = selectedIndex > 0;
  const hasNext = selectedIndex < sampleImages.length - 1;

  return (
    <Modal
      open={open}
      dimmer="inverted"
      style={{
        width: "100vw",
        maxWidth: 600,
        padding: 11,
      }}
    >
      <Modal.Content style={{ display: "flex", flexDirection: "column", gap: 16, alignItems: "center" }}>
        <div style={{ marginLeft: "auto" }}>
          <img
            src="https://360-static-assets.postco.co/close.png"
            style={{
              height: "12px",
              cursor: "pointer",

              display: "block",
              marginLeft: "auto",
            }}
            onClick={onClose}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 8,
          }}
        >
          <img
            src="https://360-static-assets.postco.co/left-arrow.png"
            style={{ height: 24, cursor: hasPrevious ? "pointer" : "not-allowed", opacity: hasPrevious ? 1 : 0.3 }}
            onClick={() => {
              if (hasPrevious) {
                setSelectedIndex(selectedIndex - 1);
              }
            }}
          />
          <Image
            src={sampleImages[selectedIndex].url}
            rounded
            bordered
            style={{ flex: "1 1", aspectRatio: "1/1", objectFit: "contain", minWidth: 0 }}
          />
          <img
            src="https://360-static-assets.postco.co/left-arrow.png"
            style={{
              height: 24,
              rotate: "180deg",
              cursor: hasNext ? "pointer" : "not-allowed",
              opacity: hasNext ? 1 : 0.3,
            }}
            onClick={() => {
              if (hasNext) {
                setSelectedIndex(selectedIndex + 1);
              }
            }}
          />
        </div>

        <div style={{ display: "flex", gap: 8, justifyContent: "center", minWidth: "fit-content", width: 0 }}>
          {sampleImages.map((image, index) => (
            <Image
              key={index}
              bordered
              rounded
              src={image.url}
              style={{
                width: "30%",
                maxWidth: 80,
                aspectRatio: "1/1",
                objectFit: "cover",
                borderColor: index === selectedIndex ? "rgba(0, 0, 0, 0.6)" : "rgba(0, 0, 0, 0.1)",
                cursor: index === selectedIndex ? "auto" : "pointer",
              }}
              onClick={() => setSelectedIndex(index)}
            />
          ))}
        </div>
      </Modal.Content>
    </Modal>
  );
};
