import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { getInvoices, getShipmentInvoice } from "api";
import { nullMainInvoice, MainInvoice, ShipmentInvoice, nullShipmentInvoice } from "constants/invoice";

export const useInvoices = (returnType: "regularReturn" | "instantExchange", options = {}) => {
  const returnOrderId = useSelector((store: StoreState) => store.order.returnOrderId);
  const { data, ...others } = useQuery({
    queryKey: ["invoice", returnType, returnOrderId],
    queryFn: () => getInvoices(returnOrderId, returnType),
    staleTime: Infinity,
    refetchOnMount: "always",
    ...options,
  });
  const invoices: MainInvoice[] = data ? data : [];
  return { invoices, ...others };
};

export const useRegularReturnInvoice = (options = {}) => {
  const { invoices, ...others } = useInvoices("regularReturn", options);

  // assuming regular return invoice can only be one - either RefundInvoice or ExchangeOrder::Invoice
  const regularReturnInvoice = invoices[0] || nullMainInvoice;
  return { regularReturnInvoice, ...others };
};

export const useInstantExchangeInvoices = (options = {}) => {
  const { invoices, ...others } = useInvoices("instantExchange", options);

  const instantExchangeExchangeOrderInvoice =
    invoices.find((invoice) => invoice.type === "InstantExchangeExchangeOrderInvoice") || nullMainInvoice;
  const instantExchangeInvoice =
    invoices.find((invoice) => invoice.type === "InstantExchangeInvoice") || nullMainInvoice;
  const instantExchangeRefundInvoice = invoices.find((invoice) => invoice.type === "ReturnOrderRefundInvoice") || null;

  return {
    invoices: { instantExchangeInvoice, instantExchangeExchangeOrderInvoice, instantExchangeRefundInvoice },
    ...others,
  };
};

export const useShipmentInvoice = () => {
  const returnOrderId = useSelector((store: StoreState) => store.order.returnOrderId);
  const { data, ...others } = useQuery({
    queryKey: ["shipmenInvoice", returnOrderId],
    queryFn: () => getShipmentInvoice(returnOrderId),
  });
  const shipmentInvoice: ShipmentInvoice = data ? data : nullShipmentInvoice;
  return { shipmentInvoice, ...others };
};
