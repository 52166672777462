import React, { useState } from "react";
import { Form, Grid, Header, TextArea } from "semantic-ui-react";
import { Button } from "components";
import styles from "./ExchangeItemForm.module.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import { appendCurrentReturnItem, closeFormModal, setCurrentReturnItemProperty } from "actions";
import parse from "html-react-parser";
import { FormModalContent } from "../FormModalContent/FormModalContent";

export const OtherProductForm: React.FC<any> = () => {
  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();
  const shopDomain = useSelector((state: StoreState) => state.currentShop.shopDomain);
  const exchangeRequestDisclaimer = useSelector((state: StoreState) => state.currentShop.exchangeRequestDisclaimer);
  const shopDomainLink = `https://${shopDomain}`;
  const [productDetail, setProductDetail] = useState("");
  const handleSubmit = () => {
    dispatch(setCurrentReturnItemProperty("exchangeForOtherProductDetail", productDetail));
    dispatch(appendCurrentReturnItem());
    dispatch(closeFormModal());
  };
  return (
    <FormModalContent>
      <Form onSubmit={handleSubmit}>
        <div>
          <Grid.Column mobile={16}>
            <Grid>
              <Grid.Column mobile={16}>
                <Header as="h5">{t("otherProductForm.header")}</Header>
              </Grid.Column>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("otherProductForm.description", {
                    shopDomainLink,
                  }),
                }}
              />
              <Grid.Column mobile={16}>
                <TextArea
                  name="productDetail"
                  value={productDetail}
                  placeholder={t("otherProductForm.placeholder")}
                  onChange={(event) => {
                    setProductDetail(event.currentTarget.value);
                  }}
                />
              </Grid.Column>
              <Grid.Column mobile={16}>
                {exchangeRequestDisclaimer && (
                  <span style={{ fontSize: "0.9rem", fontStyle: "italic" }}>{parse(exchangeRequestDisclaimer)}</span>
                )}
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <div className={styles.submitRow}>
            <Button color="primaryColor" disabled={productDetail === ""} fluid type="submit">
              {t("continue")}
            </Button>
          </div>
        </div>
      </Form>
    </FormModalContent>
  );
};
