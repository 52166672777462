import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useDispatch } from "react-redux";
import { removeReturnItemFromCart, ReturnItem, setError, ReturnSteps } from "actions";
import { variantName } from "helpers";
import { useTranslation } from "react-i18next";
import { getRoutes } from "constants/routes";
import { useHistory } from "react-router-dom";

export const useExchangeableItemsValidation = () => {
  const cart = useSelector((state: StoreState) => state.returnItems.cart);
  const errors = useSelector((state: StoreState) => state.errors);
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const returnStep = useSelector((state: StoreState) => state.returnStep);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("orderFlow");

  const { exchangeable, notExchangeableItems } = cart.reduce(
    (acc, cartItem) => {
      if (cartItem.exchangingVariant && !cartItem.exchangingVariant.exchangeableByAvailability) {
        dispatch(removeReturnItemFromCart(cartItem.uuid));
        acc.exchangeable = false;
        acc.notExchangeableItems.push(cartItem);
      }
      return acc;
    },
    { exchangeable: true, notExchangeableItems: [] as ReturnItem[] },
  );

  if (!exchangeable) {
    if (returnStep !== ReturnSteps.returnItems) {
      const ROUTES = getRoutes(shopSlug, returnOrderId);
      history.push(ROUTES[ReturnSteps.returnItems]);
    }

    const itemsName = notExchangeableItems.map((item) => variantName(item)).join(", ");
    const errorMessage = t("errorMessage.exchangeItemOutOfStock", { itemsName });
    const error = new Error(errorMessage);

    const errorAlreadyExists = errors.some((error) => error.message === errorMessage);
    if (!errorAlreadyExists) {
      dispatch(setError(error));
    }
  }
};
