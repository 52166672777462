import { ShippingAddress } from "actions";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Card } from "semantic-ui-react";
import { ShippingAddress as SummaryShippingAddress } from "types";

interface ContactDetailsProps {
  shippingAddress: ShippingAddress | SummaryShippingAddress;
}

export const ContactDetails: React.FC<ContactDetailsProps> = ({ shippingAddress }): JSX.Element => {
  const { t } = useTranslation("orderFlow");
  return (
    <Grid.Column>
      <Card.Header>
        <span style={{ textDecoration: "underline" }}>{t("addressForm.contactHeader")}</span>
      </Card.Header>
      <Card.Description>{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</Card.Description>
      <Card.Description>{shippingAddress.phone}</Card.Description>
      <Card.Description>{shippingAddress.email}</Card.Description>
    </Grid.Column>
  );
};
