import React, { useEffect, useState } from "react";
import { CollectionTabs } from "./CollectionTabs";
import styles from "./ExchangeProductSelector.module.css";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useCollectionTabs } from "./hooks";
import { Loading } from "components/Loading/Loading";
import { clearCurrentReturnItem, closeFormModal, formModalGoBack, setCurrentReturnItemProperty } from "actions";
import { isMobile } from "react-device-detect";
import { CollectionTab } from "./types";
import { AllCollectionsProducts } from "./AllCollectionsProducts";
import { CollectionProducts } from "./CollectionProducts";
import { SearchField } from "./SearchField";
import { ReturnItemValueButton } from "./ReturnItemValueButton";
import { VariantSelector } from "../VariantsSelector/components/VariantSelector/VariantSelector";

interface Props {
  onSearchButtonClick: () => void;
  onReturnItemValueChipClick: () => void;
  filterMode: "by_collections" | "by_search_string";
}

export const CollectionsView = ({ filterMode, onSearchButtonClick, onReturnItemValueChipClick }: Props) => {
  const dispatch = useDispatch();
  const currentReturnItem = useSelector((state: StoreState) => state.returnItems.currentReturnItem);

  const initialCollectionId = useSelector(
    (state: StoreState) => state.returnItems.currentReturnItem.selectedCollectionId,
  );

  const { collectionTabs, isLoading, exchangeMethods } = useCollectionTabs();

  const [selectedTab, setSelectedTab] = useState<CollectionTab | null>(null);

  useEffect(() => {
    if (!isLoading) {
      const selectedTab = collectionTabs.find((tab) => tab.id === initialCollectionId)!;
      setSelectedTab(selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (filterMode === "by_search_string") {
      dispatch(
        setCurrentReturnItemProperty("returnMethod", exchangeMethods.find((method) => method.name === "advanced")!),
      );
      dispatch(setCurrentReturnItemProperty("selectedCollectionId", "all"));
      dispatch(
        setCurrentReturnItemProperty("selectedProduct", {
          id: null,
          title: "",
          image: { src: "" },
        }),
      );
    } else if (selectedTab) {
      if (selectedTab.id === "same_product") {
        dispatch(setCurrentReturnItemProperty("selectedCollectionId", "same_product"));
        dispatch(
          setCurrentReturnItemProperty(
            "returnMethod",
            exchangeMethods.find((method) => method.name === "same_product")!,
          ),
        );
        dispatch(
          setCurrentReturnItemProperty("selectedProduct", {
            id: currentReturnItem.productId,
            title: currentReturnItem.productTitle,
            image: { src: currentReturnItem.returnItemImageUrl },
          }),
        );
      }
    }
  }, [isLoading, filterMode]);

  const componentBasedOnSelectedTabId = (tabId: string) => {
    switch (tabId) {
      case "same_product":
        return (
          <div className={styles.variantSelectorContainer}>
            <VariantSelector showHeaderText={false} />
          </div>
        );
      case "all":
        return <AllCollectionsProducts sortedCollectionTabs={collectionTabs} />;
      default:
        return <CollectionProducts selectedTab={selectedTab!} />;
    }
  };

  return isLoading || !selectedTab ? (
    <Loading />
  ) : (
    <div
      className={styles.exchangeProductSelectorModal}
      style={
        selectedTab.id === "same_product" && filterMode !== "by_search_string" && !isMobile
          ? { height: "max-content" }
          : {}
      }
    >
      <div className={styles.headerContainer}>
        <img
          src="https://360-static-assets.postco.co/left-arrow.png"
          alt="go back to previous step"
          onClick={() => dispatch(formModalGoBack())}
          style={{ height: isMobile ? "10px" : "14px", cursor: "pointer" }}
        />
        <SearchField placeholder={isMobile ? "Search" : "What are you looking for?"} onFocus={onSearchButtonClick} />
        <ReturnItemValueButton onClick={onReturnItemValueChipClick} />

        <img
          src="https://360-static-assets.postco.co/close.png"
          alt="cancel the return"
          style={{ height: isMobile ? "10px" : "14px", cursor: "pointer" }}
          onClick={() => {
            dispatch(clearCurrentReturnItem());
            dispatch(closeFormModal());
          }}
        />
      </div>
      <div style={{ marginTop: isMobile ? 0 : "5px" }}>
        <CollectionTabs
          tabs={collectionTabs}
          selectedTab={selectedTab}
          onTabSelected={setSelectedTab}
          exchangeMethods={exchangeMethods!}
        />
      </div>
      {componentBasedOnSelectedTabId(selectedTab.id)}
    </div>
  );
};
