import React from "react";
import { Grid, Label, Popup } from "semantic-ui-react";
import { SquareImage } from "components";
import { getFormattedPriceString } from "helpers";
import { useTruncateItemCardSentence } from "hooks/useTruncateItemCardSentence";
import { useTranslation } from "react-i18next";
import { getRefundMethodBadgeText, getExchangeMethodBadgeText } from "helpers";
import styles from "./ReturnItemSummary.module.css";
import { ReturnItem } from "types";

interface ReturnItemSummaryProps {
  returnItem: ReturnItem;
}

export const ReturnItemSummary: React.FC<ReturnItemSummaryProps> = ({ returnItem }): JSX.Element => {
  const { truncateSentence } = useTruncateItemCardSentence();
  const { t } = useTranslation("orderFlow");
  const { paidAmount, rejectReason, status, returnItemImageUrl, returnItemName } = returnItem;
  const variantName = returnItem.exchangeItem ? returnItem.exchangeItem.name : "no title";

  const itemPrice = getFormattedPriceString(paidAmount.cents, paidAmount.currencyIso);

  const returnTypeInfo =
    returnItem.returnMethod.type === "Exchange"
      ? getExchangeMethodBadgeText(returnItem, t("forExchange"), truncateSentence(variantName))
      : getRefundMethodBadgeText(returnItem, t);

  const itemRejected = status === "rejected";

  return (
    <Grid.Column>
      <SquareImage src={returnItemImageUrl} className={styles.image} disable={itemRejected} />
      <div className={styles.itemSummary}>
        <div className={styles.header}>{returnItemName}</div>
        <div className={styles.subheader}>
          <p>{itemPrice}</p>
          <p>{returnTypeInfo}</p>
        </div>
      </div>
      {itemRejected && (
        <Popup position="top center" trigger={<Label attached="top right">{t("exchangePaymentPage.rejected")}</Label>}>
          <Popup.Content style={{ color: "gray" }}>
            {t("exchangePaymentPage.rejectReason")}: {rejectReason}
          </Popup.Content>
        </Popup>
      )}
    </Grid.Column>
  );
};
