import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  ReturnReason,
  setCurrentReturnItemProperty,
  formModalPush,
  FormModalSteps,
  clearCurrentReturnItem,
  closeFormModal,
  updateLineItemsReturnability,
} from "actions";
import styles from "./ReturnReasonSelector.module.css";
import { Loading, PageHeader } from "components";
import { FormModalContent } from "..";
import { useReturnReasons } from "./hooks";
import { evaluateReturnabilityByReturnReason } from "./api";
import { StoreState } from "reducers";

export const ReturnReasonSelector: React.FC<any> = () => {
  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();

  const [isEvaluatingReturnability, setIsEvaluatingReturnability] = useState(false);
  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const { id: orderId, returnOrderId } = useSelector((state: StoreState) => state.order);
  const { productId, variantId, lineItemId, bundleItemId } = useSelector(
    (state: StoreState) => state.returnItems.currentReturnItem,
  );

  const { returnReasons, isLoading } = useReturnReasons();

  const requireExtraInformation = (reason: ReturnReason): boolean => {
    return reason.imageUpload || reason.extraNote || reason.optionalImageUpload || reason.optionalExtraNote;
  };

  const handleNext = (reason: ReturnReason): void => {
    setIsEvaluatingReturnability(true);
    evaluateReturnabilityByReturnReason(
      shopId,
      orderId,
      returnOrderId,
      productId,
      variantId,
      lineItemId,
      bundleItemId,
      reason.globalId,
    ).then(({ returnable }) => {
      setIsEvaluatingReturnability(false);
      if (returnable) {
        dispatch(setCurrentReturnItemProperty("returnReason", reason));

        if (requireExtraInformation(reason)) {
          dispatch(formModalPush(FormModalSteps.extraInformation));
        } else {
          dispatch(formModalPush(FormModalSteps.stageThreeQuestions));
        }
      } else {
        dispatch(updateLineItemsReturnability([lineItemId], t));
        dispatch(clearCurrentReturnItem());
        dispatch(closeFormModal());
      }
    });
  };

  return (
    <FormModalContent>
      {isLoading || isEvaluatingReturnability || returnReasons.length === 0 ? (
        <Grid style={{ paddingBottom: "32px" }}>
          <Grid.Column>
            <Loading />
          </Grid.Column>
        </Grid>
      ) : (
        <div>
          <PageHeader header={t("reasonSelector.header")} />
          {returnReasons.map(
            (reason: ReturnReason): JSX.Element => (
              <div className={styles.buttonContainer} key={reason.name} onClick={() => handleNext(reason)}>
                <span>{reason.name}</span>
                <Icon name="chevron right" />
              </div>
            ),
          )}
        </div>
      )}
    </FormModalContent>
  );
};
