import { useEffect } from "react";

export function useLowerCaseShopSlug(shopSlug: string) {
  const isUpperCase = (str: string) => /[A-Z]/.test(str);
  useEffect(() => {
    if (isUpperCase(shopSlug)) {
      window.location.replace(window.location.href.toLowerCase());
    }
  });
}
