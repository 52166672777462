import React from "react";
import { Form, Icon } from "semantic-ui-react";

import styles from "./PaymentSuccess.module.css";

interface PaymentMethodInfoProps {
  cardBrand: string;
  cardLast4: string;
  onClose: () => void;
  price: string;
}

export const PaymentMethodInfo: React.FC<PaymentMethodInfoProps> = ({ cardBrand, cardLast4, onClose, price }) => {
  return (
    <Form.Field>
      <label>Payment Details:</label>
      <div className={styles.cardElement}>
        <span>
          {price} will be charged to {cardBrand} ending {cardLast4}
        </span>
        <Icon className={styles.icon} name="close" onClick={onClose} />
      </div>
    </Form.Field>
  );
};
