import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Popup } from "semantic-ui-react";
import { ReturnItem } from "../../actions";
import styles from "./ReturnMethodTag.module.css";
import { useTruncateItemCardSentence } from "hooks/useTruncateItemCardSentence";
import { variantName, getRefundMethodBadgeText, getExchangeMethodBadgeText } from "helpers";

interface ReturnMethodTagProps {
  cancellable?: boolean;
  onCancel?: (arg0: string) => void;
  returnItems: ReturnItem[];
}

export const ReturnMethodTag: React.FC<ReturnMethodTagProps> = ({ cancellable, onCancel, returnItems }) => {
  const { t } = useTranslation("orderFlow");
  const { truncateSentence } = useTruncateItemCardSentence();

  const getBadgeContent = (badgeText: string): JSX.Element => {
    const truncatedBadgeText = truncateSentence(badgeText);

    return badgeText !== truncatedBadgeText ? (
      <Popup content={badgeText} trigger={<span className={styles.buttonText}>{truncatedBadgeText}</span>} />
    ) : (
      <span className={styles.buttonText}>{badgeText}</span>
    );
  };

  return (
    <div className={styles.statusesContainer}>
      {returnItems.map((returnItem: ReturnItem, index: number) => {
        const isExchange = returnItem.returnMethod.type === "Exchange";
        const badgeText = isExchange
          ? getExchangeMethodBadgeText(returnItem, t("forExchange"), variantName(returnItem))
          : getRefundMethodBadgeText(returnItem, t);
        const badgeColor = isExchange ? "teal" : "green";

        return (
          <div className={styles.buttonContainer} key={`${returnItem.lineItemId}-${index}`}>
            <Button.Group size="mini">
              <Button basic color={badgeColor} style={{ cursor: "default" }}>
                {getBadgeContent(badgeText)}
              </Button>
              {cancellable && (
                <Button
                  basic
                  color={badgeColor}
                  icon="close"
                  onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    e.stopPropagation();
                    onCancel && onCancel(returnItem.uuid);
                  }}
                />
              )}
            </Button.Group>
          </div>
        );
      })}
    </div>
  );
};
