import React, { useEffect, useState } from "react";
import { Image } from "semantic-ui-react";
import styles from "./Descriptions.module.css";
import DropOffIcon from "./drop_off_icon.png";
import { Button } from "components";
import { ShippingMethod } from "types";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { formatPriceString, getShippingMethodInfo } from "./helper";
import { ModalType } from "pages/ShippingMethodSelector/ShippingMethodSelector";

interface DropOffDescriptionProps {
  method: ShippingMethod;
  openModal: ModalType;
}

export const DropOffDescription: React.FC<DropOffDescriptionProps> = ({ method, openModal }) => {
  const { t } = useTranslation("orderFlow");
  const { requiresPayment, currency, priceCents } = getShippingMethodInfo(method);
  const price = formatPriceString(priceCents, currency);

  const queryClient = useQueryClient();
  const locationsUrl = method.dropOffLocationsUrl;
  const shippingServiceApiProvider = method.apiProvider;

  const hasDynamicPricing = !method.fixedPrice;
  const setInitialPriceString = () => {
    if (hasDynamicPricing) {
      return requiresPayment ? t("rates.varies") : t("rates.free");
    } else {
      return requiresPayment ? price : t("rates.free");
    }
  };

  const [priceString, setPriceString] = useState<string>(setInitialPriceString);

  useEffect(() => {
    if (shippingServiceApiProvider === "direct_integration") return;

    const dropOffFeeQueryState = queryClient.getQueryState<{ cents: number; currencyIso: string }>([
      "dropOffFee",
      method.id,
    ]);

    if (!dropOffFeeQueryState) return;

    const { data: queryData } = dropOffFeeQueryState;

    if (!queryData) return;

    switch (dropOffFeeQueryState.status) {
      case "loading":
        return setPriceString(t("rates.fetching"));
      case "error":
        return setPriceString(t("rates.fetchingError"));
      case "success":
        const priceString = requiresPayment
          ? formatPriceString(queryData.cents, queryData.currencyIso)
          : t("rates.free");
        return setPriceString(priceString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal === "dropOffModal"]);

  return (
    <div className={styles.container}>
      <div style={{ width: 35 }}>
        <Image src={DropOffIcon} size="mini" centered />
      </div>
      <div className={styles.textBox}>
        {method.badge && (
          <div className={styles.labelContainer}>
            <Button as="div" color="primaryColor" size="mini">
              {method.badge}
            </Button>
          </div>
        )}
        <div className={styles.titleContainer}>
          <strong style={{ marginRight: "6px" }}>{method.title}</strong>
          <img src={method.logoUrl} alt="courier logo" className={styles.courierLogo}></img>
        </div>
        {parse(method.description)}
      </div>
      <div className={styles.rightTextBox}>
        <div style={{ marginRight: "5px" }}>
          <strong style={{ marginBottom: "5px" }}>{priceString}</strong>
          <br />
          <i>
            {t("locationsMap.description")}{" "}
            <a href={locationsUrl} target="_blank" rel="noopener noreferrer">
              {t("locationsMap.link")}
            </a>
          </i>
        </div>
      </div>
    </div>
  );
};
