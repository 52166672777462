import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

interface Props {
  totalStepCount: number;
  currentStepCount: number;
}

export const Stepper = ({ totalStepCount, currentStepCount }: Props) => {
  const inactiveColor = "#D9D9D9";
  const activeColor = useSelector((state: StoreState) => state.currentShop.userInterfaceSetting.primaryColor);

  const Step = ({ active }: { active: boolean }) => {
    return (
      <div
        style={{
          height: "5px",
          width: "70px",
          backgroundColor: active ? activeColor : inactiveColor,
          borderRadius: "2px",
        }}
      ></div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "5px",
      }}
    >
      {[...Array(currentStepCount)].map((_, index) => (
        <Step key={index} active />
      ))}
      {[...Array(totalStepCount - currentStepCount)].map((_, index) => (
        <Step key={index} active={false} />
      ))}
    </div>
  );
};
