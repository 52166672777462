import React from "react";
import { Modal, ModalContent, ModalDescription, Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useTranslation } from "react-i18next";
import chroma from "chroma-js";

export const ResaleMarketingModal = ({ open, setOpen }: { open: boolean; setOpen: Function }) => {
  const primaryButtonTextColor = useSelector(
    (state: StoreState) => state.currentShop.userInterfaceSetting.primaryButtonTextColor,
  );
  const primaryColor = useSelector((state: StoreState) => state.currentShop.userInterfaceSetting.primaryColor);
  const getColor = (primaryColor: string | null) => {
    const black = "black";
    const white = "white";
    if (primaryColor) {
      const blackContrast = chroma.contrast(primaryColor, black);
      const whiteContrast = chroma.contrast(primaryColor, white);
      return blackContrast > whiteContrast ? black : white;
    } else {
      return "black";
    }
  };
  const textColor = primaryButtonTextColor || getColor(primaryColor);
  const { t } = useTranslation("orderFlow");

  const resaleStepsDetails = [
    {
      title: t("resaleMarketingModal.step1Title"),
      description: t("resaleMarketingModal.step1Description"),
      image: "https://360-static-assets.postco.co/resale/resell_step1.png",
    },
    {
      title: t("resaleMarketingModal.step2Title"),
      description: t("resaleMarketingModal.step2Description"),
      image: "https://360-static-assets.postco.co/resale/resell_step2.png",
    },
    {
      title: t("resaleMarketingModal.step3Title"),
      description: t("resaleMarketingModal.step3Description"),
      image: "https://360-static-assets.postco.co/resale/resell_step3.png",
    },
  ];

  return (
    <Modal closeIcon open={open} onClose={() => setOpen(false)} size="small">
      <ModalContent style={{ padding: "30px" }}>
        <div style={{ fontWeight: "bold", fontSize: "20px", paddingBottom: "5px" }}>
          Easily resell your non-returnable items
        </div>
        <div style={{ color: "#616161", paddingBottom: "10px" }}>
          In just three easy steps, you can return your pre-loved items into cash. It's our way to give your products a
          second life.
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          {resaleStepsDetails.map((resaleStep) => (
            <div style={{ display: "flex", gap: "16px", alignItems: "center", paddingTop: "15px" }}>
              <img src={resaleStep.image} style={{ maxHeight: "100px" }} />
              <div style={{ flexGrow: 1 }}>
                <p style={{ fontWeight: "bold", marginBottom: "0" }}>{resaleStep.title}</p>
                <p style={{ color: "#989898" }}>{resaleStep.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div style={{ paddingTop: "20px" }}>
          <Button
            as="a"
            href="https://postco.frontkb.com/en/articles/3577153"
            target="_blank"
            style={{ backgroundColor: primaryColor, color: textColor }}
            fluid
          >
            {t("resaleMarketingModal.gotQuestions")}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
