import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import React from "react";
import { setCurrentReturnItemProperty } from "actions";
import { getFormattedAmount, handleContinue } from "../helpers";
import parse from "html-react-parser";
import { ReturnMethodTypeButton } from "./ReturnMethodTypeButton";

type Props = {
  bonusCredit: number;
  prioritized: boolean;
};

export const StoreCreditButton = ({ bonusCredit, prioritized }: Props) => {
  const locale = useSelector((state: StoreState) => state.locale.code);
  const uiSetting = useSelector((state: StoreState) => state.currentShop.userInterfaceSetting);
  const currency = useSelector((state: StoreState) => state.order.currency);
  const dispatch = useDispatch();
  const displayPrice = useSelector((state: StoreState) => state.formModal.selectedLineItem!.displayPrice);
  const returnItemValue = getFormattedAmount(displayPrice.cents, currency);
  const formattedBonusCredit = getFormattedAmount(bonusCredit, currency);

  const titleTranslations = bonusCredit
    ? uiSetting.bonusCreditStoreCreditTitleTranslations
    : uiSetting.standardStoreCreditTitleTranslations;
  const descriptionTranslations = bonusCredit
    ? uiSetting.bonusCreditStoreCreditDescriptionTranslations
    : uiSetting.standardStoreCreditDescriptionTranslations;

  const title = titleTranslations[locale]
    ?.replaceAll("{{ return_item_value }}", returnItemValue)
    ?.replaceAll("{{ bonus_credit_amount }}", formattedBonusCredit);
  const description =
    descriptionTranslations[locale]
      ?.replaceAll("{{ return_item_value }}", returnItemValue)
      ?.replaceAll("{{ bonus_credit_amount }}", formattedBonusCredit) ?? "";

  return (
    <ReturnMethodTypeButton
      title={title}
      description={parse(description)}
      isPrioritized={prioritized}
      bonusCredit={bonusCredit}
      onClick={() => {
        if (bonusCredit) {
          dispatch(setCurrentReturnItemProperty("bonusCreditAmountCents", bonusCredit));
        }
        handleContinue("storeCredit", dispatch);
      }}
    />
  );
};
