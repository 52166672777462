import camelcaseKeys from "camelcase-keys";
import { PostCo360API } from "api/constants";

export const getReturnItemsSummary = async (returnOrderId: string) => {
  try {
    const response = await PostCo360API().get(`/api/v2/customer/return_orders/${returnOrderId}/return_items_summary`);

    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};
