import { useInvoiceContext } from "contexts/InvoiceProvider";
import { ProductListing } from "hooks/useP2PProductListings";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { Image } from "semantic-ui-react";
import { useReturnOrderStatus } from "hooks/useReturnOrder";

const RotatedBlankImage = ({ degree }: { degree: number }) => {
  return (
    <div
      style={{
        borderRadius: "5px",
        position: "absolute",
        objectFit: "cover",
        aspectRatio: "1 / 1",
        height: "60px",
        border: "1px solid #707070",
        transform: `rotate(${degree}deg)`,
      }}
    />
  );
};

const EditIcon = ({ onClick }: { onClick: () => void }) => (
  <div style={{ cursor: "pointer", padding: 5 }} onClick={onClick}>
    <svg width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6553 1.3439C11.603 0.291605 9.89692 0.291617 8.84464 1.34393L8.24615 1.94244L8.2374 1.93369L7.17674 2.99435L7.1855 3.00312L1.30543 8.88338C0.78972 9.3991 0.5 10.0986 0.5 10.8279V12.7495C0.5 12.9484 0.579019 13.1392 0.719671 13.2798C0.860323 13.4205 1.05109 13.4995 1.25 13.4995L3.17155 13.4995C3.90091 13.4995 4.6004 13.2097 5.11613 12.694L12.6554 5.15452C13.7076 4.10224 13.7076 2.39618 12.6553 1.3439ZM8.24616 4.06378L2.3661 9.94402C2.13169 10.1784 2 10.4964 2 10.8279L2 11.9995H3.17155C3.50308 11.9995 3.82103 11.8678 4.05545 11.6334L9.9355 5.75311L8.24616 4.06378ZM10.9961 4.69244L11.5947 4.09388C12.0612 3.62738 12.0612 2.87105 11.5947 2.40456C11.1282 1.93806 10.3718 1.93806 9.90532 2.40457L9.30681 3.0031L10.9961 4.69244Z"
        fill="#4A4A4A"
      />
    </svg>
  </div>
);

const P2PImageSectionItem = ({ productListing, onClick }: { productListing: ProductListing; onClick: () => void }) => {
  const returnOrderStatus = useSelector((store: StoreState) => store.order.status);
  const isDraftReturnOrder = ["draft", "update_draft"].includes(returnOrderStatus);
  const {
    id,
    productListable,
    imageUploadRequirement: { instructions },
  } = productListing;

  return (
    <div
      key={id}
      style={{
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        gap: 16,
        alignItems: "center",
      }}
    >
      <div style={{ position: "relative" }}>
        {instructions.length > 1 ? (
          <>
            <RotatedBlankImage degree={-10} />
            <RotatedBlankImage degree={7} />
          </>
        ) : null}
        <Image
          rounded
          inline
          src={instructions[0].image!.url}
          style={{
            objectFit: "cover",
            aspectRatio: "1 / 1",
            height: "60px",
            border: "1px solid #707070",
          }}
        />
      </div>

      <p style={{ fontSize: "1rem", lineHeight: "1.6rem", marginBottom: 0 }}>
        <b>
          {productListable.productTitle + (productListable.variantTitle ? " - " + productListable.variantTitle : "")}
        </b>
        <br />
        <i>{instructions.length + " image(s)"}</i>
      </p>
      {isDraftReturnOrder && <EditIcon onClick={onClick} />}
    </div>
  );
};

export const P2PImageSection = ({ children }: { children: ReactNode }) => {
  const { completedReturnOrder } = useReturnOrderStatus();
  const {
    p2pRequestInfo: { presentmentIncentiveAmount },
  } = useInvoiceContext();

  return (
    <div style={{ padding: "8px 16px" }}>
      {!completedReturnOrder && Boolean(presentmentIncentiveAmount) && (
        <p style={{ color: "#428224", fontSize: "12px" }}>
          You’ll receive your <b>{presentmentIncentiveAmount} discount</b> incentive once these items are successfully
          shipped out to the new buyer(s).
        </p>
      )}

      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>{children}</div>
    </div>
  );
};

P2PImageSection.Item = P2PImageSectionItem;
