import React, { useMemo, useState } from "react";
import { Header, Icon } from "semantic-ui-react";
import { Button, Loading } from "components";
import { useCamera } from "hooks/useCamera";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { CameraPreviewer } from "components/CameraPreviewer";
import { useTranslation } from "react-i18next";
import { SampleImagesViewer } from "./SampleImagesViewer";
import { StickyBottomAppBar } from "StickyBottomAppBar";

interface Props {
  isUploading: boolean;
  aboveTitle?: string;
  title: string;
  description: string;
  sampleImages: any[];
  onCancel: () => void;
  onCapture: (image: File) => void;
}

export const MobileCameraView = ({
  isUploading,
  aboveTitle,
  title,
  description,
  onCancel,
  onCapture,
  sampleImages,
}: Props) => {
  const { t } = useTranslation("orderFlow");
  const primaryColor = useSelector((state: StoreState) => state.currentShop.userInterfaceSetting.primaryColor);

  const camera = useCamera({
    onCapture,
  });

  const [viewSampleImages, setViewSampleImages] = useState(false);

  const BackButton = () => {
    return (
      <Button
        style={{
          position: "absolute",
          top: 0,
          padding: "20px",
          backgroundColor: "transparent",
          color: "white",
        }}
        size="massive"
        icon="chevron left"
        onClick={onCancel}
      />
    );
  };

  const FlashButton = () => {
    if (camera.status !== "ready" || !camera.flash) {
      return null;
    }

    return (
      <Button
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: "20px",
          color: camera.flash.isOn ? primaryColor : "white",
          backgroundColor: "rgba(0, 0, 0, 0)",
          margin: "0 0 0 6px",
        }}
        size="massive"
        icon="lightning"
        onClick={camera.flash.toggle}
      />
    );
  };

  const cameraPreviewSection = useMemo(() => {
    if (camera.status !== "ready") {
      return (
        <div
          style={{
            height: "40vh",
            objectFit: "contain",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {camera.status === "loading" ? <Loading /> : <span>Error: {camera.error.message}</span>}
        </div>
      );
    }

    if (camera.isCapturing || isUploading) {
      return (
        <div
          style={{
            width: "100vw",
            height: "40vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading />
        </div>
      );
    }

    return <CameraPreviewer previewStream={camera.previewStream} />;
  }, [camera.status, (camera as any)?.isCapturing, isUploading]);

  const ImageRequirementInstruction = () => (
    <div style={{ paddingInline: 16, flexGrow: 1, lineHeight: 2, width: "100%" }}>
      {aboveTitle}
      <Header style={{ fontSize: "1.8rem" }}>{title}</Header>
      {description}
      <br />
      {sampleImages.length > 0 && (
        <span style={{ color: "#5281BF" }} onClick={() => setViewSampleImages(true)}>
          <Icon name="external" /> {t("p2pProductListingPage.exampleImages")}
        </span>
      )}
    </div>
  );

  const CameraControlSection = () => {
    return (
      <StickyBottomAppBar>
        <div
          style={{
            marginBottom: "8vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={camera.status !== "ready" || camera.isCapturing || isUploading}
            color="primaryColor"
            size="massive"
            circular
            icon="camera"
            onClick={() => {
              if (camera.status === "ready") {
                camera.capture();
              }
            }}
          />
        </div>
      </StickyBottomAppBar>
    );
  };

  return (
    <>
      <div style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            alignItems: "center",
            height: "100%",
          }}
        >
          {cameraPreviewSection}
          <ImageRequirementInstruction />
          <CameraControlSection />
        </div>
        <BackButton />
        <FlashButton />
      </div>
      {sampleImages.length ? (
        <SampleImagesViewer
          sampleImages={sampleImages}
          open={viewSampleImages}
          onClose={() => setViewSampleImages(false)}
        />
      ) : null}
    </>
  );
};
