import { useFormikContext } from "formik";
import React, { useEffect } from "react";

export const ScrollToFirstErrorField = () => {
  const { submitCount } = useFormikContext();

  useEffect(() => {
    setTimeout(() => {
      if (submitCount === 0) {
        return;
      }

      document.querySelector(".error.field")?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 100);
  }, [submitCount]);

  return <></>;
};
