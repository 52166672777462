import React from "react";
import { ReturningLineItems } from "components/ReturningItemsSummary";
import { getFormattedPriceString } from "helpers";
import { useTranslation } from "react-i18next";
import { Accordion, Grid, Divider } from "semantic-ui-react";
import styles from "./RefundSection.module.css";
import { getRefundSectionSubHeader } from "./helpers";
import { useInvoiceContext } from "contexts/InvoiceProvider";

export const RefundSection: React.FC = () => {
  const { t } = useTranslation("orderFlow");
  const { instantExchangeRefundInvoice: refundInvoice } = useInvoiceContext();

  if (!refundInvoice) return <></>;

  const { returningLineItems, extraLineItems, total, refundBreakdown } = refundInvoice;
  const amount = getFormattedPriceString(total.cents, total.currencyIso, { useParenthesis: true });

  const header = t("summary.refundSummaryHeader");
  const headerWithCount = `${header} (${returningLineItems.length})`;
  const subHeader = getRefundSectionSubHeader(refundBreakdown, t);

  const ExtraLineItemsSection = (): JSX.Element => {
    return (
      <>
        {extraLineItems.map((item, index) => (
          <div key={`extraLineItems-${index}`}>
            <Grid columns={2} style={{ padding: "8px 16px" }}>
              <Grid.Column computer={10} className={styles.subtotalLineItem}>
                {item.name}
              </Grid.Column>
              <Grid.Column computer={6} textAlign="right" className={styles.subtotalLineItem}>
                {getFormattedPriceString(item.price.cents, item.price.currencyIso, { useParenthesis: true })}
              </Grid.Column>
            </Grid>
          </div>
        ))}
      </>
    );
  };

  const title = (
    <div className={styles.accordionTitle}>
      <span>{headerWithCount}</span>
      <span className={styles.amount}>{amount}</span>
      <p className={styles.subHeader}>{subHeader}</p>
    </div>
  );

  const content = (
    <div style={{ marginTop: "1rem" }}>
      <ReturningLineItems returningLineItems={returningLineItems} />
      <ExtraLineItemsSection />
    </div>
  );

  return (
    <>
      <div style={{ marginBottom: 32 }}>
        <Accordion
          panels={[
            {
              title,
              content,
            },
          ]}
        />
      </div>
      <Divider />
    </>
  );
};
