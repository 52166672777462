import React, { useEffect, useState } from "react";
import { Button } from "components";
import * as Sentry from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Message } from "semantic-ui-react";
import { useAuthContext } from "contexts/AuthProvider";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { updateReturnOrderThenContinue, ReturnSteps, updateStripePaymentIntentId } from "actions";
import { StoreState } from "reducers";
import { useInvoiceContext } from "contexts/InvoiceProvider";

export const PaymentIntentForm: React.FC = (): JSX.Element => {
  const { p2pRequestInfo } = useInvoiceContext();
  const isRenewReturns = useSelector((state: StoreState) => state.returnItems.p2pReturns);
  const currentStep = ReturnSteps.summaryAndPaymentDetails;

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const history = useHistory();
  const { broadcastLogout, setRefetchInterval } = useAuthContext();

  const { t } = useTranslation("orderFlow");

  useEffect(() => {
    if (elements) {
      elements.fetchUpdates().then(function (result) {
        if (result.error) setErrorMessage(`${result.error?.message}. Please refresh the page.`);
      });
    }
  }, [elements]);

  const clearAndSetError = (message: string) => {
    if (elements) {
      elements.getElement("payment")?.update({ readOnly: false });
      elements.getElement("payment")?.clear();
      setErrorMessage(`${message} Please try again.`);
    }
  };

  const handleSubmit = async () => {
    // prevent return order refetching which refreshes the whole page
    // including the Stripe Elements when submitting Stripe payment API request
    setRefetchInterval(false);

    setIsSubmitting(true);
    setErrorMessage("");

    if (!stripe || !elements) {
      setIsSubmitting(false);
      return;
    }

    elements.getElement("payment")?.update({ readOnly: true });

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (paymentIntent && paymentIntent.status === "succeeded") {
      dispatch(updateStripePaymentIntentId(paymentIntent.id));
      dispatch(
        updateReturnOrderThenContinue(history, currentStep, {
          continueToSubmittedStep: true,
        }),
      );
      broadcastLogout();
      // setRefetchInterval to 10 minutes will be run on SummaryAndPaymentDetails unmount
    } else if (error && error.message) {
      clearAndSetError(`${error.message}`);
      Sentry.captureException(error);
      setRefetchInterval(60000); // 1 minute
    } else {
      clearAndSetError(t("orderSummary.failedPaymentMethod"));
      setRefetchInterval(60000); // 1 minute
    }
    setIsSubmitting(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Message warning visible={isSubmitting}>
        {t("orderSummary.processingPayment")}
      </Message>
      <Message error visible={!!errorMessage}>
        {errorMessage}
      </Message>
      <PaymentElement onFocus={() => setRefetchInterval(false)} />
      <Button color="primaryColor" fluid type="submit" disabled={isSubmitting} loading={isSubmitting}>
        {p2pRequestInfo.requestable
          ? isRenewReturns
            ? t("summaryAndPaymentDetailsPage.submitRenewReturnRequest")
            : t("summaryAndPaymentDetailsPage.submitRegularReturnRequest")
          : t("orderSummary.submit")}
      </Button>
    </Form>
  );
};
