import { ProductListing } from "hooks/useP2PProductListings";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { List, Image, Icon } from "semantic-ui-react";
import { EditIcon } from "./EditIcon";

interface ProductListingListItemProps {
  productListing: ProductListing;
  onClick: (productListing: ProductListing) => void;
}

const ProductListingListItem = ({ productListing, onClick }: ProductListingListItemProps) => {
  const primaryColor = useSelector((state: StoreState) => state.frontendConfigs.primaryColor);
  const { t } = useTranslation("orderFlow");

  const {
    productListable,
    imageUploadRequirement: { instructions },
  } = productListing;

  return (
    <List.Item
      onClick={() => onClick(productListing)}
      style={{
        display: "grid",
        gridTemplateColumns: "100px 1fr auto",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Image
        rounded
        style={{ height: 0, minHeight: "100%", aspectRatio: "1 / 1", objectFit: "cover" }}
        src={productListable.returnItemImageUrl}
      />
      <div style={{ padding: 12, lineHeight: "1.3rem" }}>
        <b>{productListable.productTitle}</b>
        <br />
        {productListable.variantTitle}
        <br />
        {productListable.presentmentPrice}
        {instructions.every((instruction) => instruction.image) ? (
          <div style={{ height: 32, marginTop: 8, display: "flex", gap: 5, alignItems: "center" }}>
            {instructions.length <= 3 ? (
              instructions.map((instruction, index) => (
                <Image
                  key={index}
                  rounded
                  inline
                  bordered
                  src={instruction.image!.url}
                  style={{ height: "100%", objectFit: "cover", aspectRatio: "1 / 1" }}
                />
              ))
            ) : (
              <>
                {instructions.slice(0, 2).map((instruction, index) => (
                  <Image
                    key={index}
                    rounded
                    bordered
                    inline
                    src={instruction.image!.url}
                    style={{ height: "100%", objectFit: "cover", aspectRatio: "1 / 1" }}
                  />
                ))}
                <div
                  style={{
                    height: 32,
                    width: 32,
                    fontSize: "1rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.3125em",
                    border: "1px solid #404040",
                    color: "#404040",
                  }}
                >
                  <i>{`+${instructions.length - 2}`}</i>
                </div>
              </>
            )}

            <EditIcon />
          </div>
        ) : (
          <div style={{ marginBlock: "8px", display: "flex", alignItems: "center" }}>
            <i style={{ color: "grey" }}>
              <Icon name="check circle outline" />{" "}
              {t("p2pRequestPage.imagesCountRequired", {
                count: instructions.length,
                current: instructions.filter((instruction) => instruction.image).length,
              })}
            </i>
          </div>
        )}
      </div>
      {instructions.every((instruction) => instruction.image) ? (
        <Icon size="large" name="check circle" style={{ color: primaryColor }} />
      ) : (
        <Icon size="large" name="chevron right" />
      )}
    </List.Item>
  );
};

interface ProductListingListProps {
  children?: ReactNode;
}

export const ProductListingList = ({ children }: ProductListingListProps) => {
  return <List relaxed>{children}</List>;
};

ProductListingList.Item = ProductListingListItem;
