import { DesktopView, MobileView } from "./components";
import React from "react";
import { isMobile } from "react-device-detect";
import { NotFound } from "pages/NotFound/NotFound";
import { Loading } from "components";
import { InvoiceProvider } from "contexts/InvoiceProvider";
import { useInstantExchangeData } from "hooks/useInstantExchange";
import { PaymentPageProvider } from "./PaymentPageProvider";
import { useAuthContext } from "contexts/AuthProvider";

export const InstantExchangePayment: React.FC<any> = (): JSX.Element => {
  const { loggedIn } = useAuthContext();
  const {
    instantExchangeData: { paymentStatus },
    isLoading: isLoadingInstantExchangeData,
  } = useInstantExchangeData();

  if (isLoadingInstantExchangeData) {
    return <Loading />;
  }

  if (!loggedIn || paymentStatus !== "failed_to_charge") {
    return <NotFound />;
  }

  return (
    <PaymentPageProvider>
      <InvoiceProvider>{isMobile ? <MobileView /> : <DesktopView />}</InvoiceProvider>
    </PaymentPageProvider>
  );
};
