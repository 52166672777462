import { Loading } from "components";
import { useP2PProductListings } from "hooks/useP2PProductListings";
import React from "react";
import { P2PSummary } from "./P2PSummary";
import { P2PIntroduction } from "./P2PIntroduction";

export const P2PSection = () => {
  const { productListings, isLoading: fetchingProductListings } = useP2PProductListings();

  if (fetchingProductListings) {
    return <Loading />;
  }

  const uploadedAllImages =
    productListings.length > 0 &&
    productListings.every(({ imageUploadRequirement: { instructions } }) =>
      instructions.every((instruction) => instruction.image),
    );

  if (uploadedAllImages) {
    return <P2PSummary />;
  } else {
    return <P2PIntroduction />;
  }
};
