import { useEffect, useState } from "react";

export interface LoadingCamera {
  status: "loading";
}

export interface ErrorCamera {
  status: "error";
  error: Error;
}

export interface Flash {
  isOn: boolean;
  toggle: () => void;
}

export interface ReadyCamera {
  status: "ready";
  previewStream: MediaStream;
  flash?: Flash;
  capture: () => Promise<void>;
  isCapturing: boolean;
}
export type Camera = LoadingCamera | ReadyCamera | ErrorCamera;

export const useCamera = ({ onCapture }: { onCapture: (file: File) => void }): Camera => {
  const [previewStream, setPreviewStream] = useState<MediaStream>();
  const [error, setError] = useState<Error>();
  const [flashIsOn, setFlashIsOn] = useState(false);
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();

  useEffect(() => {
    if (!canvas) {
      return;
    }

    fetch(canvas.toDataURL("image/webp"))
      .then((res) => res.blob())
      .then((blob) => {
        onCapture(new File([blob], `${Date.now()}.webp`, { type: "image/webp" }));
      });
  }, [canvas]);

  useEffect(() => {
    if (!previewStream) {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment", aspectRatio: 1 } as any })
        .then((previewStream) => {
          const track = previewStream.getVideoTracks()[0];

          track.applyConstraints({ width: { max: 1080 }, aspectRatio: 1 }).then(() => setPreviewStream(previewStream));
        })
        .catch(setError);
    }

    return () => {
      previewStream?.getTracks().forEach((track) => track.stop());
    };
  }, [previewStream]);

  if (!previewStream) {
    if (error) {
      return {
        status: "error",
        error,
      };
    } else {
      return {
        status: "loading",
      };
    }
  } else {
    const track = previewStream.getVideoTracks()[0];
    const settings = track.getSettings() as { width: number; height: number; torch?: boolean };
    const capabilities = track.getCapabilities() as MediaTrackCapabilities & { torch?: boolean };

    const video: HTMLVideoElement & { playsInline?: boolean } = document.createElement("video");
    video.autoplay = true;
    video.playsInline = true;
    video.srcObject = previewStream;

    let flash: Flash | undefined;

    if (capabilities.torch) {
      flash = {
        isOn: flashIsOn,
        toggle: () =>
          track.applyConstraints({ advanced: [{ torch: !flashIsOn }] } as any).then(() => setFlashIsOn(!flashIsOn)),
      };
    }

    const capture = async () => {
      const canvas = document.createElement("canvas");
      canvas.width = settings.width;
      canvas.height = settings.height;
      canvas.getContext("2d")!.drawImage(video, 0, 0, settings.width, settings.height);

      setCanvas(canvas);
    };

    return {
      status: "ready",
      previewStream,
      flash,
      capture,
      isCapturing: Boolean(canvas),
    };
  }
};
