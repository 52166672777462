import camelcase from "camelcase";

import i18next from "i18next";

export const getOrderIdLabel = (orderIdLabel: Record<string, string> | string) => {
  const DEFAULT_ORDER_ID_LABEL = i18next.t("orderFlow:customerInfo.orderId");
  switch (typeof orderIdLabel) {
    case "object":
      return orderIdLabel[camelcase(i18next.language)] || DEFAULT_ORDER_ID_LABEL; // title object from rails
    default:
      return DEFAULT_ORDER_ID_LABEL; // 360 defaults
  }
};

export const getOrderIdPlaceholder = (orderIdPlaceholder: Record<string, string> | string) => {
  const DEFAULT_ORDER_ID_PLACEHOLDER = "";
  switch (typeof orderIdPlaceholder) {
    case "object":
      return orderIdPlaceholder[camelcase(i18next.language)] || DEFAULT_ORDER_ID_PLACEHOLDER; // title object from rails
    default:
      return DEFAULT_ORDER_ID_PLACEHOLDER; // 360 defaults
  }
};

export const getOrderEmailPhoneLabel = (orderEmailPhoneLabel: Record<string, string> | string) => {
  const DEFAULT_ORDER_EMAIL_PHONE_LABEL = i18next.t("orderFlow:customerInfo.emailOrPhone");
  switch (typeof orderEmailPhoneLabel) {
    case "object":
      return orderEmailPhoneLabel[camelcase(i18next.language)] || DEFAULT_ORDER_EMAIL_PHONE_LABEL; // title object from rails
    default:
      return DEFAULT_ORDER_EMAIL_PHONE_LABEL; // 360 defaults
  }
};

export const getOrderEmailPhonePlaceholder = (orderEmailPhonePlaceholder: Record<string, string> | string) => {
  const DEFAULT_ORDER_EMAIL_PHONE_PLACEHOLDER = "";
  switch (typeof orderEmailPhonePlaceholder) {
    case "object":
      return orderEmailPhonePlaceholder[camelcase(i18next.language)] || DEFAULT_ORDER_EMAIL_PHONE_PLACEHOLDER; // title object from rails
    default:
      return DEFAULT_ORDER_EMAIL_PHONE_PLACEHOLDER; // 360 defaults
  }
};

export const getStartButtonName = (startButtonName: Record<string, string> | string) => {
  const DEFAULT_START_BUTTON_NAME = i18next.t("orderFlow:customerInfo.submit");
  switch (typeof startButtonName) {
    case "object":
      return startButtonName[camelcase(i18next.language)] || DEFAULT_START_BUTTON_NAME; // title object from rails
    default:
      return DEFAULT_START_BUTTON_NAME; // 360 defaults
  }
};

export const getRedirectUrl = (nextRoute: string, orderId: string, emailOrPhone: string): string => {
  return `${process.env.REACT_APP_OLD_CUSTOMER_APP_URL}${nextRoute}?orderNameOrId=${orderId}&emailOrPhone=${emailOrPhone}&mobileEmbedded=true`;
};
