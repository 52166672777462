import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useDispatch } from "react-redux";
import { removeReturnItemFromCart, LineItem, setError, ReturnSteps } from "actions";
import { useTranslation } from "react-i18next";
import { getRoutes } from "constants/routes";
import { useHistory } from "react-router-dom";

export const useReturnableItemsValidation = (): void => {
  const lineItems = useSelector((state: StoreState) => state.order.lineItems);
  const cart = useSelector((state: StoreState) => state.returnItems.cart);
  const errors = useSelector((state: StoreState) => state.errors);
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const returnStep = useSelector((state: StoreState) => state.returnStep);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("orderFlow");

  const { returnable, notReturnableItems } = lineItems.reduce(
    (acc, lineItem) => {
      const itemsInCart = cart.filter((cartItem) => cartItem.lineItemId === lineItem.id.toString());
      let returnRequestQuantity = itemsInCart.length;

      // bundleItem.quantity is the returnable quantity of that bundle return line item, not the
      // quantity of that bundle item for its bundle product
      const lineItemQuantity =
        lineItem.bundleItems.length > 0
          ? lineItem.bundleItems.reduce((totalQuantity, bundleItem) => totalQuantity + bundleItem.quantity, 0)
          : lineItem.quantity;

      itemsInCart.forEach((itemInCart) => {
        if (lineItemQuantity < returnRequestQuantity) {
          dispatch(removeReturnItemFromCart(itemInCart.uuid));
          acc.returnable = false;
          acc.notReturnableItems.push(lineItem);
          returnRequestQuantity--;
        }
      });
      return acc;
    },
    { returnable: true, notReturnableItems: [] as LineItem[] },
  );

  if (!returnable) {
    if (returnStep !== ReturnSteps.returnItems) {
      const ROUTES = getRoutes(shopSlug, returnOrderId);
      history.push(ROUTES[ReturnSteps.returnItems]);
    }

    const itemsName = notReturnableItems.map((item) => item.name).join(", ");
    const errorMessage = t("errorMessage.itemsAlreadyReturned", { itemsName });
    const error = new Error(errorMessage);

    const errorAlreadyExists = errors.some((error) => error.message === errorMessage);
    if (!errorAlreadyExists) {
      dispatch(setError(error));
    }
  }
};
