import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { LearnMoreModal } from "./LearnMoreModal";

export const RenewBenefitsSection = () => {
  const { p2pRequestInfo } = useInvoiceContext();
  const [openLearnMoreModal, setOpenLearnMoreModal] = useState(false);

  return (
    <>
      <LearnMoreModal open={openLearnMoreModal} onClose={() => setOpenLearnMoreModal(false)} />
      <div
        style={{
          padding: 32,
          display: "flex",
          flexDirection: "column",
          borderRadius: 10,
          gap: 16,
          border: "1px solid #707070",
          marginBottom: "32px",
        }}
      >
        <p style={{ lineHeight: "2.25rem", marginBottom: 0 }}>
          <b>
            Select{"  "}
            <img
              src="https://360-static-assets.postco.co/P2P/renew_logo_with_text.svg"
              alt="reNEW returns"
              style={{ margin: 0, fontSize: "2lh", verticalAlign: "middle" }}
            />
            {"  "}
            to receive
          </b>
          <br />
          <Icon name="check circle outline" style={{ color: "#4A4A4A" }} /> Free return shipping <br />
          {Boolean(p2pRequestInfo.presentmentIncentiveAmount) && (
            <>
              <Icon name="check circle outline" style={{ color: "#4A4A4A" }} />{" "}
              {p2pRequestInfo.presentmentIncentiveAmount} off your next purchase
              <br />
            </>
          )}
        </p>
        <p style={{ color: "#707070", fontStyle: "italic" }}>
          A brand new sustainable way to return. Submit images of your return items for approval and keep your items
          until a buyer makes a purchase. If no one buys your items, the return defaults to a standard return back to
          the warehouse.
        </p>

        <span style={{ color: "#0055BC", cursor: "pointer" }} onClick={() => setOpenLearnMoreModal(true)}>
          Learn more
        </span>
      </div>
    </>
  );
};
