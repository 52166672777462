import React, { useEffect } from "react";
import { Icon, Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeReturnStep,
  updateReturnOrderThenContinue,
  ReturnSteps,
  setQuestions,
  updateLineItemsReturnability,
} from "actions";
import { Loading } from "components";
import { QuestionAnswerForm } from "components/QuestionAnswerForm/QuestionAnswerForm";
import styles from "./StageOneQuestions.module.css";
import { StoreState } from "reducers";
import { useReturnableByQuestion } from "hooks/useReturnableByQuestions";
import { useTranslation } from "react-i18next";
import { QuestionFormProvider, useQuestionFormContext } from "contexts/QuestionFormProvider";
import { getQuestionAnswerPairs } from "helpers/questions";
import { useHistory } from "react-router-dom";
import { useCheckCurrentStep } from "helpers/currentStep";
import { getRoutes } from "constants/routes";

const StageOneQuestionsModal: React.FC = () => {
  const currentStep = ReturnSteps.stageOneQuestions;
  useCheckCurrentStep(currentStep);
  const { questions, questionsData, page, setPage, isFetching, isLoading } = useQuestionFormContext();
  const { t } = useTranslation("orderFlow");
  const dispatch = useDispatch();
  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const lineItems = useSelector((state: StoreState) => state.order.lineItems);
  const { id: orderId, returnOrderId } = useSelector((state: StoreState) => state.order);
  const { productId, variantId, lineItemId, bundleItemId, returnReason } = useSelector(
    (state: StoreState) => state.returnItems.currentReturnItem,
  );
  const history = useHistory();

  const { mutate: evaluate, isLoading: evaluating } = useReturnableByQuestion({
    onSuccess: (data: { data: { returnable: boolean } }) => {
      if (!data.data.returnable) {
        dispatch(
          updateLineItemsReturnability(
            lineItems.map((item) => item.id.toString()),
            t,
          ),
        );
      }
      dispatch(setQuestions(questions));
      dispatch(updateReturnOrderThenContinue(history, currentStep));
    },
  });

  useEffect(() => {
    if (!isFetching) {
      if (questionsData.length === 0) {
        const nextStep = ReturnSteps.returnItems;
        const ROUTES = getRoutes(shopSlug, returnOrderId);
        dispatch(changeReturnStep(ReturnSteps.returnItems));
        history.push(ROUTES[nextStep]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <React.Fragment>
      <Grid centered container padded="vertically">
        <Grid.Column mobile={16} tablet={13} computer={10}>
          <div className={styles.headerContainer}>
            {page !== 0 ? (
              <Icon className={styles.headerIcon} name="chevron left" onClick={() => setPage(page - 1)} />
            ) : (
              <div />
            )}
          </div>
          {isLoading || questions.length === 0 ? (
            <Grid style={{ paddingBottom: "32px" }}>
              <Grid.Column>
                <Loading />
              </Grid.Column>
            </Grid>
          ) : (
            <QuestionAnswerForm
              buttonLoading={evaluating}
              handleContinue={() => {
                const questionAnswerPairs = getQuestionAnswerPairs(questions);

                evaluate({
                  shopId,
                  orderId,
                  returnOrderId,
                  productId,
                  variantId,
                  lineItemId,
                  bundleItemId,
                  returnReason: returnReason?.globalId || "",
                  questionAnswerPairs,
                });
              }}
            />
          )}
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

export const StageOneQuestions: React.FC = () => {
  return (
    <QuestionFormProvider stage={1}>
      <StageOneQuestionsModal />
    </QuestionFormProvider>
  );
};
