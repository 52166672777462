import { Action, ActionTypes, Holiday } from "actions";

const initialState: Holiday[] = [];

export const holidaysReducer = (state: Holiday[] = initialState, action: Action): Holiday[] => {
  switch (action.type) {
    case ActionTypes.addHoliday:
      return [...state, action.payload];
    default:
      return state;
  }
};
