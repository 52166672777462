import React from "react";
import { PaymentMethod, Stripe, StripeElements, StripeCardElementOptions } from "@stripe/stripe-js";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { Button, Form, Label } from "semantic-ui-react";
import styles from "./PaymentForm.module.css";
import { useDispatch } from "react-redux";
import { setError } from "actions";

const CARD_OPTIONS: StripeCardElementOptions = {
  iconStyle: "solid",
  style: {
    base: {
      fontFamily: "Lato, Helvetica Neue, Arial, Helvetica, sans-serif",
      fontSmoothing: "antialiased",
    },
  },
};

interface PaymentMethodFormProps {
  price: string;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  paymentStatus: string;
}

export const PaymentMethodForm: React.FC<PaymentMethodFormProps> = ({ price, setPaymentMethod, paymentStatus }) => {
  const stripe: Stripe | null = useStripe();
  const elements: StripeElements | null = useElements();

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (!paymentMethod || error) {
      dispatch(setError(new Error(error && error.message)));
      return;
    }

    setPaymentMethod(paymentMethod);
  };

  if (paymentStatus === "succeeded") return null;

  return (
    <React.Fragment>
      <Form.Field>
        <label>Payment Details:</label>
        <div className={styles.cardElement}>
          <CardElement options={CARD_OPTIONS} />
        </div>
        <Label as="span" className={styles.noteContainer} color="red" size="tiny" basic>
          {price} will be charged upon confirmation
        </Label>
      </Form.Field>
      <Button color="blue" fluid onClick={handleSubmit} size="large" type="button">
        Add Payment
      </Button>
    </React.Fragment>
  );
};
