import { Dispatch } from "redux";

import {
  ActionTypes,
  ReturnSteps,
  clearErrors,
  clearLoading,
  changeReturnStep,
  setError,
  setLoading,
  resetStore,
} from ".";
import { deleteOrder, fetchOrder } from "../api";
import { StoreState } from "reducers";
import { TFunction } from "i18next";

export interface AddOrderAction {
  type: ActionTypes.addOrder;
  payload: Order;
}

export interface ShippingAddress {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  countryCode: string;
  email: string;
  firstName: string;
  lastName: string;
  province: string;
  provinceCode: string;
  phone: string;
  zip: string;
  district?: string;
  ward?: string;
}

export interface FromAddress extends ShippingAddress {
  trackingNumber?: string;
  noPrinter?: boolean;
}

export interface Customer {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface LineItem {
  id: number;
  bundleItems: LineItem[];
  bundleItemId?: number;
  discountAmount: number;
  displayPrice: {
    cents: number;
    currencyIso: string;
  };
  displayPriceString: string;
  fulfillmentStatus: string | null;
  imageUrl: string;
  name: string;
  productId: number | null;
  productTitle: string;
  quantity: number;
  returnable: boolean;
  returnableDeadline: string;
  resalable: boolean;
  resalableUrl: string | null;
  shopPrice: number;
  sku: string;
  taxPrice: number;
  taxRate: number;
  unitPrice: number;
  maxUsableExchangeBonusCredit: number;
  storeCreditBonusCredit: number;
  unreturnableReason: string | null;
  variantId: number | null;
  variantTitle: string;
  vendor: string;
}

export interface BankAccount {
  bank: string;
  beneficiary: string;
  number: string;
  ifsc?: string;
}

export interface EwalletAccount {
  provider: string;
  name: string;
  identifier: string;
}
export interface StripePaymentMethod {
  paymentMethodId: string;
}

export interface Order {
  bankAccount?: BankAccount;
  currency: string;
  customer: Customer;
  email?: string;
  ewalletAccount?: EwalletAccount;
  id: string;
  lineItems: LineItem[];
  name: string;
  phone?: string;
  orderCreatedAt?: string;
  returnOrderId: string;
  shippingAddress: ShippingAddress;
  stripePaymentMethod?: StripePaymentMethod;
  stripePaymentIntentId?: string;
  stripeIntegrationEnabled: boolean;
  status:
    | "update_draft"
    | "draft"
    | "pending"
    | "reviewed"
    | "archived"
    | "received"
    | "pending_payment_from_customer"
    | "stripe_account_disabled"
    | "pending_action"
    | "completed"
    | "rejected";
}

export interface UpdateLineItemsReturnabilityAction {
  type: ActionTypes.updateLineItemsReturnability;
  payload: {
    lineItemIds: string[];
    t: TFunction;
  };
}

export interface UpdateShippingAddressAction {
  type: ActionTypes.updateShippingAddress;
  payload: ShippingAddress;
}

export interface UpdateBankAccountAction {
  type: ActionTypes.updateBankAccount;
  payload: BankAccount;
}

export interface UpdateEwalletAccountAction {
  type: ActionTypes.updateEwalletAccount;
  payload: EwalletAccount;
}

export interface UpdateStripePaymentMethodAction {
  type: ActionTypes.updateStripePaymentMethod;
  payload: StripePaymentMethod;
}

export interface UpdateStripePaymentIntentIdAction {
  type: ActionTypes.updateStripePaymentIntentId;
  payload: string;
}

export interface UpdateReturnOrderIdAction {
  type: ActionTypes.updateReturnOrderId;
  payload: Order["returnOrderId"];
}

export const addOrder = (order: Order): AddOrderAction => {
  return {
    type: ActionTypes.addOrder,
    payload: order,
  };
};

export const fetchAndAddOrder = (returnOrderId: string) => {
  return async (dispatch: Dispatch, getState: () => StoreState): Promise<void> => {
    dispatch(clearErrors());
    dispatch(setLoading());

    try {
      const {
        currentShop: { shopSlug },
      } = getState();
      const order: Order = await fetchOrder(shopSlug, returnOrderId);
      dispatch(addOrder(order));
    } catch (err) {
      if (err instanceof Error) dispatch(setError(err));
    }
    dispatch(clearLoading());
  };
};

export const updateLineItemsReturnability = (
  lineItemIds: string[],
  t: TFunction,
): UpdateLineItemsReturnabilityAction => {
  return {
    type: ActionTypes.updateLineItemsReturnability,
    payload: {
      lineItemIds: lineItemIds,
      t: t,
    },
  };
};

export const updateShippingAddress = (address: ShippingAddress): UpdateShippingAddressAction => {
  return {
    type: ActionTypes.updateShippingAddress,
    payload: address,
  };
};

export const updateBankAccount = (bankAccount: BankAccount): UpdateBankAccountAction => {
  return {
    type: ActionTypes.updateBankAccount,
    payload: bankAccount,
  };
};

export const updateEwalletAccount = (ewalletAccount: EwalletAccount): UpdateEwalletAccountAction => {
  return {
    type: ActionTypes.updateEwalletAccount,
    payload: ewalletAccount,
  };
};

export const updateStripePaymentMethod = (
  stripePaymentMethod: StripePaymentMethod,
): UpdateStripePaymentMethodAction => {
  return {
    type: ActionTypes.updateStripePaymentMethod,
    payload: stripePaymentMethod,
  };
};

export const updateReturnOrderId = (returnOrderId: string): UpdateReturnOrderIdAction => {
  return {
    type: ActionTypes.updateReturnOrderId,
    payload: returnOrderId,
  };
};

export const updateStripePaymentIntentId = (stripePaymentIntentId: string): UpdateStripePaymentIntentIdAction => {
  return {
    type: ActionTypes.updateStripePaymentIntentId,
    payload: stripePaymentIntentId,
  };
};
