import React from "react";
import { Header } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import { useReturnOrderStatus } from "hooks/useReturnOrder";
import { PreSubmitReturnItemsSummary, PostSubmitReturnItemsSummary } from "./components";

interface ReturnItemsSummaryProps {
  title: string;
}

export const ReturnItemsSummary: React.FC<ReturnItemsSummaryProps> = ({ title }) => {
  const { editableReturnOrder } = useReturnOrderStatus(); // draft or updateDraft ReturnOrder

  return (
    <div style={{ borderBottom: !isMobile ? "1px solid #D9D9D9" : "", padding: isMobile ? "4px 16px" : "30px 0px" }}>
      {!isMobile && (
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px", paddingBottom: "10px" }}>
          <span>
            <Header as="h3">{title}</Header>
          </span>
        </div>
      )}
      {editableReturnOrder ? <PreSubmitReturnItemsSummary /> : <PostSubmitReturnItemsSummary />}
    </div>
  );
};
