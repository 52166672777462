import React, { useState } from "react";
import { DesktopView, MobileView } from "./components";
import { isMobile } from "react-device-detect";
import { useSummary } from "./hooks";
import { Loading } from "components";
import { useParams } from "react-router-dom";
import { NotFound } from "pages/NotFound/NotFound";
import { Grid, Message } from "semantic-ui-react";
import parse from "html-react-parser";

export const PublicSummary: React.FC = (): JSX.Element | null => {
  const { returnId, shopSlug } = useParams<{ returnId: string; shopSlug: string }>();

  const { summary, isFetching } = useSummary(returnId);
  const { returnOrderId, returnOrderStatus, shop } = summary;

  const returnOrderNotFound = returnOrderId.toString() !== returnId.toString();
  const returnOrderIsDraft = returnOrderStatus === "draft";
  const shopSlugIsDifferent = shop.urlName !== shopSlug;
  const isNotFound = returnOrderNotFound || returnOrderIsDraft || shopSlugIsDifferent;

  const [showMessage, setShowMessage] = useState<Boolean>(true);
  const queryString = new URLSearchParams(window.location.search);
  const messageHeader = queryString.get("messageHeader");
  const messageContent = parse(queryString.get("messageContent") || "");
  const hasMessage: Boolean = !!messageHeader && !!messageContent;

  function handleOnDismiss() {
    setShowMessage(false);
  }

  if (isFetching) return <Loading />;
  if (isNotFound) return <NotFound />;

  return (
    <React.Fragment>
      {hasMessage && (
        <Grid container>
          <Grid.Row>
            <Message
              style={{ width: "100%" }}
              negative
              header={messageHeader}
              content={messageContent}
              hidden={!showMessage}
              onDismiss={handleOnDismiss}
            />
          </Grid.Row>
        </Grid>
      )}

      {isMobile ? <MobileView summary={summary} /> : <DesktopView summary={summary} />}
    </React.Fragment>
  );
};
