import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import React from "react";
import { getFormattedAmount, handleContinue } from "../helpers";
import parse from "html-react-parser";
import { ReturnMethodTypeButton } from "./ReturnMethodTypeButton";
import { NormalReturnMethodTypeButton } from "./NormalReturnMethodTypeButton";

export const RefundButton = () => {
  const locale = useSelector((state: StoreState) => state.locale.code);
  const uiSetting = useSelector((state: StoreState) => state.currentShop.userInterfaceSetting);
  const currency = useSelector((state: StoreState) => state.order.currency);
  const dispatch = useDispatch();
  const displayPrice = useSelector((state: StoreState) => state.formModal.selectedLineItem!.displayPrice);
  const returnItemValue = getFormattedAmount(displayPrice.cents, currency);
  const hideOpmRefundAmount = useSelector(
    (state: StoreState) => state.currentShop.userInterfaceSetting.hideOpmRefundAmount,
  );
  const title = uiSetting.refundTitleTranslations[locale]?.replaceAll("{{ return_item_value }}", returnItemValue);
  const description = uiSetting.refundDescriptionTranslations[locale]?.replaceAll(
    "{{ return_item_value }}",
    returnItemValue,
  );

  return description ? (
    <ReturnMethodTypeButton
      title={title}
      description={parse(description)}
      isPrioritized={false}
      onClick={() => {
        handleContinue("refund", dispatch);
      }}
      hideAmount={hideOpmRefundAmount}
    />
  ) : (
    <NormalReturnMethodTypeButton isBasic onClick={() => handleContinue("refund", dispatch)}>
      {title}
    </NormalReturnMethodTypeButton>
  );
};
