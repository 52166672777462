import { Question } from "types";

type QuestionsReducerState = {
  questions?: Question[];
  questionId?: number;
  answer?: string;
};
export type QuestionDispatchType = {
  type: string;
  payload: QuestionsReducerState;
};

export const questionsReducer = (state: Question[], action: QuestionDispatchType): Question[] => {
  switch (action.type) {
    case "ADD_QUESTIONS":
      return action.payload.questions as Question[];
    case "TOGGLE_MULTIPLE_CHOICE_ANSWER":
      return state.map((question: Question) => {
        if (question.id === action.payload.questionId) {
          let updatedAnswer = question.answers;

          if (question.answers.includes(action.payload.answer as string)) {
            const index = question.answers.indexOf(action.payload.answer as string);
            if (index !== -1) {
              updatedAnswer.splice(index, 1);
            }
          } else {
            updatedAnswer = [...question.answers, action.payload.answer as string];
          }
          return {
            ...question,
            answers: updatedAnswer,
          };
        } else {
          return question;
        }
      });
    case "TOGGLE_SINGLE_CHOICE_ANSWER":
      return state.map((question: Question) => {
        if (question.id === action.payload.questionId) {
          const updatedAnswer = [action.payload.answer as string];
          return {
            ...question,
            answers: updatedAnswer,
          };
        } else {
          return question;
        }
      });
    case "UPDATE_TEXT_ANSWER":
      return state.map((question: Question) => {
        if (question.id === action.payload.questionId) {
          const updatedAnswer = [action.payload.answer as string];
          return {
            ...question,
            answers: updatedAnswer,
          };
        } else {
          return question;
        }
      });
    default:
      return state;
  }
};
