import React from "react";
import { Loading, Overview } from "components";
import { Grid, Header } from "semantic-ui-react";
import { ReturnOrderDetailsSummary } from "components";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ReturnOrderSummaryTabs } from "./ReturnOrderSummaryTabs";
import { InvoiceProvider } from "contexts/InvoiceProvider";
import { useAuthContext } from "contexts/AuthProvider";
import { useGetReturnOrders } from "../hooks";

export const DesktopView: React.FC = (): JSX.Element => {
  const { t } = useTranslation("orderFlow");
  const { isLoading } = useAuthContext();
  const location = useLocation();

  const matchResult = location.pathname.match(/returns\/([\d]+)/);
  const returnOrderIdFromUrl = matchResult && matchResult.length ? matchResult[1] : "";
  const { returnOrders, isGettingReturnOrders } = useGetReturnOrders(returnOrderIdFromUrl);

  return (
    <div style={{ height: "100%", padding: "0" }}>
      <Grid style={{ padding: "0px" }}>
        <Grid.Row style={{ padding: "2rem 0 0 122px" }} stretched>
          <Header as="h2">{t("summary.header")}</Header>
        </Grid.Row>
        {!isGettingReturnOrders && returnOrders.length > 0 ? (
          <Grid.Row stretched style={{ paddingBottom: "20px" }}>
            <ReturnOrderSummaryTabs returnOrders={returnOrders} />
          </Grid.Row>
        ) : null}
      </Grid>
      <Grid columns={2} style={{ height: "100%" }}>
        {isLoading ? (
          <Loading />
        ) : (
          <InvoiceProvider>
            <Grid.Column
              style={{
                height: "100%",
                backgroundColor: "#FBFBFB",
                padding: "0 50px",
                marginLeft: "120px",
                width: "40%",
              }}
            >
              <Grid.Column mobile={16} tablet={16} computer={8}>
                <ReturnOrderDetailsSummary />
              </Grid.Column>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8} style={{ padding: "20px 120px" }}>
              <Overview />
            </Grid.Column>
          </InvoiceProvider>
        )}
      </Grid>
    </div>
  );
};
