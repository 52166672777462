import { UppyFile } from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import { UploadedImage } from "actions/returnItems";
import { PostCo360API } from "api/constants";
import { useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { createUppy } from "./helpers";

export const useImageUpload = (options = {}) => {
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);

  const { mutate: upload, ...others } = useMutation(
    async (variables: { productListingId: number; instructionId: number; imageData: any }) => {
      await PostCo360API().post(
        `/api/v2/customer/return_orders/${returnOrderId}/p2p/product_listings/${variables.productListingId}/uploaded_images`,
        {
          imageData: variables.imageData,
          instructionId: variables.instructionId,
        },
      );
    },
    {
      retry: false,
      onError: (_error: Error) => {},
      ...options,
    },
  );

  return { upload, ...others };
};

export const useDestroyImage = (options = {}) => {
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);

  const { mutate: destroy, ...others } = useMutation(
    async (variables: { imageId: number; productListingId: number }) => {
      await PostCo360API().delete(
        `/api/v2/customer/return_orders/${returnOrderId}/p2p/product_listings/${variables.productListingId}/uploaded_images/${variables.imageId}`,
      );
    },
    {
      retry: false,
      onError: (_error: Error) => {},
      ...options,
    },
  );

  return { destroy, ...others };
};

export const useDesktopUppy = ({
  attachDashboardTo,
  onImageUploaded,
}: {
  attachDashboardTo: string;
  onImageUploaded: (file: UploadedImage) => void;
}) => {
  const uppy = useRef(createUppy());

  useEffect(() => {
    const onUploadSuccess = (file: UppyFile | undefined) => {
      if (!file) return null;

      const meta = file.meta;
      const key = (meta["key"] as string) || "";
      const matches = key.match(/^\S+-cache\/(.+)/); // object key without prefix
      const id = matches ? matches[1] : null;
      onImageUploaded({
        id,
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      } as any);

      uppy.current.removeFile(file.id);
    };

    if (!uppy.current.getPlugin("Dashboard")) {
      uppy.current.use(Dashboard, {
        target: attachDashboardTo,
        inline: true,
        height: "50vh",
        width: "100%",
        proudlyDisplayPoweredByUppy: false,
        disableStatusBar: true,
        hideProgressAfterFinish: true,
        hideCancelButton: true,
      });
    }

    uppy.current.on("upload-success", onUploadSuccess);

    return () => {
      uppy.current.off("upload-success", onUploadSuccess);
    };
  }, [onImageUploaded]);
};
