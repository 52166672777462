import { Loading } from "components/Loading/Loading";
import { useDebounce } from "hooks/useDebounce";
import React, { useEffect, useRef, useState } from "react";
import styles from "./ExchangeProductSelector.module.css";
import { FilteredCollectionsProducts } from "./FilteredCollectionsProducts";
import { useExchangeableCollections } from "./hooks";
import { SearchField } from "./SearchField";

interface Props {
  filterMode: string;
  onClose: () => void;
}

export const SearchView = ({ filterMode, onClose }: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { exchangeableCollections, isLoading } = useExchangeableCollections();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (filterMode === "by_search_string") {
      inputRef.current!.focus();
    }
  }, [filterMode]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.searchView}>
      <div className={styles.searchBar}>
        <SearchField
          placeholder="What are you looking for?"
          ref={inputRef}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClearClick={() => setSearchTerm("")}
        />
        <span style={{ fontWeight: "bold", cursor: "pointer" }} onClick={onClose}>
          Cancel
        </span>
      </div>
      <FilteredCollectionsProducts
        debouncedSearchTerm={debouncedSearchTerm}
        collectionIds={exchangeableCollections.map((collection) => collection.id)}
      />
    </div>
  );
};
