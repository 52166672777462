import React from "react";
import styles from "../InstantExchangeSection.module.css";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { Grid, Icon, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { getFormattedPriceString } from "helpers";

interface RowProps {
  title: string;
  amount: string;
}

export const SavePaymentMethod = () => {
  const { instantExchangeExchangeOrderInvoice } = useInvoiceContext();
  const {
    presentmentCurrency: currency,
    total,
    totalBeforeChargeAdjustment,
    chargeAdjustments,
  } = instantExchangeExchangeOrderInvoice;
  const { t } = useTranslation("orderFlow");

  // formattedTotalBeforeChargeAdjustment is equal to formattedTotal if there is no charge adjustment
  const formattedTotalBeforeChargeAdjustment = getFormattedPriceString(totalBeforeChargeAdjustment.cents, currency);
  const formattedTotal = getFormattedPriceString(total.cents, currency);
  const formattedchargeAdjustment = getFormattedPriceString(chargeAdjustments.cents, currency, {
    useParenthesis: true,
  });

  const InfoRow: React.FC<RowProps> = ({ title, amount }) => (
    <>
      <Grid.Column computer={12} className={styles.totalDue} style={{ paddingBottom: 0 }}>
        {title}
      </Grid.Column>
      <Grid.Column computer={4} textAlign="right" className={styles.totalDue} style={{ paddingBottom: 0 }}>
        {amount}
      </Grid.Column>
    </>
  );

  const ChargeAdjustmentRow = () => (
    <>
      <Grid.Column computer={12} className={styles.chargeAdjustment}>
        {t("summary.chargeAdjustment")}
        <Popup
          trigger={<Icon name="info circle" size="small" />}
          content={"This outstanding amount is waived."}
          position="top center"
          wide="very"
        />
      </Grid.Column>
      <Grid.Column computer={4} textAlign="right" className={styles.chargeAdjustment}>
        {formattedchargeAdjustment}
      </Grid.Column>
    </>
  );

  if (chargeAdjustments.cents !== 0) {
    return (
      <>
        <InfoRow
          title={t("summary.instantExchange.totalOutstandingAmountDueToday")}
          amount={formattedTotalBeforeChargeAdjustment}
        />
        <ChargeAdjustmentRow />
        <InfoRow title={t("summary.instantExchange.totalAfterChargeAdjustment")} amount={formattedTotal} />
      </>
    );
  } else if (total.cents > 0) {
    return <InfoRow title={t("summary.instantExchange.totalOutstandingAmountDueToday")} amount={formattedTotal} />;
  } else {
    return <></>;
  }
};
