import React from "react";
import { connect } from "react-redux";
import { Header, Grid } from "semantic-ui-react";

import { LineItem } from "actions";
import styles from "./SelectedLineItemPreviewer.module.css";
import { SquareImage } from "components/SquareImage/SquareImage";

interface SelectedLineItemPreviewerProps {
  lineItem: LineItem;
}

const _SelectedLineItemPreviewer: React.FC<SelectedLineItemPreviewerProps> = ({ lineItem }) => {
  return (
    <Grid className={styles.container}>
      <Grid.Column mobile={13} tablet={13} computer={13}>
        <div>
          <Header sub>{lineItem.name}</Header>
          <span>{lineItem.displayPriceString}</span>
        </div>
      </Grid.Column>
      <Grid.Column mobile={3} tablet={3} computer={3}>
        <SquareImage src={lineItem.imageUrl} />
      </Grid.Column>
    </Grid>
  );
};

export const SelectedLineItemPreviewer = connect()(_SelectedLineItemPreviewer);
