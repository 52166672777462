import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { Image, Label } from "semantic-ui-react";
import { checkPriceIsWholeNumber, getFormattedPriceString } from "helpers";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
}

export const ReturnItemInformationSidebar = ({ onClose }: Props) => {
  const selectedLineItem = useSelector((state: StoreState) => state.formModal.selectedLineItem);
  const currency = useSelector((state: StoreState) => state.order.currency);
  const bonusCreditAmountCents = useSelector(
    (state: StoreState) => state.returnItems.currentReturnItem.bonusCreditAmountCents,
  );

  const { t } = useTranslation("orderFlow");

  const formattedBonusCredit = getFormattedPriceString(bonusCreditAmountCents, currency);

  const BonusCreditBadge = () => {
    return !!bonusCreditAmountCents ? (
      <Label
        style={{ fontSize: "9px", fontWeight: 800, backgroundColor: "#d5f5dd", color: "#00a737" }}
        size="mini"
        circular
      >
        +{" "}
        {checkPriceIsWholeNumber(bonusCreditAmountCents, currency)
          ? formattedBonusCredit.split(".")[0]
          : formattedBonusCredit}{" "}
        {t("bonusCreditText")}
      </Label>
    ) : null;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: isMobile ? "2px 2px 2px 5px" : "10px 10px 10px 20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <b style={{ flexGrow: 1, fontSize: isMobile ? "14px" : "16px" }}>
          {t("productSelector.returnItemInformationSidebar.header")}
        </b>
        <img
          src="https://360-static-assets.postco.co/close.png"
          alt="close the sidebar"
          style={{ height: isMobile ? "10px" : "14px", cursor: "pointer" }}
          onClick={onClose}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: "24px",
        }}
      >
        <Image
          src={selectedLineItem?.imageUrl}
          style={{ objectFit: "cover", width: isMobile ? "80px" : "100px", height: "auto" }}
        />
        <div
          style={{ display: "flex", flexDirection: "column", fontSize: isMobile ? "13px" : "15px", paddingTop: "10px" }}
        >
          <p>
            <span>{selectedLineItem?.name}</span>
            <br />
            <b>
              {getFormattedPriceString(
                selectedLineItem!.displayPrice.cents,
                selectedLineItem!.displayPrice.currencyIso,
              )}{" "}
              <BonusCreditBadge />
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};
