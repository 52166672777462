import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { getShipment } from "./api";

interface ShippingMethod {
  courierName: string;
  name: string;
  shippingServiceType: string;
  type: string;
  requireShipment: boolean;
  fixedPrice: boolean;
  customerNote: string;
}
interface Shipment {
  id: number | null;
  assignedCourierName: string;
  courierName: string;
  customerBorneShipping: boolean;
  customsFormUrl?: string;
  fee: { cents: number; currencyIso: string };
  paymentStage: string;
  requiresPayment: boolean;
  qrCodeUrl?: string;
  shipmentLabelLink: string;
  shippingMethod: ShippingMethod;
  trackingUrl: string;
}

const nullShipment: Shipment = {
  id: null,
  assignedCourierName: "",
  courierName: "",
  customerBorneShipping: false,
  fee: { cents: 0, currencyIso: "USD" },
  paymentStage: "",
  requiresPayment: false,
  shipmentLabelLink: "",
  shippingMethod: {
    courierName: "",
    name: "",
    shippingServiceType: "",
    type: "",
    requireShipment: true,
    fixedPrice: false,
    customerNote: "",
  },
  trackingUrl: "",
};

export function useShipment() {
  const returnOrderId = useSelector((store: StoreState) => store.order.returnOrderId);
  const { data, ...others } = useQuery({
    queryKey: ["shipmentAssignedCourierName", returnOrderId],
    queryFn: () => getShipment(returnOrderId),
  });
  const shipment: Shipment = data ? data : nullShipment;
  return { shipment, ...others };
}
