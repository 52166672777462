import React from "react";
import { Card, Popup } from "semantic-ui-react";
import { ReturnItem } from "../../actions";
import { useTruncateItemCardSentence } from "hooks/useTruncateItemCardSentence";

interface DeclineReasonInterface {
  returnItems: ReturnItem[];
}

export const DeclineReason: React.FC<DeclineReasonInterface> = ({ returnItems }) => {
  const { truncateSentence } = useTruncateItemCardSentence();

  function RejectReasonMarkup(rejectReason: string) {
    const rejectReasonTruncated = truncateSentence(rejectReason) !== rejectReason;

    if (rejectReasonTruncated) {
      return (
        <Popup
          content={rejectReason}
          trigger={
            <Card.Meta>
              <b>Decline Reason:</b> {truncateSentence(rejectReason)}
            </Card.Meta>
          }
        />
      );
    } else {
      return (
        <Card.Meta>
          <b>Decline Reason:</b> {rejectReason}
        </Card.Meta>
      );
    }
  }

  return (
    <div style={{ marginTop: "0.6rem" }}>
      {returnItems.map((returnItem: ReturnItem) => {
        if (returnItem.rejectReason) {
          return <div key={returnItem.uuid}>{RejectReasonMarkup(returnItem.rejectReason)}</div>;
        } else {
          return null;
        }
      })}
    </div>
  );
};
