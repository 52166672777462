import { nullMainInvoice } from "constants/invoice";
import { useQuery } from "react-query";
import { Summary } from "types";
import { getSummary } from "./api";

const nullSummary: Summary = {
  returnOrderId: 0,
  returnOrderStatus: "",
  custEmail: "",
  returnItems: [],
  shippingMethod: {
    name: "",
    type: "",
    customerNote: "",
  },
  shipment: {
    fee: null,
    customerBorneShipping: false,
    requiresPayment: false,
    paymentStage: null,
    customsFormUrl: null,
    shipmentLabelLink: null,
    trackingUrl: null,
    qrCodeUrl: null,
    courierName: null,
  },
  shop: {
    ecomProvider: "",
    autoHideValidShippingAddress: false,
    urlName: "",
  },
  shippingAddress: {
    address1: "",
    address2: null,
    city: "",
    province: "",
    zip: "",
    country: "",
    countryCode: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    district: null,
    ward: null,
  },
  bankAccount: {
    bank: "",
    beneficiary: "",
    number: "",
  },
  ewalletAccount: {
    provider: "",
    name: "",
    identifier: "",
  },
  invoice: nullMainInvoice,
};

export const useSummary = (returnOrderId: string) => {
  const { data, ...others } = useQuery({
    queryKey: ["publicSummary", returnOrderId],
    queryFn: () => getSummary(returnOrderId),
  });
  const summary = data ? data : nullSummary;
  return { summary, ...others };
};
