import React from "react";
import parse from "html-react-parser";
import "./style.css";
import { Accordion, Header } from "semantic-ui-react";
import { createSummaryPanels } from "helpers/summary";
import { useTranslation } from "react-i18next";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { PaymentAndSubmission } from "./PaymentAndSubmission";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { Overview } from "components";
import { ReturnTypeTabs } from "../ReturnTypeTabs";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { P2PSection } from "../P2PSection/P2PSection";

export const MobileView: React.FC<any> = (): JSX.Element => {
  const { isEmbedded } = useIsEmbedded();
  const { t } = useTranslation("orderFlow");
  const { p2pRequestInfo } = useInvoiceContext();

  const isP2PReturns = useSelector((state: StoreState) => state.returnItems.p2pReturns);

  const p2pPromotionalDescription = useSelector(
    (state: StoreState) => state.currentShop.p2pSetting?.promotionalDescription,
  );

  const showShippingMethodSummary = true;

  const summaryPanels = createSummaryPanels(showShippingMethodSummary, t);

  return (
    <div
      style={{
        padding: "0.5rem",
        height: "100%",
        overflowX: "hidden",
      }}
    >
      <div style={{ backgroundColor: "#FBFBFB" }}>
        <div style={{ padding: "1rem", paddingLeft: isEmbedded ? "64px" : "1rem" }}>
          <Header as="h2" textAlign="left">
            {t("summary.header")}
          </Header>
        </div>
        <Accordion panels={summaryPanels} exclusive={false} />
      </div>

      {p2pRequestInfo.requestable ? (
        <div style={{ padding: "1rem" }}>
          <ReturnTypeTabs />
          {p2pPromotionalDescription ? (
            <div style={{ textAlign: "center", marginTop: 16 }}>
              <i style={{ color: "#217005" }}>{parse(p2pPromotionalDescription)}</i>
            </div>
          ) : null}
        </div>
      ) : (
        <div style={{ padding: "1rem" }}>
          <Header as="h3">{t("invoiceSummary.header")}</Header>
        </div>
      )}
      {!isP2PReturns ? (
        <>
          <Overview />
          <PaymentAndSubmission />
        </>
      ) : (
        <P2PSection />
      )}
    </div>
  );
};
