import React, { useState } from "react";
import { Header, Icon, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Button, Loading } from "components";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { ImageUploadRequirementInstruction } from "hooks/useP2PProductListings";
import { useDesktopUppy } from "pages/P2PProductListingPage/hooks";
import { SampleImagesViewer } from "./SampleImagesViewer";
import { UploadedImage } from "actions";
import { useHistory } from "react-router-dom";

interface Props {
  instructions: ImageUploadRequirementInstruction[];
  selectedInstructionIndex: number;
  isUploading: boolean;
  isDestroying: boolean;
  onImageUploaded: (uploadedImage: UploadedImage) => void;
  onImageRemoved: () => void;
  onBack?: () => void;
  onProceed: () => void;
}

export const DesktopImageUpload = ({
  instructions,
  selectedInstructionIndex,
  isUploading,
  isDestroying,
  onImageUploaded,
  onImageRemoved,
  onBack,
  onProceed,
}: Props) => {
  const history = useHistory();
  const { t } = useTranslation("orderFlow");
  const instruction = instructions[selectedInstructionIndex];
  useDesktopUppy({ attachDashboardTo: "#uppy-dashboard", onImageUploaded });

  const [viewSampleImages, setViewSampleImages] = useState(false);

  const [imageDownloaded, setImageDownloaded] = useState(false);

  return (
    <>
      <Modal open dimmer="inverted" size="small" style={{ padding: "11px" }}>
        <Modal.Header>
          <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
            {onBack && (
              <div style={{ padding: 8, cursor: "pointer" }} onClick={onBack}>
                <img src="https://360-static-assets.postco.co/left-arrow.png" style={{ height: 14 }} />
              </div>
            )}
            <Header style={{ fontSize: "1.8rem", flexGrow: 1, margin: 0 }}>
              {`${instruction.title} (${selectedInstructionIndex + 1}/${instructions.length})`}
            </Header>
            <div style={{ padding: 8, cursor: "pointer" }} onClick={history.goBack}>
              <img src="https://360-static-assets.postco.co/close.png" style={{ height: 14 }} />
            </div>
          </div>
          <div style={{ gridArea: "description", fontSize: "1rem", fontWeight: "normal" }}>
            {instruction.description}
            <br />
            {instruction.sampleImages.length > 0 && (
              <span style={{ color: "#5281BF", cursor: "pointer" }} onClick={() => setViewSampleImages(true)}>
                <Icon name="external" /> {t("p2pProductListingPage.exampleImages")}
              </span>
            )}
          </div>
        </Modal.Header>
        <Modal.Content>
          {instruction.image && !imageDownloaded && (
            <div style={{ height: "50vh" }}>
              <Loading />
            </div>
          )}
          {(isUploading || isDestroying) && (
            <div style={{ height: "50vh" }}>
              <Loading />
            </div>
          )}
          <div hidden={Boolean(instruction.image) || isUploading || isDestroying} id="uppy-dashboard" />
          <img
            src={instruction.image?.url}
            onLoad={() => {
              setImageDownloaded(true);
            }}
            style={{
              display: instruction.image && imageDownloaded && !isUploading && !isDestroying ? "block" : "none",
              marginInline: "auto",
              height: "50vh",
              width: "100%",
              objectFit: "contain",
            }}
          />

          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginTop: "8px", gap: "8px" }}>
            <Button
              fluid
              disabled={!instruction.image}
              onClick={onImageRemoved}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              {t("common.remove")}
            </Button>
            <Button fluid disabled={!instruction.image} color="primaryColor" onClick={onProceed}>
              {t("common.proceed")}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      {instruction.sampleImages.length && (
        <SampleImagesViewer
          sampleImages={instruction.sampleImages}
          open={viewSampleImages}
          onClose={() => setViewSampleImages(false)}
        />
      )}
    </>
  );
};
