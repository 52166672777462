import React from "react";
import { useAuthContext } from "contexts/AuthProvider";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

interface AuthenticatedRouteProps {
  children: React.ReactNode;
  exact?: boolean;
  path: string;
}

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ children, ...rest }) => {
  const { loggedIn } = useAuthContext();
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);

  if (!loggedIn) {
    return <Redirect to={`/${shopSlug}`} />;
  }

  return <Route {...rest}>{children}</Route>;
};
