import React, { useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { ButtonGroup, Icon, Menu } from "semantic-ui-react";
import { StoreState } from "reducers";
import { snakeToCapitalize } from "helpers";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import styles from "./ReturnOrderSummaryTabs.module.css";
import { useLocation } from "react-router-dom";
import { EditReturnOrderButton } from "./EditReturnOrderButton";
import { CancelRequestButton } from "./CancelRequestButton";
import { GetReturnOrdersValues } from "api";
import { LodgeReturnButton } from "./LodgeReturnButton";

interface Props {
  returnOrders: GetReturnOrdersValues["returnOrders"];
}

const SummaryTabsStatusBadge = ({ status, selected }: { status: string; selected: boolean }) => {
  const displayedStatus = status === "awaiting_p2p" ? "reNEW in Progress" : snakeToCapitalize(status);
  const borderColor = selected ? "#D7F7C2" : "#E9E9EA";
  const textColor = selected ? "#228403" : "#777777";

  return (
    <div className={styles.statusBadge} style={{ backgroundColor: borderColor }}>
      <p className={styles.statusBadgeText} style={{ color: textColor }}>
        {displayedStatus}
      </p>
    </div>
  );
};

export const ReturnOrderSummaryTabs = ({ returnOrders }: Props) => {
  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(window.location.search);

  const matchResult = location.pathname.match(/returns\/([\d]+)/);
  const returnOrderIdFromUrl = matchResult && matchResult.length ? matchResult[1] : "";

  const [selectedReturnOrderId, setSelectedReturnOrderId] = useState<number>(Number(returnOrderIdFromUrl));
  const selectedReturnOrder = useMemo(
    () => returnOrders.find((returnOrder) => returnOrder.id === selectedReturnOrderId),
    [selectedReturnOrderId],
  );

  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const tabContainerRef = useRef<HTMLDivElement | null>(null);
  const [scrollable, setScrollable] = useState(true);
  const [hideStartArrow, setHideStartArrow] = useState(true);
  const [hideEndArrow, setHideEndArrow] = useState(true);

  const onMenuItemClick = (index: number) => {
    const targetReturnOrder = returnOrders[index];

    return () => {
      setSelectedReturnOrderId(targetReturnOrder.id);
      history.push(`/${shopSlug}/returns/${targetReturnOrder.id}/details?${searchParams.toString()}`);
    };
  };

  useEffect(() => {
    if (selectedReturnOrderId && tabContainerRef.current) {
      const element = tabContainerRef.current;
      const selectedTabElement = element.getElementsByClassName(selectedReturnOrderId.toString())[0];

      if (selectedTabElement instanceof HTMLElement) {
        const elementLeftOffset = selectedTabElement.offsetLeft - 50;
        const finalOffset = elementLeftOffset < 0 ? 0 : elementLeftOffset;

        element.scrollTo({ left: finalOffset });
      }

      const containerWidth = element.clientWidth;
      const tabsWidth = element.getElementsByTagName("div")[0]!.clientWidth;

      setHideEndArrow(containerWidth >= tabsWidth);
      setScrollable(containerWidth < tabsWidth);
    }
  }, [selectedReturnOrderId]);

  const summaryTabs = (
    <>
      {!scrollable || hideStartArrow ? (
        <></>
      ) : (
        <Icon
          color="grey"
          name="angle left"
          onClick={() => {
            tabContainerRef.current!.scrollTo({
              left: tabContainerRef.current!.scrollLeft - 200,
            });
          }}
          style={{ paddingRight: "5px", cursor: "pointer" }}
        />
      )}
      <div
        ref={tabContainerRef}
        className={styles.scrollableContainer}
        onScroll={() => {
          const container = tabContainerRef.current!;

          setHideStartArrow(container.scrollLeft === 0);
          setHideEndArrow(container.scrollWidth - container.scrollLeft - container.clientWidth < 5);
        }}
      >
        <Menu pointing secondary style={{ border: "none", marginBottom: "0px", width: "max-content" }}>
          {returnOrders.map(({ id, submittedAt, status }, index) => {
            const returnOrderName = moment.parseZone(submittedAt).format("MMMM D, YYYY");
            const returnOrderSelected = id === selectedReturnOrder?.id;

            return (
              <Menu.Item
                style={{
                  fontSize: isMobile ? "13px" : "15px",
                  borderColor: "#228403",
                  borderWidth: returnOrderSelected ? "4px" : 0,
                  paddingBottom: returnOrderSelected ? "8px" : "12px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                key={id}
                name={returnOrderName}
                className={id.toString()}
                active={returnOrderSelected}
                onClick={onMenuItemClick(index)}
              >
                <p className={styles.summaryTabName} style={{ color: returnOrderSelected ? "black" : "#777777" }}>
                  {returnOrderName}
                </p>
                <div style={{ marginLeft: "10px" }}>
                  <SummaryTabsStatusBadge status={status} selected={returnOrderSelected} />
                </div>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
      {!scrollable || hideEndArrow ? (
        <></>
      ) : (
        <Icon
          name="angle right"
          color="grey"
          onClick={() => {
            tabContainerRef.current!.scrollTo({
              left: tabContainerRef.current!.scrollLeft + 200,
            });
          }}
          style={{ paddingLeft: "5px", cursor: "pointer" }}
        />
      )}
    </>
  );

  const editable = selectedReturnOrder?.editable;
  const cancellable = selectedReturnOrder?.cancellable;
  const multiReturnable = selectedReturnOrder?.multiReturnable;
  const displayActionButtons = editable || cancellable || multiReturnable;

  // The ID of the return order whose SummaryDetails page will be redirected to
  // after cancelling the current pending return order.
  //
  // returnOrders are sorted in descending order by their createdAt timestamp and
  // only the latest returnOrder can be cancelled. Therefore, we are using returnOrders[1]
  // as the next returnOrder.
  const nextReturnOrderId = returnOrders[1] ? returnOrders[1].id : null;

  return !isMobile ? (
    <div className={styles.summaryTabsContainer}>
      {summaryTabs}
      {displayActionButtons && (
        <div className={styles.actionButtonsContainer}>
          {cancellable && editable && (
            <ButtonGroup basic>
              <CancelRequestButton nextReturnOrderId={nextReturnOrderId} />
              <EditReturnOrderButton />
            </ButtonGroup>
          )}
          {multiReturnable && <LodgeReturnButton />}
        </div>
      )}
    </div>
  ) : (
    <>
      {displayActionButtons && (
        <div className={styles.actionButtonsContainerForVert}>
          {cancellable && editable && (
            <ButtonGroup basic>
              <CancelRequestButton nextReturnOrderId={nextReturnOrderId} />
              <EditReturnOrderButton />
            </ButtonGroup>
          )}
          {multiReturnable && <LodgeReturnButton />}
        </div>
      )}
      <div className={styles.summaryTabsContainer} style={{ paddingInline: 0 }}>
        {summaryTabs}
      </div>
    </>
  );
};
