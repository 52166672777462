import { bankAccountValidationShape } from "components/BankAccountFields/constants";
import { ewalletAccountValidationShape } from "components/EwalletAccountFields/constants";
import * as yup from "yup";

export const validationSchema = (hasOnlineBankTransfer: boolean, hasEwalletTransfer: boolean) => {
  let schemaDefinition = {};
  if (hasOnlineBankTransfer) {
    schemaDefinition = {
      ...bankAccountValidationShape,
    };
  } else if (hasEwalletTransfer) {
    schemaDefinition = {
      ...ewalletAccountValidationShape,
    };
  }
  return yup.object().shape(schemaDefinition);
};
