import React from "react";
import { AccordionPanelProps } from "semantic-ui-react";
import { Title, ReturnItemsSummary, ShippingAddressDetailsSummary, ShippingMethodSummary } from "components";

export const PANEL_KEYS = {
  returnItems: "return-items",
  shippingMethod: "shipping-method",
  shippingInfo: "shipping-info",
  paymentInfo: "payment-info",
  overview: "overview",
  refundOverview: "refund-overview",
};

export const createSummaryPanels = (showShippingMethodSummary: boolean, t: Function): AccordionPanelProps[] => {
  const returnItemSummaryTitle = t("orderSummary.header");
  const panels = [
    {
      key: PANEL_KEYS.returnItems,
      title: { content: <Title>{returnItemSummaryTitle}</Title> },
      content: { content: <ReturnItemsSummary title={returnItemSummaryTitle} /> },
    },
    {
      key: PANEL_KEYS.shippingInfo,
      title: { content: <Title>{t("addressForm.pageHeader")}</Title> },
      content: { content: <ShippingAddressDetailsSummary /> },
    },
  ];
  if (showShippingMethodSummary) {
    panels.splice(1, 0, {
      key: PANEL_KEYS.shippingMethod,
      title: { content: <Title>{t("shippingMethodSummary.header")}</Title> },
      content: { content: <ShippingMethodSummary /> },
    });
  }

  return panels;
};

export const indexOf = (panels: AccordionPanelProps[], key: string): number => {
  return panels.findIndex((p) => p.key === key);
};
