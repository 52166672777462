import { populateAnswers } from "helpers/questions";
import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { QuestionDispatchType, questionsReducer } from "reducers/questions";
import { Question } from "types";
import { useQuestions } from "../hooks/useQuestions";

type ContextProps = {
  stage: number;
  questions: Question[];
  questionsData: Question[];
  questionsDispatch: React.Dispatch<QuestionDispatchType>;
  page: number;
  setPage: Function;
  isFetching: boolean;
  isLoading: boolean;
};

const QuestionFormContext = createContext<ContextProps>({
  stage: 0,
  questions: [],
  questionsData: [],
  questionsDispatch: () => {},
  page: 0,
  setPage: () => {},
  isFetching: false,
  isLoading: false,
});

function QuestionFormProvider({ children, stage }: any) {
  const { questionsData, isFetching, isLoading } = useQuestions(stage);
  const [questions, questionsDispatch] = useReducer(questionsReducer, questionsData);
  const [page, setPage] = useState<number>(0);
  const answeredReturnItemQuestions = useSelector((state: StoreState) => state.returnItems.currentReturnItem.questions);
  const answeredOrderQuestions = useSelector((state: StoreState) => state.returnItems.questions);
  const answeredQuestions = answeredReturnItemQuestions.concat(answeredOrderQuestions);
  const filteredQuestion = answeredQuestions.filter((question) => question.stage === stage);

  useEffect(() => {
    if (!isFetching) {
      questionsDispatch({
        type: "ADD_QUESTIONS",
        payload: {
          questions: populateAnswers(questionsData, filteredQuestion),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  return (
    <QuestionFormContext.Provider
      value={{ stage, questions, questionsData, questionsDispatch, page, setPage, isFetching, isLoading }}
    >
      {children}
    </QuestionFormContext.Provider>
  );
}

function useQuestionFormContext() {
  const context = useContext(QuestionFormContext);
  if (context === undefined) {
    throw new Error("useQuestionFormContext must be used within a QuestionFormProvider");
  }
  return context;
}

export { QuestionFormProvider, useQuestionFormContext };
