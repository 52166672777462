import { clearCurrentReturnItem, closeFormModal, formModalGoBack } from "actions";
import React from "react";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import { Modal } from "semantic-ui-react";
import { VariantSelector } from "./components/VariantSelector/VariantSelector";
import styles from "./VariantsSelector.module.css";

export const VariantsSelector = () => {
  const dispatch = useDispatch();

  return (
    <Modal.Content style={{ padding: 0 }}>
      <div className={styles.variantSelectorModal}>
        <div className={styles.headerContainer}>
          <img
            src="https://360-static-assets.postco.co/left-arrow.png"
            alt="go back to previous step"
            onClick={() => dispatch(formModalGoBack())}
            style={{ height: isMobile ? "10px" : "14px", cursor: "pointer" }}
          />
          <img
            src="https://360-static-assets.postco.co/close.png"
            alt="cancel the return"
            style={{ height: isMobile ? "10px" : "14px", cursor: "pointer" }}
            onClick={() => {
              dispatch(clearCurrentReturnItem());
              dispatch(closeFormModal());
            }}
          />
        </div>
        <div className={styles.variantSelectorContainer}>
          <VariantSelector />
        </div>
      </div>
    </Modal.Content>
  );
};
