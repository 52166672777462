import { useState, useCallback, useEffect } from "react";
import { Country, loadCountries } from "@shopify/address";

const nullCountry: Country = {
  name: "",
  code: "",
  continent: "",
  phoneNumberPrefix: 0,
  autocompletionField: "",
  provinceKey: "STATE",
  labels: {
    address1: "",
    address2: "",
    city: "",
    company: "",
    country: "",
    firstName: "",
    lastName: "",
    phone: "",
    postalCode: "",
    zone: "",
  },
  optionalLabels: {
    address2: "",
  },
  formatting: {
    edit: "",
    show: "",
  },
  zones: [],
};

export function useShopifyCountries(locale: string) {
  const [countries, setCountries] = useState<Country[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAndSetCountries = useCallback(async () => {
    setIsLoading(true);
    const newCountries = await loadCountries(locale);
    setCountries(newCountries);
    setIsLoading(false);
  }, [locale]);

  const findCountry = useCallback(
    (countryCode: string) => {
      return countries.find((c) => c.code === countryCode) || nullCountry;
    },
    [countries],
  );

  useEffect(() => {
    getAndSetCountries();
  }, [getAndSetCountries]);

  return { countries, isLoading, findCountry };
}
