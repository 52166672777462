import React, { useEffect } from "react";
import { ReturnSteps } from "actions";
import { useCheckCurrentStep } from "helpers/currentStep";
import { DesktopView, MobileView } from "./components";
import { isMobile } from "react-device-detect";
import { InvoiceProvider } from "contexts/InvoiceProvider";
import { useReturnableItemsValidation } from "hooks/useReturnableItemsValidation";
import { useExchangeableItemsValidation } from "hooks/useExchangeableItemsValidation";
import { useAuthContext } from "contexts/AuthProvider";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

export const SummaryAndPaymentDetails: React.FC = (): JSX.Element | null => {
  useCheckCurrentStep(ReturnSteps.summaryAndPaymentDetails);
  const instantExchange = useSelector((store: StoreState) => store.returnItems.instantExchange);

  const { refetchOrderAndReturnOrder, setRefetchInterval } = useAuthContext();
  const refetchInterval = instantExchange ? false : 60000; // 1 minute

  useEffect(
    () => {
      refetchOrderAndReturnOrder();
      return () => {
        setRefetchInterval(60000 * 10); // 10 minutes
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    setRefetchInterval(refetchInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchInterval]);

  useReturnableItemsValidation();
  useExchangeableItemsValidation();

  return <InvoiceProvider>{isMobile ? <MobileView /> : <DesktopView />}</InvoiceProvider>;
};
