import React, { useRef } from "react";
import {
  Title,
  ReturnItemsSummary,
  ShippingAddressDetailsSummary,
  ShippingMethodSummary,
  PageHeader,
  OnlineTransfer,
  Button,
} from "components";
import { Accordion } from "semantic-ui-react";
import { ReceivablePaymentForm, ReceivablePaymentFormForStickyButtons } from "./ReceivablePaymentForm";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { FormikProps } from "formik";
import { useDispatch } from "react-redux";
import { ReturnSteps, updateReturnOrderThenContinue } from "actions";
import styles from "./MobileView.module.css";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { StickyBottomAppBar } from "StickyBottomAppBar";

export const MobileView: React.FC = (): JSX.Element => {
  const { isEmbedded } = useIsEmbedded();
  const { t } = useTranslation("orderFlow");
  const returnItemSummaryTitle = t("orderSummary.itemsToReturn");
  const shippingMethodTitle = t("shippingMethodSummary.header");
  const shippingAddressTitle = t("addressForm.pageHeader");

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    featureFlags: { stickyButtons },
  } = useFeatureFlags();
  const currentStep = ReturnSteps.receivablePaymentDetails;

  const formRef = useRef<FormikProps<OnlineTransfer>>(null);
  const continueButtonDisabled = formRef.current !== null && formRef.current.isSubmitting;

  const handleSubmit = async () => {
    if (!formRef.current) {
      return;
    }

    await formRef.current.submitForm();

    if (!formRef.current.isValid) {
      return;
    }

    dispatch(updateReturnOrderThenContinue(history, currentStep));
  };

  const panels = [
    {
      title: { content: <Title>{returnItemSummaryTitle}</Title> },
      content: { content: <ReturnItemsSummary title={returnItemSummaryTitle} /> },
    },
    {
      title: { content: <Title>{shippingMethodTitle}</Title> },
      content: { content: <ShippingMethodSummary /> },
    },
    {
      title: { content: <Title>{shippingAddressTitle}</Title> },
      content: { content: <ShippingAddressDetailsSummary /> },
    },
  ];

  return stickyButtons ? (
    <div className={styles.mobileViewContainer}>
      <div
        style={{
          padding: "0.5rem",
          marginTop: isEmbedded ? "16px" : 0,
        }}
      >
        <div style={{ backgroundColor: "#FBFBFB" }}>
          <PageHeader header={t("receivablePaymentDetails.summaryTitle")} textAlign="left" />
          <Accordion panels={panels} exclusive={false} />
        </div>
        <br />
        <ReceivablePaymentFormForStickyButtons formRef={formRef} />
      </div>
      <StickyBottomAppBar>
        <Button
          style={{ margin: "1rem", width: "calc(100vw - 2rem)" }}
          color="primaryColor"
          onClick={handleSubmit}
          disabled={continueButtonDisabled}
        >
          <p>{t("continue")}</p>
        </Button>
      </StickyBottomAppBar>
    </div>
  ) : (
    <div
      style={{
        padding: "0.5rem",
        marginTop: isEmbedded ? "16px" : 0,
      }}
    >
      <div style={{ backgroundColor: "#FBFBFB" }}>
        <PageHeader header={t("receivablePaymentDetails.summaryTitle")} textAlign="left" />
        <Accordion panels={panels} exclusive={false} />
      </div>
      <br />
      <ReceivablePaymentForm />
    </div>
  );
};
