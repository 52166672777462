import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import camelcaseKeys from "camelcase-keys";
import { PostCo360API } from "api/constants";

const evaluateReturnableByQuestion = async (variables: {
  shopId: number | null;
  orderId: string;
  returnOrderId: string;
  productId: number | null;
  variantId: number | null;
  lineItemId: string;
  bundleItemId: number | null;
  returnReason: string;
  questionAnswerPairs: { gid: string; answers: string[] }[];
}): Promise<any> => {
  const {
    shopId,
    orderId,
    returnOrderId,
    productId,
    variantId,
    lineItemId,
    bundleItemId,
    returnReason,
    questionAnswerPairs,
  } = variables;
  try {
    const response: AxiosResponse<any> = await PostCo360API().post(`/api/v2/customer/questions/returnable_evaluation`, {
      data: {
        shopId,
        policyRuleData: {
          orderId,
          returnOrderId,
          productId,
          variantId,
          lineItemId,
          bundleItemId,
          returnReason,
          questions: questionAnswerPairs,
        },
      },
    });
    return camelcaseKeys({ ...response }, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};

export function useReturnableByQuestion(options = {}) {
  return useMutation(evaluateReturnableByQuestion, {
    ...options,
  });
}
