import React from "react";
import { Modal } from "semantic-ui-react";
import { SelectedLineItemPreviewer } from "..";
import { clearCurrentReturnItem, closeFormModal, formModalGoBack, FormModalSteps } from "actions";
import styles from "../../FormModal.module.css";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "reducers";
import { isMobile } from "react-device-detect";

interface ControlButtonsProps {
  onClose: Function;
  onPrev: Function;
  showBackButton: boolean;
}
export const ControlButtons: React.FC<ControlButtonsProps> = ({ onClose, onPrev, showBackButton }) => {
  return (
    <div className={styles.headerContainer}>
      {showBackButton ? (
        <img
          src="https://360-static-assets.postco.co/left-arrow.png"
          alt="go back to previous step"
          onClick={() => onPrev()}
          style={{ height: isMobile ? "10px" : "14px", cursor: "pointer" }}
        />
      ) : (
        <div />
      )}
      <img
        src="https://360-static-assets.postco.co/close.png"
        alt="cancel the return"
        style={{ height: isMobile ? "10px" : "14px", cursor: "pointer" }}
        onClick={() => onClose()}
      />
    </div>
  );
};

interface FormModalContentInterface {
  children: any;
  onPrev?: Function;
  showBackButton?: boolean;
}

export const FormModalContent: React.FC<FormModalContentInterface> = ({ children, onPrev, showBackButton }) => {
  const dispatch = useDispatch();
  const formModal = useSelector((state: StoreState) => state.formModal);
  const handleCloseModal = (): void => {
    dispatch(clearCurrentReturnItem());
    dispatch(closeFormModal());
  };

  if (!formModal.selectedLineItem) return null;

  return (
    <>
      <ControlButtons
        onClose={handleCloseModal}
        onPrev={() => {
          if (onPrev) {
            onPrev();
          } else {
            dispatch(formModalGoBack());
          }
        }}
        showBackButton={showBackButton ?? formModal.stepsHistory.length > 0}
      />
      {/*
      Semantic UI React has the style `.ui.modal > .content` with attribute `padding: 1rem !important`. The `!important`
      prevents customization from our end unless we adding `!important` to our style, which is discouraged.

      Because `.ui.modal > .content` only apply for the .content children (not descendants), the `div` below is used to
      make the `Modal.Description` the descendant, remove that `!important`ed attribute.
       */}
      <div>
        <Modal.Content style={{ padding: isMobile ? "20px" : "24px", paddingTop: "10px" }}>
          <Modal.Description>
            <SelectedLineItemPreviewer lineItem={formModal.selectedLineItem} />
            {children}
          </Modal.Description>
        </Modal.Content>
      </div>
    </>
  );
};
