import React, { useMemo } from "react";
import { PageHeader } from "components";
import { Accordion } from "semantic-ui-react";
import { createSummaryPanels, indexOf, PANEL_KEYS } from "./helpers";
import { useTranslation } from "react-i18next";
import { Summary } from "types";
import { checkRefundMethodAvailability } from "../ReturnOrderDetails/components";

interface MobileViewProps {
  summary: Summary;
}

export const MobileView: React.FC<MobileViewProps> = ({ summary }): JSX.Element => {
  const { t } = useTranslation("orderFlow");
  const { returnItems } = summary;
  const hasOnlineBankTransfer = useMemo(
    () => checkRefundMethodAvailability(returnItems, "online_bank_transfer"),
    [returnItems],
  );
  const hasEwalletTransfer = useMemo(
    () => checkRefundMethodAvailability(returnItems, "ewallet_transfer"),
    [returnItems],
  );

  const summaryPanels = createSummaryPanels(summary, hasOnlineBankTransfer, hasEwalletTransfer, t);
  const overviewIndex = indexOf(summaryPanels, PANEL_KEYS.overview);
  const defaultActiveIndex = [overviewIndex];

  return (
    <div style={{ padding: "0.5rem", height: "100%" }}>
      <PageHeader header={t("summary.header")} textAlign="left" />
      <Accordion defaultActiveIndex={defaultActiveIndex} panels={summaryPanels} exclusive={false} />
      <div style={{ paddingBottom: "3rem" }}>
        <br />
      </div>
    </div>
  );
};
