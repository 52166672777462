import React from "react";

function PostCoLogo() {
  return (
    <svg width="111.6" height="30" viewBox="0 0 639.84814 215.43637" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" transform="matrix(5.0047504 0 0 5.0047504 .000532 -.7748588)">
        <path d="m15.81307 14.977757 6.597831-3.133606-6.597831-2.902459-6.5978304 2.902459z" fill="#f6f4f1" />
        <path
          d="m22.711385 19.867688v-5.929154l-6.898315 3.257842-6.898314-3.257842v5.929154l6.898314 4.00177z"
          fill="#f6f4f1"
        />
        <g transform="translate(0 .139134)">
          <g fill="#fff">
            <path d="m47.462609 17.638724c.476833-.539509.715742-1.326994.715742-2.363445s-.310828-1.772955-.933469-2.209512c-.622149-.437051-1.589115-.65533-2.901883-.65533h-2.275301v6.03755h2.684648c1.329517 0 2.232938-.269754 2.710263-.809263zm2.927498-7.065092c1.252672 1.070604 1.879254 2.714868 1.879254 4.932795 0 2.21842-.643823 3.840907-1.930484 4.868449-1.287153 1.027541-3.251626 1.541312-5.893418 1.541312h-2.377761v5.010009h-3.989042v-17.9582225h6.315573c2.744252 0 4.742714.5355492 5.995878 1.6056575z" />
            <path d="m57.89466 20.014939c0 1.130989.319202 2.051124.958099 2.762385.639389.710271 1.457591 1.066148 2.455098 1.066148.997015 0 1.815216-.355877 2.454113-1.066148.639883-.711261.959085-1.631396.959085-2.762385 0-1.129999-.319202-2.055083-.959085-2.774264-.638897-.719675-1.457098-1.079512-2.454113-1.079512-.997507 0-1.815709.359837-2.455098 1.079512-.638897.719181-.958099 1.644265-.958099 2.774264m10.687362 0c0 2.004102-.690127 3.691429-2.070873 5.061485-1.381239 1.37055-3.11567 2.054588-5.203292 2.054588-2.088114 0-3.822545-.684038-5.203291-2.054588-1.380747-1.370056-2.071366-3.057383-2.071366-5.061485 0-2.003607.690619-3.694894 2.071366-5.073858 1.380746-1.37847 3.115177-2.067953 5.203291-2.067953 2.087622 0 3.822053.689483 5.203292 2.067953 1.380746 1.378964 2.070873 3.070251 2.070873 5.073858" />
            <path d="m81.646998 22.622895c0 1.361147-.498014 2.453529-1.495521 3.275661-.997015.822132-2.262494 1.232456-3.796438 1.232456-1.022629 0-2.088607-.183631-3.196455-.551883s-2.105355-.912215-2.991535-1.631396l1.687633-2.414921c1.431484 1.11317 2.957054 1.670003 4.57671 1.670003.494074 0 .885687-.0985 1.176319-.295492.289646-.196995.43447-.457841.43447-.783526s-.200487-.625137-.600967-.899346c-.400974-.273714-.979774-.5306-1.738372-.770657-.75909-.239561-1.338383-.445466-1.739356-.616228-.400481-.171257-.855639-.419728-1.367447-.745413-1.039377-.650875-1.559066-1.614072-1.559066-2.890085 0-1.275518.510822-2.316423 1.533451-3.121727 1.023122-.804809 2.343772-1.207213 3.962935-1.207213 1.619656 0 3.238326.548418 4.857982 1.644265l-1.431484 2.568854c-1.194053-.822132-2.382194-1.232951-3.566888-1.232951-1.184693 0-1.776794.32519-1.776794.976066 0 .359837.187187.642461.562545.84787.374866.205409.997015.445466 1.866446.71918.86894.274704 1.487148.484073 1.853639.629592.365999.145519.805395.372707 1.317202.681068.953666.582076 1.430991 1.554182 1.430991 2.915823" />
            <path d="m89.061947 15.878787v6.191483c0 .531589.135957.946368.408855 1.246315.272898.299452.596534.449426.971892.449426.715742 0 1.32065-.32519 1.814723-.976561l1.43247 2.69804c-1.193068 1.095846-2.450666 1.643769-3.771315 1.643769-1.321635 0-2.438351-.436556-3.350146-1.310165-.911302-.873113-1.367446-2.063993-1.367446-3.570658v-6.371649h-1.610789v-2.774264h1.610789v-4.1364001h3.860967v4.1364001h3.323545v2.774264z" />
            <path d="m104.55513 24.176929c1.03937 0 1.93491-.175217 2.68464-.526145.75023-.350929 1.53444-.912216 2.35264-1.683367l1.94329 2.004102c-1.89206 2.107055-4.18952 3.159839-6.89043 3.159839-2.70238 0-4.944185-.873608-6.724427-2.62033-1.78172-1.746722-2.672333-3.956234-2.672333-6.62804 0-2.671807.907854-4.898642 2.72307-6.680011 1.815709-1.7808746 4.10825-2.6718068 6.87812-2.6718068 2.76986 0 5.08408 1.0275418 6.94117 3.0831198l-1.91669 2.10656c-.85318-.822132-1.65808-1.395794-2.41668-1.721479-.7586-.32519-1.64872-.488033-2.67184-.488033-1.80733 0-3.32404.587026-4.55109 1.760086-1.227553 1.173061-1.841328 2.671807-1.841328 4.495743s.609834 3.34842 1.828518 4.572956c1.21868 1.225032 2.66297 1.836806 4.33337 1.836806" />
            <path d="m117.60798 23.316536c.80983.831041 1.8364 1.24582 3.0807 1.24582 1.2438 0 2.27136-.414779 3.08168-1.24582.80884-.831042 1.21425-1.90511 1.21425-3.224185 0-1.318579-.40541-2.393638-1.21425-3.224679-.81032-.830052-1.83788-1.245325-3.08168-1.245325-1.2443 0-2.27087.415273-3.0807 1.245325-.81032.831041-1.21474 1.9061-1.21474 3.224679 0 1.319075.40442 2.393143 1.21474 3.224185m8.19483 1.77246c-1.364 1.361641-3.06838 2.042214-5.11413 2.042214-2.04477 0-3.74964-.680573-5.11364-2.042214-1.364-1.361642-2.04477-3.02719-2.04477-4.996645s.68077-3.635498 2.04477-4.997139 3.06887-2.042215 5.11364-2.042215c2.04575 0 3.75013.680574 5.11413 2.042215 1.36301 1.361641 2.04526 3.027684 2.04526 4.997139s-.68225 3.635003-2.04526 4.996645" />
          </g>
          <path
            d="m25.626716 21.41984-9.813498 5.693551-9.8139899-5.693551v-11.4979742l9.8139899-4.3170612 9.813498 4.3170612zm-9.813498-21.4041497c-8.7194416 0-15.81331652 7.1279521-15.81331652 15.8887847 0 1.782854.29851317 3.495919.83987617 5.097122.37535815 1.11119.86598375 2.168925 1.46498055 3.15489.4098398.675128.8699245 1.316104 1.3723724 1.920453l12.1360874 16.985127 5.021031-7.028465-3.115177-4.359628c3.749148-.45487 7.096338-2.231785 9.568185-4.8536.227579-.241047.449247-.487538.661556-.743434.502448-.604349.962533-1.245325 1.372373-1.920948.598996-.98547 1.089622-2.043205 1.465473-3.154395.541363-1.601203.839383-3.314268.839383-5.097122 0-8.7608326-7.093875-15.8887847-15.812824-15.8887847z"
            fill="#fdc656"
          />
          <path d="m15.813317 8.8025086h-.000493v6.0360654l6.598323-3.133606z" fill="#f6f4f1" />
          <path d="m8.9148053 13.799599v5.929153l6.8983147 4.001275v-2.868893-3.803694z" fill="#f6f4f1" />
        </g>
      </g>
    </svg>
  );
}

export default PostCoLogo;
