import { Button, Loading } from "components";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Header } from "semantic-ui-react";
import { StickyBottomAppBar } from "StickyBottomAppBar";

interface Props {
  isDestroying: boolean;
  aboveTitle?: string;
  src: string;
  onBack?: () => void;
  onApprove: () => void;
  onReject: () => void;
}

export const MobileImagePreviewer = ({ isDestroying, aboveTitle, src, onBack, onApprove, onReject }: Props) => {
  const history = useHistory();
  const { t } = useTranslation("orderFlow");

  const ref = useRef<HTMLImageElement>(null);
  const [isLoadingImage, setLoadingImage] = useState(true);

  return (
    <div
      style={{
        height: "100%",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        gap: "10px",
      }}
    >
      {isLoadingImage || isDestroying ? (
        <div style={{ height: "40vh" }}>
          <Loading />
        </div>
      ) : null}
      <img
        ref={ref}
        onLoad={() => setLoadingImage(false)}
        style={{
          width: "100vw",
          objectFit: "contain",
          maxHeight: "60vh",
          backgroundColor: "grey",
          display: isLoadingImage || isDestroying ? "none" : "block",
        }}
        src={src}
      />

      <div style={{ paddingInline: "20px" }}>
        {aboveTitle}
        <Header size="large">{t("p2pProductListingPage.mobileImagePreviewer.title")}</Header>
        <p>{t("p2pProductListingPage.mobileImagePreviewer.description")}</p>
      </div>

      <StickyBottomAppBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "8px",
            padding: "8px",
          }}
        >
          <Button
            fluid
            disabled={isDestroying}
            style={{ color: "black", backgroundColor: "transparent", fontWeight: "normal" }}
            onClick={() => onReject()}
          >
            {t("p2pProductListingPage.mobileImagePreviewer.retake")}
          </Button>
          <Button fluid disabled={isDestroying} color="primaryColor" onClick={() => onApprove()}>
            {t("p2pProductListingPage.mobileImagePreviewer.proceed")}
          </Button>
        </div>
      </StickyBottomAppBar>

      {onBack ? (
        <Button
          style={{
            position: "absolute",
            top: 0,
            padding: "20px",
            backgroundColor: "transparent",
            color: "white",
          }}
          size="massive"
          icon="chevron left"
          onClick={onBack}
        />
      ) : null}
      <Button
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: "20px",
          backgroundColor: "transparent",
          color: "white",
        }}
        size="massive"
        icon="close"
        onClick={history.goBack}
      />
    </div>
  );
};
