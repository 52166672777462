import { fetchClient } from "api/constants";
import { useQuery } from "react-query";
import * as Sentry from "@sentry/react";
import qs from "qs";

export const getAuspostData = async (state: string | null): Promise<any> => {
  try {
    const query = {
      state: state,
    };
    const queryString = qs.stringify(query);

    return fetchClient(`/api/v2/customer/auspost_postcodes?${queryString}`);
  } catch (error) {
    Sentry.captureException(error);
    throw Error(error.message);
  }
};

function useAuspostData(state: string) {
  const { data: auspostData, ...others } = useQuery({
    queryKey: ["auspostPostcodes", state],
    queryFn: () => getAuspostData(state),
    staleTime: 3000,
    enabled: !!state,
  });

  return { auspostData, ...others };
}

export { useAuspostData };
