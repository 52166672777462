import React from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { StoreState } from "reducers";
import { Modal, Image, Button } from "semantic-ui-react";
import styles from "./P2POptInFlow.module.css";

interface Props {
  displayLoading: boolean;
  onProceed: () => void;
  onClose: () => void;
}

export const P2PUploadImagesMessageModal = ({ onProceed, onClose, displayLoading }: Props) => {
  const primaryColor = useSelector((state: StoreState) => state.currentShop.userInterfaceSetting.primaryColor);

  function handleOnClose() {
    onClose();
  }

  return (
    <Modal
      centered={false}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      dimmer="blurring"
      open
      size="tiny"
      className={"formModal"}
    >
      <>
        <div className={styles.headerContainer}>
          <img
            src="https://360-static-assets.postco.co/close.png"
            alt="cancel the return"
            style={{ height: isMobile ? "10px" : "14px", cursor: "pointer" }}
            onClick={() => handleOnClose()}
          />
        </div>
        <Modal.Content style={{ padding: isMobile ? "" : "8px 24px 24px" }}>
          <Modal.Description>
            <div style={{ display: "flex", alignItems: "stretch", flexDirection: "column" }}>
              <b style={{ fontSize: "18px", textAlign: "center", marginBottom: "8px" }}>Let's upload some images</b>
              <p style={{ fontSize: "13px", color: "#515151", textAlign: "center" }}>
                We’ll need some photos of your returns so we can assess the condition of your products before we list
                them for sale
              </p>
              <div style={{ padding: "16px", marginInline: "auto" }}>
                <Image src="https://360-static-assets.postco.co/P2P/take-picture.svg" size="medium" />
              </div>
              <Button
                style={{ backgroundColor: primaryColor, color: "white" }}
                loading={displayLoading}
                disabled={displayLoading}
                onClick={() => onProceed()}
              >
                Proceed
              </Button>
            </div>
          </Modal.Description>
        </Modal.Content>
      </>
    </Modal>
  );
};
