import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useAuthContext } from "contexts/AuthProvider";
import { NotFound } from "pages/NotFound/NotFound";
import { MobileView } from "./components/MobileView";
import { DesktopView } from "./components/DesktopView";
import parse from "html-react-parser";
import { Grid, Message } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";
import { saveMobileEmbeddedKeyToStorage } from "helpers";

export const SummaryDetails = () => {
  const { loggedIn, setHandleLoadingManually } = useAuthContext();
  const queryClient = useQueryClient();
  const location = useLocation();

  const matchResult = location.pathname.match(/returns\/([\d]+)/);
  const returnOrderIdFromUrl = matchResult && matchResult.length ? matchResult[1] : "";
  const returnOrderIdFromState = useSelector((state: StoreState) => state.order.returnOrderId);

  const [showMessage, setShowMessage] = useState<Boolean>(true);
  const queryString = new URLSearchParams(location.search);
  const messageHeader = queryString.get("messageHeader");
  const messageContent = parse(queryString.get("messageContent") || "");
  const exchangeOrderPaymentWaived = queryString.get("exchangeOrderOutstandingWaived");
  const hasMessage: Boolean = !!messageHeader && !!messageContent;

  function handleOnDismiss() {
    setShowMessage(false);
  }

  useEffect(() => {
    setHandleLoadingManually(true);

    return () => setHandleLoadingManually(false);
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isOriginFromMobileEmbeddedApp = urlSearchParams.get("mobileEmbedded");

    Boolean(isOriginFromMobileEmbeddedApp) && saveMobileEmbeddedKeyToStorage("true");
  }, []);

  useEffect(() => {
    if (returnOrderIdFromUrl !== returnOrderIdFromState) {
      queryClient.invalidateQueries(["returnOrder", returnOrderIdFromUrl]);
      queryClient.invalidateQueries(["order", returnOrderIdFromUrl]);
    }
  }, [location]);

  if (!loggedIn) return <NotFound />;

  return (
    <>
      {Boolean(exchangeOrderPaymentWaived) && (
        <div style={{ marginLeft: isMobile ? "10px" : "100px", marginRight: "20px" }}>
          <Message
            success
            style={{ width: "100%" }}
            header={"🎉 Your outstanding amount has been waived"}
            content={"You don't need to make further payments. We'll continue to process your exchange order."}
            hidden={!showMessage}
            onDismiss={handleOnDismiss}
          />
        </div>
      )}
      {hasMessage && (
        <Grid container>
          <Grid.Row>
            <Message
              style={{ width: "100%" }}
              negative
              header={messageHeader}
              content={messageContent}
              hidden={!showMessage}
              onDismiss={handleOnDismiss}
            />
          </Grid.Row>
        </Grid>
      )}
      {isMobile ? <MobileView /> : <DesktopView />}
    </>
  );
};
