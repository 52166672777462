import { FormikErrors } from "formik";
import React from "react";
import { Button } from "components";

interface FormValues {
  noPrinter: boolean;
  trackingNumber: string;
}

interface SubmitButtonProps {
  hidden: boolean;
  errors: FormikErrors<FormValues>;
  isSubmitting: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ hidden, errors, isSubmitting }) => {
  if (hidden) return null;

  const isDisabled = isSubmitting || Object.keys(errors).length !== 0;
  return (
    <Button color="primaryColor" disabled={isDisabled} fluid size="large" type="submit">
      {isSubmitting ? "Submitting..." : "Submit"}
    </Button>
  );
};
