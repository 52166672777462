import { TFunction } from "i18next";

export const shippingMethodInfo = (type: string, shippingServiceType: string | undefined, t: TFunction): string => {
  switch (type) {
    case "MailShippingMethod":
      return t(`shippingMethodSummary.mail`);
    case "InStoreShippingMethod":
      return t(`shippingMethodSummary.inStore`);
    case "IntegratedShippingMethod":
      switch (shippingServiceType) {
        case "DropOff":
        case "Pickup":
          return t(`shippingMethodSummary.integrated`);
        case "OnDemandPickup":
          return t(`shippingMethodSummary.onDemandPickup`);
        default:
          return "";
      }
    case "CustomShippingMethod":
      return `${t(`shippingMethodSummary.custom`)}`;
    default:
      return "";
  }
};
