import { useQuery } from "react-query";

declare global {
  interface Window {
    APPLICATION_VERSION: string;
  }
}

async function getApplicationVersion() {
  let version;
  const response = await fetch("/application_version");

  if (response.ok) {
    version = await response.text();
  } else {
    const errorMessage = await response.text();
    console.error(errorMessage);
  }

  return version;
}

export function useApplicationVersion() {
  const { data, isFetching } = useQuery({
    queryKey: ["application_version"],
    queryFn: () => getApplicationVersion(),
  });

  if (data) {
    window.APPLICATION_VERSION = data;
  }
  return { isFetching };
}
