import React, { useState } from "react";
import { Modal, Segment, Sidebar } from "semantic-ui-react";
import { CollectionsView } from "./CollectionsView";
import { SearchView } from "./SearchView";
import { ReturnItemInformationSidebar } from "./ReturnItemInformationSidebar";

export const ExchangeProductSelector = () => {
  const [filterMode, setFilterMode] = useState<"by_collections" | "by_search_string">("by_collections");
  const [showReturnItemInformation, setShowReturnItemInformation] = useState(false);

  return (
    <Sidebar.Pushable>
      <Sidebar.Pusher dimmed={filterMode === "by_search_string" || showReturnItemInformation}>
        <Modal.Content style={{ padding: 0 }}>
          <CollectionsView
            filterMode={filterMode}
            onSearchButtonClick={() => setFilterMode("by_search_string")}
            onReturnItemValueChipClick={() => setShowReturnItemInformation(true)}
          />
        </Modal.Content>
      </Sidebar.Pusher>
      <Sidebar as={Segment} visible={filterMode === "by_search_string"} animation="overlay" style={{ width: "100%" }}>
        <SearchView filterMode={filterMode} onClose={() => setFilterMode("by_collections")} />
      </Sidebar>
      <Sidebar
        as={Segment}
        visible={showReturnItemInformation}
        direction="top"
        animation="overlay"
        style={{ width: "100%" }}
      >
        <ReturnItemInformationSidebar onClose={() => setShowReturnItemInformation(false)} />
      </Sidebar>
    </Sidebar.Pushable>
  );
};
