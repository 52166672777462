import React from "react";

export const RenewIcon = () => (
  <div
    style={{
      position: "relative",
      backgroundColor: "#FDC465",
      borderRadius: "50%",
      padding: 10,
      width: 50,
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}
    >
      <circle cx="25" cy="25.1504" r="23.5" fill="white" stroke="url(#paint0_linear_4060_1207)" stroke-width="3" />
      <defs>
        <linearGradient
          id="paint0_linear_4060_1207"
          x1="25"
          y1="49.9998"
          x2="22.9992"
          y2="-3.00021"
          gradientUnits="userSpaceOnUse"
        >
          {" "}
          <stop stop-color="#519872" />
          <stop offset="1" stop-color="#FFCB57" />
        </linearGradient>
      </defs>
    </svg>
    <svg
      width="50"
      height="50"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ zIndex: 10, position: "absolute", padding: 10 }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.94402 12.7238C7.2841 7.72258 12.4248 4.75462 17.426 6.0947C19.0772 6.53715 20.5045 7.39238 21.6293 8.51931L24.0081 10.8981H20.0285C19.5107 10.8981 19.091 11.3179 19.091 11.8356C19.091 12.3534 19.5107 12.7731 20.0285 12.7731H26.2693C26.5179 12.7731 26.7564 12.6744 26.9322 12.4985C27.108 12.3227 27.2068 12.0842 27.2068 11.8355V5.59489C27.2068 5.07712 26.787 4.65739 26.2693 4.65739C25.7515 4.65739 25.3318 5.07712 25.3318 5.59489V9.5701L22.9564 7.19473C21.6049 5.84087 19.8893 4.81359 17.9113 4.28359C11.9098 2.67549 5.74101 6.23705 4.13291 12.2385C3.99891 12.7387 4.2957 13.2527 4.79583 13.3867C5.29595 13.5207 5.81002 13.2239 5.94402 12.7238ZM25.2033 16.9138C24.7032 16.7798 24.1891 17.0766 24.0551 17.5767C22.7151 22.5779 17.5744 25.5459 12.5731 24.2058C10.9219 23.7634 9.49469 22.9081 8.36987 21.7812L5.99251 19.4023H9.97103C10.4888 19.4023 10.9085 18.9826 10.9085 18.4648C10.9085 17.9471 10.4888 17.5273 9.97103 17.5273L3.73022 17.5273C3.21246 17.5273 2.79272 17.9471 2.79272 18.4648V24.7056C2.79272 25.2234 3.21246 25.6431 3.73022 25.6431C4.24799 25.6431 4.66772 25.2234 4.66772 24.7056V20.7292L7.04281 23.1058C8.3943 24.4598 10.1097 25.4869 12.0879 26.0169C18.0894 27.625 24.2581 24.0635 25.8662 18.062C26.0002 17.5618 25.7035 17.0478 25.2033 16.9138Z"
        fill="url(#paint0_linear_4060_1208)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4060_1208"
          x1="27.2158"
          y1="26.3947"
          x2="4.78419"
          y2="2.06161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#519872" />
          <stop offset="1" stop-color="#FFCB57" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);
