import camelcaseKeys from "camelcase-keys";
import { PostCo360API } from "api/constants";

export const getShipment = async (returnOrderId: string) => {
  try {
    const response = await PostCo360API().get(
      `/api/v2/customer/return_orders/${returnOrderId}/shipping_method_summary`,
    );
    return camelcaseKeys(response.data, { deep: true });
  } catch (error) {
    throw Error(error.message);
  }
};
