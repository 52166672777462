import { StoreState } from "reducers";
import { ReturnSteps } from "actions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getRoutes } from "constants/routes";

export function useCheckCurrentStep(currentStep: ReturnSteps) {
  const returnStep = useSelector((state: StoreState) => state.returnStep);
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const ROUTES = getRoutes(shopSlug, returnOrderId);
  const history = useHistory();

  useEffect(() => {
    if (returnStep && returnStep < currentStep) {
      history.push(ROUTES[returnStep]);
    }
  }, [returnStep, shopSlug, ROUTES, history, currentStep]);
}
