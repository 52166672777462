import { AxiosResponse } from "axios";
import { POST_RETURNS_URL, PostCo360API, ConflictError } from "./constants";
import { ShippingAddress, Order, ReturnItem, ReturnSteps, ReturnItems, UploadedImage } from "../actions";
import { Question } from "types";
import camelcaseKeys from "camelcase-keys";
import { searchParamsToString } from "./utils";

interface Params {
  order: Order;
  returnItems: ReturnItems;
  shippingAddress: ShippingAddress;
  returnStep: ReturnSteps;
  shopId: number | null;
  localeCode: string;
}

interface AnswersParams {
  questionId: number;
  contents: string[];
}

interface LineItemParams {
  id?: number;
  answers: AnswersParams[];
  bundleItemId: number | null;
  bonusCreditAmountCents: number;
  exchangeItemImageUrl?: string | null;
  manualExchangeItemName?: string | null;
  exchangeItemName?: string | null;
  exchangeItemGrams?: number | null;
  exchangeItemProductTitle?: string | null;
  exchangeItemVariantTitle?: string | null;
  exchangeItemProductId?: number | null;
  exchangeItemVariantPriceCents?: number | null;
  exchangeItemVariantPriceCurrency?: string | null;
  exchangeItemVariantTaxRate?: number | null;
  exchangeItemShopPriceCents?: number | null;
  exchangeItemVariantId?: string;
  extraNote: string;
  discountAmountCents: number;
  fulfillmentStatus: string | null;
  images?: UploadedImage[];
  lineItemId: string;
  productId: number | null;
  productTitle: string;
  returnReasonId: string;
  returnItemImageUrl: string;
  returnItemPriceCents: number;
  returnMethodId?: number | null;
  shopPriceCents: number;
  taxPriceCents: number;
  variantId: number | null;
  variantTitle: string;
  sku: string;
  exchangeItemPaidPriceCents?: number;
  vendor: string;
  taxRate: number;
}

export interface retrieveOrCreateReturnOrderParams {
  orderIdOrName: string;
  emailOrPhone: string;
  shopId: number | null;
}

export interface fetchReturnOrderParams {
  returnOrderId: string;
  orderIdOrName: string;
  emailOrPhone: string;
  shopId: number | null;
}

export interface DeleteReturnOrderParams {
  returnOrderId: string;
}

export interface CloneReturnOrderParams {
  returnOrderId: string;
  shopId: number | null;
}

export interface ClonedReturnOrderValues {
  clonedReturnOrderId: string;
  nextStep: string;
}

export interface GetReturnOrdersParams extends CloneReturnOrderParams {}

export interface GetReturnOrdersValues {
  returnOrders: GetReturnOrdersValue[];
}

export interface GetLastReturnOrderIdParams {
  returnOrderId: string;
}

export interface GetLastReturnOrderIdValues {
  lastReturnOrderId: number | null;
}

interface GetReturnOrdersValue {
  id: number;
  submittedAt: string;
  status: string;
  editable: boolean;
  cancellable: boolean;
  multiReturnable: boolean;
}

function parseResponse(response: { data: { return_order: string; next_step: string; message?: string } }) {
  const returnOrder = camelcaseKeys(JSON.parse(response.data.return_order).data?.attributes, { deep: true });
  const nextStep = response.data.next_step;
  const message = response.data.message;

  return { returnOrder, nextStep, message };
}

export const retrieveReturnOrder = async (params: retrieveOrCreateReturnOrderParams) => {
  const response: AxiosResponse<any> = await PostCo360API(params.shopId).get(
    `${POST_RETURNS_URL}/latest_return_order${searchParamsToString(params)}`,
  );

  return parseResponse(response);
};

export const createReturnOrder = async (params: retrieveOrCreateReturnOrderParams) => {
  try {
    const response: AxiosResponse<any> = await PostCo360API(params.shopId).post(POST_RETURNS_URL, params);

    return parseResponse(response);
  } catch (err) {
    throw Error(`Error creating return order: ${err}`);
  }
};

export const fetchReturnOrder = async (params: fetchReturnOrderParams) => {
  try {
    const response: AxiosResponse<any> = await PostCo360API(params.shopId).get(
      `${POST_RETURNS_URL}/${params.returnOrderId}${searchParamsToString(params)}`,
    );

    return parseResponse(response);
  } catch (err) {
    throw Error(`Error fetching return order: ${err}`);
  }
};

export const updateReturnOrder = async (params: Params) => {
  try {
    const response: AxiosResponse<any> = await PostCo360API(params.shopId).put(
      `${POST_RETURNS_URL}/${params.order.returnOrderId}`,
      {
        order: composeParams(params),
      },
    );
    const parsedResponse = camelcaseKeys(response.data, { deep: true });
    return {
      ...parsedResponse.returnOrder,
      nextStep: parsedResponse.nextStep,
    };
  } catch (err) {
    if (err instanceof ConflictError) {
      throw err;
    } else if (err instanceof Error) {
      throw Error(`Error updating return order: ${err.message}`);
    } else {
      throw Error(`Error updating return order: ${err}`);
    }
  }
};

export const deleteReturnOrder = async (params: DeleteReturnOrderParams): Promise<void> => {
  const { returnOrderId } = params;

  try {
    await PostCo360API().delete(`/api/v2/customer/return_orders/${returnOrderId}`);
  } catch (error) {
    throw Error(`Error deleting return order: ${error.message}`);
  }
};

export const cloneReturnOrder = async (params: CloneReturnOrderParams): Promise<ClonedReturnOrderValues> => {
  const { shopId, returnOrderId } = params;

  try {
    const response: AxiosResponse<any> = await PostCo360API(shopId).post(
      `${POST_RETURNS_URL}/${returnOrderId}/clone${searchParamsToString(params)}`,
    );

    return camelcaseKeys(response.data, { deep: true });
  } catch (err) {
    throw Error(`Error cloning return order: ${err}`);
  }
};

export const getReturnOrders = async (params: GetReturnOrdersParams): Promise<GetReturnOrdersValues> => {
  const { shopId } = params;

  try {
    const response: AxiosResponse<any> = await PostCo360API(shopId).get(
      `${POST_RETURNS_URL}${searchParamsToString(params)}`,
    );

    return camelcaseKeys(response.data, { deep: true });
  } catch (err) {
    throw Error(`Error getting return orders information: ${err}`);
  }
};

export const getLastReturnOrderId = async (params: GetLastReturnOrderIdParams): Promise<GetLastReturnOrderIdValues> => {
  try {
    const response: AxiosResponse<any> = await PostCo360API().get(
      `${POST_RETURNS_URL}/last_return_order${searchParamsToString(params)}`,
    );

    return camelcaseKeys(response.data, { deep: true });
  } catch (err) {
    throw Error(`Error getting last return order: ${err}`);
  }
};

// HELPERS
const composeLineItemsParams = (returnItems: ReturnItem[]): LineItemParams[] => {
  function parseExchangeItemName(returnItem: ReturnItem) {
    // Early return if this is an existing exchange
    if (returnItem.exchangeItemName) return returnItem.exchangeItemName;

    const productTitle = returnItem.selectedProduct?.title;
    const variantTitle = returnItem.exchangingVariant?.title as string;
    const isDefaultTitle = variantTitle.toLowerCase() === "default title";

    if (returnItem.returnMethod.name === "advanced") {
      return isDefaultTitle ? productTitle : `${productTitle} - ${variantTitle}`;
    } else {
      return isDefaultTitle ? productTitle : variantTitle;
    }
  }

  return returnItems.map((returnItem: ReturnItem): LineItemParams => {
    let lineItemParams: LineItemParams = {
      id: returnItem.id,
      answers: composeAnswersParams(returnItem.questions),
      bundleItemId: returnItem.bundleItemId,
      discountAmountCents: returnItem.discountAmount,
      extraNote: returnItem.extraNote,
      fulfillmentStatus: returnItem.fulfillmentStatus,
      lineItemId: returnItem.lineItemId,
      productId: returnItem.productId,
      returnReasonId: returnItem.returnReason.id,
      returnItemImageUrl: returnItem.returnItemImageUrl,
      productTitle: returnItem.productTitle,
      variantTitle: returnItem.variantTitle,
      variantId: returnItem.variantId,
      returnItemPriceCents: returnItem.returnItemPrice,
      returnMethodId: returnItem.returnMethod.returnMethodId,
      taxPriceCents: returnItem.taxPrice,
      shopPriceCents: returnItem.shopPrice,
      sku: returnItem.sku,
      vendor: returnItem.vendor,
      bonusCreditAmountCents: returnItem.bonusCreditAmountCents,
      taxRate: returnItem.taxRate,
    };
    if (returnItem.images) {
      lineItemParams = { ...lineItemParams, images: returnItem.images };
    }
    if (returnItem.returnMethod.type === "Exchange") {
      // same product or advanced exchange
      if (returnItem.exchangingVariant?.id) {
        return {
          ...lineItemParams,
          exchangeItemImageUrl: returnItem.exchangingVariant.imageUrl,
          exchangeItemName: parseExchangeItemName(returnItem),
          exchangeItemGrams: returnItem.exchangingVariant.grams,
          exchangeItemVariantTitle: returnItem.exchangingVariant?.title as string,
          exchangeItemVariantPriceCents: returnItem.exchangingVariant.presentmentPriceWithTax.cents,
          exchangeItemVariantPriceCurrency: returnItem.exchangingVariant.presentmentPriceWithTax.currencyIso,
          exchangeItemVariantTaxRate: returnItem.exchangingVariant.taxRate,
          exchangeItemProductTitle: returnItem.selectedProduct?.title,
          exchangeItemProductId: returnItem.selectedProduct?.id,
          exchangeItemShopPriceCents: returnItem.exchangingVariant.shopPriceCents,
          exchangeItemVariantId: returnItem.exchangingVariant.id,
          exchangeItemPaidPriceCents: returnItem.exchangingVariant.displayPrice.cents,
        };
      } else {
        // other product exchange
        return {
          ...lineItemParams,
          manualExchangeItemName: returnItem.exchangeForOtherProductDetail,
        };
      }
    } else {
      return {
        ...lineItemParams,
      };
    }
  });
};

function composeAnswersParams(questions: Question[]): AnswersParams[] {
  return questions.flatMap((question: Question) =>
    question.optional && question.answers.length === 0 ? [] : [{ questionId: question.id, contents: question.answers }],
  );
}

function composeParams(params: Params) {
  const { returnStep } = params;

  switch (returnStep) {
    case ReturnSteps.stageOneQuestions:
      return composeStageOneQuestionsParams(params);
    case ReturnSteps.returnItems:
      return composeReturnItemsParams(params);
    case ReturnSteps.shippingMethod:
      return composeShippingMethodParams(params);
    case ReturnSteps.shippingAddress:
      return composeShippingAddressParams(params);
    case ReturnSteps.receivablePaymentDetails:
      return composeReceivablePaymentDetails(params);
    case ReturnSteps.summaryAndPaymentDetails:
      return composeStripePaymentMethodParams(params);
    default:
      throw new Error(`Unknown return step`);
  }
}

const baseParams = (returnStep: ReturnSteps) => {
  return {
    returnStep: ReturnSteps[returnStep],
  };
};

function composeStageOneQuestionsParams(params: Params) {
  const { returnStep, returnItems } = params;
  return {
    answers: composeAnswersParams(returnItems.questions),
    ...baseParams(returnStep),
  };
}

function composeReturnItemsParams(params: Params) {
  const { returnStep, returnItems, localeCode } = params;
  return {
    returnItems: composeLineItemsParams(returnItems.cart),
    localeCode,
    ...baseParams(returnStep),
  };
}

function composeShippingMethodParams(params: Params) {
  const { returnItems, returnStep } = params;
  return {
    customerBorneShipping: returnItems.customerBorneShipping,
    fromAddress: returnItems.fromAddress,
    shippingMethodId: returnItems.shippingMethod.id,
    ...baseParams(returnStep),
  };
}

const composeShippingAddressParams = ({ returnStep, shippingAddress }: Params) => {
  return {
    custFirstName: shippingAddress.firstName,
    custLastName: shippingAddress.lastName,
    custEmail: shippingAddress.email,
    custPhone: shippingAddress.phone,
    shippingAddress: shippingAddress,
    ...baseParams(returnStep),
  };
};

const composeReceivablePaymentDetails = ({ returnStep, order: { bankAccount, ewalletAccount } }: Params) => {
  return { bankAccount, ewalletAccount, ...baseParams(returnStep) };
};

const composeStripePaymentMethodParams = ({
  returnStep,
  order: { stripePaymentMethod, stripePaymentIntentId },
  returnItems: { instantExchange, p2pReturns },
}: Params) => {
  return { stripePaymentMethod, stripePaymentIntentId, instantExchange, p2pReturns, ...baseParams(returnStep) };
};
