import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import camelcaseKeys from "camelcase-keys";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import { Question } from "types";
import qs from "qs";
import { PostCo360API } from "api/constants";

interface QuestionJson extends Omit<Question, "id"> {
  id: string;
}

export const fetchQuestions = async (
  shopId: number | null,
  stage: number,
  orderId: string,
  returnReason: string,
  productId: number | null,
  returnOrderId: string,
): Promise<Question[]> => {
  try {
    const query = {
      filter: {
        shopId,
        active: {
          eq: true,
        },
        stage: {
          eq: stage,
        },
        kept: {
          eq: true,
        },
      },
      policy_rule_data: {
        orderId,
        returnReason,
        productId: Boolean(productId) ? productId : "null",
        returnOrderId: returnOrderId,
      },
      include: ["answer_options"].join(","),
      fields: {
        questions: ["gid", "identifier", "title", "stage", "type", "optional"].join(","),
      },
    };

    const queryString = qs.stringify(query, { arrayFormat: "brackets" });

    const response: AxiosResponse<any> = await PostCo360API().get(`/api/v2/customer/questions.json?${queryString}`);
    const questions = camelcaseKeys(response?.data?.data, { deep: true });

    return questions.map((question: QuestionJson) => {
      return {
        ...question,
        id: parseInt(question.id),
        answers: [],
      };
    });
  } catch (error) {
    throw Error(error.message);
  }
};

function useQuestions(stage: number) {
  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const orderId = useSelector((state: StoreState) => state.order.id);
  const returnReason = useSelector((state: StoreState) => state.returnItems.currentReturnItem.returnReason.globalId);
  const productId = useSelector((state: StoreState) => state.returnItems.currentReturnItem.productId);
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);

  const { data, ...others } = useQuery({
    queryKey: ["questions", shopId, stage],
    queryFn: () => fetchQuestions(shopId, stage, orderId, returnReason, productId, returnOrderId),
  });

  const questionsData: Question[] = data ?? [];
  return { questionsData, ...others };
}

export { useQuestions };
