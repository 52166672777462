import { ReturnItem } from "actions";

export function getTotalBonusCredit(returnItems: ReturnItem[], type: "Exchange" | "Refund") {
  const totalBonusCredit = returnItems.reduce((total, returnItem) => {
    if (returnItem.returnMethod.type === type) {
      return total + returnItem.bonusCreditAmountCents;
    }
    return total;
  }, 0);

  return totalBonusCredit;
}
