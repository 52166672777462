import React from "react";

interface Props {
  title: string;
  description: string;
}

export const NoProductsFound = ({ title, description }: Props) => {
  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <p>
        <b>{title}</b>
        <br />
        <span style={{ color: "#6E6E6E" }}>{description}</span>
      </p>
    </div>
  );
};
