import React from "react";
import { Grid, Header } from "semantic-ui-react";
import { ReturnItemsSummary, ShippingMethodSummary, ShippingAddressDetailsSummary } from "components";
import { ReceivablePaymentForm } from "./ReceivablePaymentForm";
import { useTranslation } from "react-i18next";

export const DesktopView: React.FC = (): JSX.Element => {
  const { t } = useTranslation("orderFlow");
  const returnItemSummaryTitle = t("orderSummary.itemsToReturn");

  return (
    <div style={{ height: "100%", padding: "10px 0px 0px 0px" }}>
      <Grid columns={2} style={{ height: "100%" }}>
        <Grid.Column style={{ backgroundColor: "#FBFBFB", padding: "40px 120px" }}>
          <Grid.Row style={{ marginBottom: "24px" }}>
            <Header as="h2">{t("receivablePaymentDetails.summaryTitle")}</Header>
          </Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <ReturnItemsSummary title={returnItemSummaryTitle} />
            <ShippingMethodSummary />
            <ShippingAddressDetailsSummary />
          </Grid.Column>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8} style={{ padding: "40px 120px" }}>
          <ReceivablePaymentForm />
        </Grid.Column>
      </Grid>
    </div>
  );
};
