import React from "react";
import { ReturningLineItem } from "./ReturningLineItem";
import { Grid } from "semantic-ui-react";
import { MainInvoice } from "constants/invoice";

interface ReturningLineItemsProps {
  returningLineItems: MainInvoice["returningLineItems"];
}

export const ReturningLineItems: React.FC<ReturningLineItemsProps> = ({ returningLineItems }): JSX.Element => {
  return (
    <Grid columns={2} style={{ padding: "8px 16px 0" }}>
      {returningLineItems.map((lineItem, index) => (
        <ReturningLineItem lineItem={lineItem} key={index} />
      ))}
    </Grid>
  );
};
