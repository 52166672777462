import { ReturnItem, ReturnMethod } from "actions";

export function hasCustomName(returnMethod: ReturnMethod) {
  switch (returnMethod.name) {
    case "discount_code":
      return returnMethod.displayName !== "Discount code";
    case "gift_card":
      return returnMethod.displayName !== "Gift card";
    case "original_payment_method":
      return returnMethod.displayName !== "Original payment method";
    case "same_product":
      return returnMethod.displayName !== "Same product";
    case "other_product":
      return returnMethod.displayName !== "Other product(s)";
    case "online_bank_transfer":
      return returnMethod.displayName !== "Online bank transfer";
    case "ewallet_transfer":
      return returnMethod.displayName !== "e-Wallet transfer";
    case "apparel21_voucher":
      return returnMethod.displayName !== "Gift voucher";
  }
}

export function sameProductExchangeEnabled(returnMethods: ReturnMethod[]) {
  return returnMethods.some((method: ReturnMethod) => method.name === "same_product");
}

export function advancedExchangeEnabled(returnMethods: ReturnMethod[]) {
  return returnMethods.some((method: ReturnMethod) => method.name === "advanced");
}

export function isAdvancedExchange(returnMethod: ReturnMethod) {
  return returnMethod.name === "advanced";
}

export function isSameProductExchange(returnMethod: ReturnMethod) {
  return returnMethod.name === "same_product";
}

// Currently this is used to filter out either ewallet_transfer or online_bank_transfer
// if any of them have been selected for a previous returning item
// We are doing this because we don't want to allow a single return order to contain
// both ewallet and online bank transfer refunds
export function filterReturnMethods(returnMethods: ReturnMethod[], cart: ReturnItem[]) {
  const returnMethodsInCart = cart.map((returnItem: ReturnItem) => {
    return returnItem.returnMethod.name;
  });

  if (returnMethodsInCart.includes("online_bank_transfer")) {
    return returnMethods.filter((method: ReturnMethod) => method.name !== "ewallet_transfer");
  } else if (returnMethodsInCart.includes("ewallet_transfer")) {
    return returnMethods.filter((method: ReturnMethod) => method.name !== "online_bank_transfer");
  }
  return returnMethods;
}
