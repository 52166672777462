import React from "react";
import styles from "../ShippingMethodSummary.module.css";
import { Card, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useReturnOrderStatus } from "hooks/useReturnOrder";

export const P2PShippingMethodSummary: React.FC = () => {
  const { t } = useTranslation("orderFlow");
  const { returnOrderReviewed } = useReturnOrderStatus();

  const instruction = returnOrderReviewed
    ? t("shippingMethodSummary.p2pPostReviewInstruction")
    : t("shippingMethodSummary.p2pPreReviewInstruction");

  return (
    <Card.Description className={styles.shippingMethodInfo}>
      <Grid.Row style={{ margin: 0 }}>{instruction}</Grid.Row>
    </Card.Description>
  );
};
