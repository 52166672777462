import React, { Fragment } from "react";
import { Field, FieldProps } from "formik";
import { Form } from "semantic-ui-react";
import { CheckboxHelpText } from "components/CheckboxHelpText/CheckboxHelpText";
import styles from "./TrackingNumberField.module.css";
import { Courier } from "actions";
import { useTranslation } from "react-i18next";
import { FromAddressFormDetails } from "components/FromAddressForm/FromAddressForm";

interface TrackingNumberFieldProp {
  courier: Courier;
  hasPreprintedReturnLabel: boolean;
}

export const TrackingNumberField: React.FC<TrackingNumberFieldProp> = ({ courier, hasPreprintedReturnLabel }) => {
  const { t } = useTranslation("orderFlow");

  return courier.name === "VNCPost" ? (
    <CheckboxHelpText
      info1="We will email you your tracking number once your return request has been approved"
      info2="Please write down the tracking number on your parcel"
    />
  ) : (
    <Fragment>
      <CheckboxHelpText
        info1={hasPreprintedReturnLabel ? t("trackingNumber.preprintedFirst") : t("trackingNumber.originalFirst")}
        info2={hasPreprintedReturnLabel ? t("trackingNumber.preprintedSecond") : t("trackingNumber.originalSecond")}
      />
      <div className={styles.trackingNumberField}>
        <Field
          name="trackingNumber"
          render={({ field, form }: FieldProps<FromAddressFormDetails>): JSX.Element => (
            <Form.Field>
              <label>
                {hasPreprintedReturnLabel ? t("trackingNumber.preprintedLabel") : t("trackingNumber.originalLabel")}
              </label>
              <Form.Input
                error={form.touched.trackingNumber && form.errors.trackingNumber}
                type="text"
                {...field}
                value={field.value || ""}
              />
            </Form.Field>
          )}
        />
      </div>
    </Fragment>
  );
};
