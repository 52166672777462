import { useQuestionFormContext } from "contexts/QuestionFormProvider";
import React from "react";
import { Button, Checkbox, Icon } from "semantic-ui-react";
import { AnswerOption } from "types";
import styles from "./AnswerOptions.module.css";

export const AnswerOptions: React.FC = () => {
  const { questions, questionsDispatch, page } = useQuestionFormContext();
  const { id: questionId, answers, answerOptions, type } = questions[page];
  const actionType = type === "single_choice" ? "TOGGLE_SINGLE_CHOICE_ANSWER" : "TOGGLE_MULTIPLE_CHOICE_ANSWER";

  function handleAnswerOnClick(optionGID: string) {
    questionsDispatch({
      type: actionType,
      payload: {
        questionId,
        answer: optionGID,
      },
    });
  }

  return (
    <>
      {answerOptions.map((option: AnswerOption) => {
        return (
          <div className={styles.buttonContainer} key={option.id}>
            <Button
              basic
              color={answers.includes(option.gid) ? "green" : undefined}
              fluid
              onClick={() => handleAnswerOnClick(option.gid)}
            >
              <div className={styles.buttonContent}>
                {type === "single_choice" ? (
                  <p>{option.name}</p>
                ) : (
                  <Checkbox label={option.name} checked={answers.includes(option.gid)} />
                )}
                {type === "single_choice" && (
                  <Icon
                    color="green"
                    name="check circle"
                    style={{
                      visibility: answers.includes(option.gid) ? "visible" : "hidden",
                    }}
                  />
                )}
              </div>
            </Button>
          </div>
        );
      })}
    </>
  );
};
