import { ShippingAddress } from "actions";
import React from "react";
import { Card, Grid } from "semantic-ui-react";
import { ShippingAddress as SummaryShippingAddress } from "types";

interface ShippingAddressSummaryProps {
  shippingAddress: ShippingAddress | SummaryShippingAddress;
}

export const ShippingAddressSummary: React.FC<ShippingAddressSummaryProps> = ({ shippingAddress }): JSX.Element => {
  return (
    <Grid columns={2}>
      <Grid.Column>
        <Card.Description>{shippingAddress.address1}</Card.Description>
        <Card.Description>{shippingAddress.address2}</Card.Description>
        <Card.Description>
          {shippingAddress.ward}, {shippingAddress.district}
        </Card.Description>
        <Card.Description>{shippingAddress.city}</Card.Description>
        <Card.Description>
          {shippingAddress.zip} {shippingAddress.province}
        </Card.Description>
        <Card.Description>{shippingAddress.country}</Card.Description>
      </Grid.Column>
    </Grid>
  );
};
