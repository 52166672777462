import React from "react";
import { getFormattedPriceString } from "helpers";
import { useTranslation } from "react-i18next";
import { ReturningLineItems } from "./ReturningLineItems";
import { MainInvoice } from "constants/invoice";
import styles from "./ReturningItemsSummary.module.css";
import { useReturnOrderStatus } from "hooks/useReturnOrder";
import { Accordion } from "components";

interface ReturningLineItemsSummaryProps {
  afterResolvedInvoice: MainInvoice;
  pastTense?: boolean;
}

export const ReturningItemsSummary: React.FC<ReturningLineItemsSummaryProps> = ({
  afterResolvedInvoice,
  // TODO: remove pastTense once PublicSummary is deleted
  pastTense = false,
}): JSX.Element => {
  const { t } = useTranslation("orderFlow");
  const { returningLineItems, subtotal } = afterResolvedInvoice;
  const refundTotal = returningLineItems.reduce((acc, item) => acc + item.presentmentPrice.cents, 0);

  const { returnItemsReceived } = useReturnOrderStatus();
  const header =
    pastTense || returnItemsReceived ? t("summary.returnedItemsHeader") : t("summary.returningItemsHeader");
  const headerWithCount = `${header} (${returningLineItems.length})`;
  const amount = getFormattedPriceString(refundTotal, subtotal.currencyIso, { useParenthesis: true });

  const title = (
    <div
      style={{
        display: "inline",
        fontWeight: "bold",
        fontSize: 14,
        backgroundColor: "#fbfbfb",
        marginBottom: "32px",
      }}
    >
      <span>{headerWithCount}</span>
      <span className={styles.amount}>{amount}</span>
    </div>
  );

  return (
    <div style={{ marginBottom: 32 }}>
      <Accordion
        panels={[
          {
            title,
            content: <ReturningLineItems returningLineItems={returningLineItems} />,
          },
        ]}
      />
    </div>
  );
};
