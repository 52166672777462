import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "reducers";
import camelcaseKeys from "camelcase-keys";
import { getReturnReasons } from "./api";
import { ReturnReason, setError, closeFormModal } from "actions";

function useReturnReasons() {
  const dispatch = useDispatch();
  const shopId = useSelector((state: StoreState) => state.currentShop.id);
  const orderId = useSelector((state: StoreState) => state.order.id);
  const { productId, variantId, lineItemId, bundleItemId } = useSelector(
    (state: StoreState) => state.returnItems.currentReturnItem,
  );
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);

  const { data, ...others } = useQuery({
    queryKey: ["returnReasons", shopId, orderId, returnOrderId, productId, variantId, lineItemId, bundleItemId],
    queryFn: () => getReturnReasons(shopId, orderId, returnOrderId, productId, variantId, bundleItemId, lineItemId),
    onSuccess: (response: { data: ReturnReason[] }) => {
      const responseData = response.data;
      try {
        if (responseData.length === 0) {
          throw Error("Sorry, this item is not returnable because no return reasons are applicable.");
        }
      } catch (err) {
        dispatch(setError(err));
        dispatch(closeFormModal());
      }
    },
    onError: (error) => {
      dispatch(setError(error as Error));
      dispatch(closeFormModal());
    },
  });

  const returnReasons: ReturnReason[] = data ? data.data : [];

  return { returnReasons, ...others };
}

export { useReturnReasons };
