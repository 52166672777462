import React from "react";
import Button from "./Button";
import { useTranslation } from "react-i18next";

interface ContinueButtonProps {
  isSubmitting: boolean;
}

export const ContinueButton: React.FC<ContinueButtonProps> = ({ isSubmitting }): JSX.Element => {
  const { t } = useTranslation("orderFlow");
  return (
    <div style={{ marginTop: "1rem", marginBottom: "3rem" }}>
      <Button color="primaryColor" fluid type="submit" disabled={isSubmitting}>
        {t("continue")}
      </Button>
    </div>
  );
};
