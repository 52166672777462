import * as yup from "yup";
import { Country } from "@shopify/address";
import { UseTranslationResponse } from "react-i18next";
import {
  getMaxErrorMessage,
  getMinErrorMessage,
  getFormatErrorMessage,
  getRequiredErrorMessage,
  getRequiredFields,
} from "components/ShopifyAddressFields/helpers";

export function checkIfRequired(field: string, requiredFields: string[]) {
  return function (this: yup.TestContext, val: any) {
    if (requiredFields.includes(field)) {
      return Boolean(val);
    } else {
      return true;
    }
  };
}

export const shopifyAddressValidationSchema = (shopifyCountry: Country, t: UseTranslationResponse["t"]) => {
  return yup.object().shape(shopifyAddressValidationShape(shopifyCountry, t));
};

export const shopifyAddressValidationShape = (shopifyCountry: Country, t: UseTranslationResponse["t"]) => {
  const requiredFields = getRequiredFields(shopifyCountry);

  return {
    address1: yup
      .string()
      .test(
        "address1",
        getRequiredErrorMessage(shopifyCountry, "address1", t),
        checkIfRequired("address1", requiredFields),
      )
      .max(80, getMaxErrorMessage(shopifyCountry, "address1", 80, t))
      .when("country", {
        is: (country) => country === "India",
        then: yup
          .string()
          .min(10, getMinErrorMessage(shopifyCountry, "address1", 10, t))
          .matches(/\d+/, getFormatErrorMessage(shopifyCountry, "address1", t)),
      })
      .when("country", {
        is: (country) => country === "Australia",
        then: yup.string().max(40, getMaxErrorMessage(shopifyCountry, "address1", 40, t)),
      })
      .test("Royal Mail address1 validation", "must be less than 40 characters", function (address1) {
        if (!Boolean(address1)) return true;

        const context = this.options.context as { courierName: string | null };

        if (context.courierName === "Royal Mail") {
          return address1.length <= 40;
        } else {
          return true;
        }
      })
      .test("Royal Mail address line validation", "address line must be less than 50 characters", function (address1) {
        if (!Boolean(address1)) return true;

        const context = this.options.context as { courierName: string | null };
        const address2 = this.parent.address2;

        if (context.courierName === "Royal Mail" && Boolean(address2)) {
          return address1.length + address2.length <= 50;
        } else {
          return true;
        }
      })
      .test("SEKO address line validation", "address line must be less than 35 characters", function (address1) {
        if (!Boolean(address1)) return true;

        const context = this.options.context as { courierName: string | null };
        const address2 = this.parent.address2;

        if (context.courierName === "Seko") {
          if (Boolean(address2)) {
            return address1.length + address2.length <= 35;
          } else {
            return address1.length <= 35;
          }
        } else {
          return true;
        }
      }),
    address2: yup
      .string()
      .nullable()
      .test(
        "address2",
        getRequiredErrorMessage(shopifyCountry, "address2", t),
        checkIfRequired("address2", requiredFields),
      )
      .max(80, getMaxErrorMessage(shopifyCountry, "address1", 80, t))
      .when("country", {
        is: (country) => country === "India",
        then: yup.string().min(5, getMinErrorMessage(shopifyCountry, "address1", 5, t)),
      })
      .test("Royal Mail address line validation", "address line must be less than 50 characters", function (address2) {
        if (!Boolean(address2)) return true;

        const context = this.options.context as { courierName: string | null };
        const address1 = this.parent.address1;

        if (context.courierName === "Royal Mail" && Boolean(address1)) {
          return address1.length + address2.length <= 50;
        } else {
          return true;
        }
      })
      .test("SEKO address line validation", "address line must be less than 35 characters", function (address2) {
        if (!Boolean(address2)) return true;

        const context = this.options.context as { courierName: string | null };
        const address1 = this.parent.address1;

        if (context.courierName === "Seko" && Boolean(address2)) {
          if (Boolean(address1)) {
            return address1.length + address2.length <= 35;
          } else {
            return address2.length <= 35;
          }
        } else {
          return true;
        }
      }),
    country: yup
      .string()
      .test(
        "country",
        getRequiredErrorMessage(shopifyCountry, "country", t),
        checkIfRequired("country", requiredFields),
      ),
    province: yup
      .string()
      .test(
        "province",
        getRequiredErrorMessage(shopifyCountry, "zone", t),
        checkIfRequired("province", requiredFields),
      ),
    city: yup
      .string()
      .nullable()
      .test("city", getRequiredErrorMessage(shopifyCountry, "city", t), checkIfRequired("city", requiredFields)),
    zip: yup
      .string()
      .nullable()
      .test("zip", getRequiredErrorMessage(shopifyCountry, "postalCode", t), checkIfRequired("zip", requiredFields)),
  };
};
