import { Button, Loading, ExchangingLineItems, ExtraLineItems, ReturningItemsSummary, Summary } from "components";
import { useP2PProductListings } from "hooks/useP2PProductListings";
import { useCreateP2PRequest } from "hooks/useP2PRequest";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { StoreState } from "reducers";
import { Message } from "semantic-ui-react";
import { P2PUploadImagesMessageModal } from "../P2POptInFlow/P2PUploadImagesMessageModal";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { isMobile } from "react-device-detect";
import { RenewBenefitsSection } from "./RenewBenefitsSection";
import { StickyBottomAppBar } from "StickyBottomAppBar";

export const P2PIntroduction = () => {
  const history = useHistory();
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const shopSlug = useSelector((state: StoreState) => state.currentShop.shopSlug);
  const queryClient = useQueryClient();
  const [openImageUploadMessageModal, setOpenImageUploadMessageModal] = useState(false);
  const { productListings, isLoading: fetchingProductListings } = useP2PProductListings();

  const { regularReturnInvoice } = useInvoiceContext();
  const { extraLineItems, subtotal, exchangingForLineItems } = regularReturnInvoice;

  const {
    create,
    isLoading: creatingP2PRequest,
    error,
  } = useCreateP2PRequest({
    onSuccess: () => {
      queryClient.invalidateQueries(["p2p_product_listings", returnOrderId]);
      setOpenImageUploadMessageModal(true);
    },
    onError: () => setOpenImageUploadMessageModal(false),
  });

  const handleSubmit = () => {
    const p2pRequestIsAlreadyCreated = productListings.length > 0;

    if (p2pRequestIsAlreadyCreated) {
      history.push(`/${shopSlug}/returns/${returnOrderId}/p2p/requests/`);
    } else {
      create({ returnOrderId });
    }
  };

  if (fetchingProductListings) {
    return <Loading />;
  }

  return (
    <>
      {openImageUploadMessageModal && (
        <P2PUploadImagesMessageModal
          displayLoading={creatingP2PRequest}
          onProceed={() => history.push(`/${shopSlug}/returns/${returnOrderId}/p2p/requests/`)}
          onClose={() => setOpenImageUploadMessageModal(false)}
        />
      )}
      {error && (
        <div style={{ alignSelf: "stretch" }}>
          <Message negative fluid>
            <Message.Header>Error creating reNEW Return</Message.Header>
            <p>{error.message}</p>
          </Message>
        </div>
      )}
      <div style={{ padding: isMobile ? 0 : "1rem" }}>
        <RenewBenefitsSection />

        <ReturningItemsSummary afterResolvedInvoice={regularReturnInvoice} />
        {exchangingForLineItems && <ExchangingLineItems exchangingForLineItems={exchangingForLineItems} />}
        {extraLineItems.length > 0 && <ExtraLineItems extraLineItems={extraLineItems} subtotal={subtotal} />}

        <Summary afterResolvedInvoice={regularReturnInvoice} />
      </div>
      {isMobile ? (
        <>
          <div style={{ paddingBottom: "3rem" }}>
            <br />
          </div>
          <StickyBottomAppBar>
            <Button
              style={{ margin: "1rem 0.5rem", width: "-webkit-fill-available" }}
              color="primaryColor"
              onClick={handleSubmit}
              disabled={creatingP2PRequest}
              loading={creatingP2PRequest}
            >
              Proceed to Image Upload
            </Button>
          </StickyBottomAppBar>
        </>
      ) : (
        <div style={{ paddingInline: "14px" }}>
          <Button
            fluid
            color="primaryColor"
            loading={creatingP2PRequest}
            disabled={creatingP2PRequest}
            onClick={handleSubmit}
          >
            Proceed to Image Upload
          </Button>
        </div>
      )}
    </>
  );
};
