import { Dispatch } from "redux";

import { ActionTypes } from "./types";
import { setLoading, clearLoading } from "./loading";
import { AxiosResponse } from "axios";
import { BackgroundImageUrls, clearConfigLoading, setInitialLocale, setError } from "actions";
import {
  setPrimaryColor,
  setLogoImageUrls,
  setBackgroundImageUrls,
  setFaviconUrl,
  setTitle,
  setMobileBackgroundImageUrls,
} from "./frontendConfigs";
import { PostCo360API } from "api/constants";
import camelcaseKeys from "camelcase-keys";
import qs from "qs";

export interface SetCurrentShopAction {
  type: ActionTypes.setCurrentShop;
  payload: CurrentShop;
}

export interface SetShopSlugAction {
  type: ActionTypes.setShopSlug;
  payload: string;
}

export interface Courier {
  id: string;
  name: string;
  country: string;
  onDemand: boolean;
  weekend: boolean;
  holiday: boolean;
  startTime: string;
  cutoffTime: string;
  supportedPostcodes: string[];
  sameDayDelivery: boolean;
  hourRange: number;
  noticeHour: number;
  allowCustomTrackingNumber: boolean;
  pickup: boolean;
  dropOff: boolean;
  dropOffLocationsUrl: string;
  logoUrl: string;
}

export interface ReturnReason {
  id: string;
  extraNote: boolean;
  optionalExtraNote: boolean;
  globalId: string;
  imageUpload: boolean;
  optionalImageUpload: boolean;
  name: string;
}

export interface ShopLocale {
  id: string;
  shopId: number;
  localeId: number;
  isDefault: boolean;
  name: string;
  code: string;
}

export interface UserInterfaceSetting {
  id: number;
  customCss: string;
  orderEmailPhoneLabelTranslations: Record<string, string>;
  orderEmailPhonePlaceholderTranslations: Record<string, string>;
  orderIdLabelTranslations: Record<string, string>;
  orderIdPlaceholderTranslations: Record<string, string>;
  primaryColor: string;
  titleTranslations: Record<string, string>;
  startButtonNameTranslations: Record<string, string>;
  primaryButtonTextColor: string;
  successPageContent: string;
  returnReasonImageUploadLabelTranslations: Record<string, string>;
  returnReasonExtraNoteLabelTranslations: Record<string, string>;
  topUpRequiredMessageTranslations: Record<string, string>;
  standardExchangeTitleTranslations: Record<string, string>;
  standardExchangeDescriptionTranslations: Record<string, string>;
  bonusCreditExchangeTitleTranslations: Record<string, string>;
  bonusCreditExchangeDescriptionTranslations: Record<string, string>;
  standardStoreCreditTitleTranslations: Record<string, string>;
  standardStoreCreditDescriptionTranslations: Record<string, string>;
  bonusCreditStoreCreditTitleTranslations: Record<string, string>;
  bonusCreditStoreCreditDescriptionTranslations: Record<string, string>;
  refundTitleTranslations: Record<string, string>;
  refundDescriptionTranslations: Record<string, string>;
  backgroundImageDerivatives?: {
    jpg: {
      small: string;
      medium: string;
      large: string;
    };
    webp: {
      small: string;
      medium: string;
      large: string;
    };
  };
  mobileBackgroundImageDerivatives?: {
    jpg: {
      small: string;
      medium: string;
      large: string;
    };
    webp: {
      small: string;
      medium: string;
      large: string;
    };
  };
  favicon?: string;
  logoImageDerivatives?: {
    png: {
      navbar: string;
    };
    webp: {
      navbar: string;
    };
  };
  hideOpmRefundAmount: boolean;
}

interface P2PSetting {
  promotionalDescription: string;
}

export interface CurrentShop {
  id: number | null;
  allowCustomTrackingNumber: boolean;
  allowExchange: boolean;
  allowRefund: boolean;
  autoHideValidShippingAddress: boolean;
  country: string;
  currency: string;
  customerSupportEmail: string;
  customTrackingNumberRegexes: string;
  discardedStripeAccountId: string;
  discardedStripeChargesEnabled: boolean;
  discardedStripeParentAccountId: string;
  ecomProvider: string;
  exchangeRequestDisclaimer: string;
  exchangeBalanceRefundMethod: string;
  exchangeSetting: {
    productImageObjectFit: string;
    hideCollectionProductsCount: boolean;
    sizeGuideUrl: string;
  };
  exchangeV2Enabled: boolean;
  hasPreprintedReturnLabel: boolean;
  instantExchangeEnabled: boolean;
  allowInstantExchangeOnly: boolean;
  minimumExchangeQuantity: number;
  name: string;
  onlyReturnFromShippingCountry: boolean;
  returnPolicyLink: string;
  returnReasons: ReturnReason[];
  collectPaymentMethodOnSubmit: boolean;
  shopDomain: string;
  shopLocales: ShopLocale[];
  shopSlug: string;
  shopUrl: string;
  stripeAccountId: string;
  stripeChargesEnabled: boolean;
  stripeParentAccountId: string;
  stripePendingOnboarding: boolean;
  userInterfaceSetting: UserInterfaceSetting;
  whiteLabel: boolean;
  returnItemsSelectionDisclaimer: string;
  p2pSetting: P2PSetting | null;
  allowGiftReturns: boolean;
}

export const setCurrentShop = (currentShop: CurrentShop): SetCurrentShopAction => {
  return {
    type: ActionTypes.setCurrentShop,
    payload: currentShop,
  };
};

export const getAndSetCurrentShop = (shopSlug: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    if (shopSlug) {
      try {
        dispatch(setLoading());
        const shopExtraFields = [
          "stripe_account_id",
          "stripe_parent_account_id",
          "stripe_pending_onboarding",
          "stripe_charges_enabled",
          "discarded_stripe_account_id",
          "discarded_stripe_parent_account_id",
          "discarded_stripe_charges_enabled",
          "auto_hide_valid_shipping_address",
          "p2p_setting",
        ];
        const query = {
          filter: {
            url_name: {
              eq: shopSlug,
            },
          },
          include: "return_reasons,shop_locales,exchange_setting,user_interface_setting",
          fields: {
            return_reasons: "name,image_upload,extra_note",
            exchange_settings: "product_image_object_fit,hide_collection_products_count,size_guide_url",
            user_interface_setting: [
              "id",
              "custom_css",
              "order_email_phone_label_translations",
              "order_email_phone_placeholder_translations",
              "order_id_label_translations",
              "order_id_placeholder_translations",
              "primary_color",
              "start_button_name_translations",
              "primary_button_text_color",
              "success_page_content",
              "title_translations",
              "return_reason_image_upload_label_translations",
              "return_reason_extra_note_label_translations",
              "top_up_required_message_translations",
              "standard_exchange_title_translations",
              "standard_exchange_description_translations",
              "bonus_credit_exchange_title_translations",
              "bonus_credit_exchange_description_translations",
              "standard_store_credit_title_translations",
              "standard_store_credit_description_translations",
              "bonus_credit_store_credit_title_translations",
              "bonus_credit_store_credit_description_translations",
              "refund_title_translations",
              "refund_description_translations",
              "background_image_derivatives",
              "mobile_background_image_derivatives",
              "logo_image_derivatives",
              "favicon",
              "hide_opm_refund_amount",
            ].join(","),
          },
          extra_fields: {
            shops: shopExtraFields.join(","),
          },
        };
        const queryString = qs.stringify(query, { arrayFormat: "brackets" });
        const response: AxiosResponse<any> = await PostCo360API().get(`/api/v2/shops.json?${queryString}`);

        if (response.data.data.length !== 0) {
          const camelizedResponse = camelcaseKeys(response.data.data[0], { deep: true });

          const {
            id,
            allowCustomTrackingNumber,
            allowExchange,
            allowRefund,
            autoHideValidShippingAddress,
            backgroundImageDerivatives,
            mobileBackgroundImageDerivatives,
            country,
            currency,
            customCss,
            customerSupportEmail,
            customTrackingNumberRegexes,
            discardedStripeAccountId,
            discardedStripeChargesEnabled,
            discardedStripeParentAccountId,
            ecomProvider,
            exchangeRequestDisclaimer,
            exchangeBalanceRefundMethod,
            exchangeSetting,
            exchangeV2Enabled,
            hasPreprintedReturnLabel,
            instantExchangeEnabled,
            allowInstantExchangeOnly,
            minimumExchangeQuantity,
            name,
            onlyReturnFromShippingCountry,
            orderEmailPhoneLabelTranslations,
            orderEmailPhonePlaceholderTranslations,
            orderIdLabelTranslations,
            orderIdPlaceholderTranslations,
            returnPolicyLink,
            returnReasons,
            collectPaymentMethodOnSubmit,
            shopDomain,
            shopLocales,
            shopUrl,
            startButtonNameTranslations,
            primaryButtonTextColor,
            stripeAccountId,
            stripeChargesEnabled,
            stripeParentAccountId,
            stripePendingOnboarding,
            successPageContent,
            titleTranslations,
            urlName,
            userInterfaceSetting,
            whiteLabel,
            returnItemsSelectionDisclaimer,
            p2pSetting,
            allowGiftReturns,
          } = camelizedResponse;

          const shopData = {
            id: Number(id),
            allowCustomTrackingNumber,
            allowExchange,
            allowRefund,
            autoHideValidShippingAddress,
            backgroundImageDerivatives,
            mobileBackgroundImageDerivatives,
            country,
            currency,
            customCss,
            customerSupportEmail,
            customTrackingNumberRegexes,
            discardedStripeAccountId,
            discardedStripeChargesEnabled,
            discardedStripeParentAccountId,
            ecomProvider,
            exchangeRequestDisclaimer,
            exchangeBalanceRefundMethod,
            exchangeSetting,
            exchangeV2Enabled,
            hasPreprintedReturnLabel,
            instantExchangeEnabled,
            allowInstantExchangeOnly,
            minimumExchangeQuantity,
            name,
            onlyReturnFromShippingCountry,
            orderEmailPhoneLabelTranslations,
            orderEmailPhonePlaceholderTranslations,
            orderIdLabelTranslations,
            orderIdPlaceholderTranslations,
            primaryButtonTextColor,
            returnPolicyLink,
            returnReasons,
            collectPaymentMethodOnSubmit,
            shopDomain,
            shopLocales,
            shopSlug: urlName,
            shopUrl,
            startButtonNameTranslations,
            stripeAccountId,
            stripeChargesEnabled,
            stripeParentAccountId,
            stripePendingOnboarding,
            successPageContent,
            titleTranslations,
            userInterfaceSetting,
            whiteLabel,
            returnItemsSelectionDisclaimer,
            p2pSetting,
            allowGiftReturns,
          };

          dispatch(setCurrentShop(shopData));
          dispatch(setInitialLocale(shopLocales));

          if (userInterfaceSetting.primaryColor) {
            document.documentElement.style.setProperty("--primary-color", userInterfaceSetting.primaryColor);
            dispatch(setPrimaryColor(userInterfaceSetting.primaryColor));
          }

          if (userInterfaceSetting.favicon) {
            dispatch(setFaviconUrl(new URL(userInterfaceSetting.favicon, document.location.origin)));
          }

          const parsedlogoImageDerivatives = Object.keys(userInterfaceSetting.logoImageDerivatives).reduce<any>(
            (acc, mimeType) => {
              acc[mimeType] = Object.keys(userInterfaceSetting.logoImageDerivatives[mimeType]).reduce<any>(
                (acc1, derivative) => {
                  acc1[derivative] = new URL(userInterfaceSetting.logoImageDerivatives[mimeType][derivative]);
                  return acc1;
                },
                acc[mimeType],
              );

              return acc;
            },
            { webp: { navbar: null }, png: { navbar: null } },
          );

          dispatch(setLogoImageUrls(parsedlogoImageDerivatives));

          const parsedBackgroundImageDerivatives = Object.keys(
            userInterfaceSetting.backgroundImageDerivatives,
          ).reduce<any>(
            (acc, mimeType) => {
              acc[mimeType] = Object.keys(userInterfaceSetting.backgroundImageDerivatives[mimeType]).reduce<any>(
                (acc1, size) => {
                  acc1[size] = new URL(userInterfaceSetting.backgroundImageDerivatives[mimeType][size]);
                  return acc1;
                },
                acc[mimeType],
              );

              return acc;
            },
            { webp: { small: null, medium: null, large: null }, jpg: { small: null, medium: null, large: null } },
          );

          dispatch(setBackgroundImageUrls(parsedBackgroundImageDerivatives as BackgroundImageUrls));

          const parsedMobileBackgroundImageDerivatives = Object.keys(
            userInterfaceSetting.mobileBackgroundImageDerivatives,
          ).reduce<any>(
            (acc, mimeType) => {
              acc[mimeType] = Object.keys(userInterfaceSetting.mobileBackgroundImageDerivatives[mimeType]).reduce<any>(
                (acc1, size) => {
                  acc1[size] = new URL(userInterfaceSetting.mobileBackgroundImageDerivatives[mimeType][size]);
                  return acc1;
                },
                acc[mimeType],
              );

              return acc;
            },
            { webp: { small: null, medium: null, large: null }, jpg: { small: null, medium: null, large: null } },
          );
          dispatch(setMobileBackgroundImageUrls(parsedMobileBackgroundImageDerivatives as BackgroundImageUrls));

          dispatch(setTitle(userInterfaceSetting.titleTranslations));
          dispatch(clearLoading());
          dispatch(clearConfigLoading());
        } else {
          dispatch(clearLoading());
          dispatch(clearConfigLoading());
        }
      } catch (error) {
        if (error instanceof Error) dispatch(setError(error));
        dispatch(clearLoading());
        dispatch(clearConfigLoading());
      }
    }
  };
};

export const setShopSlug = (shopSlug: string): SetShopSlugAction => {
  return {
    type: ActionTypes.setShopSlug,
    payload: shopSlug,
  };
};
