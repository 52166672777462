import { COUNTRIES_DATA, State, District } from "./constants";
import { ShippingAddress } from "actions";
const countryParser = require("@ejarnutowski/country-parser");

type Option = { text: string; value: string };

const buildOptions = (options: string[]): Option[] => options.map((option) => ({ text: option, value: option }));

export const getCountryOptions = (): Option[] => {
  const country_names = COUNTRIES_DATA.map((country) => country.name);
  return country_names ? buildOptions(country_names) : [];
};

const getStates = (country: string): State[] => {
  const selectedCountry = COUNTRIES_DATA.find((selectedCountry) => selectedCountry.name === country);

  return selectedCountry ? selectedCountry.states : [];
};

export const getStateOptions = (country: string): Option[] =>
  buildOptions(getStates(country).map((state) => state.name));

const getDistricts = (country: string, selectedState: string): District[] => {
  const state = getStates(country).find((state: State) => state.name === selectedState);
  return state ? state.districts : [];
};

export const getDistrictOptions = (country: string, selectedState: string): Option[] => {
  const districts = getDistricts(country, selectedState);
  const district_names = districts ? districts.map((d) => d.name) : [];
  return buildOptions(district_names);
};

export const getWardOptions = (country: string, selectedState: string, selectedDistrict?: string): Option[] => {
  const districts = getDistricts(country, selectedState);
  const district = districts.find((district) => district.name === selectedDistrict);

  const wards = district ? district.wards : [];

  return buildOptions(wards);
};

function sanitizeVietnamAddress(address: ShippingAddress) {
  const { country, province, district, ward } = address;
  // clear zip and city as Vietnam do not use it
  address["zip"] = "";
  address["city"] = "";

  let provinceValid: boolean = false;
  let districtValid: boolean = false;
  let wardValid: boolean = false;

  provinceValid = !!getStates(country).find((state) => state.name === province);

  if (provinceValid) {
    const district_data = getDistricts(country, province).find((d) => d.name === district);
    districtValid = !!district_data;

    if (district_data) wardValid = !!district_data.wards.find((w) => w === ward);
  }

  if (!provinceValid) address["province"] = "";
  if (!districtValid) address["district"] = "";
  if (!wardValid) address["ward"] = "";

  return address;
}

export const sanitizeAddress = (address: ShippingAddress): ShippingAddress => {
  // parse country code if the country is a country code
  if (address.country.length === 2) {
    const countryName = countryParser.getName(address.country, true);
    if (countryName) address = { ...address, country: countryName };
  }

  if (address.country === "Vietnam") return sanitizeVietnamAddress(address);

  return { ...address, zip: address.zip || "" };
};
