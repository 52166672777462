import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, compose, createStore } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { setupSentry } from "initializers";
import whyDidYouRender from "@welldone-software/why-did-you-render";
import App from "./App";
import { reducers } from "./reducers";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import cssVars from "css-vars-ponyfill";
import "url-search-params-polyfill";
import "normalize.css";
import "semantic-ui-css/semantic.css";
import "./index.css";
import "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import { EmbeddedAppIosScrollChainingSupport } from "EmbeddedAppIosScrollChainingSupport";
cssVars();

if (process.env.NODE_ENV === "development") {
  // const ReactRedux = require("react-redux");
  whyDidYouRender(React, {
    // Change trackAllPureComponents to true if you want to track
    trackAllPureComponents: false,
  });
}
const devToolsExtension: Function = (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  : (f: any) => f;

export const store = createStore(reducers, compose(applyMiddleware(thunk), devToolsExtension));

const history = createBrowserHistory();
setupSentry(history);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: 1,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Suspense fallback={null}>
        <Router history={history}>
          <EmbeddedAppIosScrollChainingSupport>
            <App />
          </EmbeddedAppIosScrollChainingSupport>
        </Router>
      </Suspense>
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
