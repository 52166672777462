import Uppy, { UppyOptions } from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import Compressor from "@uppy/compressor";

export const createUppy = () => {
  const uppyConfig: UppyOptions = {
    autoProceed: true,
    restrictions: {
      allowedFileTypes: ["image/jpeg", "image/png", "image/heic", "image/heif", "image/webp", "image/avif"],
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
    },
  };

  const awsS3Config = {
    timeout: 60_000,
    companionUrl: `${process.env.REACT_APP_SERVER_URL}/`, // default to /s3/params
  };

  return new Uppy(uppyConfig).use(Compressor).use(AwsS3, awsS3Config);
};
