import React from "react";
import { isMobile } from "react-device-detect";
import { Header, Modal } from "semantic-ui-react";
import { Co2WarningIcon } from "./Co2WarningIcon";
import { ConnectorLine } from "./ConnectorLine";
import { RenewIcon } from "./RenewIcon";
import { RewardIcon } from "./RewardIcon";

export const LearnMoreModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  return (
    <Modal
      dimmer="inverted"
      open={open}
      size="small"
      onClose={onClose}
      closeOnDimmerClick
      style={{ padding: isMobile ? "16px 24px" : "64px 96px", fontSize: "16px" }}
    >
      <Modal.Content style={{ padding: 0 }}>
        <img
          src="https://360-static-assets.postco.co/close.png"
          alt="close the learn more modal"
          style={{ height: "16px", display: "block", marginLeft: "auto", cursor: "pointer", overflowX: "hidden" }}
          onClick={onClose}
        />
        <Header
          as="h1"
          style={{
            fontSize: isMobile ? "1.5em" : "2em",
            marginBottom: ".5em",
            padding: 0,
          }}
        >
          <img
            src="https://360-static-assets.postco.co/P2P/renew_logo_with_text.svg"
            alt="reNEW returns"
            style={{
              height: "1lh",
              width: "auto",
              margin: 0,
            }}
          />{" "}
          returns
        </Header>

        <p style={{ fontSize: "1em", color: "#8A8A8A", fontWeight: 300, marginBottom: "1em" }}>
          A new sustainable way to return
        </p>

        <div style={{ display: "flex", flexDirection: "column", fontWeight: 300 }}>
          <div style={{ display: "flex", alignItems: "stretch" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <ConnectorLine color="#FDC465" dashed />
              <Co2WarningIcon />
              <ConnectorLine color="#FDC465" dashed />
            </div>
            <p style={{ margin: isMobile ? "16px" : "36px", marginRight: 0 }}>
              <b style={{ fontWeight: "bold", fontSize: "1em" }}>More returns = more CO2 emissions</b>
              <br />
              24 million tons of CO2 are emitted from billions of returns every year. That’s about 5.1 million cars
              driven for one year!
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "stretch" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <ConnectorLine color="#FDC465" dashed />
              <RenewIcon />
              <ConnectorLine color="#519872" />
            </div>
            <p style={{ margin: isMobile ? "16px" : "36px", marginRight: 0 }}>
              <b style={{ fontWeight: "bold", fontSize: "1em" }}>Ship your returns directly to the next buyer </b>
              <br />
              to reduce CO2 emissions. We’ll just need you to hold on to your returns until we find them a new home.
            </p>
          </div>
          <div style={{ display: "flex", alignItems: "stretch" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <ConnectorLine color="#519872" />
              <RewardIcon />
              <ConnectorLine color="rgb(0,0,0,0)" />
            </div>
            <p style={{ margin: isMobile ? "16px" : "36px", marginRight: 0 }}>
              <b style={{ fontWeight: "bold", fontSize: "1em" }}>Ship your returns directly to the next buyer </b>
              <br />
              to reduce CO2 emissions. We’ll just need you to hold on to your returns until we find them a new home.
            </p>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
