import React, { Fragment } from "react";
import styles from "./CheckboxHelpText.module.css";

interface CheckboxHelpTextProps {
  info1: string;
  info2: string;
}

export const CheckboxHelpText: React.FC<CheckboxHelpTextProps> = ({ info1, info2 }) => {
  return (
    <Fragment>
      <ol>
        <li>{info1}</li>
        <li>{info2}</li>
      </ol>
      <div className={styles.checkboxHelpText}>
        <p className={styles.textDanger}>*Failing to do so may result in unsuccessful pick-up.</p>
      </div>
    </Fragment>
  );
};
