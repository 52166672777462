import React from "react";
import { InstantExchangeSection, RefundSection } from ".";
import { ExchangeOptionsSection } from "..";
import { isMobile } from "react-device-detect";
import { Divider } from "semantic-ui-react";
import { ExchangeOrderSection } from "./ExchangeOrderSection";
import { useTranslation } from "react-i18next";
import { Header } from "semantic-ui-react";
import { useInvoiceContext } from "contexts/InvoiceProvider";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";

export const InstantExchangeOverview: React.FC = (): JSX.Element => {
  const { p2pRequestInfo } = useInvoiceContext();

  const { t } = useTranslation("orderFlow");

  const returnOrderStatus = useSelector((store: StoreState) => store.order.status);
  const isPostSubmission = !["draft", "update_draft"].includes(returnOrderStatus);

  const showHeader = !isMobile && (!p2pRequestInfo.requestable || isPostSubmission);

  return (
    <div style={{ padding: isMobile ? 0 : "1rem" }}>
      {showHeader && <Header>{t("invoiceSummary.header")}</Header>}

      <RefundSection />
      <ExchangeOrderSection />
      <ExchangeOptionsSection />
      <Divider />
      <InstantExchangeSection />
    </div>
  );
};
