import React from "react";
import { Header } from "semantic-ui-react";
import { Button } from "components";
import styles from "./QuestionAnswerForm.module.css";
import { useQuestionFormContext } from "contexts/QuestionFormProvider";
import { AnswerOptions } from "./AnswerOptions";
import { AnswerTextArea } from "./AnswerTextArea";
import { useTranslation } from "react-i18next";
import { capitalize } from "helpers";

interface QuestionAnswerFormInterface {
  buttonLoading?: boolean;
  handleContinue: Function;
}

export const QuestionAnswerForm: React.FC<QuestionAnswerFormInterface> = ({
  buttonLoading = false,
  handleContinue,
}) => {
  const { t } = useTranslation("orderFlow");

  const { questions, page, setPage } = useQuestionFormContext();
  const MAX_PAGE = questions.length - 1;
  const { title, type, answers, optional } = questions[page];
  const isTextQuestion = type === "text";

  const buttonDisabled = () => {
    if (optional) {
      return false;
    } else if (type === "text") {
      const typedAnswer = answers[0];
      return typedAnswer === undefined || typedAnswer?.trim()?.length === 0;
    } else {
      return answers.length === 0;
    }
  };

  function getHelpText() {
    switch (type) {
      case "single_choice":
        return t("question.form.singleChoiceHelpText");
      case "multiple_choice":
        return t("question.form.multipleChoiceHelpText");
      default:
        return "";
    }
  }

  return (
    <>
      <div className={styles.headerContainer}>
        <Header as="h2" textAlign="center">
          {title}
        </Header>
        {!isTextQuestion && (
          <div className={styles.helptext}>
            {optional ? `${getHelpText()} (${capitalize(t("optional"))})` : getHelpText()}
          </div>
        )}
      </div>

      {isTextQuestion ? <AnswerTextArea /> : <AnswerOptions />}

      <div style={{ padding: "0.5rem 0", paddingTop: "2rem" }}>
        <Button
          color="primaryColor"
          fluid
          loading={buttonLoading}
          disabled={buttonDisabled() || buttonLoading}
          onClick={() => (page === MAX_PAGE ? handleContinue() : setPage(page + 1))}
        >
          {page === MAX_PAGE ? t("question.form.continue") : t("question.form.next")}
        </Button>
      </div>
    </>
  );
};
