import React, { useEffect, useState } from "react";
import { Loading } from "components";
import { Modal } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { useP2PProductListings } from "hooks/useP2PProductListings";
import { NotFound } from "pages";
import { ContinuousImageUpload } from "./components/ContinuousImageUpload";
import { ImageUploadSummary } from "./components/ImageUploadSummary";
import { AdaptiveModal } from "components/AdaptiveModal";

export const P2PProductListingPage = () => {
  const { productListingId } = useParams();
  const { productListings, isLoading, isSuccess } = useP2PProductListings({ staleTime: Infinity });

  const productListing = productListings?.find((productListing) => productListing.id === Number(productListingId));

  const [showContinuousImageCapture, setShowContinuousImageCapture] = useState<
    "is determining" | "from first unfulfilled" | number | "don't show"
  >("is determining");

  useEffect(() => {
    if (isSuccess) {
      if (productListing?.imageUploadRequirement.instructions.some((instruction) => !instruction.image)) {
        setShowContinuousImageCapture("from first unfulfilled");
      } else {
        setShowContinuousImageCapture("don't show");
      }
    }
  }, [isSuccess]);

  if (isLoading || showContinuousImageCapture === "is determining") {
    return (
      <AdaptiveModal>
        <Modal.Content style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Loading />
        </Modal.Content>
      </AdaptiveModal>
    );
  }

  if (!productListing) {
    return <NotFound />;
  }

  if (showContinuousImageCapture !== "don't show") {
    return (
      <ContinuousImageUpload
        show={showContinuousImageCapture}
        onFinish={() => setShowContinuousImageCapture("don't show")}
      />
    );
  }

  return <ImageUploadSummary />;
};
