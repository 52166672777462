import React from "react";
import { Card, Header } from "semantic-ui-react";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import { useStripePromise } from "helpers";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { PaymentIntentForm, Container } from ".";
import { Loading } from "components";
import { useTranslation } from "react-i18next";
import { StripeElementLocale } from "@stripe/stripe-js";
import { setError } from "actions";
import { usePaymentPageContext } from "../PaymentPageProvider";

export const PaymentDetails: React.FC<any> = (): JSX.Element => {
  const currentShop = useSelector((state: StoreState) => state.currentShop);
  const stripePromise = useStripePromise(currentShop, true);
  const { usePaymentIntent } = usePaymentPageContext();
  const { clientSecret, isLoading, isError, error } = usePaymentIntent();
  const { t } = useTranslation("orderFlow");
  const locale = useSelector((state: StoreState) => state.locale.code);
  const dispatch = useDispatch();

  const stripeOptions: StripeElementsOptions = {
    clientSecret,
    locale: locale as StripeElementLocale,
  };

  if (isLoading || isError) {
    error && dispatch(setError(error as Error));
    return <Container>{isLoading ? <Loading /> : <Header>{t("exchangePaymentPage.error")}</Header>}</Container>;
  }

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <Card fluid style={{ padding: "1rem" }}>
        <Header>{t("exchangePaymentPage.payment")}</Header>
        <br />
        <PaymentIntentForm />
      </Card>
    </Elements>
  );
};
