import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Button, PageHeader } from "components";
import {
  ReturnSteps,
  setShippingMethod,
  setError,
  setCustomerBorneShipping,
  updateReturnOrderThenContinue,
  setFromAddress,
} from "actions";
import styles from "./ShippingMethodSelector.module.css";
import { StoreState } from "reducers";
import { Description } from "./components";
import { DropOffModal } from "components/DropOffModal/DropOffModal";
import { PickupModal } from "components/PickupModal/PickupModal";
import { useQuery, useQueryClient } from "react-query";
import camelcaseKeys from "camelcase-keys";
import { ShippingMethod } from "types";
import { getShippingMethods } from "./api";
import { createUUID } from "helpers";
import { Loading } from "components/Loading/Loading";
import { nullShippingMethod } from "./constants";
import { useHistory } from "react-router-dom";
import { useCheckCurrentStep } from "helpers/currentStep";
import { useIsEmbedded } from "hooks/useIsEmbedded";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import { StickyBottomAppBar } from "StickyBottomAppBar";
import { ContinueBottomAppBar } from "components/ContinueBottomAppBar";
import { Stepper } from "components/Stepper";

const listingPriorities = (method: ShippingMethod) => {
  switch (method.type) {
    case "InStoreShippingMethod":
      return 1;
    case "IntegratedShippingMethod":
      switch (method.serviceType) {
        case "DropOff":
          return 3;
        case "OnDemandPickup":
        case "Pickup":
          return 2;
        default:
          return 6;
      }
    case "MailShippingMethod":
      return 5;
    case "CustomShippingMethod":
      return 4;
  }
};

export type ModalType = "pickupModal" | "none" | "dropOffModal";

export const ShippingMethodSelector: React.FC = () => {
  const currentStep = ReturnSteps.shippingMethod;
  const customerSupportEmail = useSelector((state: StoreState) => state.currentShop.customerSupportEmail);
  const { name: shopName, country } = useSelector((state: StoreState) => state.currentShop);
  const shopId = useSelector((state: StoreState) => state.currentShop.id) as number;
  const returnOrderId = useSelector((state: StoreState) => state.order.returnOrderId);
  const shippingAddress = useSelector((state: StoreState) => state.order.shippingAddress);
  const orderEmail = useSelector((state: StoreState) => state.order.email);

  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  function sortShippingMethods(shippingMethods: ShippingMethod[]) {
    return shippingMethods.sort((a: ShippingMethod, b: ShippingMethod) => {
      return listingPriorities(a) - listingPriorities(b);
    });
  }

  const { t } = useTranslation("orderFlow");
  const { isEmbedded } = useIsEmbedded();

  const [openModal, setOpenModal] = useState<ModalType>("none");
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([]);
  const [hasShippingMethods, setHasShippingMethods] = useState<boolean>(false);
  const [selected, setSelected] = React.useState<ShippingMethod>(nullShippingMethod);

  useCheckCurrentStep(ReturnSteps.shippingMethod);

  useEffect(() => {
    queryClient.removeQueries("pickupFee");
    queryClient.removeQueries("dropOffFee");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmbedded) {
      window.top?.postMessage({ type: "scrollToTop" }, "*");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const dropOffFeeQueryState = queryClient.getQueryState<{ cents: number; currencyIso: string }>([
    "dropOffFee",
    selected.id,
  ]);
  const pickupFeeQueryState = queryClient.getQueryState<{ cents: number; currencyIso: string }>([
    "pickupFee",
    selected.id,
  ]);
  const dropOffFeeSet = dropOffFeeQueryState ? dropOffFeeQueryState.status === "success" : false;
  const pickupFeeSet = pickupFeeQueryState ? pickupFeeQueryState.status === "success" : false;

  const { isFetching, isLoading } = useQuery({
    queryKey: ["shippingMethods", shopId, returnOrderId],
    queryFn: () => getShippingMethods(shopId, returnOrderId),
    onSuccess: (response: any) => {
      try {
        if (response.data.length > 0) {
          const responseData = camelcaseKeys(response.data, { deep: true }).map((response: any) => {
            return {
              uuid: createUUID(),
              ...response,
            };
          });
          const sortedShippingMethods = sortShippingMethods(responseData);
          setShippingMethods(sortedShippingMethods);
          setHasShippingMethods(true);
          setInitialLoading(false);
          setSelected(sortedShippingMethods[0]);
        } else {
          setShippingMethods([]);
          setHasShippingMethods(false);
          setInitialLoading(false);
          throw Error(
            `Please reach out to our support team at ${customerSupportEmail} to help you with this return request.`,
          );
        }
      } catch (err) {
        dispatch(setError(err));
      }
    },
    onError: (error: Error) => {
      setShippingMethods([]);
      setHasShippingMethods(false);
      setInitialLoading(false);
      dispatch(setError(error));
    },
  });

  const nextStepAndRedirect = () => {
    dispatch(updateReturnOrderThenContinue(history, currentStep));
  };

  const handleContinue = (): void => {
    if (!selected.id) return;
    const customerBorneShipping = selected.customerBorne;
    dispatch(setCustomerBorneShipping(customerBorneShipping));
    dispatch(setShippingMethod(selected));

    if (selected.courier?.name === "Monta") {
      dispatch(
        setFromAddress({
          ...shippingAddress,
          email: shippingAddress.email || (orderEmail as string),
        }),
      );
      nextStepAndRedirect();
    } else if (["Pickup", "OnDemandPickup"].includes(selected.serviceType)) {
      pickupFeeSet ? nextStepAndRedirect() : setOpenModal("pickupModal");
      return;
    } else if (selected.serviceType === "DropOff") {
      if (dropOffFeeSet) {
        nextStepAndRedirect();
      } else {
        setOpenModal("dropOffModal");
      }
      return;
    }
    nextStepAndRedirect();
  };

  const setButtonColor = (method: ShippingMethod): "green" | undefined => {
    if (selected === method) return "green";
  };

  function handleDescriptionBlockOnClick(method: ShippingMethod) {
    setSelected(method);
  }

  const {
    featureFlags: { stickyButtons },
  } = useFeatureFlags();

  const ProgressBarSection = () => (
    <div style={{ display: "flex" }}>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "space-around",
          margin: isEmbedded ? "16.5px" : 0,
        }}
      >
        <Stepper totalStepCount={3} currentStepCount={2} />
      </div>
    </div>
  );

  return isLoading || isFetching || initialLoading || !selected ? (
    <Loading />
  ) : stickyButtons ? (
    <div className={styles.shippingMethodSelectorContainer}>
      <Grid centered container padded="vertically">
        <Grid.Column
          mobile={16}
          tablet={13}
          computer={10}
          style={{
            marginBottom: isEmbedded ? "100px" : 0,
          }}
        >
          <ProgressBarSection />
          <PageHeader header={t("selectMethod")} />
          {shippingMethods.map((method: ShippingMethod): JSX.Element => {
            return (
              <div className={styles.buttonContainer} key={method.id}>
                <Button
                  basic
                  color={setButtonColor(method)}
                  fluid
                  onClick={() => handleDescriptionBlockOnClick(method)}
                >
                  <div className={styles.buttonContent}>
                    <Description method={method} openModal={openModal} country={country} shopName={shopName} />
                    <Icon
                      color="green"
                      name="check circle"
                      style={{
                        visibility: selected?.uuid === method.uuid ? "visible" : "hidden",
                      }}
                    />
                  </div>
                </Button>
              </div>
            );
          })}
        </Grid.Column>
      </Grid>
      {shippingMethods.length > 0 && (
        <div className={styles.submitButtonContainer}>
          <StickyBottomAppBar>
            <ContinueBottomAppBar
              helperText={t("shippingMethodSelectorPage.selectReturnShipping")}
              action={{ label: t("continue"), onClick: handleContinue, disabled: !hasShippingMethods }}
            />
          </StickyBottomAppBar>
        </div>
      )}
      <PickupModal shippingMethod={selected} openModal={openModal} setOpenModal={setOpenModal} />
      <DropOffModal
        method={selected}
        isOpen={openModal === "dropOffModal"}
        onClose={() => {
          setOpenModal("none");
        }}
      />
    </div>
  ) : (
    <React.Fragment>
      <Grid centered container padded="vertically">
        <Grid.Column mobile={16} tablet={13} computer={10} style={{ marginBottom: isEmbedded ? "100px" : 0 }}>
          <ProgressBarSection />
          <PageHeader header={t("selectMethod")} />
          {shippingMethods.map((method: ShippingMethod): JSX.Element => {
            return (
              <div className={styles.buttonContainer} key={method.id}>
                <Button
                  basic
                  color={setButtonColor(method)}
                  fluid
                  onClick={() => handleDescriptionBlockOnClick(method)}
                >
                  <div className={styles.buttonContent}>
                    <Description method={method} openModal={openModal} country={country} shopName={shopName} />
                    <Icon
                      color="green"
                      name="check circle"
                      style={{
                        visibility: selected?.uuid === method.uuid ? "visible" : "hidden",
                      }}
                    />
                  </div>
                </Button>
              </div>
            );
          })}
          {shippingMethods.length > 0 && (
            <div className={styles.buttonContainer}>
              <Button color="primaryColor" disabled={!hasShippingMethods} fluid onClick={handleContinue}>
                {t("continueToNextStep")}
              </Button>
            </div>
          )}
        </Grid.Column>
      </Grid>
      <PickupModal shippingMethod={selected} openModal={openModal} setOpenModal={setOpenModal} />
      <DropOffModal
        method={selected}
        isOpen={openModal === "dropOffModal"}
        onClose={() => {
          setOpenModal("none");
        }}
      />
    </React.Fragment>
  );
};
