import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getInstantExchangePaymentIntent, getRegularExchangePaymentIntent } from "./api";

const nullRegularExchangePaymentIntent = {
  clientSecret: "",
  exchangeOrderId: "",
};

export const useInstantExchangePaymentIntent = () => {
  const { returnId: returnOrderId } = useParams<{ returnId: string }>();
  const { data, ...others } = useQuery({
    queryKey: ["InstantExchangeClientSecret", returnOrderId],
    queryFn: () => getInstantExchangePaymentIntent(returnOrderId),
    staleTime: Infinity,
  });

  const paymentIntent = data ? data : { clientSecret: "" };

  return { ...paymentIntent, ...others };
};

export const useRegularExchangePaymentIntent = (options = {}) => {
  const { returnId: returnOrderId } = useParams<{ returnId: string }>();
  const { data, ...others } = useQuery({
    queryKey: ["regularExchangeClientSecret", returnOrderId],
    queryFn: () => getRegularExchangePaymentIntent(returnOrderId),
    staleTime: Infinity,
    ...options,
  });
  const paymentIntent = data ? data : nullRegularExchangePaymentIntent;
  return { ...paymentIntent, ...others };
};
