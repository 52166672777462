import { Dispatch } from "redux";

import { ActionTypes } from "./types";

import { setLoading, clearLoading } from "./loading";
import { StoreState } from "reducers";
import { AxiosResponse } from "axios";
import { PostCo360API } from "api/constants";

export interface Holiday {
  date: string;
}

export interface AddHolidayAction {
  type: ActionTypes.addHoliday;
  payload: Holiday;
}

export const addHoliday = (holiday: Holiday): AddHolidayAction => {
  return {
    type: ActionTypes.addHoliday,
    payload: holiday,
  };
};

export const getAndSetHolidays = () => {
  return async (dispatch: Dispatch, getState: () => StoreState): Promise<void> => {
    dispatch(setLoading());
    const {
      currentShop: { country },
      holidays,
    } = getState();

    const response: AxiosResponse<any> = await PostCo360API().get(`/api/v2/holidays?filter[country][eq]=${country}`);

    if (response.data) {
      const data: {
        attributes: {
          date: string;
        };
      }[] = response.data.data;
      const holidayDate = holidays.map((h) => h.date);
      data.forEach(({ attributes: { date } }) => {
        if (!holidayDate.includes(date)) {
          dispatch(
            addHoliday({
              date,
            }),
          );
        }
      });
    }
    dispatch(clearLoading());
  };
};
